import InputLabel from '@material-ui/core/InputLabel'
import { makeStyles, Theme } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import SearchIcon from '@material-ui/icons/Search'
import Autocomplete, {
    AutocompleteInputChangeReason,
} from '@material-ui/lab/Autocomplete'
import clsx from 'clsx'
import RoundButton from 'components/Buttons/RoundButton'
import GridContainer from 'components/Surfaces/GridContainer'
import GridItem from 'components/Surfaces/GridItem'
import { pxToRem } from 'components/Utilities/unitUtils'
import React from 'react'
import { NormalizedProductType } from '../../model'
import { LeftSideProps } from './ContentContainer'
import { LeftSideListGroup } from './LeftSideListGroup'
import { LeftSideListItem } from './LeftSideListItem'
import { ProductTypesContext } from './ProductTypesFilterContext'

const useStyles = makeStyles((theme: Theme) => ({
    fullHeight: {
        height: '100%',
    },
    fullWidth: {
        width: '100%',
    },
    sidePadding: {
        paddingLeft: theme.spacing(1.5),
        paddingRight: theme.spacing(1.5),
    },
    body: {
        backgroundColor: '#5E5F61',
        paddingLeft: '0 !important',
        paddingRight: '0 !important',
    },
    header: {
        height: 'calc(20%)',
    },
    autoCompleteTextField: {
        backgroundColor: '#FFF',
        '&:hover': {
            backgroundColor: '#FFF',
        },
        '&:focused': {
            backgroundColor: '#FFF',
        },
        borderRadius: 5,
        borderColor: '#D0D0D0',
    },
    bodyWrapper: {
        height: 'calc(73%)',
    },
    bodyContainer: {
        overflowY: 'auto',
        overflowX: 'hidden',
    },
    footer: {
        height: 'calc(7%)',
    },
    clearButton: {
        '& div button.MuiButton-contained.Mui-disabled': {
            backgroundColor: '#95979c',
        },
        '& div button.MuiButton-contained': { color: '#5E5F61', height: 35 },
    },
    clearButtonText: {
        fontSize: pxToRem(13),
        fontWeight: 'bold',
    },
    popupIndicatorOpen: {
        transform: 'unset',
    },
    selectLabel: {
        '&.Mui-focused': {
            width: '100%',
        },
        width: '70%',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        color: '#5E5F61',
    },
    optionText: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        display: 'block',
    },
    inputName: {
        ...theme.typography.h3,
        fontWeight: 500,
        color: '#FFF',
        paddingTop: theme.spacing(2.25),
        paddingBottom: theme.spacing(2),
    },
}))

interface Group {
    [key: string]: NormalizedProductType[]
}

export const LeftSide: React.FC<LeftSideProps> = ({
    productTypeOptions,
    debounceInput,
    setProductType,
    loadingSelectedProductType,
}) => {
    const classes = useStyles()
    const {
        handleAutoCompleteOpen,
        handleAutoCompleteClose,
        handleAutoCompleteChange,
        handleAutoCompleteRemove,
        handleClearProductTypes,
        handleAutoCompleteInputChange,
        productTypeSelections,
        loadingAutoSuggest: loading,
    } = React.useContext(ProductTypesContext)

    const [inputValue, setInputValue] = React.useState<string>('')
    const [listBoxHeight, setListBoxHeight] = React.useState<string | number>(
        '100%'
    )
    const [loadingOverride, setLoadingOverride] = React.useState<boolean>(false)

    const bodyRef = React.useRef<HTMLDivElement>(null)
    const footerRef = React.useRef<HTMLDivElement>(null)

    const handleOpen = () => {
        handleAutoCompleteOpen()
        setLoadingOverride(true)
    }

    const handleClose = () => {
        handleAutoCompleteClose()
        setInputValue('')
        handleAutoCompleteInputChange('')
    }

    const handleChange = (
        _: React.ChangeEvent<{}>,
        value: NormalizedProductType | null
    ) => {
        if (value) {
            handleAutoCompleteChange(value)
        }
    }

    const handleInputChange = (
        _: React.ChangeEvent<{}>,
        value: string,
        reason: AutocompleteInputChangeReason
    ) => {
        if (reason !== 'reset') {
            debounceInput(value)
            setInputValue(value)
            setLoadingOverride(true)
        }
    }

    const handleRemove = (value: NormalizedProductType) => {
        if (!loadingSelectedProductType) {
            handleAutoCompleteRemove(value)
        }
    }

    const handleSelect = (value: NormalizedProductType) => {
        if (!loadingSelectedProductType) {
            setProductType({ ...value, selected: true })
        }
    }

    const onEvent = React.useCallback(() => {
        if (bodyRef.current && footerRef.current) {
            setListBoxHeight(
                bodyRef.current.getBoundingClientRect().height +
                    footerRef.current.getBoundingClientRect().height
            )
        }
    }, [bodyRef, footerRef])

    React.useEffect(onEvent)

    React.useEffect(() => {
        window.addEventListener('resize', onEvent)
        return () => {
            window.removeEventListener('resize', onEvent)
        }
    }, [onEvent])

    const groups: Group = productTypeSelections?.reduce((r, a) => {
        r[a.sectionNameWithoutNmber!] = r[a.sectionNameWithoutNmber!] || []
        r[a.sectionNameWithoutNmber!].push(a)
        return r
    }, Object.create(null))

    React.useEffect(() => {
        if (loading) {
            setLoadingOverride(false)
        }
    }, [loading])

    return (
        // This is an item of ContentComponent
        <GridContainer
            className={clsx('dvLeftSide', classes.body, classes.fullHeight)}
            direction="column"
            item
            xs={5}
            sm={4}
            md={3}
        >
            <GridItem className={clsx(classes.sidePadding, classes.header)}>
                <InputLabel
                    htmlFor="dvProductTypesFilterList"
                    className={classes.inputName}
                >
                    Search Product Types
                </InputLabel>
                <input
                    style={{ display: 'none' }}
                    type="text"
                    name="fakedvProductTypesFilterList"
                />
                <Autocomplete<NormalizedProductType, false, true>
                    id="dvProductTypesFilterList"
                    key={productTypeSelections?.length || 0}
                    data-testid="dvProductTypesFilterList"
                    value={undefined}
                    options={productTypeOptions.sort(
                        (a, b) =>
                            -b.sectionNameWithoutNmber!.localeCompare(
                                a.sectionNameWithoutNmber!
                            )
                    )}
                    getOptionLabel={(option) => option.productTypeName ?? ''}
                    getOptionDisabled={(option) =>
                        productTypeSelections.some(
                            (each) => option.value === each.value
                        )
                    }
                    renderOption={(option) => (
                        <Typography
                            variant="caption"
                            component="span"
                            className={classes.optionText}
                            key={`opt${option.value}`}
                        >
                            {option.productTypeName}
                        </Typography>
                    )}
                    renderTags={() => null}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            placeholder="Search Product Types"
                            variant="outlined"
                            size="small"
                            className={clsx(
                                'autocompleteProductType',
                                classes.autoCompleteTextField
                            )}
                            InputLabelProps={{
                                ...params.InputLabelProps,
                                className: classes.selectLabel,
                            }}
                            inputProps={{
                                ...params.inputProps,
                                'aria-autocomplete': 'none',
                                autoComplete: 'chrome-off',
                            }}
                        />
                    )}
                    ListboxProps={{ style: { maxHeight: listBoxHeight } }}
                    inputValue={inputValue}
                    onInputChange={handleInputChange}
                    loading={loading || loadingOverride}
                    loadingText={
                        <Typography component="span" variant="caption">
                            Loading...
                        </Typography>
                    }
                    onChange={handleChange}
                    popupIcon={<SearchIcon />}
                    classes={{
                        popupIndicatorOpen: classes.popupIndicatorOpen,
                    }}
                    onOpen={handleOpen}
                    onClose={handleClose}
                    getOptionSelected={(option) =>
                        productTypeSelections.some(
                            (each) => option.value === each.value
                        )
                    }
                    renderGroup={(params) => (
                        <div key={params.key}>
                            <Typography
                                variant="caption"
                                component="span"
                                className={classes.optionText}
                            >
                                {params.group}
                            </Typography>
                            {params.children}
                        </div>
                    )}
                    groupBy={(option) => option.sectionNameWithoutNmber || ''}
                    filterOptions={(options, { inputValue }) =>
                        options.filter(
                            (option) =>
                                // To be included in the drop-down list, an option can't already be selected...
                                (!productTypeSelections.some(
                                    (each) => option?.value === each?.value
                                ) &&
                                    // ...AND it must contain the input search string, if any.
                                    !inputValue) ||
                                option
                                    ?.productTypeName!.toLocaleLowerCase()
                                    .includes(inputValue.toLocaleLowerCase())
                        )
                    }
                    disableClearable
                />
            </GridItem>
            <GridContainer
                item
                className={classes.bodyWrapper}
                innerRef={bodyRef}
            >
                <div
                    className={clsx(
                        classes.fullHeight,
                        classes.fullWidth,
                        classes.bodyContainer
                    )}
                    data-testid="dvList"
                >
                    {groups &&
                        Object.keys(groups).map((group, index) => (
                            <LeftSideListGroup
                                key={`${group}${index}`}
                                name={group}
                            >
                                {Array.from(Object.values(groups)[index]).map(
                                    (each) => (
                                        <LeftSideListItem
                                            key={`${group}${each.productTypeName}${index}`}
                                            item={each}
                                            handleRemove={handleRemove}
                                            handleSelect={handleSelect}
                                        />
                                    )
                                )}
                            </LeftSideListGroup>
                        ))}
                </div>
            </GridContainer>
            <GridContainer
                className={clsx(classes.sidePadding, classes.footer)}
                item
                justifyContent="center"
                alignItems="center"
                innerRef={footerRef}
            >
                <GridItem
                    className={classes.clearButton}
                    data-testid="dvClearBtn"
                >
                    <RoundButton
                        backgroundColor="#FFF"
                        disabled={
                            !productTypeSelections ||
                            productTypeSelections?.length === 0 ||
                            loadingSelectedProductType
                        }
                        onClick={handleClearProductTypes}
                        hasShadow={false}
                    >
                        <Typography
                            className={classes.clearButtonText}
                            data-testid="btnLblClearProductTypes"
                        >
                            Clear Search
                        </Typography>
                    </RoundButton>
                </GridItem>
            </GridContainer>
        </GridContainer>
    )
}
