import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles, Theme } from '@material-ui/core/styles'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import clsx from 'clsx'
import RoundButton from 'components/Buttons/RoundButton'
import HoverTooltip from 'components/Feedbacks/HoverTooltip'
import GridContainer from 'components/Surfaces/GridContainer'
import GridItem from 'components/Surfaces/GridItem'
import {
    CdnBaseUrl,
    navigateToExternalLink,
} from 'components/Utilities/urlUtils'
import React from 'react'

interface ViewDocumentRowStyleProps {
    disabled?: boolean
}

const useStyles = makeStyles((theme: Theme) => ({
    fullWidth: {
        width: '100%',
    },
    itemContainer: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    tableBorder: {
        border: '1px solid #C9C9C9',
    },
    tableWhiteText: {
        color: '#FFF',
        fontWeight: 500,
    },
    tableRowText: {
        color: '#333333',
        fontWeight: 500,
        width: '100%',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    tableRowButton: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
    },
    circularLoaderContainer: {
        minWidth: 120,
        textAlign: 'center',
    },
    itemLeftSide: { width: 30 },
    itemRightSide: {
        width: 'calc(100% - 30px)',
        paddingLeft: theme.spacing(1),
    },
    icon: {
        height: 30,
        width: 30,
    },
    downloadContainer: {
        '& .loadingSkeleton': {
            minWidth: 70,
            height: 25,
            backgroundColor: 'rgba(0,0,0,0.15)',
        },
        '& button.MuiButton-contained': {
            backgroundColor: (props: ViewDocumentRowStyleProps) =>
                props.disabled ? 'rgba(0, 0, 0, 0.12)' : '#0098D6',
            borderRadius: 100,
            paddingLeft: theme.spacing(2.5),
            paddingRight: theme.spacing(2.5),
        },
    },
}))

interface ViewDocumentRowProps {
    icon: 'shortform' | 'longform' | 'catalog'
    text: string
    title?: string
    link?: string
    loading?: boolean
    showSpecificationDoc?: boolean
    executePSDQuery?: () => void
}

const ViewDocumentRow: React.FC<ViewDocumentRowProps> = ({
    icon,
    text,
    title,
    link,
    loading = false,
    showSpecificationDoc,
    executePSDQuery,
}) => {
    const classes = useStyles({ disabled: !showSpecificationDoc && !link })
    const key = text.replace(/\s/g, '')
    return (
        <TableRow key={key} className={classes.tableBorder}>
            <TableCell component="th" scope="row">
                <GridContainer>
                    <GridContainer
                        className={classes.itemLeftSide}
                        item
                        alignItems="center"
                        justifyContent="center"
                    >
                        <HoverTooltip
                            placements={['bottom', 'top']}
                            text={title}
                        >
                            <img
                                src={`${CdnBaseUrl}/knowledgepoint/client/${icon}.svg`}
                                alt={icon}
                                className={clsx('documentIcon', classes.icon)}
                            />
                        </HoverTooltip>
                    </GridContainer>
                    <GridContainer
                        className={classes.itemRightSide}
                        item
                        alignItems="center"
                        justifyContent="flex-start"
                    >
                        <GridItem>
                            <Typography
                                variant="subtitle2"
                                className={clsx(
                                    'documentType',
                                    classes.tableRowText
                                )}
                            >
                                {text}
                            </Typography>
                        </GridItem>
                    </GridContainer>
                </GridContainer>
            </TableCell>
            <TableCell align="right" className={classes.downloadContainer}>
                {!loading ? (
                    <RoundButton
                        data-testid={`btn${key}`}
                        disabled={!showSpecificationDoc && !link}
                        hasShadow={false}
                        borderColor="#0098D6"
                        backgroundColor="#0098D6"
                        borderRadius={100}
                        onClick={() =>
                            showSpecificationDoc
                                ? executePSDQuery?.()
                                : navigateToExternalLink(link!)
                        }
                    >
                        <Typography
                            variant="subtitle2"
                            className={clsx(
                                classes.tableWhiteText,
                                classes.tableRowButton
                            )}
                        >
                            Download
                        </Typography>
                    </RoundButton>
                ) : (
                    <div className={classes.circularLoaderContainer}>
                        <CircularProgress size={24} />
                    </div>
                )}
            </TableCell>
        </TableRow>
    )
}

export default ViewDocumentRow
