import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { Theme, makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import Layout from 'components/common/Layout'
import {
    ShowGlobalSnackbarMutation,
    ShowGlobalSnackbarMutationProps,
    ShowGlobalSnackbarRequest,
} from 'components/Configurations/GlobalSnackbar'
import ProductCategories from 'components/Constants/productCategories'
import ProductListingLevels from 'components/Constants/productListingLevels'
import { AppHeaderContext } from 'components/Contexts/AppHeaderContext'
import {
    ListingsGetListingProductTypeProperties,
    ProductSearchAutosuggestQuery,
    ProductSearchQuery,
} from 'components/GraphQL/Queries/productSearch.queries'
import {
    ProductPropertyDetailResponse,
    ProductSearchAutosuggestResponse,
    ProductSearchResponse,
} from 'components/GraphQL/Types/product.types'
import {
    ListingMatch,
    ListingSearchInput,
    LogEventSource,
} from 'components/Models/listingSearch'
import { ProductTypeSelectionInput } from 'components/Models/productType'
import { SearchFacet } from 'components/Models/searchFacet'
import { SearchFacetInput } from 'components/Models/searchFacetInput'
import AccordionWindow from 'components/ProductListing/ProductListingGroupingTree'
import { ProductListingResultGrid } from 'components/ProductListing/ProductListingResultGrid'
import GridContainer from 'components/Surfaces/GridContainer'
import GridItem from 'components/Surfaces/GridItem'
import { summarizeApolloError } from 'components/Utilities/apolloUtils'
import { isEqual } from 'components/Utilities/objectUtils'
import ProductSearchBar from 'components/Widgets/ProductListing/Filter/ProductSearchBar'
import { NormalizedProductType } from 'components/Widgets/ProductListing/model'
import {
    createFilter,
    emptyInputSelectionObject,
    emptySelectionObject,
    getFacetInputsFromFilterSelection,
    getFilterDisabledValues,
    getInputSelectionValuesFromQueryString,
    getSelectedProductTypesFromQueryString,
    getSelectedValuesFromQueryString,
    initialListings,
    isAdditionalContentSelected,
    updateQueryString,
} from 'components/Widgets/ProductListing/productsUtils'
import React, { useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import AutoSizer from 'react-virtualized-auto-sizer'
import { FixedSizeGrid } from 'react-window'

interface ProductListingsPageStyleProps {
    hasLowerBottomToolbar?: boolean
}

interface CurrentDropDownInput {
    filterName: string
    inputValue: string
}

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        height: '100%',
        marginRight: -theme.spacing(3),
        marginLeft: -theme.spacing(3),
        [theme.breakpoints.down('xs')]: {
            width: 'calc(100% + 40px)',
        },
        [theme.breakpoints.up('sm')]: {
            width: 'calc(100% + 48px)',
        },
    },
    productSearchContOuter: {
        [theme.breakpoints.down('sm')]: {
            height: 94,
        },
        [theme.breakpoints.up('md')]: {
            height: 60,
        },
        zIndex: 1,
    },
    productContent: (props: ProductListingsPageStyleProps) => ({
        height: `calc(100vh - ${
            props.hasLowerBottomToolbar ? '244px' : '194px'
        })`,
        [theme.breakpoints.up('md')]: {
            height: 'calc(100vh - 160px)',
        },
        width: '100%',
        overflow: 'hidden',
    }),
    resultContainer: {
        width: '100%',
        paddingTop: theme.spacing(4),
        height: '100%',
        margin: 0,
        overflow: 'hidden',
        overflowY: 'auto',
        overscrollBehavior: 'none',
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
    },
}))

export const OPTIMISTIC_CARDS = 5

export const PER_PAGE = 200

const All: React.FC = () => {
    const {
        updateAppHeaderContent,
        updateAppHeaderStyles,
        hasLowerBottomToolbar,
    } = React.useContext(AppHeaderContext)

    const history = useHistory()
    const { location } = history

    const [
        listingMatchDataResults,
        setListingMatchDataResults,
    ] = React.useState<Partial<ListingMatch>[] | undefined>()

    const classes = useStyles({ hasLowerBottomToolbar })

    const [showGlobalSnackbar] = useMutation<
        ShowGlobalSnackbarRequest,
        ShowGlobalSnackbarMutationProps
    >(ShowGlobalSnackbarMutation)

    const [selectedValues, setSelectedValues] = React.useState<
        Record<string, string[]> | undefined
    >({
        ...emptySelectionObject,
        ...getSelectedValuesFromQueryString(location.search),
    })

    const [selectedInputValues, setSelectedInputValues] = React.useState<
        Record<string, Date | string | null> | undefined
    >({
        ...emptyInputSelectionObject,
        ...getInputSelectionValuesFromQueryString(location.search),
    })

    const [facets, setFacets] = React.useState<SearchFacetInput[]>([
        ...getFacetInputsFromFilterSelection(selectedValues),
    ])

    const [
        currentDropDownName,
        setCurrentDropDownName,
    ] = React.useState<string>('')

    const [
        currentDropDownInput,
        setCurrentDropDownInput,
    ] = React.useState<CurrentDropDownInput>({ filterName: '', inputValue: '' })

    const [scrollState, setScrollState] = React.useState({
        rowIndex: 0,
        columnIndex: 0,
    })

    const [columnCount, setColumnCount] = React.useState<number>(0)

    const [refetch, setRefetch] = React.useState<boolean>(false)

    const [hasNextPage, setHasNextPage] = React.useState<boolean>(true)

    const infiniteLoaderRef = React.useRef(null)

    const hasMountedRef = React.useRef(false)

    const [productTypeSelections, setProductTypeSelections] = React.useState<
        NormalizedProductType[]
    >([...getSelectedProductTypesFromQueryString(location.search)])

    const [currentTotal, setCurrentTotal] = React.useState<number>(PER_PAGE)

    const [
        searchFetchMoreLoading,
        setSearchFetchMoreLoading,
    ] = React.useState<boolean>(false)

    const [searchLoading, setSearchLoading] = React.useState<boolean>(false)

    const [
        manufacturerProductSearchInput,
        setManufacturerProductSearchInput,
    ] = React.useState<Partial<ListingSearchInput>>({
        skip: 0,
        top: PER_PAGE,
        orderBy: 'firmName,productType', // TODO apply user's sort preferences
        logEventSource: LogEventSource.PublicSearchListings,
    })

    const [
        getListings,
        { data: searchData, fetchMore: searchFetchMore, called: searchCalled },
    ] = useLazyQuery<ProductSearchResponse>(ProductSearchQuery, {
        displayName: 'ProductSearchResult',
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first',
        onCompleted: () => setSearchLoading(false),
        onError: () => {
            showGlobalSnackbar({
                variables: {
                    message: 'Error on product search',
                    variant: 'error',
                    id: `ProductListingsPage_ProductSearchError`,
                },
            })
            setSearchLoading(false)
        },
    })

    const [productCount, setProductCount] = React.useState<number>(0)

    const {
        loading: autosuggestLoading,
        data: autosuggestData,
    } = useQuery<ProductSearchAutosuggestResponse>(
        ProductSearchAutosuggestQuery,
        {
            variables: {
                input: {
                    facets: createFilter(
                        facets,
                        'AutoSuggest',
                        currentDropDownName,
                        ['Manufacturer'],
                        true
                    ).filter(
                        (facet) => facet.name !== currentDropDownName
                        // don't limit autosuggest by what's already selected in same filter
                    ),
                    searchLimit: 50,
                    searchField: currentDropDownName,
                    searchText: currentDropDownInput.inputValue,
                    productTypeSelections:
                        currentDropDownName !== 'sectionNameWithProductType'
                            ? productTypeSelections.map(
                                  (each) =>
                                      ({
                                          productTypeProperties:
                                              each.productTypeProperties,
                                          sectionName: each.sectionName,
                                          productTypeName: each.productTypeName,
                                      } as ProductTypeSelectionInput)
                              )
                            : [],
                },
            },
            skip: !currentDropDownName, // only run query if an autosuggest drop-down is open
            fetchPolicy: 'no-cache',
            onError: () => {
                showGlobalSnackbar({
                    variables: {
                        message: 'Error on product autosuggest',
                        variant: 'error',
                        id: `ProductListingsPage_ProductAutosuggestError`,
                    },
                })
            },
        }
    )

    const [onSelected, setOnSelected] = React.useState<boolean>(false)

    const [
        getListingProductTypeProperties,
        {
            loading: loadingSelectedProductType,
            error: errorSelectedProductType,
            data: dataSelectedProductType,
        },
    ] = useLazyQuery<ProductPropertyDetailResponse>(
        ListingsGetListingProductTypeProperties,
        {
            displayName: 'GetListingProductTypeProperties',
            onError: (error) => {
                showGlobalSnackbar?.({
                    variables: {
                        message: summarizeApolloError(
                            error,
                            'Error fetching product type properties.'
                        ),
                        variant: 'error',
                        id:
                            'ProductTypeFilter_ListingProductTypePropertiesError',
                    },
                })
            },
            onCompleted: () => setOnSelected(true),
        }
    )

    const [resultFacetLookup, setResultFacetLookup] = React.useState<
        Record<string, string[]>
    >({})

    const [filterValues, setFilterValues] = React.useState<
        Record<string, string[]>
    >({})

    const handleAutoSuggestDropdown = (value: string) => {
        setCurrentDropDownName(value)
        setFilterValues((past) => ({
            ...past,
            sectionNames: [],
            sectionNameWithProductType: [],
            displayNameWithFirmName: [],
        }))
    }

    const setScrollRowAndColum = React.useCallback(
        (rowIndex: number, columnIndex: number) => {
            setScrollState({ rowIndex, columnIndex })
        },
        []
    )

    const isItemLoaded = (index: number) => !!listingMatchDataResults?.[index]

    const handleLoadMore = React.useCallback(
        (_startIndex: number, stopIndex: number) => {
            if (
                !searchLoading &&
                !searchFetchMoreLoading &&
                !refetch &&
                searchData?.listingSearch?.count &&
                Math.ceil(currentTotal / columnCount) - stopIndex > 0 &&
                hasNextPage &&
                !!searchFetchMore
            ) {
                setSearchFetchMoreLoading(true)
                setCurrentTotal((prev) => prev + OPTIMISTIC_CARDS)

                return searchFetchMore({
                    variables: {
                        manufacturerProductSearchInput: {
                            ...manufacturerProductSearchInput,
                            skip: searchData.listingSearch.matches?.length,
                            top: PER_PAGE,
                        },
                    },
                }).then(() => setSearchFetchMoreLoading(false))
            }
            return null
        },
        [
            manufacturerProductSearchInput,
            searchLoading,
            searchFetchMoreLoading,
            refetch,
            searchData,
            currentTotal,
            columnCount,
            hasNextPage,
            searchFetchMore,
        ]
    )
    // Refetch
    const doRefetch = React.useCallback(() => {
        getListings({
            variables: {
                manufacturerProductSearchInput,
            },
        })
        setRefetch(false)
        setSearchLoading(true)
    }, [getListings, manufacturerProductSearchInput])

    React.useEffect(() => {
        updateAppHeaderStyles?.({
            titleTextColor: '#04325B',
            backgroundColor: '#ffffff',
        })
    }, [updateAppHeaderStyles])

    React.useEffect(() => {
        if (searchData) {
            updateAppHeaderContent(
                `Product Listings (${searchData.listingSearch.count ?? 0})`
            )
        }
    }, [updateAppHeaderContent, searchData])

    React.useEffect(() => {
        const searchParams = new URLSearchParams(history.location.search)
        let updatedSelectedValues = undefined

        if (
            history.location.search === null ||
            history.location.search === ''
        ) {
            const queryParams = `groupby=sectionNumber%2520false%252CproductType%2520false&sortby=sectionNumber%252CproductType%252Ctype%252ClastUpdated%2520desc&ia=true&defaultFilter=true`
            history.replace({
                search: `?${queryParams}`,
            })
            updatedSelectedValues = {
                ...selectedValues,
                ...getSelectedValuesFromQueryString(queryParams),
            }
        } else {
            history.replace({
                ...history.location,
                search: `?${searchParams.toString()}`,
            })

            updatedSelectedValues = {
                ...selectedValues,
                ...getSelectedValuesFromQueryString(searchParams.toString()),
            }
        }

        if (
            !isEqual(
                facets,
                getFacetInputsFromFilterSelection(updatedSelectedValues)
            )
        ) {
            setSelectedValues(updatedSelectedValues)
            setFacets([
                ...getFacetInputsFromFilterSelection(updatedSelectedValues),
            ])
        }
    }, [selectedValues, history, facets])

    React.useEffect(() => {
        const searchFacets = createFacetLookup(
            searchData?.listingSearch.facets ?? []
        )
        if (searchData?.listingSearch.matches) {
            setProductCount(searchData?.listingSearch.count ?? 0)
            setResultFacetLookup(searchFacets)
            setListingMatchDataResults([...searchData?.listingSearch?.matches])
        }
    }, [searchData, location, selectedValues])

    React.useEffect(() => {
        if (!autosuggestLoading) {
            const autosuggestValues =
                autosuggestData?.listingAutoSuggest?.result || []
            setFilterValues({
                categories:
                    resultFacetLookup?.Categories?.sort() ?? ProductCategories,
                listingLevels: ProductListingLevels.filter(
                    (each) => each !== 'Manufacturer'
                ),
                displayNameWithFirmName:
                    currentDropDownName === 'displayNameWithFirmName'
                        ? autosuggestValues
                        : [],
                sectionNames:
                    currentDropDownName === 'sectionNames'
                        ? autosuggestValues
                        : [],
                sectionNameWithProductType:
                    currentDropDownName === 'sectionNameWithProductType'
                        ? autosuggestValues
                        : [],
            })
        }
    }, [
        autosuggestData,
        autosuggestLoading,
        currentDropDownName,
        resultFacetLookup,
    ])

    // Used to reset the scrollbar
    React.useEffect(() => {
        if (hasMountedRef.current) {
            if (infiniteLoaderRef) {
                const list = infiniteLoaderRef.current as any
                if (list) {
                    const grid = list._listRef as FixedSizeGrid
                    list.resetloadMoreItemsCache() //Reset infinite scroll
                    grid.scrollToItem({
                        align: 'start',
                        columnIndex: 0,
                        rowIndex: 0,
                    }) //scroll to top after refresh
                    list.scrollTop = 0 //firefox solution for scroll not reseting
                }
            }
        }
        hasMountedRef.current = true
    }, [refetch, searchLoading])

    const [skip, setSkip] = React.useState<number>(PER_PAGE)

    const [groupBy, setGroupBy] = React.useState<
        Record<string, boolean | string>
    >({})

    const sortRef = React.useRef<string>('')

    React.useMemo(() => {
        let computedFacets = createFilter(
            facets,
            'Search',
            undefined,
            ['Manufacturer'],
            true
        )
        setManufacturerProductSearchInput((past) => ({
            ...past,
            orderBy: selectedValues?.orderBy?.length
                ? selectedValues.orderBy.join(',')
                : 'firmName,productType',
            facets: computedFacets,
            lastUpdatedEndDate: selectedInputValues?.lastUpdatedEndDate
                ? selectedInputValues.lastUpdatedEndDate + 'T23:59:59.999Z'
                : undefined,
            lastUpdatedStartDate: selectedInputValues?.lastUpdatedStartDate
                ? selectedInputValues.lastUpdatedStartDate + 'T00:00:00.000Z'
                : undefined,
            lastUpdatedInPastDays: selectedInputValues?.lastUpdatedInPastDays
                ? 180
                : undefined,
            hasBimLinks: selectedValues
                ? isAdditionalContentSelected(
                      selectedValues['additionalContent'],
                      'hasBimLinks'
                  )
                : undefined,
            hasCatalogs: selectedValues
                ? isAdditionalContentSelected(
                      selectedValues['additionalContent'],
                      'hasCatalogs'
                  )
                : undefined,
            hasDocumentFL: selectedValues
                ? isAdditionalContentSelected(
                      selectedValues['additionalContent'],
                      'hasDocumentFL'
                  )
                : undefined,
            hasDocumentSF: selectedValues
                ? isAdditionalContentSelected(
                      selectedValues['additionalContent'],
                      'hasDocumentSF'
                  )
                : undefined,
            productTypeSelections: productTypeSelections.map(
                (each) =>
                    ({
                        productTypeProperties: each.productTypeProperties,
                        sectionName: each.sectionName,
                        productTypeName: each.productTypeName,
                    } as ProductTypeSelectionInput)
            ),
        }))
        setSkip(PER_PAGE)
        setRefetch(true)
        setCurrentTotal(10)
    }, [facets, selectedValues, productTypeSelections, selectedInputValues])

    const createFacetLookup = (dataFacets: Partial<SearchFacet>[]) => {
        return dataFacets.reduce((result, facet) => {
            if (result && facet.name) {
                // Do not include
                if (facet.name === 'ListingLevels') {
                    result[facet.name] =
                        facet?.values
                            ?.filter(
                                (each) =>
                                    each.value && each.value !== 'Manufacturer'
                            )
                            .map((fv) => fv.value || '') || []
                } else {
                    result[facet.name] =
                        facet?.values?.map((fv) => fv.value || '') || []
                }
            }
            return result
        }, {} as Record<string, string[]>)
    }

    // on refetch change
    React.useMemo(() => {
        if (!searchLoading && refetch) {
            doRefetch()
            setHasNextPage(false)
        }
    }, [doRefetch, searchLoading, refetch])

    // on Render
    React.useMemo(() => {
        if (!searchCalled && hasMountedRef.current) {
            doRefetch()
            setHasNextPage(false)
        }
    }, [doRefetch, searchCalled, hasMountedRef])

    // Update current total based on cache
    React.useEffect(() => {
        if (
            !searchLoading &&
            !searchFetchMoreLoading &&
            !refetch &&
            !!searchData?.listingSearch?.matches &&
            !!productCount
        ) {
            const count =
                searchData?.listingSearch?.matches?.length < PER_PAGE
                    ? productCount
                    : searchData?.listingSearch?.matches?.length

            if (currentTotal !== count && searchData?.listingSearch?.count) {
                setCurrentTotal(count)
                setHasNextPage(
                    count >= PER_PAGE
                        ? count < searchData?.listingSearch?.count
                        : false
                )
            }
        }
    }, [
        searchData,
        searchLoading,
        searchFetchMoreLoading,
        currentTotal,
        refetch,
        productCount,
    ])

    React.useEffect(() => {
        const searchParams = new URLSearchParams(location.search)

        if (
            Object.keys(groupBy).length === 0 &&
            location.search &&
            (searchParams.has('groupby') || searchParams.has('sortby'))
        ) {
            const groupByData =
                (searchParams.has('groupby') &&
                    decodeURIComponent(searchParams.get('groupby')!)) ||
                ''
            const sortBy =
                (searchParams.has('sortby') &&
                    decodeURIComponent(searchParams.get('sortby')!)) ||
                ''
            let groupByObj: Record<string, boolean> = {}
            groupByData
                ?.split(',')
                .forEach(
                    (g) =>
                        g?.split(' ')[0] &&
                        (groupByObj[g?.split(' ')[0]] =
                            g?.split(' ')[1] === 'true')
                )
            if (sortRef.current === sortBy && isEqual(groupByObj, groupBy))
                return
            if (!isEqual(groupByObj, groupBy)) {
                setGroupBy(groupByObj)
            }
            const currentOrderby = ((sortBy && sortBy.split(',')) || []).filter(
                (f) => !!f
            )
            if (!isEqual(selectedValues?.orderBy, currentOrderby)) {
                setSelectedValues({
                    ...selectedValues,
                    orderBy: currentOrderby,
                })
            }
            sortRef.current = sortBy
        }
    }, [location, selectedValues, groupBy])

    const lastGroupingInfoRef = React.useRef<Record<string, boolean | string>>()

    const handleLoadMoreResults = React.useCallback(() => {
        const nextSkip = skip + PER_PAGE
        setSearchLoading(true)
        getListings &&
            getListings({
                variables: {
                    manufacturerProductSearchInput: {
                        ...manufacturerProductSearchInput,
                        skip,
                        top: PER_PAGE,
                    },
                },
            })
        if (!Object.values(groupBy).includes('spLoadMore'))
            lastGroupingInfoRef.current = groupBy
        let modifiedGroupBy: Record<string, boolean | string> = {}
        Object.keys(groupBy).forEach((key) => {
            modifiedGroupBy[key] = 'spLoadMore'
        })

        if (!isEqual(groupBy, modifiedGroupBy)) setGroupBy(modifiedGroupBy)
        setSkip(nextSkip)
    }, [getListings, skip, manufacturerProductSearchInput, groupBy])

    const onFilterSelectionChangeHandler = (
        selection: Record<string, string[]> | undefined
    ) => {
        const selectedData: Record<string, string[]> = {
            ...selection,
        }
        if (
            selectedData.orderBy?.length === 2 &&
            !isEqual(selectedData.orderBy, selectedValues?.orderBy)
        ) {
            const groupByDataObj = JSON.parse(selectedData.orderBy[0])
            const sortByDataObj = JSON.parse(selectedData.orderBy[1])
            const orderByData = [...sortByDataObj].filter(
                (fieldName) => !!fieldName
            ) as string[]
            setGroupBy(groupByDataObj)
            selectedData.orderBy = orderByData
        } else if (
            selectedData.orderBy?.length !== 2 &&
            Object.keys(groupBy).length !== 0
        ) {
            setGroupBy({})
        } else if (Object.values(groupBy).includes('spLoadMore')) {
            setGroupBy({ ...lastGroupingInfoRef.current })
        }
        setSelectedValues(selectedData)
        setFacets(getFacetInputsFromFilterSelection(selectedData))
        updateQueryString(selectedData, undefined, history, location)
    }

    const handelProductTypeSelection = (values: NormalizedProductType[]) => {
        const propArr: string[] = []
        values.forEach((prop) => {
            if (!!prop.productTypeProperties?.length) {
                prop.productTypeProperties?.forEach((ptp: any) =>
                    propArr.push(
                        `${prop.sectionName}%|%${prop.productTypeName}%|%${
                            ptp.propertyName
                        }:${ptp.propertyOptionIds?.join(',')}`
                    )
                )
            } else {
                propArr.push(`${prop.sectionName}%|%${prop.productTypeName}`)
            }
        })

        const productTypePropertiesQuery =
            (propArr.length && encodeURIComponent(propArr.join(';'))) || ''

        const searchParams = new URLSearchParams(location.search)
        searchParams.has('ptp') && searchParams.delete('ptp')
        if (productTypePropertiesQuery) {
            searchParams.set('ptp', productTypePropertiesQuery)
        }
        if (values.length > 0 && searchParams.has('sp')) {
            history.replace({
                ...location,
                search: searchParams.toString(),
            })
        }
        if (!isEqual(values, productTypeSelections)) {
            setProductTypeSelections(values)
        }
    }

    const disabledFilterValues = useMemo(() => {
        const hasManufacturerOptions = !!selectedValues?.withManufacturerOption?.some(
            (value) => value === 'true'
        )
        return getFilterDisabledValues(
            resultFacetLookup,
            hasManufacturerOptions
        )
    }, [selectedValues, resultFacetLookup, getFilterDisabledValues])

    return (
        <Layout>
            <GridContainer className={classes.container} direction="column">
                <GridItem
                    className={classes.productSearchContOuter}
                    data-testid="productListingsPage"
                >
                    <ProductSearchBar
                        filterValues={filterValues}
                        filterSelection={selectedValues}
                        filterDisabledValues={disabledFilterValues}
                        onFilterSelectionChange={onFilterSelectionChangeHandler}
                        onFilterInputChangeDebounced={(
                            filterName,
                            inputValue
                        ) => {
                            setCurrentDropDownInput({
                                filterName: filterName,
                                inputValue: inputValue,
                            })
                        }}
                        loadingFilterName={
                            autosuggestLoading ? currentDropDownName : ''
                        }
                        handleEmptyInputSelection={(selection) => {
                            setSelectedInputValues(selection)
                            updateQueryString(
                                undefined,
                                selection,
                                history,
                                location
                            )
                        }}
                        emptyInputSelection={selectedInputValues}
                        handleAutoSuggestDropdown={handleAutoSuggestDropdown}
                        productTypeSelections={productTypeSelections}
                        handelProductTypeSelection={handelProductTypeSelection}
                        showGlobalSnackbar={showGlobalSnackbar}
                        resultCount={productCount}
                        loadingSelectedProductType={loadingSelectedProductType}
                        errorSelectedProductType={errorSelectedProductType}
                        dataSelectedProductType={dataSelectedProductType}
                        getListingProductTypeProperties={
                            getListingProductTypeProperties
                        }
                        onSelected={onSelected}
                        setOnSelected={setOnSelected}
                        showSubmissionStatus={false}
                        showSwatchboxFilter={true}
                    />
                </GridItem>
                <GridItem className={classes.productContent}>
                    {!Object.keys(groupBy).length ? (
                        <ProductListingResultGrid
                            searchLoading={searchLoading}
                            searchFetchMoreLoading={searchFetchMoreLoading}
                            handleLoadMore={handleLoadMore}
                            products={listingMatchDataResults}
                            currentTotal={currentTotal}
                            hasNextPage={hasNextPage}
                            isItemLoaded={isItemLoaded}
                            scrollState={scrollState}
                            setScrollRowAndColumn={setScrollRowAndColum}
                            infiniteLoaderRef={infiniteLoaderRef}
                            setColumnCount={setColumnCount}
                            forGatsby={true}
                        />
                    ) : !searchLoading &&
                      listingMatchDataResults?.length === 0 ? (
                        <GridContainer
                            spacing={1}
                            className={clsx(
                                'divProductListingNoResultGrid',
                                classes.resultContainer
                            )}
                            justifyContent="center"
                            alignContent="space-between"
                        >
                            <GridItem>
                                <h3>No Results</h3>
                            </GridItem>
                        </GridContainer>
                    ) : (
                        <AutoSizer
                            className="accordionTree"
                            data-testid="accordion-tree"
                        >
                            {({ height, width }) => (
                                <AccordionWindow
                                    listingCount={productCount}
                                    listings={
                                        listingMatchDataResults ??
                                        initialListings
                                    }
                                    searchLoading={searchLoading}
                                    onLoadMore={handleLoadMoreResults}
                                    groupBy={groupBy}
                                    height={height}
                                    width={width}
                                />
                            )}
                        </AutoSizer>
                    )}
                </GridItem>
            </GridContainer>
        </Layout>
    )
}

export default All
