import { makeStyles, Theme } from '@material-ui/core/styles'
import Skeleton from '@material-ui/lab/Skeleton'
import clsx from 'clsx'
import SingleLineEllipsis from 'components/Feedbacks/SingleLineEllipsis'
import GridContainer from 'components/Surfaces/GridContainer'
import { pxToRem } from 'components/Utilities/unitUtils'
import React from 'react'

const useStyles = makeStyles((theme: Theme) => ({
    propertiesHeaderLower: {
        height: '8%',
        backgroundColor: '#24CEAD',
    },
    propertiesHeaderLowerItem: {
        height: '100%',
        padding: theme.spacing(1.25),
    },
    propertiesHeaderLowerLeftSide: {
        ...theme.typography.subtitle1,
        color: '#FFF',
        fontWeight: 'bold',
    },
    propertiesHeaderLowerRightSide: {
        fontSize: pxToRem(13),
        color: '#FFF',
        fontWeight: 'bold',
    },
}))

interface RightSideHeaderLowerProps {
    loading?: boolean
    count?: number
}

export const RightSideHeaderLower: React.FC<RightSideHeaderLowerProps> = ({
    loading,
    count = 0,
}) => {
    const classes = useStyles()

    return (
        <GridContainer className={classes.propertiesHeaderLower} item>
            <GridContainer
                item
                xs={6}
                justifyContent="flex-start"
                alignItems="center"
                className={classes.propertiesHeaderLowerItem}
            >
                {!loading ? (
                    <SingleLineEllipsis
                        className={classes.propertiesHeaderLowerLeftSide}
                        text="Select Your Properties:"
                    />
                ) : (
                    <Skeleton variant="text" animation="pulse" width={200} />
                )}
            </GridContainer>
            <GridContainer
                item
                xs={6}
                justifyContent="flex-end"
                alignItems="center"
                className={classes.propertiesHeaderLowerItem}
            >
                {!loading ? (
                    <SingleLineEllipsis
                        className={clsx(
                            'lblPropertiesSelectedCount',
                            classes.propertiesHeaderLowerRightSide
                        )}
                        text={`Selected Properties: ${count}`}
                    />
                ) : (
                    <Skeleton
                        data-testid="imgLoadingCount"
                        variant="text"
                        animation="pulse"
                        width={200}
                    />
                )}
            </GridContainer>
        </GridContainer>
    )
}
