import {
    ListingLevel,
    ManufacturerProductBimLinkDetail,
    ManufacturerProductCatalogDetail,
    ManufacturerProductImageDetail,
    ManufacturerProductSpecDocDetail,
    ManufacturerProductVideoDetail,
} from './product'
import { ProductTypeSelectionInput } from './productType'
import { SearchFacet } from './searchFacet'
import { SearchFacetInput } from './searchFacetInput'

export interface ListingAutoSuggestInput {
    facets: Partial<SearchFacetInput>[]
    lastUpdatedEndDate: string
    lastUpdatedInPastDays: number
    lastUpdatedStartDate: string
    orderBy: string
    searchAfterKey: string
    searchField: string
    searchLimit: number
    searchText: string
    skip: number
    top: number
    hasBimLinks: boolean
    hasCatalogs: boolean
    productTypeSelections: Partial<ProductTypeSelectionInput>[]
    projectId?: string
}

export interface ListingMatch {
    listingId: string
    bimLinks: Partial<ManufacturerProductBimLinkDetail>[]
    catalogs: Partial<ManufacturerProductCatalogDetail>[]
    categories: string[]
    displayNameWithFirmName: string
    firmDisplayName: string
    firmId: string
    firmLogoUrl: string
    firmName: string
    type: ListingLevel
    images: Partial<ManufacturerProductImageDetail>[]
    isActive: boolean
    isDeltekWriterDraft: boolean
    lastUpdated: string
    manufacturerDocs: Partial<ManufacturerProductSpecDocDetail>[]
    productId: string
    productName: string
    productType: string
    sectionName: string
    submissionStatus: string
    videos: Partial<ManufacturerProductVideoDetail>[]
    canAutoGenerateProductSpecification?: boolean
    specAgentProductTypeId?: number
    familyNumber?: string
    partnershipLinksBIM?: string
    partnershipLinksULE?: string
    partnershipLinksSM?: string
    isBestPractice?: boolean
    isGlobalBestPractice?: boolean
    hasManufacturerOptions?: boolean
    isListingProductTypeManufacturerProductType?: boolean
    hasProductDetailNotes?: boolean
    hasSupportedOptions?: boolean
    model?: string
}

export interface ListingSearchInput {
    facets: Partial<SearchFacetInput>[]
    lastUpdatedEndDate: string
    lastUpdatedInPastDays: number
    lastUpdatedStartDate: string
    orderBy: string
    skip: number
    top: number
    hasBimLinks: boolean
    hasCatalogs: boolean
    productTypeSelections: Partial<ProductTypeSelectionInput>[]
    listingIds: string
    listingIdsList: string[]
    isOnAE?: boolean
    projectId?: string
    logEventSource?: string
}

export interface ListingAutoSuggestResult {
    result: string[]
    searchAfterKey: string
}

export interface ManufacturerSearchResultOfListingMatch {
    count: number
    facets: Partial<SearchFacet>[]
    matches: Partial<ListingMatch>[]
}

export enum LogEventSource {
    Undefined = 'Undefined',
    AeViewProducts = 'AeViewProducts',
    BpmSearchListings = 'BpmSearchListings',
    PublicSearchListings = 'PublicSearchListings',
    BpmRecentProducts = 'BpmRecentProducts',
    AeRecentProducts = 'AeRecentProducts',
    CreateListingValidation = 'CreateListingValidation',
    AeRelatedProducts = 'AeRelatedProducts',
}
