import Checkbox from '@material-ui/core/Checkbox'
import IconButton from '@material-ui/core/IconButton'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import clsx from 'clsx'
import SimpleSelect from 'components/Forms/SimpleSelect'
import GridContainer from 'components/Surfaces/GridContainer'
import GridItem from 'components/Surfaces/GridItem'
import React from 'react'
import { DomObject } from './ProductSortingModal'

const useStyles = makeStyles((theme: Theme) => ({
    formLayout: {
        marginTop: theme.spacing(1),
    },
    header: {
        marginTop: theme.spacing(2),
        '& span': {
            margin: '24px 30px -24px',
            color: '#5E5F61',
        },
    },
    options: {
        paddingLeft: theme.spacing(3.5),
    },
    rowContainer: {
        backgroundColor: '#ffffff',

        '&:hover': {
            '& $dragIcon': {
                display: 'block',
            },
        },
    },

    dragIcon: {
        fill: '#C9C9C9',
        display: 'none',
        marginTop: theme.spacing(1.5),
        '&:hover': {
            cursor: 'move',
        },
    },
    container: {
        marginLeft: theme.spacing(7),
    },
}))
interface ProductSortingFormProps {
    groupingDomArr: DomObject[]
    columnValueChangeHandler: (
        key: string,
        value: string | boolean,
        id: string
    ) => void
    handleRemoveGrouping: (id: string) => void
}
export const ProductGroupingForm: React.FC<ProductSortingFormProps> = ({
    groupingDomArr,
    columnValueChangeHandler,
    handleRemoveGrouping,
}) => {
    const classes = useStyles()

    const groupingLevels = [
        'FIRST GROUPING',
        'SECOND GROUPING',
        'THIRD GROUPING',
    ]
    return (
        <GridContainer item data-testid="groupingForm">
            {groupingDomArr.map((group: DomObject, index: number) => (
                <GridContainer key={group.id}>
                    {index !== 0 && (
                        <GridItem xs={12} className={classes.header}>
                            <Typography
                                variant="body2"
                                component="span"
                                className={classes.options}
                            >
                                {groupingLevels[index]}
                            </Typography>
                        </GridItem>
                    )}
                    <GridItem
                        xs={12}
                        className={clsx(classes.container, 'dataRowContainer')}
                    >
                        <GridContainer>
                            <GridItem
                                xs={6}
                                className={clsx(
                                    classes.formLayout,
                                    'rowContainer'
                                )}
                            >
                                <SimpleSelect
                                    {...{
                                        inputprops: {
                                            'aria-label': 'columnName',
                                        },
                                    }}
                                    fontSize={14}
                                    width="90%"
                                    value={group.columnName}
                                    options={group.columnOptions}
                                    onValueChange={(value) =>
                                        columnValueChangeHandler(
                                            'columnName',
                                            value,
                                            group.id
                                        )
                                    }
                                />
                            </GridItem>
                            <GridItem xs={3} className={classes.formLayout}>
                                <Checkbox
                                    color="primary"
                                    checked={!!group.isChecked}
                                    inputProps={{
                                        'aria-label': 'collapse',
                                    }}
                                    onChange={(
                                        event: React.ChangeEvent<{}>
                                    ) => {
                                        const target = event.target as HTMLInputElement
                                        columnValueChangeHandler(
                                            'isChecked',
                                            target.checked,
                                            group.id
                                        )
                                    }}
                                />
                            </GridItem>
                            {(group.columnName ||
                                groupingDomArr.length > 1) && (
                                <GridItem xs={2} className={classes.formLayout}>
                                    <IconButton
                                        className="deleteGrouping"
                                        onClick={() => {
                                            handleRemoveGrouping(group.id)
                                        }}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </GridItem>
                            )}
                        </GridContainer>
                    </GridItem>
                </GridContainer>
            ))}
        </GridContainer>
    )
}
