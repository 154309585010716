import { NameValuePair } from 'components/Models/nameValuePair'

const ProductBuildingType: NameValuePair<string>[] = [
    { name: 'Automotive Facility', value: 'Automotive Facility' },
    { name: 'Convention Center', value: 'Convention Center' },
    { name: 'Courthouse', value: 'Courthouse' },
    { name: 'Dining Cafeteria Fast Food', value: 'Dining Cafeteria Fast Food' },
    {
        name: 'Dining Bar Lounge or Leisure',
        value: 'Dining Bar Lounge or Leisure',
    },
    { name: 'Dining Family', value: 'Dining Family' },
    { name: 'Dormitory', value: 'Dormitory' },
    { name: 'Exercise Center', value: 'Exercise Center' },
    { name: 'Fire Station', value: 'Fire Station' },
    { name: 'Gymnasium', value: 'Gymnasium' },
    { name: 'Hospital or Healthcare', value: 'Hospital or Healthcare' },
    { name: 'Hotel', value: 'Hotel' },
    { name: 'Library', value: 'Library' },
    { name: 'Manufacturing', value: 'Manufacturing' },
    { name: 'Motel', value: 'Motel' },
    { name: 'Motion Picture Theatre', value: 'Motion Picture Theatre' },
    { name: 'Multi Family', value: 'Multi Family' },
    { name: 'Museum', value: 'Museum' },
    { name: 'Office', value: 'Office' },
    { name: 'Parking Garage', value: 'Parking Garage' },
    { name: 'Penitentiary', value: 'Penitentiary' },
    { name: 'Performing Arts Theater', value: 'Performing Arts Theater' },
    { name: 'Police Station', value: 'Police Station' },
    { name: 'Post Office', value: 'Post Office' },
    { name: 'Religious Building', value: 'Religious Building' },
    { name: 'Retail', value: 'Retail' },
    { name: 'School or University', value: 'School or University' },
    { name: 'Single Family', value: 'Single Family' },
    { name: 'Sports Arena', value: 'Sports Arena' },
    { name: 'Town Hall', value: 'Town Hall' },
    { name: 'Transportation', value: 'Transportation' },
    { name: 'Warehouse', value: 'Warehouse' },
    { name: 'Workshop', value: 'Workshop' },
]

export default ProductBuildingType
