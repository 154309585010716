import Divider from '@material-ui/core/Divider'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Skeleton from '@material-ui/lab/Skeleton'
import clsx from 'clsx'
import GridContainer from 'components/Surfaces/GridContainer'
import GridItem from 'components/Surfaces/GridItem'
import React from 'react'
import { NormalizedProductTypeProperties } from '../../model'
import { RightSideProps } from './ContentContainer'
import { RightSideHeaderLower } from './RightSideHeaderLower'
import { RightSideHeaderUpper } from './RightSideHeaderUpper'
import { RightSidePropertyItem } from './RightSidePropertyItem'

const useStyles = makeStyles((theme: Theme) => ({
    properties: {
        backgroundColor: '#F2F2F2',
        height: '100%',
    },
    propertiesBody: {
        padding: `${theme.spacing(1)}px 0 ${theme.spacing(1)}px ${theme.spacing(
            1.5
        )}px`,
        overflow: 'auto',
    },
    propertiesMinBody: {
        height: '81%',
    },
    propertiesMaxBody: {
        maxHeight: '81%',
    },
    propertiesBodyItem: {
        paddingBottom: theme.spacing(1),
    },
    fullHeight: {
        height: '100%',
    },
    divider: {
        marginLeft: -theme.spacing(1.5),
        width: `calc(100% + ${theme.spacing(1.5)}px)`,
        backgroundColor: '#FFF',
    },
}))

export const RightSide: React.FC<RightSideProps> = ({
    selectedProductType,
    loadingSelectedProductType,
    errorSelectedProductType,
    onSelectProductTypeProperty,
    propertiesSelectedCount,
}) => {
    const classes = useStyles()

    // This will group the properties by property type level
    // Properties with same level and name will be in one group
    const groupedMap = selectedProductType?.properties?.reduce(
        (entryMap, e) => {
            const groupName =
                e.propertyTypeLevel === e.propertyName
                    ? ''
                    : e.propertyTypeLevel
            return entryMap.set(groupName ?? '', [
                ...(entryMap.get(groupName ?? '') || []),
                e,
            ])
        },
        new Map<string, Partial<NormalizedProductTypeProperties>[]>()
    )

    return (
        // This is an item of ContentComponent
        <GridContainer
            direction="column"
            item
            xs={7}
            sm={8}
            md={9}
            className={classes.properties}
            data-testid="dvRightSide"
        >
            {!errorSelectedProductType && selectedProductType && (
                <>
                    <RightSideHeaderUpper
                        loading={loadingSelectedProductType}
                        categories={selectedProductType.categories}
                        sectionName={
                            selectedProductType.sectionNameWithoutNmber
                        }
                        productType={selectedProductType.productTypeName}
                    />
                    <RightSideHeaderLower
                        loading={loadingSelectedProductType}
                        count={propertiesSelectedCount}
                    />

                    {loadingSelectedProductType && (
                        <GridContainer
                            direction="row"
                            className={clsx(
                                classes.propertiesBody,
                                classes.propertiesMaxBody
                            )}
                        >
                            <GridContainer
                                item
                                justifyContent="center"
                                alignItems="center"
                                className={classes.propertiesBodyItem}
                                data-testid="dvLoadingProperties"
                            >
                                <GridItem xs={2}>
                                    <Skeleton
                                        variant="rect"
                                        animation="pulse"
                                        width="90%"
                                        height={50}
                                    />
                                </GridItem>
                                <GridItem xs={10}>
                                    <Skeleton
                                        variant="rect"
                                        animation="pulse"
                                        width="50%"
                                        height={50}
                                    />
                                </GridItem>
                            </GridContainer>
                            <GridContainer
                                item
                                justifyContent="center"
                                alignItems="center"
                                className={classes.propertiesBodyItem}
                            >
                                <GridItem xs={2}>
                                    <Skeleton
                                        variant="rect"
                                        animation="pulse"
                                        width="90%"
                                        height={50}
                                    />
                                </GridItem>
                                <GridItem xs={10}>
                                    <Skeleton
                                        variant="rect"
                                        animation="pulse"
                                        width="60%"
                                        height={100}
                                    />
                                </GridItem>
                            </GridContainer>
                        </GridContainer>
                    )}
                    {!loadingSelectedProductType && (
                        <>
                            {selectedProductType.properties &&
                            selectedProductType.properties?.length > 0 ? (
                                <GridContainer
                                    direction="row"
                                    className={clsx(
                                        classes.propertiesBody,
                                        classes.propertiesMaxBody
                                    )}
                                >
                                    {groupedMap &&
                                        Array.from(groupedMap.keys()).map(
                                            (value, groupIndex) => (
                                                <React.Fragment
                                                    key={groupIndex}
                                                >
                                                    {groupedMap
                                                        ?.get(value)
                                                        ?.map(
                                                            (
                                                                each,
                                                                optionIndex,
                                                                array
                                                            ) =>
                                                                each.optionGroups?.flatMap(
                                                                    (grp) =>
                                                                        grp.options
                                                                ).length !==
                                                                    0 && (
                                                                    <React.Fragment
                                                                        key={`${groupIndex}-${each.propertyId}`}
                                                                    >
                                                                        {((optionIndex ===
                                                                            0 &&
                                                                            groupIndex !==
                                                                                0) ||
                                                                            array.length ===
                                                                                1) && (
                                                                            <Divider
                                                                                className={
                                                                                    classes.divider
                                                                                }
                                                                            />
                                                                        )}
                                                                        <RightSidePropertyItem
                                                                            property={
                                                                                each
                                                                            }
                                                                            onSelectProductTypeProperty={
                                                                                onSelectProductTypeProperty
                                                                            }
                                                                            showHeader={
                                                                                value !==
                                                                                    '' &&
                                                                                optionIndex ===
                                                                                    0
                                                                            }
                                                                        />
                                                                    </React.Fragment>
                                                                )
                                                        )}
                                                </React.Fragment>
                                            )
                                        )}
                                </GridContainer>
                            ) : (
                                <GridContainer
                                    direction="row"
                                    className={clsx(
                                        classes.propertiesBody,
                                        classes.propertiesMinBody
                                    )}
                                >
                                    <GridContainer
                                        item
                                        justifyContent="center"
                                        alignItems="center"
                                        className={classes.propertiesBodyItem}
                                    >
                                        <GridItem>
                                            <Typography data-testid="lblNoProperties">
                                                No Properties
                                            </Typography>
                                        </GridItem>
                                    </GridContainer>
                                </GridContainer>
                            )}
                        </>
                    )}
                </>
            )}
        </GridContainer>
    )
}
