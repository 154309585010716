import { makeStyles, Theme } from '@material-ui/core/styles'
import Switch from '@material-ui/core/Switch'
import clsx from 'clsx'
import React from 'react'

const styleSwitch = makeStyles((theme: Theme) => ({
    switchBody: {
        width: 42,
        height: 24,
        padding: 0,
        margin: theme.spacing(1),
    },
    focusVisible: {},
    switchBase: {
        padding: 0,
        '&$checked': {
            transform: 'translateX(19px)',
            color: theme.palette.common.white,
            width: 23,
            height: 23,
            border: '1px solid #46B4EB',

            '& + $track': {
                backgroundColor: '#46B4EB',
                opacity: 1,
            },
        },
    },
    thumb: {
        width: 23,
        height: 23,
        backgroundColor: '#FFFFFF',
    },
    track: {
        borderRadius: 28,
        border: `1px solid #ffffff`,
        backgroundColor: '#EEEEEE',
        opacity: 1,
        transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {},
}))
export interface SwitchButtonProps {
    /** Event handler for click event */
    onChange?:
        | ((
              event: React.ChangeEvent<HTMLInputElement>,
              checked: boolean
          ) => void)
        | undefined

    color?: 'primary' | 'secondary'
    /**
     * Switch is checked or not
     */
    checked?: boolean
}

export const SwitchButton: React.FC<SwitchButtonProps> = ({
    color = 'primary',
    checked,
    onChange,
    ...rest
}) => {
    const classes = styleSwitch()
    return (
        <Switch
            data-testid={`switch-Button`}
            color={color}
            className={clsx('switchButton', classes.switchBase)}
            {...rest}
            disableRipple
            classes={{
                root: classes.switchBody,
                switchBase: classes.switchBase,
                thumb: classes.thumb,
                track: classes.track,
                checked: classes.checked,
            }}
            checked={checked}
            onChange={onChange}
        />
    )
}
