import { Checkbox, FormControlLabel, makeStyles } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import Popover from '@material-ui/core/Popover'
import Typography from '@material-ui/core/Typography'
import React, { ChangeEvent } from 'react'
import GridContainer from 'components/Surfaces/GridContainer'
import GridItem from 'components/Surfaces/GridItem'
import { pxToRem } from 'components/Utilities/unitUtils'
import AutoCompleteFilter from './AutocompleteFilter'
import CheckboxFilter from './CheckboxFilter'

const FilterButtonStyles = makeStyles({
    filterButton: {
        backgroundColor: '#D9F1F0',
        border: '2px solid #02A09C',
        borderRadius: 100,
        height: 38,
    },
    filterButtonEmptySelection: {
        backgroundColor: '#FFFFFF',
        border: '1px solid #02A09C',
        borderRadius: 100,
        height: 38,
    },
    filterButtonOpen: {
        backgroundColor: '#24CEAD',
        border: '1px solid #02A09C',
        borderRadius: 100,
        height: 38,
    },
    filterButtonText: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '14px',
        lineHeight: '16px',
        color: '#202020',
        textTransform: 'none',
    },
    filterButtonTextEmptySelection: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '16px',
        color: '#5E5F61',
        textTransform: 'none',
    },
    filterButtonTextOpen: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '14px',
        lineHeight: '16px',
        color: '#FFFFFF',
        textTransform: 'none',
    },
    filterBox: {
        width: 360,
        height: 486,
        border: '1px solid #C9C9C9',
        boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.3)',
        borderRadius: 5,
    },
    autoHeight: {
        height: 'auto',
    },
    filterBoxHeaderTextContainer: {
        height: 56,
        padding: 14,
    },
    filterBoxAutocompleteContainer: {
        padding: 14,
        flex: 1,
    },
    filterBoxBottomButtonsContainer: {
        padding: 14,
        height: 71,
    },
    filterBoxHeaderText: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '20px',
        lineHeight: '25px',
        color: '#047A7D',
    },
    divider: {
        backgroundColor: '#000000',
        height: 2,
    },
    clearButton: {
        border: '1px solid #5E5F61',
        textTransform: 'none',
        borderRadius: 100,
        height: 38,
        paddingLeft: 20,
        paddingRight: 20,
        marginRight: 9,
    },
    closeButton: {
        textTransform: 'none',
        background: '#04797C',
        '&:hover': {
            background: '#036063',
        },
        '&:focus': {
            outline: 'none',
            background: '#036063',
        },
        fontWeight: 'normal',
        borderRadius: 100,
        height: 38,
        paddingLeft: 20,
        paddingRight: 20,
        border: 'none',
        cursor: 'pointer',
    },
    secondaryFilter: {
        float: 'right',
        marginRight: 0,
    },
    secondaryFilterCheckbox: {
        transform: 'scale(.75)',
        paddingRight: 0,
    },
    secondaryFilterLabel: {
        fontSize: pxToRem(12),
        fontWeight: 500,
        float: 'right',
    },
})

export type SecondaryFilter = {
    key: string
    label: string
}
export interface FilterButtonProps {
    /** What kind of filter to display in the popover. */
    variant?: 'Autocomplete' | 'Checkboxes'

    /**
     * Label for the button.
     */
    label: string

    /**
     * Text to show in the selection box (e..g, "Select a value")
     */
    selectPlaceholder?: string

    /**
     * Filter button name (needed to link the popover to the filter button.)
     */
    name: string

    /**
     * Values to show in the filter list.
     */
    items: string[]

    /**
     * Callback fired when the selection changes.
     */
    onChange?: (
        /** Event source */
        event: ChangeEvent<{}>,
        /** New selection */
        value: string[]
    ) => void

    /** Callback fired shortly after the input text changes. */
    onInputChangeDebounced?: (value: string) => void

    /**
     * Items to show as selected.
     */
    selectedItems?: string[]

    /** If displayed pill and on hover are different, we can control it by passing the same set of modified array here.
     * For Family Names, the displayed value in the pill has no sectionNumber compared to the value on hover which has
     */
    selectedDisplayItems?: string[]

    /** Items to show in the list as disabled/non-selectable. */
    disabledItems?: string[]

    /** Fired when user opens the autocomplete drop-down. */
    onOpenDropDown?: (event: ChangeEvent<{}>) => void

    /** Fired when user closes the autocomplete drop-down. */
    onCloseDropDown?: (event: ChangeEvent<{}>) => void

    /**
     * Fired when the filter popover is closed, either due to a "Display result" button click,
     * or closing the popover some other way such as clicking outside of it.
     */
    onClose?: () => void

    /** If true, show a loading state for the Autocomplete drop-down listbox. */
    loading?: boolean
    /**
     * handles show/hide of the more filters container
     */
    expandMoreFilters: (val: boolean) => void
    /** If true, show custom format for option. */
    customFormat?: boolean

    secondaryFilter?: SecondaryFilter

    hasSelectedSecondaryFilter?: boolean

    onSecondaryFilterChange?: (event: ChangeEvent<{}>, value: string[]) => void

    onClearFilters?: (keys: string[]) => void
}

/**
 * Component with a button that, when clicked, displays a popover with an Autocomplete multi-select.
 */
const FilterButton: React.FC<FilterButtonProps> = ({
    label,
    selectPlaceholder,
    name,
    items,
    selectedItems = [],
    selectedDisplayItems = [],
    disabledItems,
    onOpenDropDown,
    onCloseDropDown,
    onChange,
    onInputChangeDebounced,
    onClose,
    expandMoreFilters,
    variant = 'Autocomplete',
    loading = false,
    customFormat = false,
    secondaryFilter,
    hasSelectedSecondaryFilter = false,
    onSecondaryFilterChange,
    onClearFilters,
}) => {
    const filterButtonStyles = FilterButtonStyles()
    const emptyArray: string[] = []

    const [
        popoverAnchorEl,
        setPopoverAnchorEl,
    ] = React.useState<HTMLButtonElement | null>(null)
    const isPopoverOpen = Boolean(popoverAnchorEl)
    const popoverId = isPopoverOpen ? `${name}-popover` : undefined

    const isSelectionEmpty = !selectedItems || !selectedItems.length
    const labelText = isSelectionEmpty
        ? label
        : `${label} (${selectedItems?.length})`

    let autocompleteInputEl: HTMLElement
    const onInputRef = (inputElement: HTMLElement) => {
        autocompleteInputEl = inputElement
    }

    const handleFilterButtonClick = (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        expandMoreFilters(false)
        setPopoverAnchorEl(event.currentTarget)
    }

    const handlePopoverClose = () => {
        setPopoverAnchorEl(null)
        onClose?.()
    }

    const handleChange = (event: ChangeEvent<{}>, value: string[]) => {
        onChange?.(event, value)
    }

    const handleClearButtonClick = (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        if (name === 'filter-listing-type')
            onClearFilters?.(['listingLevels', 'withManufacturerOption'])
        else onChange?.(event, emptyArray)

        autocompleteInputEl && autocompleteInputEl.focus()
    }

    const handleSecondaryFilterChange = (
        event: ChangeEvent<HTMLInputElement>
    ) => {
        if (!onSecondaryFilterChange) {
            return
        }
        if (event.target.checked) onSecondaryFilterChange(event, ['true'])
        else onSecondaryFilterChange(event, [])
    }

    const filter =
        variant === 'Autocomplete' ? (
            <>
                <AutoCompleteFilter
                    name={`${name}-autocomplete`}
                    items={items}
                    placeholder={isSelectionEmpty ? selectPlaceholder : ''}
                    onChange={handleChange}
                    onInputChangeDebounced={onInputChangeDebounced}
                    onOpen={onOpenDropDown}
                    onClose={onCloseDropDown}
                    selectedItems={selectedItems}
                    selectedDisplayItems={selectedDisplayItems}
                    onInputRef={onInputRef}
                    loading={loading}
                    customFormat={customFormat}
                />
            </>
        ) : (
            <CheckboxFilter
                name={`${name}-checkbox`}
                items={items}
                selectedItems={selectedItems}
                disabledItems={disabledItems}
                onChange={handleChange}
            />
        )
    return (
        <>
            <Button
                disableElevation
                className={
                    isPopoverOpen
                        ? filterButtonStyles.filterButtonOpen
                        : isSelectionEmpty
                        ? filterButtonStyles.filterButtonEmptySelection
                        : filterButtonStyles.filterButton
                }
                aria-describedby={popoverId}
                variant="contained"
                onClick={handleFilterButtonClick}
                data-testid={`${name}-filter-button`}
            >
                <Typography
                    className={
                        isPopoverOpen
                            ? filterButtonStyles.filterButtonTextOpen
                            : isSelectionEmpty
                            ? filterButtonStyles.filterButtonTextEmptySelection
                            : filterButtonStyles.filterButtonText
                    }
                >
                    {labelText}
                </Typography>
            </Button>
            <Popover
                id={popoverId}
                anchorEl={popoverAnchorEl}
                open={isPopoverOpen}
                onClose={handlePopoverClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: -7,
                    horizontal: 'center',
                }}
                onEntered={() => {
                    autocompleteInputEl && autocompleteInputEl.focus()
                }}
                marginThreshold={7}
            >
                <GridContainer
                    direction="column"
                    className={
                        variant === 'Autocomplete'
                            ? filterButtonStyles.filterBox
                            : `${filterButtonStyles.filterBox} ${filterButtonStyles.autoHeight}`
                    }
                >
                    <GridItem
                        className={
                            filterButtonStyles.filterBoxHeaderTextContainer
                        }
                    >
                        <Typography
                            className={filterButtonStyles.filterBoxHeaderText}
                            component="div"
                        >
                            {label}
                            {!!secondaryFilter && (
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            name={secondaryFilter.key}
                                            color="default"
                                            checked={hasSelectedSecondaryFilter}
                                            onChange={
                                                handleSecondaryFilterChange
                                            }
                                            className={
                                                filterButtonStyles.secondaryFilterCheckbox
                                            }
                                        />
                                    }
                                    label={secondaryFilter.label}
                                    classes={{
                                        root:
                                            filterButtonStyles.secondaryFilter,
                                        label:
                                            filterButtonStyles.secondaryFilterLabel,
                                    }}
                                    data-testid={`${secondaryFilter.key}-checkbox-secondary`}
                                />
                            )}
                        </Typography>
                    </GridItem>
                    <GridItem>
                        <Divider className={filterButtonStyles.divider} />
                    </GridItem>
                    <GridItem
                        className={
                            filterButtonStyles.filterBoxAutocompleteContainer
                        }
                    >
                        {filter}
                    </GridItem>
                    <GridItem>
                        <Divider className={filterButtonStyles.divider} />
                    </GridItem>
                    <GridItem
                        container
                        className={
                            filterButtonStyles.filterBoxBottomButtonsContainer
                        }
                        direction="row"
                        justifyContent="flex-end"
                    >
                        <Button
                            disableElevation
                            data-testid={`${name}-clear-button`}
                            className={filterButtonStyles.clearButton}
                            onClick={handleClearButtonClick}
                        >
                            Clear
                        </Button>

                        {/* Native button used inorder to rectify flickering issue for chrome browser*/}
                        <button
                            data-testid={`${name}-close-button`}
                            className={filterButtonStyles.closeButton}
                            onClick={handlePopoverClose}
                        >
                            <Typography variant="body2" color="secondary">
                                Close
                            </Typography>
                        </button>
                    </GridItem>
                </GridContainer>
            </Popover>
        </>
    )
}

export default FilterButton
