import { FieldFunctionOptions, FieldPolicy, TypePolicy } from '@apollo/client'
import {
    ListingSearchInput,
    ManufacturerSearchResultOfListingMatch,
} from 'components/Models/listingSearch'

export const listingDetailTypePolicy = {
    ListingDetail: {
        keyFields: ['listingId'],
    } as TypePolicy,
}

export const listingMatchTypePolicy = {
    ListingMatch: {
        keyFields: ['listingId'],
    } as TypePolicy,
}

export const listingSearchFieldPolicy = {
    listingSearch: {
        keyArgs: (args: Record<string, any> | null) => {
            /*
             *   Exclude top and skip
             *   If included apollo will create a new listing in cache
             *   for each change in top and skip
             */
            const {
                top,
                skip,
                ...uniqueInput
            }: ListingSearchInput = args?.input

            // Unique identifier of list in cache based on the input
            return JSON.stringify(uniqueInput)
        },
        merge(
            existing: Partial<ManufacturerSearchResultOfListingMatch> = {},
            incoming: Partial<ManufacturerSearchResultOfListingMatch>,
            { args }: FieldFunctionOptions
        ) {
            if (args && args.input.skip === 0) {
                // Initial fetch or refetch
                return incoming
            }

            // Pagination
            return {
                ...existing,
                matches: [
                    ...(existing?.matches ?? []),
                    ...(incoming?.matches ?? []),
                ],
            }
        },
    } as FieldPolicy,
}
