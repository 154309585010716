import { ApolloError } from '@apollo/client'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import clsx from 'clsx'
import { FacetValueToCount } from 'components/Models/facet'
import GridContainer from 'components/Surfaces/GridContainer'
import GridItem from 'components/Surfaces/GridItem'
import WidgetContainer from 'components/Surfaces/WidgetContainer'
import { pxToRem } from 'components/Utilities/unitUtils'
import { CdnBaseUrl } from 'components/Utilities/urlUtils'
import {
    defaultSortAndGroup,
    escapeElementDelimiter,
} from 'components/Widgets/ProductListing/productsUtils'
import SpecImage from 'components/Widgets/SpecImage'
import React from 'react'
import NumberFormat from 'react-number-format'
import { Link } from 'react-router-dom'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'

interface ListingCategoriesWidgetStyleProps {
    height: number
}

const useStyles = makeStyles({
    headerLinkContainer: {
        marginTop: -5,
    },
    headerLink: {
        color: '#FFFFFF',
        fontSize: pxToRem(16),
        cursor: 'default',
        textDecoration: 'none',
        '&:hover': {
            color: '#D4F1F4',
            textDecoration: 'none',
            cursor: 'pointer',
        },
    },
    outerContainer: {
        padding: 10,
        width: '100%',
    },
    innerContainerNoScroll: {
        height: (props: ListingCategoriesWidgetStyleProps) =>
            props.height - 10 * 2, //this is the outerContainer margin
        overflowY: 'auto',
    },
    innerContainerScroll: {
        overflowY: 'auto',
        maxHeight: (props: ListingCategoriesWidgetStyleProps) =>
            props.height - 74,
    },
    categoryItem: {
        textAlign: 'center',
        width: 125,
        fontSize: pxToRem(14),
    },
    categoryImage: {
        margin: 2,
        width: 100,
        height: 100,
        borderRadius: '50%',
    },
    categoryText: {
        fontSize: pxToRem(14),
        textDecoration: 'none',
        color: '#000000',
    },
})

interface ListingCategoriesWidgetProps {
    width?: number | string
    minWidth?: number | string
    contentHeight?: number
    categories?: FacetValueToCount[]
    firmLoading?: boolean
    firmName?: string
    firmDisplayName?: string
    error?: ApolloError
    /**
     * Function to run when clicking the link
     */
    handleCategoriesWidgetClick?: (link: string) => void
    /**
     * This prop is temporary during ae-bpm merge and will be removed once BPM will be deprecated

     */
    callingFromAE?: boolean
}

interface ItemProps {
    category: FacetValueToCount
    itemClassName?: string
    imageClassName?: string
    textClassName?: string
    firmName?: string
    firmDisplayName?: string
    handleCategoriesWidgetClick?: (link: string) => void
    callingFromAE?: boolean
}

const Item = SortableElement<ItemProps>(
    React.memo(
        ({
            category,
            itemClassName,
            imageClassName,
            textClassName,
            firmName,
            firmDisplayName,
            handleCategoriesWidgetClick,
            callingFromAE,
        }: ItemProps) => {
            const categoryTotal = category.count
            const mergedClientURL = callingFromAE ? '/bpm' : ''
            const defaultParams = `${mergedClientURL}/products/all?${encodeURI(
                defaultSortAndGroup
            )}&df=${encodeURIComponent(
                `'${escapeElementDelimiter(
                    firmDisplayName ?? ''
                )}'%|%'${escapeElementDelimiter(firmName ?? '')}'`
            )}&ia=true&ct=${category.value}`
            return (
                <GridItem
                    className={clsx(itemClassName, 'dvListingCategoryItem')}
                >
                    <Link
                        to={defaultParams}
                        className={clsx(
                            textClassName,
                            'linkListingCategoryItem'
                        )}
                        onClick={() =>
                            handleCategoriesWidgetClick?.(defaultParams)
                        }
                    >
                        <div>
                            <SpecImage
                                alt="Category image"
                                src={`${CdnBaseUrl}/specpoint/ae/categories/${category.value
                                    .toLocaleLowerCase()
                                    .replace(/\s/g, '_')}.jpg`}
                                data-testid="categoryImage"
                                height="100"
                                width="100"
                                additionalClassNames={clsx(imageClassName)}
                            />
                        </div>
                        <NumberFormat
                            prefix={`${category.value} (`}
                            suffix={`)`}
                            thousandSeparator={true}
                            value={categoryTotal}
                            displayType="text"
                        />
                    </Link>
                </GridItem>
            )
        }
    )
)
interface ContainerProps {
    children?: React.ReactNode
    className?: string
}

const Container = SortableContainer<ContainerProps>(
    ({ className, children }: ContainerProps) => {
        return (
            <GridContainer
                alignContent="flex-start"
                alignItems="flex-start"
                spacing={1}
                className={className}
            >
                {children}
            </GridContainer>
        )
    }
)

const ListingCategoriesWidget: React.FC<ListingCategoriesWidgetProps> = ({
    width = '100%',
    minWidth = 200,
    contentHeight = 200,
    categories,
    firmLoading = true,
    firmName,
    firmDisplayName,
    error,
    handleCategoriesWidgetClick,
    callingFromAE,
}) => {
    const classes = useStyles({ height: contentHeight })

    const [items, setItems] = React.useState<FacetValueToCount[]>([])

    React.useEffect(() => {
        const sorted = [...(categories ?? [])].sort(
            (first: FacetValueToCount, second: FacetValueToCount) =>
                second.count - first.count
        )

        setItems(sorted)
    }, [categories])

    const name = React.useMemo(
        () =>
            firmLoading
                ? 'Listing Categories'
                : `Listing Categories (${items.length.toLocaleString()})`,
        [firmLoading, items]
    )

    const defaultUrl = React.useMemo(
        () =>
            `${callingFromAE ? '/bpm' : ''}/products/all?${encodeURI(
                defaultSortAndGroup
            )}&ia=true&df=${encodeURIComponent(
                `'${escapeElementDelimiter(
                    firmDisplayName ?? ''
                )}'%|%'${escapeElementDelimiter(firmName ?? '')}'`
            )}`,
        [defaultSortAndGroup, firmDisplayName, firmName]
    )

    const HeaderViewAll = React.useMemo(
        () =>
            items.length > 1 && (
                <Typography
                    className={classes.headerLink}
                    component={Link}
                    variant="h6"
                    to={defaultUrl}
                    data-testid={'linkListingCategoriesViewAll'}
                    onClick={() => handleCategoriesWidgetClick?.(defaultUrl)}
                >
                    View All
                </Typography>
            ),
        [items, classes.headerLink, defaultUrl]
    )

    return (
        <WidgetContainer
            headerBackground="linear-gradient(90deg, #02A09C 0%, #05666D 100%);"
            borderRadius={8}
            title={name}
            titleFontSize={16}
            width={width}
            minWidth={minWidth}
            titleFontWeight={500}
            contentBackground="#f2f2f2"
            headerComponent={HeaderViewAll}
            centerHeaderComponent
            centerHeaderTitle
            loading={firmLoading}
            error={error}
            contentHeight={contentHeight}
            data-testid={'listingCategoriesWidget'}
        >
            <div className={classes.outerContainer}>
                <Container
                    className={
                        items?.length > 4
                            ? classes.innerContainerScroll
                            : classes.innerContainerNoScroll
                    }
                    pressDelay={200}
                    axis="xy"
                >
                    {items?.map(
                        (category: FacetValueToCount, index: number) => {
                            return (
                                <Item
                                    key={`item-${index}`}
                                    index={index}
                                    disabled={true}
                                    category={category}
                                    itemClassName={classes.categoryItem}
                                    imageClassName={classes.categoryImage}
                                    textClassName={classes.categoryText}
                                    firmName={firmName}
                                    firmDisplayName={firmDisplayName}
                                    handleCategoriesWidgetClick={
                                        handleCategoriesWidgetClick
                                    }
                                    callingFromAE
                                />
                            )
                        }
                    )}
                </Container>
            </div>
        </WidgetContainer>
    )
}

export default ListingCategoriesWidget
