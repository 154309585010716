import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormGroup from '@material-ui/core/FormGroup'
import { makeStyles } from '@material-ui/core/styles'
import React, { ChangeEvent } from 'react'

const CheckboxFilterStyles = makeStyles({
    label: {
        color: '#5E5F61',
    },
})

export interface CheckboxFilterProps {
    name: string
    items: string[]
    onChange?: (event: ChangeEvent<{}>, value: string[]) => void
    selectedItems?: string[]
    disabledItems?: string[]
}

const CheckboxFilter: React.FC<CheckboxFilterProps> = ({
    name,
    items,
    selectedItems,
    disabledItems,
    onChange,
}) => {
    const styles = CheckboxFilterStyles()
    const selectedValues = new Set<string>(selectedItems)

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!onChange) {
            return
        }

        const itemName = event.target.name
        if (event.target.checked) {
            const newSelection = selectedItems
                ? [...selectedItems, itemName]
                : [itemName]
            onChange(event, newSelection)
        } else {
            const newSelection = selectedItems
                ? selectedItems.filter((item) => item !== itemName)
                : []
            onChange(event, newSelection)
        }
    }
    return (
        <FormGroup>
            {items.map((item, index) => (
                <FormControlLabel
                    key={`${name}-checkbox-${index}`}
                    data-testid={`${name}-label-${item}-test`}
                    classes={{ label: styles.label }}
                    control={
                        <Checkbox
                            data-testid={`${name}-checkbox-${item}-test`}
                            name={item}
                            color="default"
                            checked={selectedValues.has(item)}
                            disabled={disabledItems?.includes(item)}
                            onChange={handleChange}
                        />
                    }
                    label={item}
                />
            ))}
        </FormGroup>
    )
}

export default CheckboxFilter
