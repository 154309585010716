import { makeStyles } from '@material-ui/core/styles'
import Skeleton from '@material-ui/lab/Skeleton'
import { suffixDimension } from 'components/Utilities/stringUtils'
import SpecImage from 'components/Widgets/SpecImage'
import React, { useEffect, useState } from 'react'
import { getDefaultBannerUrlByCategoryName } from './companyBannerUtil'
import useCompanyBannerSizeConstants from './useCompanyBannerSizeConstants'

const companyBannerStyles = makeStyles(() => ({
    companyBannerImg: {
        maxWidth: '100%',
        objectFit: 'fill',
    },
}))

export interface CompanyBannerProps {
    bannerUrl?: string
    defaultCategoryName: string
    loading: boolean
}

const CompanyBanner: React.FC<CompanyBannerProps> = ({
    bannerUrl,
    defaultCategoryName,
    loading,
}) => {
    const classes = companyBannerStyles()
    const [computedBannerUrl, setComputedBannerUrl] = useState<string>()
    const {
        isXs,
        isGreaterThanXl,
        computedDimension,
        theme,
    } = useCompanyBannerSizeConstants()
    const [specImageLoading, setSpecImageLoading] = useState<boolean>(false)

    useEffect(() => {
        if (loading) return

        if (bannerUrl) {
            setComputedBannerUrl(
                suffixDimension({
                    filename: bannerUrl,
                    dimension: computedDimension,
                })
            )
            return
        }

        if (defaultCategoryName) {
            let thisBannerUrl = getDefaultBannerUrlByCategoryName({
                categoryName: defaultCategoryName,
            })

            thisBannerUrl = suffixDimension({
                filename: thisBannerUrl,
                dimension: computedDimension,
            })

            setComputedBannerUrl(thisBannerUrl)
        }
    }, [loading, bannerUrl, computedDimension, defaultCategoryName])

    return (
        <div
            style={{
                marginLeft: isGreaterThanXl ? 0 : -theme.spacing(3),
                marginRight: isGreaterThanXl
                    ? 0
                    : isXs // For XS screens, the 3-point spacing is causing horizontal overflow.
                    ? -theme.spacing(2)
                    : -theme.spacing(3),
                height: computedDimension.height,
            }}
            data-testid="companyBannerContainer"
        >
            {(!computedBannerUrl || loading || specImageLoading) && (
                <Skeleton
                    data-testid="companyBannerSkeleton"
                    variant="rect"
                    className={classes.companyBannerImg}
                    height={computedDimension.height}
                />
            )}
            {!loading && computedBannerUrl && (
                <SpecImage
                    additionalClassNames={classes.companyBannerImg}
                    alt={defaultCategoryName}
                    src={computedBannerUrl}
                    handleImageLoading={setSpecImageLoading}
                    data-testid="companyBannerImg"
                    height={computedDimension.height}
                />
            )}
        </div>
    )
}

export default CompanyBanner
