import { FormProps, FormPropsObject } from 'components/Models/formProps'
import { stringIsNullOrWhitespace } from './stringUtils'

export interface StateSetStateProps {
    state: FormPropsObject
    setState: React.Dispatch<React.SetStateAction<FormPropsObject>>
}

export interface FormElementNameValueProps extends Pick<FormProps, 'value'> {
    name: string
    valid?: boolean
}

export interface FormValueDirtyProps {
    prevFormState: FormPropsObject
    currentFormState: FormPropsObject
}

export interface FormItemValidProps extends Pick<FormProps, 'value'> {
    currentFormItem: FormProps
}

export interface ValidateFormStateProps {
    state: FormPropsObject
    setState: React.Dispatch<React.SetStateAction<FormPropsObject>>
}

/**
 * This validates the property.
 * If the required property is set to true, it checks the value to
 * see if there is a value.  It also checks input fields that are of type number
 * and will return invalid if it is "0" since all input value return strings
 */
export const validateRequiredForm = ({
    required,
    value,
}: Pick<FormProps, 'required' | 'value'>) =>
    !required || !stringIsNullOrWhitespace(value)

/**
 * This is a helper method to update your form state with the current value being passed in.
 * Normally this is used in an OnClick / OnChange event of the form.
 * You can include your own validation by passing the result to valid property and then it will
 * validate the required field
 */
export const updateFormState = ({ state, setState }: StateSetStateProps) => (
    ...formElements: FormElementNameValueProps[]
) => {
    const newState = formElements.reduce((accumulator, each) => {
        const currentFormItem = state[each.name] as FormProps
        accumulator[each.name] = {
            ...currentFormItem,
            value: each.value,
            valid:
                each.valid &&
                validateRequiredForm({
                    required: currentFormItem.required,
                    value: each.value,
                }),
        }

        return accumulator
    }, {} as FormPropsObject)

    setState({ ...state, ...newState })
}

/**
 * Compares two `FormPropsObject` and find if any values has changed.
 */
export const isFormValueDirty = ({
    prevFormState,
    currentFormState,
}: FormValueDirtyProps) => {
    const prevFormStateKeys = Object.keys(prevFormState)
    return (
        prevFormStateKeys.find(
            (prevFormStateKey: string) =>
                prevFormState[prevFormStateKey].value !==
                currentFormState[prevFormStateKey].value
        ) !== undefined
    )
}

/**
 * This is a helper function to check if the form props is valid.
 * Used by TextFields to verify if it needs to throw error on the component
 */
export const hasError = ({ valid }: FormProps) => valid !== undefined && !valid

/**
 * This is a helper method that will run through all the object keys in your state
 * and make sure that all the values are filled for required properties
 */
export const validateFormState = ({
    state,
    setState,
}: ValidateFormStateProps): boolean => {
    const stateObjects = Object.keys(state)

    const updatedState: FormPropsObject = { ...state }
    let formValid = true
    stateObjects.forEach((stateName: string) => {
        const currentStateItem = state[stateName]
        if (!currentStateItem.required && currentStateItem.valid === undefined)
            return
        if (formValid && hasError(currentStateItem)) {
            formValid = false
            return
        }

        currentStateItem.valid =
            (currentStateItem.valid ?? true) &&
            validateRequiredForm({
                ...currentStateItem,
            })

        if (formValid && !currentStateItem.valid) formValid = false
    })

    setState(updatedState)
    return formValid
}

/**
 * Utility method to listen to keypress for debouncing
 * @param fn function to debounce
 * @param delay optional default of 300ms
 */
export const debounce = (fn: Function, delay: number = 300) => {
    let timeoutId: NodeJS.Timeout
    return function (...args: any) {
        clearInterval(timeoutId)
        timeoutId = setTimeout(() => fn(...args), delay)
    }
}

export const GuidEmpty = '00000000-0000-0000-0000-000000000000'

/**
 * This is a functional method that will reorder an item within an array.
 * Example is this array
 * const currentArray = ["Apple", "Orange", "Banana"]
 * // If you want to move Banana before the Orange, you can call this method
 * const reorderedArray = reorderArrayItems(currentArray, 2, 1)
 * Note that the index i'm using is Banana's index.
 *
 * This method is useful when you are sorting list items. Good use for drag and drop with sortable.
 * @param arrayObj The TData[] array
 * @param fromIdx The from/source index
 * @param toIdx The to/destination index
 */
export const reorderArrayItems = <TData>(
    arrayObj: TData[],
    fromIdx: number,
    toIdx: number
): TData[] => {
    const result = Array.from(arrayObj)
    const [removed] = result.splice(fromIdx, 1)
    result.splice(toIdx, 0, removed)
    return result
}

export interface MoveItemToAnotherArrayResponse<TData> {
    fromArray: TData[]
    toArray: TData[]
}
export interface MoveItemToAnotherArrayProps<TData>
    extends MoveItemToAnotherArrayResponse<TData> {
    fromIndex: number
    toIndex: number
}

/**
 * Pure method that will transfer an item from one array container to another.
 * @param MoveItemToAnotherArrayProps
 */
export const moveItemToAnotherArray = <TData extends Object>({
    fromArray,
    toArray,
    fromIndex,
    toIndex,
}: MoveItemToAnotherArrayProps<TData>): MoveItemToAnotherArrayResponse<TData> => {
    const sourceColumnInstancesClone = Array.from(
        JSON.parse(JSON.stringify(fromArray))
    ) as TData[]
    const destColumnInstancesClone = Array.from(
        JSON.parse(JSON.stringify(toArray))
    ) as TData[]

    const [removed] = sourceColumnInstancesClone.splice(fromIndex, 1)
    destColumnInstancesClone.splice(toIndex, 0, removed)

    return {
        fromArray: sourceColumnInstancesClone,
        toArray: destColumnInstancesClone,
    }
}
