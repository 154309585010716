import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import clsx from 'clsx'
import GridItem from 'components/Surfaces/GridItem'
import format from 'date-fns/format'
import React from 'react'

const useStyles = makeStyles(() => ({
    dateLabel: {
        textTransform: 'uppercase',
    },
    dateText: {
        fontWeight: 500,
    },
}))

interface ViewPublishedDateProps {
    label: string
    date?: string
}

const ViewPublishedDate: React.FC<ViewPublishedDateProps> = ({
    label,
    date,
}) => {
    const classes = useStyles()
    return (
        <>
            <GridItem>
                <Typography
                    variant="subtitle2"
                    className={clsx(classes.dateLabel, classes.dateText)}
                    data-testid="lblDateLabel"
                >
                    {label}
                </Typography>
            </GridItem>
            <GridItem>
                <Typography
                    variant="subtitle2"
                    className={classes.dateText}
                    data-testid="lblDateValue"
                >
                    {date ? format(new Date(date), 'MM/dd/yyyy') : ''}
                </Typography>
            </GridItem>
        </>
    )
}

export default ViewPublishedDate
