import { Button } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import clsx from 'clsx'
import ProductBuildingType from 'components/Constants/productBuildingType'
import GridContainer from 'components/Surfaces/GridContainer'
import React from 'react'

const useStyles = makeStyles((theme: Theme) => ({
    hiddenText: {
        transition: 'max-height 0.5s ease-out',
        maxHeight: 100,
        '&::after': {
            content: '""',
            position: 'absolute',
            bottom: 0,
            left: 0,
            pointerEvents: 'none',
            backgroundImage:
                'linear-gradient(to bottom, rgba(255,255,255, 0), rgba(255,255,255, 1) 90%)',
            width: '100%',
            height: '2.5em',
        },
    },
    showText: {
        height: 'auto',
        transition: 'max-height 0.5s ease-in',
        maxHeight: 500,
    },
    textContainer: {
        overflow: 'hidden',
        position: 'relative',
        marginTop: theme.spacing(-0.25),
    },
    readMoreButton: {
        marginLeft: theme.spacing(-2),
    },
    label: {
        fontWeight: 500,
    },
}))

interface ViewBuildingTypeProps {
    buildingTypes?: string[]
}

const ViewBuildingType: React.FC<ViewBuildingTypeProps> = ({
    buildingTypes,
}) => {
    const [showMore, setShowMore] = React.useState<Record<string, boolean>>({})
    const maxLength = 325
    const handleTextShow = (field: string) => (
        _e: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        setShowMore((prevState: Record<string, boolean>) => ({
            ...prevState,
            [field]: !prevState[field],
        }))
    }
    const buildingTypeText =
        ProductBuildingType.length === buildingTypes?.length
            ? 'All'
            : buildingTypes?.length === 0
            ? 'N/A'
            : buildingTypes?.join(', ')
    const classes = useStyles()
    return (
        <>
            {
                <>
                    <GridContainer>
                        <Typography
                            variant="subtitle1"
                            className={classes.label}
                        >
                            Building Type:
                        </Typography>
                    </GridContainer>
                    <GridContainer
                        item
                        className={clsx(
                            buildingTypeText &&
                                buildingTypeText.length > maxLength &&
                                (showMore.showMoreDesc
                                    ? classes.showText
                                    : classes.hiddenText),
                            classes.textContainer
                        )}
                    >
                        <Typography
                            variant="body1"
                            data-testid="lblBuildingType"
                        >
                            {buildingTypeText}
                        </Typography>
                    </GridContainer>
                </>
            }
            {buildingTypeText && buildingTypeText.length > maxLength && (
                <GridContainer item>
                    <Button
                        color="primary"
                        onClick={handleTextShow('showMoreDesc')}
                        className={classes.readMoreButton}
                        data-testid="btnRead"
                    >
                        {showMore.showMoreDesc ? 'Less' : 'More'}
                    </Button>
                </GridContainer>
            )}
        </>
    )
}

export default ViewBuildingType
