const ProductCategories = [
    'Administration',
    'Air Terminals',
    'Cable Trays',
    'Casework',
    'Ceilings',
    'Columns',
    'Communication Devices',
    'Conduits',
    'Curtain Panels',
    'Curtain Systems',
    'Curtain Wall Mullions',
    'Data Devices',
    'Detail Items',
    'Doors',
    'Duct Accessories',
    'Duct Fittings',
    'Ducts',
    'Electrical Equipment',
    'Electrical Fixtures',
    'Entourage',
    'Fire Alarm Devices',
    'Flex Ducts',
    'Flex Pipes',
    'Floors',
    'Furniture',
    'Furniture Systems',
    'Generic Models',
    'Lighting Devices',
    'Lighting Fixtures',
    'Mechanical Equipment',
    'Nurse Call Devices',
    'Parking',
    'Pipe Accessories',
    'Pipe Fittings',
    'Pipes',
    'Planting',
    'Plumbing Fixtures',
    'Profiles',
    'Railings',
    'Ramps',
    'Roofs',
    'Security Devices',
    'Site',
    'Specialty Equipment',
    'Sprinklers',
    'Stairs',
    'Structural Beam Systems',
    'Structural Columns',
    'Structural Foundations',
    'Structural Framing',
    'Structural Loads',
    'Structural Rebar',
    'Structural Stiffeners',
    'Structural Trusses',
    'Telephone Devices',
    'Wall Sweeps',
    'Walls',
    'Windows',
    'Wire',
]

export default ProductCategories
