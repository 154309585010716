import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import clsx from 'clsx'
import React from 'react'
import { FilterCategoriesExpansion } from './Context/FilterCategoriesExpansionContext'
import MoreFilterSubCategories from './MoreFilterSubCategories'
import { moreFilterCategories } from './ProductSearchBar'

const useStyles = makeStyles((theme: Theme) => ({
    moreFilter: {
        background: '#EFEDED',
        minHeight: '50px',
    },
    expandedFilter: {
        margin: '0px !important',
    },
    filterCategories: {
        height: '410px',
        overflowY: 'auto',
        overflowX: 'hidden',
        [theme.breakpoints.down('sm')]: {
            height: 140,
        },
    },
    expansionSummary: {
        background: '#ABD1D4',
        minHeight: '50px',
        border: '1px solid #22756D',
    },
    categoryName: {
        color: '#5E5F61',
        textTransform: 'capitalize',
        fontSize: '1.8rem',
    },
    expansionSummaryCont: {
        padding: 0,
    },
}))

enum FilterCategories {
    Partnerships = 1,
    AdditionalContent,
    DateRange,
    State,
    Status,
}
interface MoreFilterCategoriesProps {
    filterDisabledValues?: Record<string, string[]>
    showSubmissionStatus?: boolean
    showSwatchboxFilter?: boolean
}
const MoreFilterCategories: React.FC<MoreFilterCategoriesProps> = ({
    filterDisabledValues,
    showSubmissionStatus,
    showSwatchboxFilter,
}) => {
    const { expandedFiltersList, setExpandedFiltersList } = React.useContext(
        FilterCategoriesExpansion
    )
    /**
     * handles expand/collapse of filter category
     * @param value categoryId
     */
    const expandFilterCategory = (value: number) => {
        const checkExpanded = expandedFiltersList.findIndex(
            (categoryId) => categoryId === value
        )
        if (checkExpanded > -1) {
            expandedFiltersList.splice(checkExpanded, 1)
            setExpandedFiltersList([...expandedFiltersList])
        } else {
            setExpandedFiltersList([value, ...expandedFiltersList])
        }
    }

    /**
     * check if selected category in more filter is already expanded or not
     */
    const checkExpanded = (value: number) => {
        return expandedFiltersList.some((categoryId) => categoryId === value)
    }

    const handleExpansion = (panel: number) => () => {
        expandFilterCategory(panel)
    }

    const classes = useStyles()
    return (
        <div
            className={classes.filterCategories}
            data-testid={`filterCategoryPanelDiv`}
        >
            {moreFilterCategories.map((category) => {
                if (category.value === FilterCategories.Status) {
                    return (
                        <React.Fragment key={category.value}></React.Fragment>
                    )
                } else if (
                    !showSubmissionStatus &&
                    category.value === FilterCategories.State
                ) {
                    return (
                        <React.Fragment key={category.value}></React.Fragment>
                    )
                } else {
                    const isCategoryExpanded: boolean = checkExpanded(
                        category.value
                    )

                    return (
                        <Accordion
                            key={category.value}
                            data-testid={`filterCategoryPanel${category.value}`}
                            expanded={isCategoryExpanded}
                            onChange={handleExpansion(category.value)}
                            className={clsx(
                                classes.expandedFilter,
                                classes.moreFilter
                            )}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                className={classes.expansionSummary}
                            >
                                <Typography
                                    variant={'h4'}
                                    className={classes.categoryName}
                                >
                                    {category.name}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails
                                className={clsx(
                                    classes.expandedFilter,
                                    classes.expansionSummaryCont
                                )}
                            >
                                <MoreFilterSubCategories
                                    value={category.value}
                                    filterDisabledValues={filterDisabledValues}
                                    showSwatchboxFilter={showSwatchboxFilter}
                                />
                            </AccordionDetails>
                        </Accordion>
                    )
                }
            })}
        </div>
    )
}
export default MoreFilterCategories
