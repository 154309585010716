import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { Theme, makeStyles } from '@material-ui/core/styles'
import Skeleton from '@material-ui/lab/Skeleton'
import clsx from 'clsx'
import HoverTooltip from 'components/Feedbacks/HoverTooltip'
import {
    ListingLevel,
    ManufacturerProductCatalogDetail,
    ManufacturerProductImageDetail,
    ManufacturerProductSpecDocDetail,
    ManufacturerProductVideoDetail,
} from 'components/Models/product'
import GridContainer from 'components/Surfaces/GridContainer'
import GridItem from 'components/Surfaces/GridItem'
import { suffixDimension } from 'components/Utilities/stringUtils'
import { pxToRem } from 'components/Utilities/unitUtils'
import { ProductListingCardFooter } from 'components/Widgets/ProductListing/Card/ProductListingCardFooter'
import { ProductListingCardLogo } from 'components/Widgets/ProductListing/Card/ProductListingCardLogo'
import { getDocDetail } from 'components/Widgets/ProductListing/Details/productDetailUtils'
import { removeFamilyNumberFromFamilyNameRegex } from 'components/Widgets/ProductListing/productsUtils'
import { addMinutes, format } from 'date-fns'
import { Link as GatsbyLink } from 'gatsby'
import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import Truncate from 'react-truncate'
import { ListingStatus } from '../../Constants/ProductListing'
import ProductListingCardCarousel from './ProductListingCardCarousel'

const useStyles = makeStyles((theme: Theme) => ({
    cardContainer: {
        width: 'calc(100% - 4px)', // 4px is the column separator
        height: 'calc(100% - 4px)', // 4px is the row separator
        border: 'solid 1px #AAAAAA',
        '&:hover': {
            border: '1px solid #10B091',
            boxShadow: '0px 0px 10px #B9EAE0',
        },
    },

    customCardHeader: {
        height: 60,
        maxHeight: 60,
    },
    mediumWeight: {
        fontWeight: 500,
    },
    cardContent: {
        height: 'calc(100% - 220px)',
    },
    productLink: {
        textDecoration: 'none',
        height: '100%',
    },
    guttered: {
        padding: theme.spacing(1.2),
    },
    grayText: {
        color: '#5E5F61',
    },
    alignText: {
        marginTop: theme.spacing(3),
    },
    publishStatusDateText: {
        fontSize: pxToRem(10),
        lineHeight: '15px',
    },
    fullHeight: {
        height: '100%',
    },
    smallerLineHeight: {
        lineHeight: '20px',
    },
    customCaptionSized: {
        fontSize: pxToRem(12),
    },
    fullWidth: {
        width: '100%',
    },
    noImage: {
        textDecoration: 'none',
        textAlign: 'center',
        height: 130,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    darkGrayBackground: {
        backgroundColor: '#B1B0B1',
    },
    lightGrayBackground: {
        backgroundColor: '#c9c9c9',
    },
    largeFont: {
        fontSize: pxToRem(30),
        color: theme.palette.secondary.light,
        fontWeight: 500,
    },
    textStyle: {
        textTransform: 'uppercase',
    },
    smallFont: {
        fontSize: pxToRem(20),
        color: theme.palette.secondary.light,
        fontWeight: 500,
    },
    premiumNoImageTextContainer: {
        maxWidth: 308,
        fontWeight: 400,
        fontSize: pxToRem(36),
        lineHeight: pxToRem(42),
    },
    firmLogoImage: {
        height: 40,
        objectFit: 'contain',
        display: 'block',
        maxWidth: '100%',
    },
    companyLink: {
        display: 'block',
        textDecoration: 'none',
        color: '#000',
    },
    cardContentItem: {
        paddingTop: theme.spacing(0.35),
        paddingBottom: theme.spacing(0.35),
    },
    proprietaryLabel: {
        height: 22,
        width: 81,
        fontSize: pxToRem(11),
        fontWeight: 500,
        marginTop: theme.spacing(1.5),
        position: 'absolute',
        backgroundColor: '#FD3431',
        color: theme.palette.secondary.light,
        padding: `${theme.spacing(0.37)}px ${theme.spacing(1.37)}px`,
        borderTopRightRadius: 2,
        borderBottomRightRadius: 2,
        zIndex: 1,
    },
}))

export interface ProductCardProps {
    sectionName?: string
    firmName?: string
    displayFirmName?: string
    firmLogoUrl?: string
    productName?: string
    hideProductName?: boolean
    productType?: string
    productTypeId?: number
    listingLevel?: ListingLevel
    submissionStatus?: string
    publishDate?: string
    isActive?: boolean
    familyName?: string
    listingId?: string
    categories?: string[]
    firmId?: string
    images?: Partial<ManufacturerProductImageDetail>[]
    videos?: Partial<ManufacturerProductVideoDetail>[]
    catalogs?: Partial<ManufacturerProductCatalogDetail>[]
    manufacturerDocs?: Partial<ManufacturerProductSpecDocDetail>[]
    partnershipLinksBIM?: string
    partnershipLinksSwatchbox?: string
    loading?: boolean
    canAutoGenerateProductSpecification?: boolean
    hasManufacturerOptions?: boolean
}

export interface ListingCardMedia {
    imgUrl: string
    isVideo: boolean
    order: number
}

const ProductListingCard: React.FC<ProductCardProps> = ({
    productName = '',
    listingId,
    firmName = '',
    displayFirmName = '',
    productType = '',
    hideProductName = productName === 'Unknown Product Name',
    listingLevel = ListingLevel.Manufacturer,
    publishDate,
    familyName,
    isActive = false,
    firmLogoUrl,
    categories = [],
    firmId = '',
    images,
    videos,
    catalogs,
    manufacturerDocs,
    partnershipLinksBIM = '',
    partnershipLinksSwatchbox = '',
    loading,
    submissionStatus,
    canAutoGenerateProductSpecification,
    hasManufacturerOptions,
}) => {
    const classes = useStyles()
    const history = useHistory()
    const listingPath = listingId
        ? '/product/' + listingId + history.location.search
        : '/products/all'

    const formattedPublishDate =
        (publishDate &&
            format(
                addMinutes(
                    new Date(publishDate),
                    new Date(publishDate).getTimezoneOffset()
                ),
                'MM/dd/yyyy'
            )) ||
        ''
    const showSpecificationDoc =
        (listingLevel === ListingLevel.Premium ||
            listingLevel === ListingLevel.PremiumMasterSpec) &&
        (submissionStatus === ListingStatus.Completed ||
            submissionStatus === ListingStatus.PremiumMasterSpecReview ||
            submissionStatus ===
                ListingStatus.PremiumMasterSpecReviewBPMSubmitted) &&
        !getDocDetail(manufacturerDocs, 'ShortForm') &&
        !getDocDetail(manufacturerDocs, 'FullLength')
    const consolidatedMedia: ListingCardMedia[] = React.useMemo(() => {
        const imageContents = images
            ? images
                  .filter((each) => each.filename)
                  .map(
                      (imageItem: Partial<ManufacturerProductImageDetail>) => ({
                          imgUrl: suffixDimension({
                              filename: imageItem.filename!,
                              dimension: {
                                  width: 400,
                                  height: 300,
                              },
                          }),
                          isVideo: false,
                          order: imageItem.order ?? 0,
                      })
                  )
            : []

        const videoStills = videos
            ? videos
                  .filter((each) => each.videoStillImageUrl)
                  .map(
                      (videoItem: Partial<ManufacturerProductVideoDetail>) => ({
                          imgUrl: videoItem.videoStillImageUrl!,
                          isVideo: true,
                          order: videoItem.order ?? 0,
                      })
                  )
            : []

        return [...imageContents, ...videoStills].sort(
            (a, b) => a.order - b.order
        )
    }, [images, videos])

    React.useEffect(() => {
        const currentState = history.location.state as any
        const fromParams = currentState?.from?.split('?')?.[1]
        const searchParams = new URLSearchParams(
            fromParams ?? history.location.search
        )
        if (
            !history.location.search &&
            currentState?.from?.includes('/products/all')
        ) {
            history.replace({
                ...history.location,
                search: searchParams.toString(),
            })
        }
    }, [history])

    return (
        <Card
            variant="elevation"
            square
            elevation={2}
            className={clsx('productListingCardItem', classes.cardContainer)}
        >
            <GridContainer
                justifyContent="space-between"
                alignItems="center"
                className={clsx(classes.customCardHeader, classes.guttered)}
            >
                <GridItem xs={5}>
                    <Link
                        target="_blank"
                        to={`/products/company/${firmId}${history.location.search}`}
                        className={clsx(classes.companyLink)}
                    >
                        {loading && (
                            <Skeleton
                                variant="rect"
                                animation="pulse"
                                height={40}
                                width={110}
                                className="loadingSkeleton"
                            />
                        )}
                        {!loading && (
                            <>
                                {firmLogoUrl ? (
                                    <img
                                        src={firmLogoUrl}
                                        className={classes.firmLogoImage}
                                        alt={firmName}
                                        title={firmName}
                                    />
                                ) : (
                                    <Truncate lines={2}>
                                        {displayFirmName
                                            ? displayFirmName
                                            : firmName}
                                    </Truncate>
                                )}
                            </>
                        )}
                    </Link>
                </GridItem>
                <GridItem>
                    <GridContainer
                        className={classes.fullHeight}
                        direction="column"
                        alignItems="flex-end"
                        justifyContent="space-between"
                    >
                        <ProductListingCardLogo
                            listingLevel={listingLevel}
                            loading={loading}
                        />

                        <GridItem>
                            <Typography
                                component="div"
                                variant="caption"
                                className={clsx(
                                    classes.publishStatusDateText,
                                    classes.grayText
                                )}
                            >
                                {!loading ? (
                                    <>
                                        {publishDate
                                            ? `Published ${formattedPublishDate} `
                                            : 'Published '}
                                        - {isActive ? 'Active' : 'Inactive'}
                                    </>
                                ) : (
                                    <Skeleton
                                        variant="text"
                                        animation="pulse"
                                        width={100}
                                        className="loadingSkeleton"
                                    />
                                )}
                            </Typography>
                        </GridItem>
                    </GridContainer>
                </GridItem>
            </GridContainer>
            <CardMedia>
                {loading ? (
                    <Skeleton variant="rect" animation="pulse" height={130} />
                ) : (
                    <>
                        {listingLevel === ListingLevel.PremiumProprietary && (
                            <span className={classes.proprietaryLabel}>
                                Proprietary
                            </span>
                        )}
                        {listingLevel === ListingLevel.Manufacturer ? (
                            <GatsbyLink
                                to={listingPath}
                                unselectable="on"
                                className={clsx(
                                    classes.noImage,
                                    classes.darkGrayBackground
                                )}
                            >
                                <Typography
                                    className={clsx(
                                        classes.largeFont,

                                        'productListingCardBasicListingPreviewText'
                                    )}
                                >
                                    <Typography
                                        className={clsx(classes.smallFont)}
                                    >
                                        MasterSpec Approved
                                    </Typography>
                                    <Typography
                                        className={clsx(
                                            classes.largeFont,
                                            classes.textStyle
                                        )}
                                    >
                                        {listingLevel}
                                    </Typography>
                                </Typography>
                            </GatsbyLink>
                        ) : consolidatedMedia.length > 0 ? (
                            <ProductListingCardCarousel
                                media={consolidatedMedia}
                                redirectPath={listingPath}
                            />
                        ) : (
                            <GatsbyLink
                                to={listingPath}
                                unselectable="on"
                                className={clsx(
                                    classes.noImage,
                                    classes.lightGrayBackground
                                )}
                            >
                                <Typography
                                    className={clsx(
                                        classes.largeFont,
                                        listingLevel.includes('Premium') &&
                                            classes.premiumNoImageTextContainer,
                                        'productListingCardNoImageText'
                                    )}
                                >
                                    {listingLevel &&
                                    listingLevel.includes('Premium')
                                        ? listingLevel
                                        : listingLevel + ' Listing'}
                                </Typography>
                            </GatsbyLink>
                        )}
                    </>
                )}
            </CardMedia>
            <CardContent
                component={Grid}
                container
                direction="column"
                spacing={1}
                className={clsx(classes.guttered, classes.cardContent)}
            >
                <GatsbyLink to={listingPath} className={classes.productLink}>
                    {!loading ? (
                        <>
                            {categories?.length > 0 && (
                                <GridItem>
                                    <Typography
                                        className={clsx(
                                            classes.customCaptionSized,
                                            classes.grayText,
                                            classes.cardContentItem
                                        )}
                                    >
                                        <Truncate lines={2}>
                                            {categories
                                                .join(', ')
                                                .toUpperCase()}
                                        </Truncate>
                                    </Typography>
                                </GridItem>
                            )}
                            {productName && !hideProductName && (
                                <GridItem>
                                    <Typography
                                        variant="h6"
                                        className={clsx(
                                            'productListingCardProductName',
                                            classes.mediumWeight,
                                            classes.smallerLineHeight,
                                            classes.grayText,
                                            classes.cardContentItem
                                        )}
                                    >
                                        <Truncate lines={2}>
                                            {productName}
                                        </Truncate>
                                    </Typography>
                                </GridItem>
                            )}
                            {familyName && (
                                <GridItem>
                                    <Typography
                                        className={clsx(
                                            classes.customCaptionSized,
                                            classes.mediumWeight,
                                            classes.grayText,
                                            classes.cardContentItem
                                        )}
                                    >
                                        <HoverTooltip
                                            text={familyName}
                                            children={
                                                <Truncate lines={2}>
                                                    {familyName
                                                        .replace(
                                                            removeFamilyNumberFromFamilyNameRegex,
                                                            ''
                                                        )
                                                        .toUpperCase()}
                                                </Truncate>
                                            }
                                        />
                                    </Typography>
                                </GridItem>
                            )}
                            <GridItem className={classes.fullWidth}>
                                <Typography
                                    className={clsx(
                                        classes.customCaptionSized,
                                        classes.grayText,
                                        classes.cardContentItem
                                    )}
                                >
                                    <Truncate lines={2}>{productType}</Truncate>
                                </Typography>
                            </GridItem>
                        </>
                    ) : (
                        <>
                            {/* categories */}
                            <Skeleton
                                variant="text"
                                animation="pulse"
                                width={100}
                                className="loadingSkeleton"
                            />

                            {/* Product Name */}
                            <Skeleton
                                variant="text"
                                animation="pulse"
                                width={70}
                                className="loadingSkeleton"
                            />

                            {/* Family Name */}
                            <Skeleton
                                variant="text"
                                animation="pulse"
                                width={150}
                                className="loadingSkeleton"
                            />

                            {/* Product Type */}
                            <Skeleton
                                variant="rect"
                                animation="pulse"
                                height={20}
                                width={200}
                                className="loadingSkeleton"
                            />
                        </>
                    )}
                </GatsbyLink>
            </CardContent>
            {listingLevel !== ListingLevel.Manufacturer && (
                <ProductListingCardFooter
                    listingLevel={listingLevel}
                    loading={loading}
                    firmName={firmName}
                    familyName={familyName}
                    catalogs={catalogs}
                    partnershipLinkBIM={partnershipLinksBIM}
                    partnershipLinkSwatchbox={partnershipLinksSwatchbox}
                    manufacturerDocs={manufacturerDocs}
                    listingId={listingId}
                    showSpecificationDoc={
                        !!canAutoGenerateProductSpecification ||
                        showSpecificationDoc
                    }
                    showManufacturerOptionItem={hasManufacturerOptions}
                />
            )}
        </Card>
    )
}

export default ProductListingCard
