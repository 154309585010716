import Avatar from '@material-ui/core/Avatar'
import Container from '@material-ui/core/Container'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import {
    ILoadingPageContext,
    LoadingPageContext,
} from 'components/Contexts/LoadingPageContext'
import GridContainer from 'components/Surfaces/GridContainer'
import GridItem from 'components/Surfaces/GridItem'
import { pxToRem } from 'components/Utilities/unitUtils'
import { CdnClientUrl } from 'components/Utilities/urlUtils'
import React, { useContext } from 'react'

interface ImageCaptionProps {
    path: string
    caption: string
}

const useImageCaptionStyles = makeStyles({
    image: {
        width: '238px',
        height: '238px',
        background: '#fff',
        border: '4px solid #24CEAD',
        boxSizing: 'border-box',
        boxShadow: '0px 20px 20px rgba(0, 0, 0, 0.25)',
        '& img': {
            objectFit: 'none',
        },
    },
    caption: {
        fontSize: pxToRem(20),
    },
})

const ImageCaption: React.FC<ImageCaptionProps> = ({
    path,
    caption,
}: ImageCaptionProps) => {
    const classes = useImageCaptionStyles()
    const fullPath = `${CdnClientUrl}/${path}`

    return (
        <GridContainer
            item
            direction="column"
            alignItems="center"
            spacing={3}
            xs={12}
            md={6}
            lg={3}
        >
            <GridItem>
                <Avatar src={fullPath} alt="" className={classes.image} />
            </GridItem>
            <GridItem>
                <Typography variant="h4" className={classes.caption}>
                    {caption}
                </Typography>
            </GridItem>
        </GridContainer>
    )
}

const useStyles = makeStyles({
    '@global': {
        body: {
            background: `linear-gradient(141.34deg, #04325B 28.07%, #02A09C 100%)`,
            backgroundAttachment: 'fixed',
        },
    },
    root: {
        width: '100%',
        height: '100vh',
        paddingTop: 90,
        color: '#fff',
        textAlign: 'center',
        '& h1': {
            opacity: '0.5',
            fontSize: pxToRem(50),
        },
        '& h2': {
            fontSize: pxToRem(40),
        },
        '& h3': {
            fontSize: pxToRem(20),
        },
    },
})

const images = [
    {
        path: 'clumsy.svg',
        caption: 'Give them even more work',
    },
    {
        path: 'coffee.svg',
        caption: 'Take away their coffee',
    },
    {
        path: 'ice_cream.svg',
        caption: 'Remove all snacks',
    },
    {
        path: 'zombie.svg',
        caption: 'Zombiefy them',
    },
]

/**
 * Not Found Page
 */
const NotFoundPage: React.FC = () => {
    const classes = useStyles()
    const {
        handleLoadingPageChange,
        showLoadingScreenPage,
    } = useContext<ILoadingPageContext>(LoadingPageContext)

    if (showLoadingScreenPage) {
        handleLoadingPageChange(false)
    }

    return (
        <Container className={classes.root}>
            <GridContainer
                direction="row"
                alignItems="center"
                justifyContent="center"
                spacing={8}
            >
                <GridItem>
                    <Typography variant="h1">
                        Oops! 404
                        <br />
                        This page is gone.
                    </Typography>
                </GridItem>
                <GridContainer
                    item
                    direction="column"
                    alignItems="center"
                    spacing={4}
                >
                    <GridItem>
                        <Typography variant="h2">
                            Our development team must be punished for this
                            unacceptable failure!
                        </Typography>
                    </GridItem>
                    <GridItem>
                        <Typography variant="h3">
                            Select their punishement:
                        </Typography>
                    </GridItem>
                </GridContainer>
                <GridContainer
                    item
                    direction="row"
                    justifyContent="center"
                    spacing={10}
                >
                    {images.map((imageItem) => {
                        const { path, caption } = imageItem
                        return (
                            <ImageCaption
                                path={path}
                                caption={caption}
                                key={path}
                            />
                        )
                    })}
                </GridContainer>
            </GridContainer>
        </Container>
    )
}

export default NotFoundPage
