import React, { createContext, useState } from 'react'

export interface ILoadingPageContext {
    handleLoadingPageChange: (isLoadingPage: boolean) => void
    showLoadingScreenPage: boolean
}

export const LoadingPageContext = createContext({} as ILoadingPageContext)

export const LoadingPageProvider: React.FC = ({ children }) => {
    const [showLoadingScreenPage, setShowLoadingScreenPage] = useState<boolean>(
        true
    )

    const context: ILoadingPageContext = {
        handleLoadingPageChange: setShowLoadingScreenPage,
        showLoadingScreenPage,
    }
    return (
        <LoadingPageContext.Provider value={context}>
            {children}
        </LoadingPageContext.Provider>
    )
}
