import { gql } from '@apollo/client'
import {
    ManufacturerProductCatalogDetailFragment,
    ManufacturerProductDetailFragments,
    ManufacturerProductSpecDocDetailFragment,
} from 'components/GraphQL/Fragments/productDetail.fragments'

const listingMatchFragment = gql`
    fragment listingMatchFragment on ListingMatch {
        listingId
        categories
        displayNameWithFirmName
        firmName
        firmDisplayName
        productName
        productId
        productType
        specAgentProductTypeId
        firmLogoUrl
        sectionName
        type
        submissionStatus
        lastUpdated
        isActive
        firmId
        images {
            ...imagesFragment
        }
        videos {
            ...videosFragment
        }
        catalogs {
            ...manufacturerProductCatalogDetailFragment
        }
        manufacturerDocs {
            ...manufacturerProductSpecDocDetailFragment
        }
        canAutoGenerateProductSpecification
        familyNumber
        partnershipLinksBIM
        partnershipLinksSwatchbox
        partnershipLinksSM
        partnershipLinksULE
        specAgentProductTypeId
        hasManufacturerOptions
    }
    ${ManufacturerProductDetailFragments.imagesFragment}
    ${ManufacturerProductDetailFragments.videosFragment}
    ${ManufacturerProductCatalogDetailFragment}
    ${ManufacturerProductSpecDocDetailFragment}
`

export const ProductSearchQuery = gql`
    query productSearchQuery(
        $manufacturerProductSearchInput: ManufacturerProductSearchInput!
    ) {
        listingSearch(input: $manufacturerProductSearchInput) {
            facets {
                name
                values {
                    value
                }
            }
            matches {
                ...listingMatchFragment
            }
            count
        }
    }
    ${listingMatchFragment}
`

export const ProductSearchAutosuggestQuery = gql`
    query listingAutoSuggest($input: ListingAutoSuggestInput!) {
        listingAutoSuggest(input: $input) {
            result
            searchAfterKey
        }
    }
`

export const ListingsGetListingProductTypeProperties = gql`
    query listingsGetListingProductTypeProperties(
        $input: GetListingProductTypePropertiesInput!
    ) {
        listingsGetListingProductTypeProperties(input: $input) {
            categories
            properties {
                ...propertiesFragment
            }
        }
    }
    ${ManufacturerProductDetailFragments.propertiesFragment}
`
