import React from 'react'

export interface IAppHeader {
    pageTitle: string
    updateAppHeaderContent: (
        newPageTitle: string,
        customComponent?: React.ReactNode,
        fullHeight?: boolean,
        customToolBar?: boolean
    ) => void
    getCurrentPageTitle: () => string
    customComponent?: React.ReactNode
    hasLowerBottomToolbar?: boolean
    setHasLowerBottomToolbar: (value: boolean) => void
    fullHeight?: boolean
    updateAppHeaderStyles?: ({}: AppHeaderStyles) => void
    barColor?: string
    customTextColor?: string
    isCustomToolbar?: boolean
}

export const AppHeaderContext = React.createContext({} as IAppHeader)

export interface AppHeaderProviderProps {
    appTitle?: string
}

export interface AppHeaderStyles {
    titleTextColor?: string
    backgroundColor?: string
}

export const AppHeaderProvider: React.FC<AppHeaderProviderProps> = ({
    appTitle = '',
    children,
}) => {
    const [pageTitle, setPageTitle] = React.useState('')
    const [isCustomToolbar, setIsCustomToolbar] = React.useState(false)
    const [barColor, setBarColor] = React.useState()
    const [customTextColor, setCustomTextColor] = React.useState()
    const [customComponent, setCustomComponent] = React.useState<
        React.ReactNode
    >(null)
    const [hasLowerBottomToolbar, setHasLowerBottomToolbar] = React.useState<
        boolean
    >(false)
    const [fullHeight, setFullHeight] = React.useState<boolean>(false)

    const getCurrentPageTitle = () => {
        return pageTitle
    }

    const updateAppHeaderContent = React.useMemo(
        () => (
            newPageTitle: string,
            newCustomComponent: React.ReactNode = null,
            fullHeight: boolean = false,
            customToolBar = false
        ) => {
            setCustomComponent(newCustomComponent)

            const newTitle = `${appTitle}: ${newPageTitle}`
            if (document.title === newTitle) return

            document.title = newTitle
            setPageTitle(newPageTitle)
            setIsCustomToolbar(customToolBar)
            setFullHeight(fullHeight)

            //initialized
            setHasLowerBottomToolbar(false)
        },
        []
    )

    const updateAppHeaderStyles = React.useCallback(
        ({ titleTextColor, backgroundColor }) => {
            if (titleTextColor !== undefined) setCustomTextColor(titleTextColor)
            if (backgroundColor !== undefined) setBarColor(backgroundColor)
        },
        []
    )

    const context: IAppHeader = {
        pageTitle,
        isCustomToolbar,
        getCurrentPageTitle,
        updateAppHeaderContent,
        updateAppHeaderStyles,
        customComponent,
        hasLowerBottomToolbar,
        setHasLowerBottomToolbar,
        fullHeight,
        barColor,
        customTextColor,
    }

    return (
        <AppHeaderContext.Provider value={context}>
            {children}
        </AppHeaderContext.Provider>
    )
}
