import { ApolloError, useMutation } from '@apollo/client'
import { Theme, makeStyles } from '@material-ui/core/styles'
import Layout from 'components/common/Layout'
import {
    ShowGlobalSnackbarMutation,
    ShowGlobalSnackbarMutationProps,
    ShowGlobalSnackbarRequest,
} from 'components/Configurations/GlobalSnackbar'
import { AppHeaderContext } from 'components/Contexts/AppHeaderContext'
import { GenerateProductSpecification } from 'components/GraphQL/Mutations/productSpecification.mutation'
import { ListingDetail } from 'components/Models/listing'
import { ListingLevel } from 'components/Models/product'
import ListingDetailMediaCarousel from 'components/ProductListing/Detail/Media/ListingDetailMediaCarousel'
import { ProductDetails } from 'components/ProductListing/Detail/ProductDetails'
import GridContainer from 'components/Surfaces/GridContainer'
import GridItem from 'components/Surfaces/GridItem'
import { pxToRem } from 'components/Utilities/unitUtils'
import { navigateToExternalLink } from 'components/Utilities/urlUtils'
import ViewDocuments from 'components/Widgets/ProductListing/Details/Documents/ViewDocuments'
import ViewLinks from 'components/Widgets/ProductListing/Details/Links/ViewLinks'
import ViewPartnerships from 'components/Widgets/ProductListing/Details/Partnerships/ViewPartnerships'
import { getDocDetail } from 'components/Widgets/ProductListing/Details/productDetailUtils'
import { ListingStatus } from 'components/Widgets/ProductListing/ListingStatusVariant'
import { ProductListingHeader } from 'components/Widgets/ProductListing/ProductListingHeader'
import { PageProps, graphql, navigate } from 'gatsby'
import React from 'react'
import ImageGallery from 'react-image-gallery'
import { useHistory } from 'react-router-dom'

interface PureProductPageProps {
    productInfo?: Partial<ListingDetail>
}

// The query for this page
// $listingId is passed down as context when the page is produced in gatsby-node
// This is accessed on build as such, kindly optimize to lesser build time
export const query = graphql`
    query($listingId: String!) {
        spapi {
            getListingDetail(input: { listingId: $listingId }) {
                model
                productId
                listingId
                productName
                firmLogoUrl
                firmId
                firmName
                firmPartnershipTypes
                categories
                specAgentProductTypeId
                productType
                submissionStatus
                isActive
                isPremiumPlus
                buildingTypes
                firstPublished
                lastPublished
                shortDescription
                longDescription
                sectionNumber
                sectionTitle
                sectionName
                type
                lastUpdated
                properties {
                    options {
                        isOther
                        isPremium
                        isPremiumPlus
                        isSupported
                        optionId
                        value
                    }
                    optionGroups {
                        groupId
                        prefix
                        postfix
                        options {
                            isOther
                            isPremium
                            isPremiumPlus
                            isSupported
                            optionId
                            value
                        }
                    }
                    propertyId
                    propertyName
                    isPremiumPlus
                }
                images {
                    id
                    filename
                    isPrimary
                    order
                    originalFilename
                }
                videos {
                    videoStillImageUrl
                    fromSource
                    videoId
                    id
                    order
                }
                webLinks {
                    order
                    type
                    url
                }
                catalogs {
                    path
                    thumbnailPath
                    title
                    uploadedDate
                }
                manufacturerDocs {
                    docType
                    docVariation
                    expirationDate
                    path
                    title
                    id
                }
                partnershipLinks {
                    level
                    name
                    partnerType
                    url
                }
                canAutoGenerateProductSpecification
            }
        }
    }
`
interface PSDResults {
    downloadURI: string | null
    errorMessage: string | null
    listingId: string
}
const useStyles = makeStyles((theme: Theme) => ({
    basicListing: { backgroundColor: '#343334' },
    basicListContainer: {
        display: 'flex',
        height: 400,
        color: theme.palette.secondary.light,
        padding: theme.spacing(2),
        marginTop: theme.spacing(2),
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: pxToRem(72),
        [theme.breakpoints.down('sm')]: { marginRight: 0 },
    },
    imageGalleryFlexContainer: {
        [theme.breakpoints.down('sm')]: {
            borderBottom: '1px solid #c9c9c9',
        },
    },
    linksContainer: {
        paddingTop: theme.spacing(1),
    },
}))

// this is a temeplete page, it recieves data and is rendered by gatsby-node.js
// The pageContext has the value passed down from the createPage context
// data is the resulf of the static query of this page
export const PureProductPage: React.FC<PureProductPageProps> = ({
    productInfo = {},
}) => {
    const { updateAppHeaderContent } = React.useContext(AppHeaderContext)

    const history = useHistory()
    const carouselRef = React.useRef<ImageGallery | null>(null)

    const handleClick = (url: string) => {
        history.push(url)
        navigate(url)
    }
    const [showGlobalSnackbar] = useMutation<
        ShowGlobalSnackbarRequest,
        ShowGlobalSnackbarMutationProps
    >(ShowGlobalSnackbarMutation)
    const {
        type,
        submissionStatus,
        manufacturerDocs,
        listingId,
        canAutoGenerateProductSpecification,
    } = productInfo

    const [
        generateProductSpecification,
        { loading: spfDocLoading },
    ] = useMutation<{
        generateProductSpecification: PSDResults
    }>(GenerateProductSpecification, {
        onCompleted({
            generateProductSpecification,
        }: {
            generateProductSpecification: PSDResults
        }) {
            if (
                listingId &&
                (showSpecificationDoc || canAutoGenerateProductSpecification) &&
                generateProductSpecification?.downloadURI
            )
                navigateToExternalLink(
                    generateProductSpecification?.downloadURI
                )
            else if (
                listingId &&
                (showSpecificationDoc || canAutoGenerateProductSpecification) &&
                generateProductSpecification?.errorMessage
            ) {
                // this error occurs when there is no prduct specification doc present in the data base
                showGlobalSnackbar({
                    variables: {
                        message: 'Product Specification Document not Available',
                        variant: 'error',
                        id: `PSD_Error`,
                    },
                })
            }
        },
        onError: (_error: ApolloError) => {
            showGlobalSnackbar({
                variables: {
                    message:
                        'Could not generate product specification document',
                    variant: 'error',
                    id: `PSD_Error`,
                },
            })
        },
    })

    const showSpecificationDoc =
        (type === ListingLevel.Premium ||
            type === ListingLevel.PremiumMasterSpec) &&
        (submissionStatus === ListingStatus.Completed ||
            submissionStatus === ListingStatus.PremiumMasterSpecReview ||
            submissionStatus ===
                ListingStatus.PremiumMasterSpecReviewBPMSubmitted) &&
        !getDocDetail(manufacturerDocs, 'ShortForm') &&
        !getDocDetail(manufacturerDocs, 'FullLength')
    const classes = useStyles()

    React.useEffect(() => {
        updateAppHeaderContent(
            '',
            <ProductListingHeader
                backButton={true}
                listingHeader="Product Listing"
                handleClick={handleClick}
            />,
            false,
            true
        )
    }, [updateAppHeaderContent])
    const executePSDQuery = React.useCallback(() => {
        if (
            listingId &&
            (showSpecificationDoc || canAutoGenerateProductSpecification)
        )
            generateProductSpecification({
                variables: { listingId: listingId },
            })
    }, [listingId])

    return (
        <Layout>
            {productInfo && (
                <div className="product-listing-detail-container">
                    <GridContainer alignItems="flex-start">
                        <GridItem
                            container
                            justifyContent="center"
                            md={7}
                            xs={12}
                            className={classes.imageGalleryFlexContainer}
                            data-testid="ehanced-premium-detail-container"
                        >
                            <ListingDetailMediaCarousel
                                images={productInfo.images}
                                videos={productInfo.videos}
                                ref={carouselRef}
                            />

                            <GridContainer
                                className={classes.linksContainer}
                                spacing={2}
                            >
                                <GridItem lg={7} xl={6}>
                                    <ViewDocuments
                                        level={productInfo.type}
                                        catalog={productInfo.catalogs?.[0]}
                                        specificationDocLoading={spfDocLoading}
                                        showSpecificationDoc={
                                            showSpecificationDoc ||
                                            ((productInfo.canAutoGenerateProductSpecification as unknown) as string) ===
                                                'True'
                                        }
                                        executePSDQuery={executePSDQuery}
                                        longDocType={getDocDetail(
                                            productInfo.manufacturerDocs,
                                            'FullLength'
                                        )}
                                        shortDocType={getDocDetail(
                                            productInfo.manufacturerDocs,
                                            'ShortForm'
                                        )}
                                    />
                                    <ViewPartnerships
                                        level={productInfo.type}
                                        partnerships={
                                            productInfo.partnershipLinks
                                        }
                                        showSwatchbox={true}
                                    />
                                </GridItem>
                                <GridItem lg={5} xl={6}>
                                    <ViewLinks
                                        level={productInfo.type}
                                        webLinks={productInfo.webLinks}
                                    />
                                </GridItem>
                            </GridContainer>
                        </GridItem>
                        <GridItem md={12} xs={12} lg={5}>
                            <ProductDetails productInfo={productInfo} />
                        </GridItem>
                    </GridContainer>
                </div>
            )}
        </Layout>
    )
}

interface PageContext {
    listingId: string
}

interface PageData {
    spapi?: {
        getListingDetail: Partial<ListingDetail>
    }
}

const ProductPage: React.FC<PageProps<PageData, PageContext>> = ({
    data,
    pageContext,
    ...rest
}) => {
    return (
        <PureProductPage {...rest} productInfo={data.spapi?.getListingDetail} />
    )
}

export default ProductPage
