import { createTheme, Theme, ThemeProvider } from '@material-ui/core/styles'
import React from 'react'
import { DefaultTextColor } from '../Utilities/unitUtils'

export const appTheme = createTheme({
    palette: {
        primary: {
            main: '#0098d6',
        },
        secondary: {
            main: '#fff',
            dark: '#33acde',
            light: '#fff',
            contrastText: '#0098d6',
        },
    },
    typography: {
        fontSize: 16,
        fontFamily: 'Roboto',
        htmlFontSize: 10,

        h1: {
            fontSize: '2.4rem',
            fontWeight: 'normal',
        },
        h2: {
            fontSize: '2.2rem',
            fontWeight: 'normal',
        },
        h3: {
            fontSize: '2rem',
            fontWeight: 'normal',
        },
        h4: {
            fontSize: '1.6rem',
            fontWeight: 500,
        },
        h5: {
            fontSize: '1.6rem',
            fontWeight: 'normal',
        },
        h6: {
            fontSize: '1.8rem',
            fontWeight: 'normal',
        },
        subtitle1: {
            fontSize: '1.6rem',
            fontWeight: 'normal',
        },
        subtitle2: {
            fontSize: '1.4rem',
            fontWeight: 500,
        },
        body1: {
            fontSize: '1.6rem',
            fontWeight: 'normal',
        },
        body2: {
            fontSize: '1.4rem',
            fontWeight: 'normal',
        },
        button: {
            textTransform: 'unset',
            fontSize: '1.4rem',
            fontWeight: 500,
        },
        caption: {
            fontSize: '1.2rem',
            fontWeight: 'normal',
        },
        overline: {
            fontSize: '1.2rem',
            fontWeight: 'normal',
        },
    },
    overrides: {
        MuiCssBaseline: {
            '@global': {
                html: {
                    fontSize: '62.5%',
                },
                body: {
                    color: DefaultTextColor,
                    fontFamily: 'Roboto',
                    backgroundColor: '#fff',
                },
                input: {
                    '&::-ms-clear': {
                        display: 'none',
                    },
                },
                // chrome
                '::-webkit-scrollbar': {
                    width: '7px',
                    height: '7px',
                },
                '::-webkit-scrollbar-track': {
                    background: '#fff',
                    border: '1px solid #C9C9C9',
                    boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.3)',
                    borderRadius: '100px',
                },
                '::-webkit-scrollbar-thumb': {
                    backgroundColor: '#9D9D9D',
                    borderRadius: '100px',
                },
                // firefox
                div: {
                    scrollbarWidth: 'thin',
                    scrollbarColor: '#9D9D9D #fff',
                },
            },
        },
        MuiButton: {
            containedSecondary: {
                '&:hover': {
                    color: '#fff',
                },
            },
        },
    },
    props: {
        MuiUseMediaQuery: {
            noSsr: true,
        },
    },
})

export interface AppThemeProps {
    theme?: Theme
}

/** App theme provider used for setting consistent styling
 * across the components. Ideally, this wraps the top-level components
 * of your app.
 */
const AppTheme: React.FC<AppThemeProps> = ({ children, theme = appTheme }) => {
    return <ThemeProvider theme={theme ?? appTheme}>{children}</ThemeProvider>
}

export default AppTheme
