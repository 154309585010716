import { makeStyles, Theme } from '@material-ui/core'
import clsx from 'clsx'
import HoverTooltip from 'components/Feedbacks/HoverTooltip'
import React from 'react'

const useStyles = makeStyles((theme: Theme) => ({
    iconBase: {
        width: 20,
        height: 20,
        marginLeft: theme.spacing(0.4),
        marginRight: theme.spacing(0.4),
        borderRadius: 3,
        cursor: 'pointer',
    },
    icon: {
        display: 'block',
        marginTop: theme.spacing(0.25),
        marginLeft: 'auto',
        marginRight: 'auto',
        height: 16,
    },
}))

export interface ProductListingManufacturerOptionItemProps {
    onIconClick?: () => void
}

export const ProductListingManufacturerOptionItem: React.FC<ProductListingManufacturerOptionItemProps> = ({
    onIconClick,
}) => {
    const classes = useStyles()
    const tooltipDelay = 1000
    return (
        <HoverTooltip
            text="Manufacturer options available"
            enterDelay={tooltipDelay}
            enterNextDelay={tooltipDelay}
        >
            <div
                className={clsx('dvManufacturerOption', classes.iconBase)}
                data-testid={`dvManufacturerOption`}
            >
                <img
                    data-testid="imgManufacturerOption"
                    className={classes.icon}
                    src={`https://avitrucdnblob.blob.core.windows.net/knowledgepoint/ManufacturerOption.svg`}
                    onClick={onIconClick}
                    role="button"
                    tabIndex={0}
                />
            </div>
        </HoverTooltip>
    )
}

export default ProductListingManufacturerOptionItem
