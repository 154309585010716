import Link from '@material-ui/core/Link'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Skeleton from '@material-ui/lab/Skeleton'
import { Address } from 'components/Models/address'
import GridContainer from 'components/Surfaces/GridContainer'
import GridItem from 'components/Surfaces/GridItem'
import { formatCityRegionPostalCode } from 'components/Utilities/addressUtils'
import { joinArray } from 'components/Utilities/stringUtils'
import React from 'react'

const useStyles = makeStyles({
    mapText: {
        fontWeight: 500,
        overflowWrap: 'anywhere',
    },
})
export interface CompanyContactInfoProps {
    address?: Partial<Address>
    website?: string
    publicPhone?: string
    firmLoading?: boolean
}

const CompanyContactInfo: React.FC<CompanyContactInfoProps> = ({
    address,
    website = '',
    publicPhone = '',
    firmLoading = true,
}) => {
    const classes = useStyles()
    const trimmedWebsiteUrlText = React.useMemo(() => {
        return website.trim()
    }, [website])

    // Redirect URL for the company website
    const resultingWebsiteUrl = React.useMemo(() => {
        return trimmedWebsiteUrlText.startsWith('http://') ||
            trimmedWebsiteUrlText.startsWith('https://')
            ? `${trimmedWebsiteUrlText}`
            : `//${trimmedWebsiteUrlText}`
    }, [trimmedWebsiteUrlText])

    // Display text for the company website URL
    const cleanWebsiteUrl = React.useMemo(() => {
        let startIndex = trimmedWebsiteUrlText.indexOf('://')
        startIndex = startIndex < 0 ? 0 : startIndex + 3

        return website.trim().substr(startIndex)
    }, [trimmedWebsiteUrlText, website])

    if (firmLoading) {
        return <LoadSkeleton />
    }

    return (
        <>
            <div>
                <Typography
                    variant="h6"
                    data-testid="dvCompanyAddress"
                    className={classes.mapText}
                >
                    {address
                        ? joinArray(
                              [
                                  address?.street1?.trim(),
                                  address?.street2?.trim(),
                                  formatCityRegionPostalCode(address!).trim(),
                              ],
                              ', '
                          )
                        : ''}
                </Typography>
            </div>
            <div>
                <Typography
                    variant="h6"
                    data-testid="dvCompanyPhoneNumber"
                    className={classes.mapText}
                >{`${publicPhone}`}</Typography>
            </div>
            <div>
                <Link
                    href={resultingWebsiteUrl}
                    target="_blank"
                    variant="h6"
                    data-testid="dvCompanyEmail"
                    className={classes.mapText}
                >
                    {cleanWebsiteUrl}
                </Link>
            </div>
        </>
    )
}

const LoadSkeleton = () => (
    <GridContainer
        direction="column"
        spacing={1}
        data-testid="dvContactInfoSkeleton"
    >
        {Array.from(Array(3)).map((_, idx) => {
            return (
                <GridItem key={idx}>
                    <Skeleton width="25%" />
                </GridItem>
            )
        })}
    </GridContainer>
)

export default CompanyContactInfo
