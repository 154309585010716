import {
    ListingLevel,
    ManufacturerProductBimLinkDetail,
    ManufacturerProductCatalogDetail,
    ManufacturerProductSpecDocDetail,
} from 'components/Models/product'
import React from 'react'
import ProductListingCardDocumentItem from './ProductListingCardDocumentItem'

export interface ProductListingCardDocumentsProps {
    listingLevel: ListingLevel
    familyName?: string
    companyName?: string
    catalogs?: Partial<ManufacturerProductCatalogDetail>[]
    bimLinks?: Partial<ManufacturerProductBimLinkDetail>[]
    partnershipLinkBIM?: string
    partnershipLinkSwatchbox?: string
    manufacturerDocs?: Partial<ManufacturerProductSpecDocDetail>[]
    showSpecificationDoc?: boolean
    spfDocLoading?: boolean
    executePSDQuery?: () => void
}

const ProductListingCardDocuments: React.FC<ProductListingCardDocumentsProps> = ({
    listingLevel,
    familyName,
    companyName,
    catalogs,
    bimLinks,
    partnershipLinkBIM,
    partnershipLinkSwatchbox,
    manufacturerDocs,
    showSpecificationDoc,
    executePSDQuery,
    spfDocLoading,
}) => {
    const shortForm = React.useMemo(
        () =>
            manufacturerDocs?.filter(
                (each) => each.docType === 'ShortForm' && !!each.path
            ),
        [manufacturerDocs]
    )
    const fullLength = React.useMemo(
        () =>
            manufacturerDocs?.filter(
                (each) => each.docType === 'FullLength' && !!each.path
            ),
        [manufacturerDocs]
    )
    return (
        <>
            {catalogs && catalogs.filter((each) => !!each.path).length > 0 && (
                <ProductListingCardDocumentItem
                    link={catalogs
                        .filter((each) => !!each.path)
                        .map((each) => {
                            const pathArr = each.path?.split('/')
                            return {
                                url: each.path!,
                                familyName,
                                companyName,
                                name: (each.title && each.title !== ''
                                    ? each.title
                                    : pathArr?.[pathArr?.length - 1])!,
                                // support for old db data
                            }
                        })}
                    cardType="Catalog"
                />
            )}
            {bimLinks && bimLinks.filter((each) => !!each.bimUrl).length > 0 && (
                <ProductListingCardDocumentItem
                    link={bimLinks
                        .filter((each) => !!each.bimUrl)
                        .map((each) => ({
                            url: each.bimUrl!,
                            companyName,
                            familyName,
                            name: each.productName ?? '',
                        }))}
                    cardType="BIM"
                />
            )}
            {partnershipLinkBIM && (
                <ProductListingCardDocumentItem
                    link={[
                        {
                            url: partnershipLinkBIM,
                            companyName,
                            familyName,
                            name: 'BIMsmith',
                        },
                    ]}
                    cardType="BIM"
                />
            )}
            {partnershipLinkSwatchbox && (
                <ProductListingCardDocumentItem
                    link={[
                        {
                            url: partnershipLinkSwatchbox,
                            companyName,
                            familyName,
                            name: 'Swatchbox',
                        },
                    ]}
                    cardType="Swatchbox"
                />
            )}
            {listingLevel !== ListingLevel.Manufacturer && (
                <>
                    {showSpecificationDoc && (
                        <ProductListingCardDocumentItem
                            link={[
                                {
                                    url: '',
                                    name: '',
                                },
                            ]}
                            cardType="Specification"
                            loadingPSD={spfDocLoading}
                            executePSDQuery={executePSDQuery}
                        />
                    )}
                    {!showSpecificationDoc &&
                        fullLength &&
                        fullLength.length > 0 && (
                            <ProductListingCardDocumentItem
                                link={fullLength.map((each) => ({
                                    url: each.path!,
                                    name: each.title ?? '',
                                }))}
                                cardType="FullLength"
                            />
                        )}
                    {!showSpecificationDoc &&
                        shortForm &&
                        shortForm.length > 0 && (
                            <ProductListingCardDocumentItem
                                link={shortForm.map((each) => ({
                                    url: each.path!,
                                    name: each.title ?? '',
                                }))}
                                cardType="ShortForm"
                            />
                        )}
                </>
            )}
        </>
    )
}

export default ProductListingCardDocuments
