import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import Link from '@material-ui/core/Link'
import Paper from '@material-ui/core/Paper'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import AddSharpIcon from '@material-ui/icons/AddSharp'
import CloseIcon from '@material-ui/icons/Close'
import EditOutlinedIcon from '@material-ui/icons/EditOutlined'
import TabContext from '@material-ui/lab/TabContext'
import TabList from '@material-ui/lab/TabList'
import TabPanel from '@material-ui/lab/TabPanel'
import clsx from 'clsx'
import GridContainer from 'components/Surfaces/GridContainer'
import GridItem from 'components/Surfaces/GridItem'
import { reorderArrayItems } from 'components/Utilities/formUtils'
import { newGuid } from 'components/Utilities/GuidGenerator'
import { pxToRem } from 'components/Utilities/unitUtils'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import {
    DragDropContext,
    Droppable,
    DroppableProvided,
    DropResult,
} from 'react-beautiful-dnd'
import { useHistory } from 'react-router-dom'
import { ProductGroupingForm } from './ProductGroupingForm'
import { ProductSortingForm } from './ProductSortingForm'

const useStyles = makeStyles((theme: Theme) => ({
    mainContent: {
        margin: 'auto',
        overflow: 'hidden',
    },
    mainContainer: {
        overflowY: 'auto',
        overflowX: 'hidden',
        maxHeight: 280,
    },
    editContainer: {
        marginLeft: theme.spacing(0.5),
        color: '#211F20',
    },
    editbutton: {
        color: '#000000',
        fontSize: '1.6rem',
        fontWeight: 500,
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        cursor: 'pointer',
        alignItems: 'center',
        display: 'flex',
        '&:hover': {
            textDecoration: 'none',
        },
    },
    editIcon: {
        color: '#211F20',
    },
    tabs: {
        fontSize: pxToRem(14),
    },
    headContent: {
        display: 'flex',
        alignItems: 'center',
    },
    addSorting: {
        fontSize: pxToRem(14),
        marginLeft: theme.spacing(5.5),
        marginTop: theme.spacing(1),
    },
    options: {
        paddingLeft: theme.spacing(3.5),
    },
    header: {
        '& span': {
            margin: '24px 30px -24px',
            color: '#5E5F61',
        },
    },
    modalheader: {
        color: theme.palette.primary.main,
    },
    addIcon: { color: '#13B5ea', fontSize: pxToRem(14) },
    addButton: {
        fontSize: pxToRem(14),
        marginLeft: theme.spacing(5.5),
        marginTop: theme.spacing(1),
    },
    buttonHeader: {
        borderTop: '1px solid #0069AA',
        height: 42,
        backgroundColor: '	rgba(19,181,234,0.25)',
        marginLeft: theme.spacing(-3),
        marginRight: theme.spacing(-3),
        '& button': {
            margin: '8px 10px',
            height: 52,
            fontSize: pxToRem(18),
            paddingBottom: theme.spacing(3),
            '&:focus': {
                backgroundColor: '#ffffff',
            },
        },
    },
    actionContainer: {
        height: 103,
        borderTop: '1px solid #0069AA',
        paddingRight: theme.spacing(4),
    },
    cancelbutton: {
        width: 72,
        height: 42,
        color: '#13B5ea',
        fontSize: pxToRem(14),
    },
    applyButton: {
        width: 65,
        height: 40,
        borderRadius: 8,
        backgroundColor: '#13B5ea',
        color: '#FFFFFF',
        fontSize: pxToRem(14),
        '&:hover': {
            backgroundColor: '#13B5ea',

            color: '#FFFFFF',
        },
    },
    closeButton: {
        position: 'absolute',

        right: theme.spacing(2),
        color: theme.palette.grey[500],
    },
    dialogMainContent: {
        maxWidth: 705,
    },
    paper: {
        marginTop: theme.spacing(1),
        boxShadow: 'none',
    },
    title: {
        height: 74,
        display: 'flex',
        alignItems: 'center',
        borderBottom: '1px solid #13B5ea ',
    },
    activeButton: {
        backgroundColor: '#ffffff',
    },
    dialogcontent: {
        width: 705,
        padding: '0px 24px',
        height: 390,
        overflow: 'hidden',
    },
    addOptions: {
        fontSize: pxToRem(16),
        marginLeft: theme.spacing(5.5),
        marginTop: theme.spacing(1),
    },
}))

interface productSortingModalInterface {
    onFilterChange?: (neValue: string[], keyName: string) => void
    takeFirstOptionAsDefault?: boolean
}
export interface DomObject {
    columnName: string
    columnOptions: { display: string; value: string }[]
    id: string
    value: string
    isChecked?: boolean
    isAddedDynamically?: boolean
    groupRefId?: string
}

export const ProductSortingModal: React.FC<productSortingModalInterface> = ({
    onFilterChange,
    takeFirstOptionAsDefault = false,
}) => {
    const mainContainerRef = useRef()
    const [dialogOpen, setDialogOpen] = useState<boolean>(false)

    const [columnOptions, setColumnOptions] = useState<
        { display: string; value: string }[]
    >([
        { display: 'None', value: '' },
        {
            display: 'Company',
            value: 'firmDisplayName',
        },
        {
            display: 'Listing type',
            value: 'type',
        },

        {
            display: 'Family',
            value: 'sectionNumber', // Family number + Family name combined
        },
        {
            display: 'Product type',
            value: 'productType',
        },
        {
            display: 'State',
            value: 'submissionStatus',
        },
        {
            display: 'Publish date',
            value: 'lastUpdated',
        },
        {
            display: 'Status',
            value: 'isActive',
        },
    ])

    const defaultGroupAndSort = [
        {
            id: newGuid(),
            columnOptions,
            columnName: takeFirstOptionAsDefault ? columnOptions[1].value : '',
            value: '',
        },
    ]

    const defaultGroup: DomObject[] = [
        {
            id: newGuid(),
            columnOptions: columnOptions.filter(
                (c) => c.value !== columnOptions[4].value
            ),
            columnName: columnOptions[3].value,
            value: '',
        },
        {
            id: newGuid(),
            columnOptions: columnOptions.filter(
                (c) => c.value !== columnOptions[3].value
            ),

            columnName: columnOptions[4].value,
            value: '',
        },
    ]
    const defaultSort: DomObject[] = (() => [
        ...defaultGroup.map((dg) => ({
            ...dg,
            id: newGuid(),
            groupRefId: dg.id,
            isAddedDynamically: true,
        })),
        {
            id: newGuid(),
            columnOptions: columnOptions.filter(
                (c) =>
                    c.value !== columnOptions[4].value &&
                    c.value !== columnOptions[3].value &&
                    c.value !== columnOptions[6].value
            ),
            columnName: columnOptions[2].value,
            value: '',
        },
        {
            id: newGuid(),
            columnOptions: columnOptions.filter(
                (c) =>
                    c.value !== columnOptions[4].value &&
                    c.value !== columnOptions[3].value &&
                    c.value !== columnOptions[2].value
            ),
            columnName: columnOptions[6].value,
            value: '',
        },
    ])()

    const classes = useStyles()

    const [sortingDomArr, setSortingDomArr] = useState<DomObject[]>([])
    const [groupingDomArr, setGroupingDomArr] = useState<DomObject[]>([])
    const handleDialogOpen = () => {
        setDialogOpen(true)
        if (!sortingDomArr.length) setSortingDomArr(defaultSort)
        if (!groupingDomArr.length) setGroupingDomArr(defaultGroup)
    }
    const onDialogClose = () => {
        setLastSavedDomArr([])
        setLastSavedGroupDomArr([])
        setDialogOpen(false)
    }

    const [tab, setTab] = useState<string>('group')
    const handleTabs = (_e: React.ChangeEvent<{}>, tabValue: string) => {
        setTab(tabValue)
    }
    const addSorting = () => {
        const id = newGuid()
        const columnName = ''

        const newDom: DomObject = {
            id,
            columnName,
            columnOptions,
            value: '',
        }
        const newDomArr = [...sortingDomArr, newDom].map((c) => ({
            ...c,
            columnOptions:
                (!c.isAddedDynamically &&
                    getColumnOption(c.id, [...sortingDomArr, newDom], false)) ||
                columnOptions,
        }))
        setSortingDomArr(newDomArr)
    }
    const [lastSavedDomArr, setLastSavedDomArr] = useState<DomObject[]>([])
    const [lastSavedGroupDomArr, setLastSavedGroupDomArr] = useState<
        DomObject[]
    >([])

    const selectedGroupRef = useRef<string[]>([])

    const columnValueChangeHandler = (
        key: string,
        value: string | boolean,
        id: string
    ) => {
        const isSorting = tab === 'sort'
        const selectedGroup: string[] = []
        const domArr = updateRowValue(
            key,
            value,
            id,
            isSorting ? sortingDomArr : groupingDomArr
        )
        const newDomArr = domArr.map((row) => {
            !isSorting && selectedGroup.push(row.columnName)
            return {
                ...row,
                columnOptions: getColumnOption(row.id, domArr),
            }
        })

        if (isSorting) {
            const newState = newDomArr.map((sd) => {
                if (!sd.isAddedDynamically)
                    sd.columnOptions = deleteSortingOption(
                        sd.columnOptions,
                        selectedGroupRef.current
                    ) as {
                        display: string
                        value: string
                    }[]
                return sd
            })
            setSortingDomArr(newState)
        } else {
            setGroupingDomArr(newDomArr)
            selectedGroupRef.current = selectedGroup
            const groupToSortArr: DomObject[] = newDomArr
                .filter((gs) => !!gs.columnName)
                .map((gs) => {
                    const existingSortRow = sortingDomArr.filter(
                        (sd) =>
                            sd.groupRefId === gs.id ||
                            sd.columnName === gs.columnName
                    )
                    if (existingSortRow.length)
                        return {
                            ...existingSortRow[0],
                            isAddedDynamically: true,
                            value:
                                existingSortRow[0].columnName !== gs.columnName
                                    ? ''
                                    : existingSortRow[0].value,
                            groupRefId: gs.id,
                            columnOptions,
                            columnName: gs.columnName,
                        }
                    return {
                        ...gs,
                        columnOptions,
                        groupRefId: gs.id,
                        id: newGuid(),
                        isAddedDynamically: true,
                    }
                })

            const newState = sortingDomArr.filter(
                (sd) =>
                    !sd.isAddedDynamically &&
                    sd.columnName &&
                    !selectedGroup.includes(sd.columnName)
            )

            const newSorDomArr = [
                ...groupToSortArr,
                ...newState.map((sd) => ({
                    ...sd,
                    columnOptions: getColumnOption(sd.id, newState, false),
                })),
            ]
            setSortingDomArr(
                (newSorDomArr.length && newSorDomArr) || defaultSort
            )
        }
    }
    const deleteSortingOption = (
        columnOptions: {
            display: string
            value: string
        }[],
        items: string[]
    ) => {
        return columnOptions
            .map((option) => !items.includes(option.value) && option)
            .filter((filterOption) => filterOption)
    }
    const updateRowValue = (
        key: string,
        value: string | boolean,
        sortId: string,
        domArr: DomObject[]
    ) => {
        return domArr.map((c) =>
            c.id === sortId
                ? {
                      ...c,
                      [key]: value,
                  }
                : c
        )
    }
    const getSelectedColumnName = useCallback(
        (sortId: string, domArr: DomObject[] = []) => {
            const newDomArr = (domArr.length && domArr) || sortingDomArr
            return newDomArr.filter((c) => c.id === sortId)[0]?.columnName || ''
        },
        [sortingDomArr]
    )

    const getColumnOption = useCallback(
        (sortId: string, domArr: DomObject[] = [], isGrouping = true) => {
            const selectedValue = getSelectedColumnName(sortId, domArr)
            const filteredOptions = columnOptions.filter((option) => {
                if (!option.value) return true
                const newDomArr = (domArr.length && domArr) || sortingDomArr
                const optionDoesExist =
                    newDomArr.filter(
                        (sortingDom: DomObject) =>
                            sortingDom.columnName === option.value
                    ).length > 0
                return (
                    !optionDoesExist ||
                    (selectedValue && selectedValue === option.value)
                )
            })

            return isGrouping
                ? filteredOptions
                : (deleteSortingOption(
                      filteredOptions,
                      selectedGroupRef.current
                  ) as {
                      display: string
                      value: string
                  }[])
        },
        [columnOptions, getSelectedColumnName, sortingDomArr]
    )

    const onDragEnd = (result: DropResult) => {
        if (!result.destination) {
            return
        }
        if (tab === 'sort') {
            const local = reorderArrayItems(
                sortingDomArr,
                result.source.index,
                result.destination.index
            )
            setSortingDomArr(local)
        }
        if (document.getElementById('menu-'))
            document.getElementById('menu-')!.style.display = 'block'
    }
    const addGrouping = () => {
        const id = newGuid()
        const columnName = ''
        if (groupingDomArr.length < 3) {
            const newDom = {
                id,
                columnName,
                columnOptions,
                value: '',
            }
            const newDomArr = [...groupingDomArr, newDom].map((c) => ({
                ...c,
                columnOptions: getColumnOption(c.id, [
                    ...groupingDomArr,
                    newDom,
                ]),
            }))
            setGroupingDomArr(newDomArr)
        }
    }

    const history = useHistory()
    const applySorting = () => {
        const sortByArr: string[] = []
        sortingDomArr.forEach((c) => {
            if (c.columnName) {
                if (c.value) {
                    c.columnName !== 'isActive'
                        ? sortByArr.push(c.columnName + ' ' + c.value)
                        : sortByArr.push(c.columnName + '')
                } else {
                    c.columnName !== 'isActive'
                        ? sortByArr.push(c.columnName)
                        : sortByArr.push(c.columnName + ' DESC')
                }
            }
        })

        let groupObj: Record<string, boolean> = {}
        let groupBySearch: string[] = []
        groupingDomArr.forEach((g) => {
            if (g.columnName) {
                groupObj[g.columnName] = !!g.isChecked
                groupBySearch.push(g.columnName + ' ' + !!g.isChecked)
            }
        })

        onFilterChange?.(
            [JSON.stringify(groupObj), JSON.stringify(sortByArr)],
            'orderBy'
        )

        const searchParams = new URLSearchParams(history.location.search)
        searchParams.has('groupby') && searchParams.delete('groupby')
        searchParams.has('sortby') && searchParams.delete('sortby')
        searchParams.has('page') && searchParams.delete('page')

        groupBySearch.length &&
            searchParams.append(
                'groupby',
                encodeURIComponent(groupBySearch.join(','))
            )
        sortByArr.length &&
            searchParams.append(
                'sortby',
                encodeURIComponent(sortByArr.join(','))
            )

        history.replace({
            ...history.location,
            search: `?${searchParams.toString()}`,
        })
        onDialogClose()
    }

    const handleRemoveSorting = (index: string) => {
        const domArr = sortingDomArr.filter((optionId) => {
            return optionId.id !== index
        })
        const newDomArr = domArr.map((row) => {
            return {
                ...row,
                columnOptions:
                    (!row.isAddedDynamically &&
                        getColumnOption(row.id, domArr, false)) ||
                    columnOptions,
            }
        })
        if (newDomArr.length === 0) {
            setSortingDomArr(defaultGroupAndSort)
        } else {
            setSortingDomArr(newDomArr)
        }
    }
    const handleRemoveGrouping = (groupingId: string) => {
        const domArr = groupingDomArr.filter((optionId) => {
            return optionId.id !== groupingId
        })
        const selectedGroup: string[] = []
        const newDomArr = domArr.map((row) => {
            selectedGroup.push(row.columnName)
            return {
                ...row,
                columnOptions: getColumnOption(row.id, domArr),
            }
        })
        selectedGroupRef.current = selectedGroup
        const newState = sortingDomArr.filter(
            (sd) => sd.groupRefId !== groupingId
        )

        setSortingDomArr(
            (newState.length &&
                newState.map((sd) => ({
                    ...sd,
                    columnOptions:
                        (!sd.isAddedDynamically &&
                            getColumnOption(sd.id, newState, false)) ||
                        columnOptions,
                }))) ||
                defaultGroupAndSort
        )
        setGroupingDomArr(
            (newDomArr.length && newDomArr) || defaultGroupAndSort
        )
    }

    const beforeDragStart = () => {
        if (document.getElementById('menu-'))
            document.getElementById('menu-')!.style.display = 'none'
    }

    useEffect(() => {
        if (mainContainerRef.current) {
            const elm = mainContainerRef.current! as HTMLDivElement
            elm.scrollTop = elm.scrollHeight
        }
    }, [mainContainerRef, sortingDomArr.length])

    useEffect(() => {
        const searchParams = new URLSearchParams(history.location.search)
        if (
            history.location.search &&
            ((searchParams.has('groupby') && searchParams.get('groupby')) ||
                (searchParams.has('sortby') && searchParams.get('sortby')))
        ) {
            if (lastSavedGroupDomArr.length > 0 || lastSavedDomArr.length > 0)
                return
            const groupByData =
                (searchParams.has('groupby') &&
                    decodeURIComponent(searchParams.get('groupby')!)) ||
                ''
            const sortBy =
                (searchParams.has('sortby') &&
                    decodeURIComponent(searchParams.get('sortby')!)) ||
                ''
            const selectedGroup: string[] = []
            const groupByObjDom = groupByData?.split(',').map((g) => {
                const id = newGuid()
                selectedGroup.push(g?.split(' ')[0])
                return {
                    id,
                    columnName: g?.split(' ')[0],
                    columnOptions: [],
                    value: '',
                    isChecked: g?.split(' ')[1] === 'true',
                }
            }) as DomObject[]
            selectedGroupRef.current = selectedGroup
            const newDomArr = groupByObjDom.map((c) => ({
                ...c,
                columnOptions: getColumnOption(c.id, [...groupByObjDom]),
            }))
            setGroupingDomArr(newDomArr)
            setLastSavedGroupDomArr(newDomArr)
            const sortByObjDom = sortBy?.split(',').map((g) => {
                const id = newGuid()
                const columnName = g?.split(' ')[0]
                const groupRef = newDomArr.filter(
                    (gd) => gd.columnName === columnName
                )?.[0]
                const sortValue =
                    columnName !== 'isActive'
                        ? g?.split(' ')[1] ?? ''
                        : g?.split(' ')[1]
                        ? ''
                        : 'desc'
                return {
                    id,
                    isAddedDynamically: !!groupRef,
                    groupRefId: groupRef?.id,
                    columnName,
                    columnOptions: [],
                    value: sortValue,
                }
            }) as DomObject[]
            const newSortByArr = sortByObjDom.map((c) => ({
                ...c,
                columnOptions:
                    (!c.isAddedDynamically &&
                        getColumnOption(c.id, [...sortByObjDom], false)) ||
                    columnOptions,
            }))
            setSortingDomArr(newSortByArr)
            setLastSavedDomArr(newSortByArr)
        } else {
            if (lastSavedGroupDomArr.length > 0 || lastSavedDomArr.length > 0)
                return
            setSortingDomArr(defaultSort)
            setLastSavedDomArr(defaultSort)
            setGroupingDomArr(defaultGroup)
            setLastSavedGroupDomArr(defaultGroup)
        }
    }, [
        history.location.search,
        getColumnOption,
        lastSavedDomArr.length,
        lastSavedGroupDomArr.length,
        columnOptions,
    ])

    return (
        <>
            <Link
                data-testid="sorting-modal-button"
                className={classes.editbutton}
                onClick={handleDialogOpen}
            >
                <EditOutlinedIcon
                    className={classes.editIcon}
                    fontSize="small"
                />
                <span className={classes.editContainer}>
                    Edit Grouping & Sorting
                </span>
            </Link>

            <Dialog
                open={dialogOpen}
                onClose={onDialogClose}
                maxWidth="md"
                disableBackdropClick={true}
                disableEscapeKeyDown={true}
                aria-labelledby="draggable-dialog-title"
            >
                <DialogTitle className={classes.title}>
                    <Typography
                        variant="h1"
                        component="span"
                        className={classes.modalheader}
                    >
                        Grouping and Sorting
                    </Typography>
                    <IconButton
                        className={classes.closeButton}
                        data-testid="close-btn"
                        aria-label="close"
                        size="small"
                        onClick={onDialogClose}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent className={classes.dialogcontent}>
                    <Paper square className={classes.paper}>
                        <TabContext value={tab}>
                            <TabList
                                indicatorColor="primary"
                                textColor="primary"
                                onChange={handleTabs}
                                aria-label="simple tabs example"
                            >
                                <Tab
                                    data-testid="group-tab"
                                    label="Grouping"
                                    value="group"
                                    className={classes.tabs}
                                />
                                <Tab
                                    data-testid="sort-tab"
                                    label="Sorting"
                                    value="sort"
                                    className={classes.tabs}
                                />
                            </TabList>
                            <TabPanel value="group">
                                {' '}
                                <>
                                    {groupingDomArr.length > 0 && (
                                        <GridContainer
                                            className={classes.headContent}
                                        >
                                            <GridItem
                                                xs={6}
                                                className={classes.header}
                                            >
                                                <Typography
                                                    variant="body2"
                                                    component="span"
                                                    className={classes.options}
                                                >
                                                    FIRST GROUPING
                                                </Typography>
                                            </GridItem>
                                            <GridItem
                                                xs={6}
                                                className={classes.header}
                                            >
                                                <Typography
                                                    variant="body2"
                                                    component="span"
                                                >
                                                    COLLAPSED
                                                </Typography>
                                            </GridItem>
                                        </GridContainer>
                                    )}

                                    <GridContainer
                                        className={classes.mainContent}
                                    >
                                        <GridItem
                                            xs={12}
                                            data-testid="groupingMainContainer"
                                        >
                                            <GridContainer
                                                className={
                                                    classes.mainContainer
                                                }
                                                ref={mainContainerRef}
                                                item
                                            >
                                                <ProductGroupingForm
                                                    groupingDomArr={
                                                        groupingDomArr
                                                    }
                                                    columnValueChangeHandler={
                                                        columnValueChangeHandler
                                                    }
                                                    handleRemoveGrouping={
                                                        handleRemoveGrouping
                                                    }
                                                />
                                            </GridContainer>{' '}
                                            {groupingDomArr?.filter(
                                                (gd) => !gd.columnName
                                            )?.length === 0 &&
                                                groupingDomArr.length < 3 && (
                                                    <Button
                                                        onClick={addGrouping}
                                                        className={clsx(
                                                            classes.addButton,
                                                            'add-grouping-btn'
                                                        )}
                                                    >
                                                        <AddSharpIcon
                                                            className={
                                                                classes.addIcon
                                                            }
                                                        />{' '}
                                                        Add Grouping
                                                    </Button>
                                                )}
                                        </GridItem>
                                    </GridContainer>
                                </>
                            </TabPanel>

                            <TabPanel value="sort">
                                {' '}
                                <>
                                    <GridContainer
                                        className={classes.headContent}
                                    >
                                        <GridItem
                                            xs={6}
                                            className={classes.header}
                                        >
                                            <Typography
                                                variant="body2"
                                                component="span"
                                                className={classes.options}
                                            >
                                                OPTIONS
                                            </Typography>
                                        </GridItem>
                                        <GridItem
                                            xs={6}
                                            className={classes.header}
                                        >
                                            <Typography
                                                variant="body2"
                                                component="span"
                                            >
                                                SORT
                                            </Typography>
                                        </GridItem>
                                    </GridContainer>

                                    <GridItem
                                        xs={12}
                                        data-testid="sortingMainContainer"
                                    >
                                        <GridContainer
                                            className={classes.mainContainer}
                                            ref={mainContainerRef}
                                            item
                                        >
                                            <DragDropContext
                                                onBeforeDragStart={
                                                    beforeDragStart
                                                }
                                                onDragEnd={onDragEnd}
                                            >
                                                <Droppable droppableId="drop1">
                                                    {(
                                                        provided: DroppableProvided
                                                    ) => (
                                                        <GridContainer
                                                            ref={
                                                                provided.innerRef
                                                            }
                                                            {...provided.droppableProps}
                                                            className={
                                                                classes.mainContent
                                                            }
                                                        >
                                                            <ProductSortingForm
                                                                sortingDomArr={
                                                                    sortingDomArr
                                                                }
                                                                columnValueChangeHandler={
                                                                    columnValueChangeHandler
                                                                }
                                                                handleRemoveSorting={
                                                                    handleRemoveSorting
                                                                }
                                                                placeHolder={
                                                                    provided.placeholder
                                                                }
                                                            />
                                                        </GridContainer>
                                                    )}
                                                </Droppable>
                                            </DragDropContext>{' '}
                                            {sortingDomArr.length <
                                                columnOptions.length - 1 &&
                                                sortingDomArr?.filter(
                                                    (sd) => !sd.columnName
                                                )?.length === 0 && (
                                                    <Button
                                                        onClick={addSorting}
                                                        className={clsx(
                                                            classes.addSorting,
                                                            'add-sorting-btn'
                                                        )}
                                                    >
                                                        <AddSharpIcon
                                                            className={
                                                                classes.addIcon
                                                            }
                                                        />{' '}
                                                        Add Sorting
                                                    </Button>
                                                )}
                                        </GridContainer>
                                    </GridItem>
                                </>
                            </TabPanel>
                        </TabContext>
                    </Paper>
                </DialogContent>
                <DialogActions className={classes.actionContainer}>
                    <Button
                        className={classes.cancelbutton}
                        onClick={onDialogClose}
                    >
                        Cancel
                    </Button>
                    <Button
                        data-testid="apply-button"
                        className={classes.applyButton}
                        onClick={applySorting}
                    >
                        Apply
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
