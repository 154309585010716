import Chip from '@material-ui/core/Chip'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Autocomplete, {
    AutocompleteGetTagProps,
} from '@material-ui/lab/Autocomplete'
import HoverTooltip from 'components/Feedbacks/HoverTooltip'
import SingleLineEllipsis from 'components/Feedbacks/SingleLineEllipsis'
import { debounce } from 'components/Utilities/formUtils'
import React, { ChangeEvent, useCallback, useState } from 'react'
import Truncate from 'react-truncate'

const autoCompleteStyles = makeStyles({
    chipLabel: {
        overflow: 'unset',
        textOverflow: 'unset',
        whiteSpace: 'normal',
        maxWidth: 200,
    },
    option: {
        color: '#5E5F61',
    },
})

export interface AutoCompleteFilterProps {
    /** Name of this autocomplete. Used to create its id. */
    name: string

    /** The items the user can choose from. */
    items: string[]

    /** Hint text for textbox. */
    placeholder?: string

    /** Fired when user selects or removes an item. */
    onChange?: (
        event: ChangeEvent<{}>,
        /** The selected items. */
        value: string[]
    ) => void

    /** Fired shortly after the autocomplete input text field changes. */
    onInputChangeDebounced?: (
        /** The value of the input text field. */
        value: string
    ) => void

    /** Fired when user opens the drop-down (e.g., by clicking the textbox,
     * clicking the arrow button, or typing in the textbox when the drop-down is closed). */
    onOpen?: (event: ChangeEvent<{}>) => void

    /** Fired when the user closes the drop-down (e.g., by clicking the arrow button or hitting Esc.) */
    onClose?: (event: ChangeEvent<{}>) => void

    /** Items to show as selected. Each will have a pill with an [x] that
     * can be clicked to remove them from this list.
     */
    selectedItems?: string[]

    /** If displayed pill and on hover are different, we can control it by passing the same set of modified array here.
     * For Family Names, the displayed value in the pill has no sectionNumber compared to the value on hover which has
     */
    selectedDisplayItems?: string[]

    /** Reference element for input textbox. */
    onInputRef?: (inputElement: HTMLElement) => void

    /** If true, show a loading state for the drop-down selection listbox. */
    loading?: boolean

    /** If true, show custom format for option. */
    customFormat?: boolean
}

interface AutoCompleteTagsProps {
    name: string
    customFormat: boolean
    option: string
    index: number
    selectedDisplayItems?: string[]
    getTagProps: AutocompleteGetTagProps
}

const AutoCompleteTags: React.FC<AutoCompleteTagsProps> = ({
    name,
    customFormat,
    option,
    index,
    selectedDisplayItems,
    getTagProps,
}) => {
    const styles = autoCompleteStyles()
    const optionName = customFormat ? customOptionDisplay(option, name) : option
    return (
        <Chip
            {...getTagProps({ index })}
            classes={{ label: styles.chipLabel }}
            variant="outlined"
            label={
                !!selectedDisplayItems && selectedDisplayItems?.length > 0 ? (
                    // Use hover tooltip to always show tooltip not only when truncated
                    <HoverTooltip
                        text={optionName}
                        children={
                            <div>
                                <Truncate lines={1}>
                                    {selectedDisplayItems[index]}
                                </Truncate>
                            </div>
                        }
                    />
                ) : (
                    <SingleLineEllipsis text={optionName} />
                )
            }
        />
    )
}

const customOptionDisplay = (option: string, name: string) => {
    //string = display name - firm name
    const names = option.split('%|%')
    if (names.length > 1 && name === 'filter-company-autocomplete') {
        if (names[0] === names[1]) {
            return names[0].slice(1, -1)
        }
        if (names[0] === 'null') {
            return names[1].slice(1, -1)
        }
        return `${names[0].slice(1, -1)} (${names[1].slice(1, -1)})`
    }
    return option
}

const AutoCompleteFilter: React.FC<AutoCompleteFilterProps> = ({
    name,
    items,
    placeholder,
    onChange,
    onInputChangeDebounced,
    onOpen,
    onClose,
    selectedItems,
    selectedDisplayItems,
    onInputRef,
    loading = false,
    customFormat = false,
}) => {
    const styles = autoCompleteStyles()
    let onTextInputRef = onInputRef
    const [inputValue, setInputValue] = React.useState('')
    const [loadingOverride, setLoadingOverride] = useState<boolean>(false)
    const selectedValues = new Set<string>(selectedItems)
    const debouncedRaiseOnInput = useCallback(
        debounce((newValue: string) => {
            onInputChangeDebounced?.(newValue)
            setLoadingOverride(false) // we can now wait on the parent component to set 'loading' (e.g., from a useQuery)
        }),
        []
    )

    return (
        <Autocomplete<string, true, true>
            classes={{ option: styles.option }}
            id={`${name}-autocomplete`}
            defaultValue={undefined}
            value={selectedItems}
            multiple
            autoHighlight
            ListboxProps={{ style: { maxHeight: 260 } }}
            inputValue={inputValue}
            onInputChange={(_unusedEvent, newInputValue) => {
                // show 'Loading...' as soon as user presses a key; this avoids showing a 'No Results' briefly before 'loading' state
                // is refreshed by parent component (e.g., from a useQuery call)
                setLoadingOverride(true)
                setInputValue(newInputValue)
                debouncedRaiseOnInput(newInputValue)
            }}
            options={items}
            filterOptions={(options, { inputValue }) =>
                options.filter(
                    (option) =>
                        // To be included in the drop-down list, an option can't already be selected...
                        !selectedValues.has(option) &&
                        // ...AND it must contain the input search string, if any.
                        (!inputValue ||
                            option
                                .toLocaleLowerCase()
                                .includes(inputValue.toLocaleLowerCase()))
                )
            }
            disableClearable
            renderOption={(option) => (
                <Typography>
                    {customFormat ? customOptionDisplay(option, name) : option}
                </Typography>
            )}
            renderTags={(value, getTagProps) => {
                return value.map((option, index) => (
                    <AutoCompleteTags
                        key={index}
                        name={name}
                        customFormat={customFormat}
                        option={option}
                        index={index}
                        getTagProps={getTagProps}
                        selectedDisplayItems={selectedDisplayItems}
                    />
                ))
            }}
            renderInput={(params) => {
                const { InputProps, ...restParams } = params
                const { startAdornment, ...restInputProps } = InputProps
                return (
                    <>
                        <input
                            style={{ display: 'none' }}
                            type="text"
                            name={`fake${name}`}
                        />
                        <TextField
                            {...restParams}
                            inputProps={{
                                ...params.inputProps,
                                'aria-autocomplete': 'none',
                                autoComplete: 'chrome-off',
                            }}
                            InputProps={{
                                ...restInputProps,
                                startAdornment: (
                                    <div
                                        style={{
                                            maxHeight: 266,
                                            overflowY: 'auto', // show vertical scrollbar when many items selected
                                        }}
                                    >
                                        {startAdornment}
                                    </div>
                                ),
                            }}
                            id={`${name}-input`}
                            data-testid={`${name}-input-test`}
                            name={`input${name}`}
                            label="SELECT"
                            variant="outlined"
                            inputRef={(input) =>
                                onTextInputRef && onTextInputRef(input)
                            }
                            placeholder={placeholder}
                        />
                    </>
                )
            }}
            onChange={onChange}
            onOpen={onOpen}
            onClose={onClose}
            loading={loading || loadingOverride}
        />
    )
}

export default AutoCompleteFilter
