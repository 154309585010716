import { CdnBaseUrl } from 'components/Utilities/urlUtils'

export interface GetDefaultBannerUrlByCategoryNameProps {
    categoryName?: string
}

export const getDefaultBannerUrlByCategoryName = ({
    categoryName,
}: GetDefaultBannerUrlByCategoryNameProps) => {
    switch (categoryName?.toLocaleLowerCase().trim()) {
        case 'air terminals':
        case 'mechanical equipment':
        case 'flex ducts':
        case 'flex pipes':
            return `${CdnBaseUrl}/specpoint/global/banner-default-images/air_terminals.jpg`
        case 'electrical equipment':
        case 'electrical fixtures':
        case 'conduits':
        case 'fire alarm devices':
        case 'wire':
            return `${CdnBaseUrl}/specpoint/global/banner-default-images/conduits.jpg`
        case 'security devices':
        case 'communication devices':
            return `${CdnBaseUrl}/specpoint/global/banner-default-images/communication_devices.jpg`
        case 'lighting devices':
        case 'lighting fixtures':
            return `${CdnBaseUrl}/specpoint/global/banner-default-images/lighting_fixtures.jpg`
        case 'sprinklers':
        case 'pipe accessories':
        case 'pipe fittings':
        case 'pipes':
            return `${CdnBaseUrl}/specpoint/global/banner-default-images/pipe_accessories.jpg`
        case 'planting':
        case 'site':
            return `${CdnBaseUrl}/specpoint/global/banner-default-images/planting.jpg`
        case 'casework':
            return `${CdnBaseUrl}/specpoint/global/banner-default-images/case_work.jpg`
        case 'curtain wall mullions':
            return `${CdnBaseUrl}/specpoint/global/banner-default-images/curtain_wall_mullions.jpg`
        case 'curtain systems':
            return `${CdnBaseUrl}/specpoint/global/banner-default-images/curtain_systems.jpg`
        case 'curtain panels':
            return `${CdnBaseUrl}/specpoint/global/banner-default-images/curtain_panels.jpg`
        case 'doors':
            return `${CdnBaseUrl}/specpoint/global/banner-default-images/doors.jpg`
        case 'plumbing fixtures':
            return `${CdnBaseUrl}/specpoint/global/banner-default-images/plumbing_fixtures.jpg`
        case 'windows':
            return `${CdnBaseUrl}/specpoint/global/banner-default-images/windows.jpg`
        case 'roofs':
            return `${CdnBaseUrl}/specpoint/global/banner-default-images/roofs.jpg`
        case 'floors':
            return `${CdnBaseUrl}/specpoint/global/banner-default-images/floors.jpg`
        case 'ceilings':
            return `${CdnBaseUrl}/specpoint/global/banner-default-images/ceilings.jpg`
        case 'columns':
            return `${CdnBaseUrl}/specpoint/global/banner-default-images/columns.jpg`
        case 'parking':
            return `${CdnBaseUrl}/specpoint/global/banner-default-images/parking.jpg`
        case 'stairs':
        case 'railings':
            return `${CdnBaseUrl}/specpoint/global/banner-default-images/railings.jpg`
        case 'walls':
            return `${CdnBaseUrl}/specpoint/global/banner-default-images/walls.jpg`
        // generic models
        // specialty equipment
        default:
            return `${CdnBaseUrl}/specpoint/global/banner-default-images/specialty_equipment.jpg`
    }
}
