// TODO: Uncomment lines if edit of categories is needed

// import IconButton from '@material-ui/core/IconButton'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
// import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import clsx from 'clsx'
// import InfoBubble from 'components/Feedbacks/InfoBubble'
import WidgetContainer from 'components/Surfaces/WidgetContainer'
import { pxToRem } from 'components/Utilities/unitUtils'
import {
    defaultSortAndGroup,
    escapeElementDelimiter,
} from 'components/Widgets/ProductListing/productsUtils'
import React from 'react'
import NumberFormat from 'react-number-format'
import { Link } from 'react-router-dom'

const useStyles = makeStyles({
    textContent: {
        fontSize: (props: ListingTypeWidgetInfoBubbleStyleProps) => {
            let size = 50
            if (props.contentLength >= 6) {
                size = 50 - (props.contentLength - 5) * 5
            }
            return pxToRem(size)
        },
        color: '#13B5EA',
        fontWeight: 'bold',
        textDecoration: 'none',
    },
})

// const useInfoBubbleStyles = makeStyles({
//     infoBubbleInfoButton: {
//         color: '#fff',
//         padding: 0,
//         visibility: 'hidden',
//     },
// })

export interface ListingTypeWidgetInfoBubbleStyleProps {
    contentLength: number
}

export interface ListingTypeWidgetInfoBubbleProps {
    /**
     * Info bubble title
     */
    title?: string

    /**
     * Info bubble text content.
     */
    content?: string
}

// interface HeaderComponentProps {
/**
 * Info bubble props to be passed onto the info bubble component.
 */
//     infoBubble?: ListingTypeWidgetInfoBubbleProps
// }

/**
 *  Custom header component passed onto the base WidgetContainer.
 *
 *  This includes the InfoBubble anchor element icon.
 *  Remove line below this comment if used
 */

// const HeaderComponent: React.FC<HeaderComponentProps> = ({ infoBubble }) => {
//     const classes = useInfoBubbleStyles()
//     const [anchor, setAnchor] = React.useState<null | HTMLElement>(null)

//     const handleClick = (event: React.MouseEvent<HTMLElement>) => {
//         setAnchor(event.currentTarget)
//     }

//     const closeInfoBubble = () => {
//         setAnchor(null)
//     }

//     return (
//         <>
//             <IconButton
//                 onClick={handleClick}
//                 className={clsx(
//                     'listingTypeInfoBubbleAnchor',
//                     classes.infoBubbleInfoButton
//                 )}
//             >
//                 <InfoOutlinedIcon />
//             </IconButton>
//             <InfoBubble
//                 anchorEl={anchor}
//                 onCloseRequest={closeInfoBubble}
//                 text={infoBubble?.content}
//                 title={infoBubble?.title}
//             />
//         </>
//     )
// }

export interface ListingTypeWidgetProps {
    /**
     * Number to be used as text content for he widget.
     */
    count?: string | number

    /**
     * Title to be used for the widget container
     */
    listingName?: string

    /**
     * Info bubble props
     */
    infoBubble?: ListingTypeWidgetInfoBubbleProps

    /**
     * Loading state for WidgetContainer
     */
    loading?: boolean

    /**
     * Error object for WidgetContainer
     */
    error?: Error

    /**
     * Widget width for layout
     */
    width?: string | number

    headerMinHeight?: number

    /**
     * Manufacturer name to link to
     */
    firmName?: string

    /**
     * Display name of the Manufacturer to link to
     */
    firmDisplayName?: string

    /**
     * Listing level filter strings to link to
     */
    listingTypeFilters?: string[]
    /**
     * Function to run when clicking the link
     */
    handleListingTypeWidgetClick?: (link: string) => void
    /**
     * This prop is temporary during ae-bpm merge and will be removed once BPM will be deprecated

     */
    callingFromAE?: boolean
}

/**
 *  Pre-styled tile widget for listing type counts.
 *
 *  @param {ListingTypeWidgetProps} props
 */
const ListingTypeWidget: React.FC<ListingTypeWidgetProps> = React.memo(
    ({
        count = 0,
        listingName,
        infoBubble,
        loading = false,
        error,
        width = '100%',
        headerMinHeight = 5,
        firmName,
        firmDisplayName,
        listingTypeFilters,
        handleListingTypeWidgetClick,
        callingFromAE,
        ...props
    }) => {
        const classes = useStyles({ contentLength: count?.toString().length })
        const mergedClientURL = callingFromAE ? '/bpm' : ''
        // In group by sectionNumber is param name and false is collapse state

        const defaultParams = `${encodeURI(
            defaultSortAndGroup
        )}&df=${encodeURIComponent(
            `'${escapeElementDelimiter(
                firmDisplayName ?? ''
            )}'%|%'${escapeElementDelimiter(firmName ?? '')}'`
        )}&ia=true&ll=${encodeURIComponent(
            listingTypeFilters?.join(',') ?? ''
        )}`
        return (
            <WidgetContainer
                headerBackground="linear-gradient(90deg, #02A09C 0%, #05666D 100%);"
                borderRadius={8}
                title={listingName}
                titleFontSize={16}
                minWidth={120}
                headerMinHeight={headerMinHeight}
                width={width}
                titleFontWeight={500}
                contentBackground="#f2f2f2"
                height="100%"
                contentHeight="100%"
                // headerComponent={<HeaderComponent infoBubble={infoBubble} />}
                centerHeaderComponent
                centerHeaderTitle
                loading={loading}
                error={error}
                {...props}
            >
                <Typography
                    component={Link}
                    to={`${mergedClientURL}/products/all?${defaultParams}`}
                    className={clsx('listingTypeCount', classes.textContent)}
                    variant="h3"
                    onClick={() =>
                        handleListingTypeWidgetClick?.(
                            `${mergedClientURL}/products/all?${defaultParams}`
                        )
                    }
                >
                    <NumberFormat
                        thousandSeparator={true}
                        value={count}
                        displayType="text"
                    />
                </Typography>
            </WidgetContainer>
        )
    }
)

export default ListingTypeWidget
