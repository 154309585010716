import Button from '@material-ui/core/Button'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import clsx from 'clsx'
import GridContainer from 'components/Surfaces/GridContainer'
import React from 'react'

const useStyles = makeStyles((theme: Theme) => ({
    hiddenText: {
        overflow: 'hidden',
        transition: 'max-height 0.5s ease-out',
        maxHeight: 100,
        '&::after': {
            content: '""',
            position: 'absolute',
            bottom: 0,
            left: 0,
            pointerEvents: 'none',
            backgroundImage:
                'linear-gradient(to bottom, rgba(255,255,255, 0), rgba(255,255,255, 1) 90%)',
            width: '100%',
            height: '2.5em',
        },
    },
    showText: {
        height: 'auto',
        transition: 'max-height 0.5s ease-in',
        maxHeight: 500,
        overflowY: 'auto',
    },
    textContainer: {
        position: 'relative',
        marginTop: theme.spacing(1),
        wordBreak: 'break-all',
    },
    readMoreButton: {
        marginLeft: theme.spacing(-2),
    },
    description: {
        wordBreak: 'break-word',
        //For overriding Browese  user-agent style/auto created style for content editable
        whiteSpace: 'pre-wrap',
        '& span,& i, & div, & b, & strong, & li, & blockquote': {
            fontFamily: 'inherit !important',
            fontSize: 'inherit !important',
            lineHeight: 'inherit !important',
            textDecoration: 'none !important',
            color: 'inherit !important',
            border: 'none !important',
            width: 'auto !important',
        },
    },
}))

interface ViewDescriptionProps {
    longDescription?: string
}

const ViewDescription: React.FC<ViewDescriptionProps> = ({
    longDescription,
}) => {
    const [showMore, setShowMore] = React.useState<Record<string, boolean>>({})
    const descriptionRef = React.useRef<HTMLDivElement | null>(null)
    const maxLength = 525
    const handleTextShow = (field: string) => (
        _e: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        descriptionRef && descriptionRef.current?.scrollTo(0, 0)
        setShowMore((prevState: Record<string, boolean>) => ({
            ...prevState,
            [field]: !prevState[field],
        }))
    }
    const classes = useStyles()
    return (
        <>
            {
                <GridContainer
                    item
                    className={clsx(
                        longDescription &&
                            longDescription.length > maxLength &&
                            (showMore.showMoreDesc
                                ? classes.showText
                                : classes.hiddenText),
                        classes.textContainer
                    )}
                    ref={descriptionRef}
                >
                    <Typography
                        variant="body1"
                        data-testid="lblLongDescription"
                        className={classes.description}
                        dangerouslySetInnerHTML={{
                            __html: longDescription ?? '',
                        }}
                    />
                </GridContainer>
            }
            {longDescription && longDescription.length > maxLength && (
                <GridContainer item>
                    <Button
                        color="primary"
                        onClick={handleTextShow('showMoreDesc')}
                        className={classes.readMoreButton}
                        data-testid="btnRead"
                    >
                        {showMore.showMoreDesc ? 'Less' : 'More'}
                    </Button>
                </GridContainer>
            )}
        </>
    )
}

export default ViewDescription
