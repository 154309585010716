import { makeStyles, Theme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import clsx from 'clsx'
import { ListingLevel, Partnership } from 'components/Models/product'
import React from 'react'
import GridContainer from 'components/Surfaces/GridContainer'
import GridItem from 'components/Surfaces/GridItem'
import { PartnerName, PartnerType } from './partnershipTypes'
import ViewPartnershipType from './ViewPartnershipType'

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        maxWidth: 504,
        paddingBottom: theme.spacing(1),
    },
    content: {
        paddingTop: theme.spacing(1),
        paddingLeft: theme.spacing(1),
    },
    fullWidth: {
        width: '100%',
    },
    title: {
        color: '#04325B',
        fontWeight: 500,
        borderBottom: '6px solid #04325B',
        textTransform: 'uppercase',
    },
}))

const getLinkDetail = (
    partnerType: PartnerType,
    name: PartnerName,
    links: Partial<Partnership>[]
) => {
    return (
        links?.filter(
            (each) =>
                each.partnerType === partnerType &&
                each.name === name &&
                each.url !== ''
        )?.[0] ?? undefined
    )
}

export interface ViewPartnershipsProps {
    level?: ListingLevel
    partnerships?: Partial<Partnership>[]
    showSwatchbox?: boolean
}

export const ViewPartnerships: React.FC<ViewPartnershipsProps> = ({
    level,
    partnerships,
    showSwatchbox = false,
}) => {
    const classes = useStyles()
    if (!level || !partnerships) return null
    if (partnerships.length < 1) return null

    const bimLink = getLinkDetail('BIM', 'BIMsmith', partnerships)
    const swatchboxLink = getLinkDetail('Swatchbox', 'Swatchbox', partnerships)
    const ule = getLinkDetail('Sustainability', 'ULE', partnerships)
    const sustainability = getLinkDetail(
        'Sustainability',
        'Sustainable Minds',
        partnerships
    )

    if (!bimLink && !ule && !sustainability && !swatchboxLink) return null

    return (
        <GridContainer
            data-testid="dvPartnerships"
            className={classes.container}
        >
            <GridItem className={classes.fullWidth}>
                <Typography variant="h6" className={classes.title}>
                    Partnerships
                </Typography>
            </GridItem>
            {bimLink && (
                <GridItem className={clsx(classes.content, classes.fullWidth)}>
                    <GridContainer direction="row">
                        <ViewPartnershipType
                            label="BIMsmith"
                            xs={4}
                            link={bimLink?.url}
                        />
                    </GridContainer>
                </GridItem>
            )}

            {showSwatchbox && swatchboxLink && (
                <GridItem className={clsx(classes.content, classes.fullWidth)}>
                    <GridContainer direction="row">
                        <ViewPartnershipType
                            label="Swatchbox"
                            xs={4}
                            link={swatchboxLink?.url}
                        />
                    </GridContainer>
                </GridItem>
            )}
            {ule && (
                <GridItem className={clsx(classes.content, classes.fullWidth)}>
                    <GridContainer direction="row">
                        <ViewPartnershipType
                            label="ULE"
                            xs={2}
                            link={ule?.url}
                        />
                    </GridContainer>
                </GridItem>
            )}

            {sustainability && (
                <GridItem className={clsx(classes.content, classes.fullWidth)}>
                    <GridContainer direction="row">
                        <ViewPartnershipType
                            label="Sustainable Minds"
                            xs={6}
                            link={sustainability?.url}
                        />
                    </GridContainer>
                </GridItem>
            )}
        </GridContainer>
    )
}

export default ViewPartnerships
