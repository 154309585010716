import Skeleton from '@material-ui/lab/Skeleton'
import GridContainer from 'components/Surfaces/GridContainer'
import GridItem from 'components/Surfaces/GridItem'
import React from 'react'
import { ListingTypeWidgetInfoBubbleProps } from './ListingTypeWidget'

export const dummyListingTypeWidgetInfoBubble: ListingTypeWidgetInfoBubbleProps = {
    title: 'Title',
    content: 'Info bubble with quick explanation of heading meaning.',
}

export const TextBlockLoadSkeleton = () => (
    <GridContainer
        direction="column"
        spacing={1}
        className="dvTextBlockLoadSkeleton"
    >
        {Array.from(Array(4)).map((_, idx) => {
            return (
                <GridItem key={idx}>
                    <Skeleton width="100%" />
                </GridItem>
            )
        })}
        <GridItem>
            <Skeleton width="40%" />
        </GridItem>
    </GridContainer>
)
