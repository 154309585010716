import Divider from '@material-ui/core/Divider'
import { GridSize } from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { Theme, makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import OptionTooltip from 'components/Feedbacks/OptionTooltip'
import {
    ListingLevel,
    ManufacturerProductPropertyDetail,
    ManufacturerProductPropertyOptionDetail,
} from 'components/Models/product'
import GridContainer from 'components/Surfaces/GridContainer'
import GridItem from 'components/Surfaces/GridItem'
import { pxToRem } from 'components/Utilities/unitUtils'
import React, { Fragment, useMemo } from 'react'
import { Propertyname } from './Propertyname'
import {
    MANUFACTURER_OPTION,
    MANUFACTURER_OPTIONS,
    MASTERSPEC_OPTION,
} from './constants'
import colors from './listingColors'
import {
    PropertyType,
    getMasterSpecPropertyGroup,
    getNonMasterSpecPropertyGroup,
    getPropertyGroupSelectedData,
} from './propertiesUtils'

const {
    defaultDark,
    masterPrimary,
    premiumPrimary,
    propertiesDivider,
    premiumPlusHeader,
} = colors

const useStyles = makeStyles((theme: Theme) => ({
    divider: {
        backgroundColor: propertiesDivider,
        marginBottom: theme.spacing(1),
        width: '100%',
    },
    noPropertyMainContent: {
        padding: theme.spacing(1),
        marginBottom: theme.spacing(4),
        display: 'flex',
        alignItems: 'center',
        aligncontent: 'center',
        margin: '0 auto',
    },
    propertyPillsOption: {
        fontSize: pxToRem(13),
        minHeight: 20,
        minWidth: 'fit-content',
        marginRight: theme.spacing(2),
        color: defaultDark,
        '&:last-child': {
            marginRight: 0,
        },
    },
    propertyPillsPremiumOption: {
        fontSize: pxToRem(13),
        minHeight: 20,
        minWidth: 'fit-content',
        marginRight: theme.spacing(2),
        color: premiumPrimary,
    },
    propContent: {
        margin: theme.spacing(1),
    },
    propName: {
        lineHeight: 'inherit',
        display: 'block',
        margin: '0 8px',
    },
    propPrefixPostfix: {
        fontSize: pxToRem(13),
        minWidth: 'fit-content',
        marginBottom: theme.spacing(1),
        marginRight: theme.spacing(2),
    },
    mainPropContainer: {
        flexDirection: 'column',
        minHeight: 400,
        overflowX: 'hidden',
    },
    containerFullWidth: {
        width: '100%',
        marginTop: theme.spacing(2),
    },
    noProperty: {
        color: '#5E5F61',
        paddingTop: theme.spacing(1),
    },
    lessView: {
        color: masterPrimary,
    },
    lessViewPremium: {
        color: premiumPlusHeader,
    },
    propertiesDetails: {
        marginTop: theme.spacing(0.5),
        marginBottom: theme.spacing(0.5),
    },

    pillWidth: { maxWidth: 'fit-content' },

    propertiesContainer: { display: 'flex' },

    propertyPills: {
        fontSize: pxToRem(13),
        minWidth: 'fit-content',
        marginBottom: theme.spacing(1.2),
        marginRight: theme.spacing(2),
        color: '#474747',
    },

    checkedProperty: {
        fontSize: pxToRem(13),
        fontWeight: 500,
        minWidth: 'fit-content',
        marginBottom: theme.spacing(1),
        marginRight: theme.spacing(2),
        color: masterPrimary,

        '&::before': {
            content: "'\\2713'",
            display: 'inline-block',
            color: masterPrimary,
            marginRight: '0.2rem',
            marginLeft: '0.2rem',
        },
    },
    checkedPremium: {
        fontSize: pxToRem(13),
        fontWeight: 500,
        minWidth: 'fit-content',
        marginBottom: theme.spacing(1),
        marginRight: theme.spacing(2),
        color: premiumPrimary,

        '&::before': {
            content: "'\\2713'",
            display: 'inline-block',
            color: premiumPrimary,
            marginRight: '0.2em',
        },
    },
    prePostFixText: {
        display: 'inline-flex',
        marginRight: theme.spacing(0.5),
    },
    separator: {
        paddingLeft: theme.spacing(0.5),
    },
    subPropertyText: {
        marginRight: theme.spacing(1),
    },
    options: {
        display: 'inline-flex',
        marginRight: theme.spacing(0.5),
    },
    premiumOption: {
        color: premiumPrimary,
    },
    premiumHeader: {
        marginTop: theme.spacing(1),
        marginLeft: theme.spacing(1),
    },
    optionText: {
        fontSize: pxToRem(13),
        cursor: 'default',
    },
    tooltip: {
        '&.MuiTooltip-tooltipPlacementBottom': {
            top: 1,
            '& .MuiTooltip-arrow': {
                '&::after': {
                    left: 24,
                },
            },
        },
    },
    optGrpContainer: {
        display: 'inline-flex',
        flexWrap: 'wrap',
    },
}))

interface DetailPropertiesProps {
    xs?: GridSize
    isFullView: boolean
    properties: Record<string, Partial<ManufacturerProductPropertyDetail>[]>
    selectedData: Record<string, Record<string, string[]>>
}

export const DetailProperties: React.FC<DetailPropertiesProps> = ({
    isFullView,
    properties,
    selectedData,
}) => {
    const { masterSpec, nonMasterSpec } = useMemo(() => {
        let masterSpec: Record<
            string,
            PropertyType
        > = getMasterSpecPropertyGroup(properties)
        let nonMasterSpec: Record<
            string,
            PropertyType
        > = getNonMasterSpecPropertyGroup(properties)
        return { masterSpec, nonMasterSpec }
    }, [properties, selectedData])

    const {
        masterSpecSelectedData,
        nonMasterSpecSelectedData,
    } = useMemo(() => {
        return {
            masterSpecSelectedData: getPropertyGroupSelectedData({
                selectedData,
                propertyGroup: masterSpec,
                type: 'masterSpec',
            }),
            nonMasterSpecSelectedData: getPropertyGroupSelectedData({
                selectedData,
                propertyGroup: nonMasterSpec,
                type: 'premiumPlus',
            }),
        }
    }, [
        properties,
        selectedData,
        masterSpec,
        nonMasterSpec,
        getPropertyGroupSelectedData,
    ])
    const separator = window.localStorage.getItem('propSeparator') || ' | '

    const classes = useStyles()

    const propertiesSelected =
        Object.keys(selectedData).find(
            (propertyName) =>
                !!Object.keys(selectedData[propertyName]).find(
                    (k) => selectedData[propertyName][k]?.length !== 0
                )
        ) !== undefined //blank string is a valid property here

    const subPropertiesSelected = (propertyName: string) =>
        !!Object.keys(selectedData[propertyName]).find(
            (k) => selectedData[propertyName][k]?.length
        )

    const isSelectedPremium = (
        propertyName: string,
        subPropertyName: string,
        optionId: string
    ) => {
        if (properties[propertyName] != null) {
            const selectedProperty = properties[propertyName].filter(
                (d) => d.subPropertyName === subPropertyName
            )
            return !!selectedProperty[0].options?.find(
                (d) => d.isPremium && d.optionId === optionId
            )
        }
    }

    const assembleSelectedOptions = (
        propertyName: string,
        subPropertyName: string
    ) => {
        if (properties[propertyName] != null) {
            const selectedProperty = properties[propertyName].filter(
                (d) => d.subPropertyName === subPropertyName
            )

            return selectedProperty[0]?.optionGroups?.map(
                ({ groupId, prefix, postfix, options }, index) => {
                    //This is the intersection of the options in the groups and the selected option ids
                    const selectedOptions = options?.filter(
                        (
                            opt: Partial<ManufacturerProductPropertyOptionDetail>
                        ) =>
                            selectedData[propertyName][
                                subPropertyName
                            ].includes(opt.optionId ?? '')
                    )

                    return selectedOptions && selectedOptions.length > 0 ? (
                        <span key={groupId}>
                            {prefix && (
                                <span className={classes.prePostFixText}>
                                    {prefix}
                                </span>
                            )}
                            {selectedOptions.map(
                                ({ value, isPremiumPlus, optionId }, index) => {
                                    const isSelected =
                                        selectedData[propertyName] &&
                                        value &&
                                        selectedData[propertyName][
                                            subPropertyName!
                                        ]?.includes(optionId ?? '')

                                    const tooltipText = !!isPremiumPlus
                                        ? `${MANUFACTURER_OPTION} compliant`
                                        : isSelected
                                        ? `${MASTERSPEC_OPTION} compliant`
                                        : MASTERSPEC_OPTION

                                    return (
                                        <OptionTooltip
                                            title={tooltipText || ''}
                                            delay={2000}
                                            TransitionProps={{
                                                onEntered: (
                                                    node: HTMLElement
                                                ) => {
                                                    setTimeout(() => {
                                                        node.style.display =
                                                            'none'
                                                    }, 3000)
                                                },
                                                timeout: 0,
                                            }}
                                            tooltipClass={classes.tooltip}
                                            key={optionId}
                                        >
                                            <span
                                                className={clsx(
                                                    classes.options,
                                                    !!isPremiumPlus
                                                        ? classes.premiumOption
                                                        : ''
                                                )}
                                                key={optionId}
                                            >
                                                {value}
                                                {index !==
                                                selectedOptions.length - 1 ? (
                                                    <span
                                                        className={
                                                            classes.separator
                                                        }
                                                    >
                                                        {separator}
                                                    </span>
                                                ) : (
                                                    ''
                                                )}
                                            </span>
                                        </OptionTooltip>
                                    )
                                }
                            )}
                            {postfix && (
                                <span className={classes.prePostFixText}>
                                    {postfix}
                                </span>
                            )}
                        </span>
                    ) : (
                        // TODO: verify if we need to show this one
                        <span key={groupId}>
                            {index === 0 ? (
                                <>
                                    {prefix}
                                    {` `}
                                </>
                            ) : (
                                ''
                            )}
                            {postfix}
                        </span>
                    )
                }
            )
        }
    }

    const RenderFullView = (
        propertyArray: Record<string, PropertyType>,
        renderMode: string
    ) =>
        Object.keys(propertyArray).map((propertyName, index) => {
            const subPropertyArray = propertyArray[propertyName].filter(
                (prop) =>
                    !!propertyName ||
                    (renderMode === 'masterSpec' &&
                        !propertyName &&
                        !prop.isPremiumPlus) ||
                    (renderMode === 'nonMasterSpec' &&
                        !propertyName &&
                        !!prop.isPremiumPlus)
            )

            return !subPropertyArray.length ? (
                <></>
            ) : (
                <GridItem
                    key={propertyName}
                    className={classes.containerFullWidth}
                >
                    {propertyName && (
                        <Typography
                            variant="h4"
                            component="span"
                            className={clsx(
                                classes.propContent,
                                classes.propName
                            )}
                        >
                            {propertyName}
                        </Typography>
                    )}

                    {subPropertyArray.map(
                        ({ propertyId, subPropertyName, optionGroups }) => (
                            <GridContainer
                                className={clsx(classes.propContent)}
                                key={propertyId}
                            >
                                <GridItem md={3} sm={3} xs={3}>
                                    <Typography
                                        component="div"
                                        className={classes.propertyPills}
                                    >
                                        {subPropertyName}:
                                    </Typography>
                                </GridItem>
                                <GridItem md={9} sm={9} xs={9}>
                                    <span className={classes.optGrpContainer}>
                                        {optionGroups?.map(
                                            ({
                                                groupId,
                                                prefix,
                                                postfix,
                                                options,
                                            }) => (
                                                <Fragment
                                                    key={`${groupId}_${prefix}`}
                                                >
                                                    {prefix && (
                                                        <Typography
                                                            component="span"
                                                            className={
                                                                classes.propPrefixPostfix
                                                            }
                                                        >
                                                            {prefix}
                                                        </Typography>
                                                    )}
                                                    {options?.map(
                                                        ({
                                                            isPremiumPlus,
                                                            isOther,
                                                            optionId,
                                                            value,
                                                        }: Partial<ManufacturerProductPropertyOptionDetail>) => {
                                                            const isSelected =
                                                                selectedData[
                                                                    propertyName
                                                                ] &&
                                                                value &&
                                                                selectedData[
                                                                    propertyName
                                                                ][
                                                                    subPropertyName!
                                                                ]?.includes(
                                                                    optionId ??
                                                                        ''
                                                                )
                                                            const tooltipText = !!isPremiumPlus
                                                                ? `${MANUFACTURER_OPTION} compliant`
                                                                : isSelected
                                                                ? `${MASTERSPEC_OPTION} compliant`
                                                                : MASTERSPEC_OPTION

                                                            return (
                                                                !isOther &&
                                                                (!isPremiumPlus ||
                                                                    (isPremiumPlus &&
                                                                        isSelected)) && (
                                                                    <GridItem
                                                                        sm={12}
                                                                        key={
                                                                            optionId
                                                                        }
                                                                        className={clsx(
                                                                            isSelected
                                                                                ? !!isPremiumPlus
                                                                                    ? classes.checkedPremium
                                                                                    : classes.checkedProperty
                                                                                : classes.propertyPillsOption,
                                                                            classes.pillWidth
                                                                        )}
                                                                    >
                                                                        <OptionTooltip
                                                                            title={
                                                                                tooltipText ||
                                                                                ''
                                                                            }
                                                                            delay={
                                                                                2000
                                                                            }
                                                                            TransitionProps={{
                                                                                onEntered: (
                                                                                    node: HTMLElement
                                                                                ) => {
                                                                                    setTimeout(
                                                                                        () => {
                                                                                            node.style.display =
                                                                                                'none'
                                                                                        },
                                                                                        3000
                                                                                    )
                                                                                },
                                                                                timeout: 0,
                                                                            }}
                                                                            tooltipClass={
                                                                                classes.tooltip
                                                                            }
                                                                        >
                                                                            <Typography
                                                                                component="span"
                                                                                className={
                                                                                    classes.optionText
                                                                                }
                                                                            >
                                                                                {value ||
                                                                                    'NA'}
                                                                            </Typography>
                                                                        </OptionTooltip>
                                                                    </GridItem>
                                                                )
                                                            )
                                                        }
                                                    )}
                                                    {postfix && (
                                                        <Typography
                                                            component="span"
                                                            className={
                                                                classes.propPrefixPostfix
                                                            }
                                                        >
                                                            {postfix}
                                                        </Typography>
                                                    )}
                                                </Fragment>
                                            )
                                        )}
                                    </span>
                                </GridItem>
                            </GridContainer>
                        )
                    )}

                    {Object.keys(propertyArray).length - 1 !== index && (
                        <Divider classes={{ root: classes.divider }} />
                    )}
                </GridItem>
            )
        })

    const RenderSelected = ({
        selectedObject,
        propertyName,
    }: Record<string, string | Record<string, string[]>>) => {
        return (
            <GridItem
                className={clsx(
                    classes.containerFullWidth,
                    classes.propContent
                )}
            >
                <Typography
                    variant="h4"
                    component="div"
                    className={clsx(classes.lessView, {
                        [classes.lessViewPremium]: Object.keys(
                            nonMasterSpec
                        ).includes(propertyName as string),
                    })}
                >
                    {propertyName}
                </Typography>
                {Object.keys(selectedObject).map(
                    (subPropertyName) =>
                        Object.keys(
                            (selectedObject as Record<string, string[]>)[
                                subPropertyName
                            ]
                        ).length !== 0 && (
                            <GridContainer
                                className={clsx(
                                    classes.propertiesContainer,
                                    classes.propertiesDetails
                                )}
                                key={subPropertyName}
                            >
                                <GridItem md={3} sm={3}>
                                    <Typography
                                        component="div"
                                        className={classes.subPropertyText}
                                    >
                                        {subPropertyName}
                                        {':'}
                                    </Typography>
                                </GridItem>
                                <GridItem md={9} sm={9}>
                                    {assembleSelectedOptions(
                                        propertyName as string,
                                        subPropertyName
                                    )}
                                </GridItem>
                            </GridContainer>
                        )
                )}
            </GridItem>
        )
    }

    return (
        <GridContainer
            data-testid="properties"
            className={classes.mainPropContainer}
        >
            {isFullView ? (
                !!RenderFullView(masterSpec, 'masterSpec').length ||
                !!RenderFullView(nonMasterSpec, 'nonMasterSpec').length ? (
                    <>
                        {' '}
                        {RenderFullView(masterSpec, 'masterSpec')}
                        {!!Object.keys(nonMasterSpec).length &&
                            !!Object.keys(nonMasterSpecSelectedData).length && (
                                <GridItem className={classes.premiumHeader}>
                                    <Propertyname
                                        propertyName={MANUFACTURER_OPTIONS}
                                    />
                                    <Divider
                                        classes={{
                                            root: classes.divider,
                                        }}
                                    />
                                </GridItem>
                            )}
                        {!!Object.keys(nonMasterSpec).length &&
                            RenderFullView(nonMasterSpec, 'nonMasterSpec')}
                    </>
                ) : (
                    <GridItem className={classes.noPropertyMainContent}>
                        <Typography
                            variant="h6"
                            component="span"
                            className={classes.noProperty}
                        >
                            No Properties Available
                        </Typography>
                    </GridItem>
                )
            ) : propertiesSelected ? (
                <>
                    {!!Object.keys(masterSpecSelectedData).length &&
                        Object.keys(masterSpecSelectedData).map(
                            (propertyName, index) => {
                                return (
                                    subPropertiesSelected(propertyName) && (
                                        <Fragment
                                            key={`${propertyName}_${index}`}
                                        >
                                            <RenderSelected
                                                propertyName={propertyName}
                                                selectedObject={
                                                    masterSpecSelectedData[
                                                        propertyName
                                                    ]
                                                }
                                            />
                                        </Fragment>
                                    )
                                )
                            }
                        )}
                    {!!Object.keys(nonMasterSpecSelectedData).length &&
                        Object.keys(nonMasterSpecSelectedData).map(
                            (propertyName, index) => {
                                return (
                                    subPropertiesSelected(propertyName) && (
                                        <Fragment
                                            key={`${propertyName}_${index}`}
                                        >
                                            {index === 0 && (
                                                <GridItem
                                                    className={
                                                        classes.premiumHeader
                                                    }
                                                    key="PremiumPlusSeparator"
                                                >
                                                    <Propertyname
                                                        propertyName={
                                                            MANUFACTURER_OPTIONS
                                                        }
                                                    />
                                                    <Divider
                                                        classes={{
                                                            root:
                                                                classes.divider,
                                                        }}
                                                    />
                                                </GridItem>
                                            )}
                                            <RenderSelected
                                                propertyName={propertyName}
                                                selectedObject={
                                                    nonMasterSpecSelectedData[
                                                        propertyName
                                                    ]
                                                }
                                            />
                                        </Fragment>
                                    )
                                )
                            }
                        )}
                </>
            ) : (
                <GridItem className={classes.noPropertyMainContent}>
                    <Typography
                        variant="h6"
                        component="span"
                        className={classes.noProperty}
                    >
                        No Properties Available
                    </Typography>
                </GridItem>
            )}
        </GridContainer>
    )
}
