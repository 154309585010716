import { makeStyles, Theme } from '@material-ui/core/styles'
import Typography, { TypographyProps } from '@material-ui/core/Typography'
import clsx from 'clsx'
import React from 'react'
import { pxToRem } from '../Utilities/unitUtils'
import GridContainer from './GridContainer'
import GridItem from './GridItem'
import WidgetError from './WidgetError'
import WidgetLoader from './WidgetLoader'

const useStyles = makeStyles((theme: Theme) => {
    return {
        widgetContainerRoot: {
            width: (props: WidgetContainerStyleProps) => props.width,
            height: (props: WidgetContainerStyleProps) => props.height,
            minWidth: (props: WidgetContainerStyleProps) => props.minWidth,
            minHeight: (props: WidgetContainerStyleProps) => props.minHeight,
            headerMinHeight: (props: WidgetContainerStyleProps) =>
                props.headerMinHeight,
            flexWrap: 'nowrap',
        },
        widgetContainerHeader: {
            minHeight: (props: WidgetContainerStyleProps) =>
                theme.spacing(props.headerMinHeight),
            background: (props: WidgetContainerStyleProps) =>
                props.headerBackground,
            padding: theme.spacing(1),
            color: '#fff',
            borderTopLeftRadius: (props: WidgetContainerStyleProps) =>
                props.borderRadius,
            borderTopRightRadius: (props: WidgetContainerStyleProps) =>
                props.borderRadius,
            flexGrow: 0,
            flexShrink: 0,
        },
        widgetContainerHeaderComponent: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        verticallyCenteredTitleText: {
            display: 'flex',
            alignItems: 'center',
        },
        widgetTitle: {
            fontSize: (props: WidgetContainerStyleProps) =>
                pxToRem(props.titleFontSize),
            fontWeight: (props: WidgetContainerStyleProps) =>
                props.titleFontWeight,
            alignSelf: 'center',
        },
        widgetContainerBody: {
            padding: (props: WidgetContainerStyleProps) =>
                props.disableGutters ? 0 : theme.spacing(1),
            border: '1px solid #DBDFE0',
            background: (props: WidgetContainerStyleProps) =>
                props.contentBackground,
            width: '100%',
            height: (props: WidgetContainerStyleProps) => props.contentHeight,
            borderBottomLeftRadius: (props: WidgetContainerStyleProps) =>
                props.borderRadius,
            borderBottomRightRadius: (props: WidgetContainerStyleProps) =>
                props.borderRadius,
        },
        widgetContainerChildren: {
            width: '100%',
            height: '100%',
        },
    }
})

export interface WidgetContainerStyleProps {
    /** Custom fixed width for the container */
    width: string | number

    /** Custom fixed minimum width for the container */
    minWidth?: string | number

    /** Custom fixed height for the container */
    height?: string | number

    /** Height of content area */
    contentHeight?: string | number

    /** Custom fixed minimum height for the container */
    minHeight?: string | number

    /** Custom fixed minimum height for the header */
    headerMinHeight: number

    /** Enable/Disable padding in all directions */
    disableGutters: boolean

    /** Change header background color */
    headerBackground: string

    /** Change content background color */
    contentBackground: string

    /** Border radius for all corners */
    borderRadius: string | number

    /** Title font size */
    titleFontSize: number

    /** Title font weight */
    titleFontWeight: number
}

export interface WidgetContainerProps
    extends Partial<WidgetContainerStyleProps> {
    /** Title text */
    title?: string

    /** Custom component to render beside title */
    headerComponent?: React.ReactNode

    /** Custom variant for typography of header */
    headerVariant?: TypographyProps['variant']

    /** Center the header title */
    centerHeaderTitle?: boolean

    /** Center the custom component beside title */
    centerHeaderComponent?: boolean

    /** Error message to display. If there are no errors, pass empty string, null, or undefined. */
    error?: Error

    /** Determines whether to show cirular loader or not. */
    loading?: boolean

    /** Text to display above the progress bar while loading*/
    loadMessage?: string
}

const WidgetContainer: React.FC<WidgetContainerProps> = ({
    width = 'auto',
    contentHeight,
    title = '',
    disableGutters = false,
    headerComponent,
    headerBackground = '#0069AA',
    headerVariant = 'h2',
    titleFontSize = 22,
    titleFontWeight = 500,
    centerHeaderTitle = false,
    centerHeaderComponent = false,
    contentBackground = '#fff',
    borderRadius = 0,
    height,
    minWidth,
    minHeight,
    headerMinHeight = 5,
    children,
    error,
    loading = false,
    loadMessage,
    ...rest
}) => {
    const classes = useStyles({
        width,
        height,
        borderRadius,
        contentHeight,
        minWidth,
        minHeight,
        headerMinHeight,
        disableGutters,
        headerBackground,
        titleFontSize,
        titleFontWeight,
        contentBackground,
    })
    return (
        <GridContainer
            direction="column"
            className={clsx('widgetContainer', classes.widgetContainerRoot)}
            {...rest}
        >
            <GridContainer
                item
                justifyContent="space-between"
                className={classes.widgetContainerHeader}
            >
                <GridItem
                    className={clsx(
                        centerHeaderTitle && classes.verticallyCenteredTitleText
                    )}
                >
                    <div>
                        <Typography
                            variant={headerVariant}
                            className={clsx('widgetTitle', classes.widgetTitle)}
                        >
                            {title}
                        </Typography>
                    </div>
                </GridItem>
                <GridItem
                    className={clsx(
                        centerHeaderComponent &&
                            classes.widgetContainerHeaderComponent
                    )}
                >
                    {headerComponent}
                </GridItem>
            </GridContainer>
            <GridContainer
                item
                alignItems="center"
                justifyContent="center"
                className={classes.widgetContainerBody}
            >
                <WidgetContent
                    error={error}
                    loading={loading}
                    loadMessage={loadMessage}
                >
                    {children}
                </WidgetContent>
            </GridContainer>
        </GridContainer>
    )
}

const WidgetContent: React.FC<{
    loading?: boolean
    error?: Error
    loadMessage?: string
    children: any
}> = ({ loading, loadMessage, error, children }) => {
    if (error) {
        return <WidgetError {...error} />
    }
    if (loading) {
        return <WidgetLoader loadMessage={loadMessage} />
    }

    return children || null
}

export default WidgetContainer
