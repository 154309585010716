import { makeStyles, Theme, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Skeleton from '@material-ui/lab/Skeleton'
import clsx from 'clsx'
import SingleLineEllipsis from 'components/Feedbacks/SingleLineEllipsis'
import GridContainer from 'components/Surfaces/GridContainer'
import GridItem from 'components/Surfaces/GridItem'
import React from 'react'

const useStyles = makeStyles((theme: Theme) => ({
    propertiesHeaderUpper: {
        height: '11%',
    },
    propertiesHeaderUpperItem: {
        padding: theme.spacing(1.25),
    },
    propertiesHeaderUpperLeftSide: {
        ...theme.typography.h1,
        color: '#05676E',
    },
    propertiesHeaderUpperRightSideTitle: {
        ...theme.typography.subtitle2,
        color: '#5E5F61',
        fontWeight: 'normal',
    },
    propertiesHeaderUpperRightSideValue: {
        ...theme.typography.subtitle2,
        color: '#5E5F61',
    },
    fullWidth: {
        width: '100%',
    },
    flexContainer: {
        display: 'flex',
    },
    categories: {
        width: '64px',
    },
    categoriesValue: {
        width: 'calc(100% - 64px)',
    },
    family: {
        width: '48px',
    },
    familyValue: {
        width: 'calc(100% - 48px)',
    },
}))

interface RightSideHeaderUpperProps {
    loading?: boolean
    productType?: string
    categories?: string[]
    sectionName?: string
}

export const RightSideHeaderUpper: React.FC<RightSideHeaderUpperProps> = ({
    loading,
    productType,
    categories,
    sectionName,
}) => {
    const classes = useStyles()

    const theme = useTheme()

    const downSM = useMediaQuery(theme.breakpoints.down('sm'))

    return (
        <GridContainer className={classes.propertiesHeaderUpper} item>
            <GridContainer
                item
                xs={8}
                justifyContent="flex-start"
                alignItems="center"
                className={classes.propertiesHeaderUpperItem}
            >
                {!loading ? (
                    <SingleLineEllipsis
                        className={clsx(
                            'lblProductType',
                            classes.propertiesHeaderUpperLeftSide
                        )}
                        text={productType ?? ''}
                    />
                ) : (
                    <Skeleton
                        data-testid="imgLoadingProductType"
                        variant="text"
                        animation="pulse"
                        width={200}
                    />
                )}
            </GridContainer>
            <GridContainer
                item
                xs={4}
                justifyContent="flex-end"
                alignItems="center"
                className={classes.propertiesHeaderUpperItem}
            >
                <GridItem className={classes.fullWidth}>
                    {!loading ? (
                        <>
                            {!!categories &&
                                categories.length > 0 &&
                                (downSM ? (
                                    <SingleLineEllipsis
                                        className={clsx(
                                            'lblCategories',
                                            classes.propertiesHeaderUpperRightSideTitle
                                        )}
                                        text={`Category: ${categories?.join(
                                            ', '
                                        )}`}
                                    />
                                ) : (
                                    <div
                                        className={clsx(
                                            classes.fullWidth,
                                            classes.flexContainer
                                        )}
                                    >
                                        <div
                                            className={clsx(
                                                classes.categories,
                                                classes.propertiesHeaderUpperRightSideTitle
                                            )}
                                        >{`Category: `}</div>
                                        <div
                                            className={classes.categoriesValue}
                                        >
                                            <SingleLineEllipsis
                                                className={clsx(
                                                    'lblCategories',
                                                    classes.propertiesHeaderUpperRightSideValue
                                                )}
                                                text={`${categories?.join(
                                                    ', '
                                                )}`}
                                            />
                                        </div>
                                    </div>
                                ))}
                            {downSM ? (
                                <SingleLineEllipsis
                                    className={clsx(
                                        'lblSectionName',
                                        classes.propertiesHeaderUpperRightSideTitle
                                    )}
                                    text={`Family: ${sectionName}`}
                                />
                            ) : (
                                <div
                                    className={clsx(
                                        classes.fullWidth,
                                        classes.flexContainer
                                    )}
                                >
                                    <div
                                        className={clsx(
                                            classes.family,
                                            classes.propertiesHeaderUpperRightSideTitle
                                        )}
                                    >{`Family: `}</div>
                                    <div className={classes.familyValue}>
                                        <SingleLineEllipsis
                                            className={clsx(
                                                'lblSectionName',
                                                classes.propertiesHeaderUpperRightSideValue
                                            )}
                                            text={sectionName ?? ''}
                                        />
                                    </div>
                                </div>
                            )}
                        </>
                    ) : (
                        <>
                            <Skeleton
                                variant="text"
                                animation="pulse"
                                width={200}
                            />
                            <Skeleton
                                variant="text"
                                animation="pulse"
                                width={250}
                            />
                        </>
                    )}
                </GridItem>
            </GridContainer>
        </GridContainer>
    )
}
