import CssBaseline from '@material-ui/core/CssBaseline'
import { Theme } from '@material-ui/core/styles'
import React from 'react'
import AppTheme, { appTheme } from '../Themes/AppTheme'

export interface AppThemeConfigurationProps {
    theme?: Theme
}

const AppThemeConfiguration: React.FC<AppThemeConfigurationProps> = ({
    children,
    theme = appTheme,
}) => {
    return (
        <AppTheme theme={theme ?? appTheme}>
            <CssBaseline />
            {children}
        </AppTheme>
    )
}

export default AppThemeConfiguration
