import { Divider, Theme, Typography } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import makeStyles from '@material-ui/core/styles/makeStyles'
import clsx from 'clsx'
import React from 'react'
import { pxToRem } from '../Utilities/unitUtils'
import { NavPromptMessageProps } from './NavPrompt'

const NavPromptDialogContentStyles = makeStyles((theme: Theme) => ({
    dialogRootOverride: {
        zIndex: `${theme.zIndex.tooltip + 1}!important` as any,
    },
    dialogTitle: {
        '& .MuiTypography-h6': {
            color: '#EB5757',
            fontSize: pxToRem(34),
        },
        '&.MuiDialogTitle-root': {
            paddingLeft: theme.spacing(0),
            paddingRight: theme.spacing(0),
        },
    },
    dialogCont: {
        color: theme.palette.common.black,
        '@media (max-width: sm)': {
            width: 250,
        },
        '&.MuiDialogContent-root': {
            paddingLeft: theme.spacing(0),
            paddingRight: theme.spacing(0),
        },
        height: 100,
        fontSize: pxToRem(18),
    },
    dialogContentText: {
        whiteSpace: 'pre-wrap',
        color: '#5e5f61',
    },
    actionButton: {
        color: '#A78966',
        fontSize: pxToRem(18),
        width: 108,
        marginBottom: 16,
    },
    mainColor: {
        color: ({ buttonColor }: { buttonColor: string }) => buttonColor,
    },
    rootTitle: {
        '& h2': {
            fontSize: pxToRem(24),
        },
    },
    confirmationText: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        fontSize: pxToRem(18),
        color: theme.palette.common.black,
    },
    warningDialogCont: {
        '& .MuiDialog-paperScrollPaper': {
            paddingLeft: theme.spacing(3),
            paddingRight: theme.spacing(3),
        },
    },
    rootButton: {
        fontWeight: 'normal',
    },
    rootConfirmButton: {
        color: '#fff',
        backgroundColor: ({ buttonColor }: { buttonColor: string }) =>
            buttonColor,
        borderColor: ({ buttonColor }: { buttonColor: string }) => buttonColor,
        '&:hover': {
            boxShadow:
                '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);',
            backgroundColor: ({ buttonColor }: { buttonColor: string }) =>
                buttonColor,
            borderColor: ({ buttonColor }: { buttonColor: string }) =>
                buttonColor,
        },
    },
}))

export interface NavPromptDialogContentProps extends NavPromptMessageProps {
    onClose: (callbackState: boolean) => void
}

const NavPromptDialogContent = ({
    buttonColor,
    cancelText,
    confirmText,
    preferredMessage,
    title,
    onClose,
    isImportTab,
}: NavPromptDialogContentProps) => {
    const classes = NavPromptDialogContentStyles({
        buttonColor: buttonColor!,
    })

    const handleCloseDialog = (callbackState: boolean) => () => {
        onClose(callbackState)
    }

    return (
        <Dialog
            open={true}
            onClose={handleCloseDialog(false)}
            aria-labelledby="specpointAlertDialogTitle"
            aria-describedby="specpointAlertDialogDescription"
            data-testid="alertDialog"
            className={isImportTab ? classes.warningDialogCont : ''}
            classes={{
                root: classes.dialogRootOverride,
            }}
        >
            <DialogTitle
                id="specpointAlertDialogTitle"
                data-testid="specpointAlertDialogTitle"
                classes={{
                    root: isImportTab
                        ? ''
                        : clsx(classes.mainColor, classes.rootTitle),
                }}
                className={isImportTab ? classes.dialogTitle : ''}
            >
                {title}
            </DialogTitle>
            {isImportTab ? (
                <DialogContent className={classes.dialogCont}>
                    {preferredMessage}
                </DialogContent>
            ) : (
                <DialogContent>
                    <DialogContentText
                        id="specpointAlertDialogDescription"
                        data-testid="specpointAlertDialogDescription"
                        className={classes.dialogContentText}
                    >
                        {preferredMessage}
                    </DialogContentText>
                </DialogContent>
            )}
            {isImportTab && (
                <>
                    {' '}
                    <Divider variant="fullWidth" />
                    <Typography className={classes.confirmationText}>
                        Select OK to continue, or Cancel to stay on the current
                        page.
                    </Typography>
                </>
            )}
            <DialogActions>
                <Button
                    data-testid="specpointAlertCancelBtn"
                    onClick={handleCloseDialog(false)}
                    classes={{
                        root: isImportTab
                            ? classes.actionButton
                            : clsx(classes.rootButton, classes.mainColor),
                    }}
                >
                    {cancelText}
                </Button>
                <Button
                    data-testid="specpointAlertConfirmBtn"
                    classes={{
                        root: isImportTab
                            ? classes.actionButton
                            : clsx(
                                  classes.rootButton,
                                  classes.rootConfirmButton
                              ),
                    }}
                    onClick={handleCloseDialog(true)}
                    variant={isImportTab ? 'text' : 'contained'}
                    disableElevation={isImportTab ? false : true}
                >
                    {confirmText}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default NavPromptDialogContent
