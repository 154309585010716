import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import ReactResizeDetector from 'react-resize-detector'
import HoverTooltip from './HoverTooltip'

const useStyles = makeStyles({
    contentDiv: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
})

interface SingleLineEllipsisProps {
    /**
     * Callback for when the truncation state changes.
     */
    onTruncate?: () => void

    /**
     * Text content.
     */
    text: string
    /**
     * Style text displayed
     */
    className?: string
}

/**
 *
 * This component truncates long texts, and shows
 * the full text in a tooltip.
 */
const SingleLineEllipsis: React.FC<SingleLineEllipsisProps> = ({
    onTruncate,
    text = '',
    className = '',
}) => {
    const classes = useStyles()
    const [divRef, setDivRef] = React.useState<HTMLDivElement | undefined>()
    const [isTruncated, setIsTruncated] = React.useState(false)

    const setRef = (element: HTMLDivElement) => {
        setDivRef(element)
    }

    const handleResize = (width: any) => {
        if (divRef) {
            setIsTruncated(divRef?.scrollWidth > Math.round(width))
            onTruncate?.()
        }
    }

    return (
        <div className={classes.contentDiv}>
            <HoverTooltip text={isTruncated ? text : ''}>
                <div ref={setRef} className={classes.contentDiv}>
                    <span className={className}>{text}</span>
                </div>
            </HoverTooltip>
            {divRef && (
                <ReactResizeDetector
                    handleWidth
                    onResize={handleResize}
                    targetDomEl={divRef}
                />
            )}
        </div>
    )
}

export default SingleLineEllipsis
