const premiumPrimary = '#136BFB'
const premiumSecondary = '#2213fb'
const premiumPrimaryDisabled = '#136BFB40'
const propertiesDivider = '#BDBDBD'
const noPropertyLabel = '#5E5F61'
const masterSpecPrimary = '#F78909'
const defaultDark = '#000000'

export default {
    defaultDark,
    premiumPrimary,
    premiumSecondary,
    premiumPrimaryDisabled,
    propertiesDivider,
    masterSpecPrimary,
    noPropertyLabel,
}
