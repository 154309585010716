import { makeStyles } from '@material-ui/core/styles'
import Skeleton from '@material-ui/lab/Skeleton'
import clsx from 'clsx'
import {
    suffixDimension,
    SuffixDimensionProps,
} from 'components/Utilities/stringUtils'
import SpecImage from 'components/Widgets/SpecImage'
import React, { useState } from 'react'

const companyLogoStyles = makeStyles({
    companyLogoHeightWidth: {
        width: 200,
        height: 60,
    },
    companyLogoImg: {
        objectFit: 'scale-down',
        objectPosition: 'left',
    },
})

export interface CompanyLogoProps {
    loading?: boolean
    logoUrl?: string
}

const CompanyLogo: React.FC<CompanyLogoProps> = ({
    loading = true,
    logoUrl = '',
}) => {
    const classes = companyLogoStyles()
    const computedLogoUrl = logoUrl
        ? suffixDimension({
              filename: logoUrl,
              dimension: { percentage: 50 },
          } as SuffixDimensionProps)
        : ''
    const [specImageLoading, setSpecImageLoading] = useState<boolean>(
        !!computedLogoUrl
    )

    return (
        <>
            {(loading || specImageLoading) && (
                <Skeleton
                    variant="rect"
                    data-testid="companyLogoSkeleton"
                    className={classes.companyLogoHeightWidth}
                />
            )}
            {!loading && computedLogoUrl && (
                <SpecImage
                    src={computedLogoUrl}
                    data-testid="imgCompanyLogo"
                    alt="company logo"
                    handleImageLoading={setSpecImageLoading}
                    additionalClassNames={clsx(
                        'company-logo',
                        classes.companyLogoHeightWidth,
                        classes.companyLogoImg
                    )}
                />
            )}
        </>
    )
}

export default CompanyLogo
