import { ApolloError, QueryLazyOptions } from '@apollo/client'
import { ProductPropertyDetailResponse } from 'components/GraphQL/Types/product.types'
import React from 'react'
import { NormalizedProductType } from '../../model'

export interface ProductTypesContextValue {
    /** Loading auto suggest */
    loadingAutoSuggest?: boolean
    /** Data from auto suggest */
    dataAutoSuggest?: string[]
    /** Selected items from auto suggest*/
    selectedItems: string[]
    /** Function to run when opening autocomplete*/
    handleAutoCompleteOpen: VoidFunction
    /** Function to run when closing autocomplete*/
    handleAutoCompleteClose: VoidFunction
    /** Function to run when clearing the selected product type and its properties*/
    handleClearProductTypes: VoidFunction
    /** Function to run when removing product type*/
    handleAutoCompleteRemove: (value: NormalizedProductType) => void
    /** Function to run when adding product type*/
    handleAutoCompleteChange: (value: NormalizedProductType) => void
    /** Function to run when users type in autocomplete*/
    handleAutoCompleteInputChange: (value: string) => void
    /** Selected product type*/
    productTypeSelections: NormalizedProductType[]
    /** Function to run when changing a product type*/
    handleProductTypeChange: (value: NormalizedProductType) => void
    dataSelectedProductType?: ProductPropertyDetailResponse
    loadingSelectedProductType: boolean
    errorSelectedProductType?: Partial<ApolloError>
    getListingProductTypeProperties: (
        options?: QueryLazyOptions<Record<string, any>> | undefined
    ) => void
    onSelected: boolean
    setOnSelected: (value: boolean) => void
}

export const ProductTypesContext = React.createContext(
    {} as ProductTypesContextValue
)

export interface ProductTypesProviderProps {
    autoSuggestName: string
    dataAutoSuggest?: string[]
    loadingAutoSuggest?: boolean
    selectedItems?: string[]
    handleAutoSuggestDropdown?: (value: string) => void
    onFilterInputChangeDebounced?: (
        filterName: string,
        inputValue: string
    ) => void
    onFilterChange?: (newSelectedItems: string[], selectionKey: string) => void
    handelProductTypeSelection?: (value: NormalizedProductType[]) => void
    productTypeSelections?: NormalizedProductType[]
    dataSelectedProductType?: ProductPropertyDetailResponse
    loadingSelectedProductType: boolean
    errorSelectedProductType?: Partial<ApolloError>
    getListingProductTypeProperties: (
        options?: QueryLazyOptions<Record<string, any>> | undefined
    ) => void
    onSelected: boolean
    setOnSelected: (value: boolean) => void
}

export const ProductTypesProvider: React.FC<ProductTypesProviderProps> = ({
    autoSuggestName,
    dataAutoSuggest,
    loadingAutoSuggest,
    selectedItems,
    productTypeSelections,
    onFilterInputChangeDebounced,
    onFilterChange,
    handleAutoSuggestDropdown,
    handelProductTypeSelection,
    dataSelectedProductType,
    loadingSelectedProductType,
    errorSelectedProductType,
    getListingProductTypeProperties,
    onSelected,
    setOnSelected,
    children,
}) => {
    const handleAutoCompleteOpen = () =>
        handleAutoSuggestDropdown?.(autoSuggestName)

    const handleAutoCompleteClose = () => handleAutoSuggestDropdown?.('')

    const handleAutoCompleteInputChange = (value: string) =>
        onFilterInputChangeDebounced?.(autoSuggestName, value)

    const handleAutoCompleteChange = (value: NormalizedProductType) => {
        onFilterChange?.(
            Array.from(new Set([...(selectedItems ?? []), value.value ?? ''])),
            autoSuggestName
        )
        handelProductTypeSelection?.(
            Array.from(new Set([...(productTypeSelections ?? []), value]))
        )
    }

    const handleAutoCompleteRemove = (value: NormalizedProductType) => {
        onFilterChange?.(
            selectedItems?.filter((each) => each !== value.value) ?? [],
            autoSuggestName
        )
        handelProductTypeSelection?.(
            productTypeSelections?.filter(
                (each) => each.value !== value.value
            ) || []
        )
    }

    const handleClearProductTypes = () => {
        onFilterChange?.([], autoSuggestName)
        handelProductTypeSelection?.([])
    }

    const handleProductTypeChange = (value: NormalizedProductType) => {
        handelProductTypeSelection?.(
            productTypeSelections?.map((each) =>
                each.productTypeName?.trim() ===
                    value.productTypeName?.trim() &&
                each.sectionName?.trim() === value.sectionName?.trim()
                    ? value
                    : { ...each, selected: false }
            ) || []
        )
    }

    const context: ProductTypesContextValue = {
        handleAutoCompleteInputChange,
        handleAutoCompleteChange,
        handleAutoCompleteRemove,
        handleAutoCompleteOpen,
        handleAutoCompleteClose,
        handleClearProductTypes,
        productTypeSelections: productTypeSelections ?? [],
        dataAutoSuggest,
        loadingAutoSuggest,
        selectedItems: selectedItems ?? [],
        handleProductTypeChange,
        loadingSelectedProductType,
        errorSelectedProductType,
        dataSelectedProductType,
        getListingProductTypeProperties,
        onSelected,
        setOnSelected,
    }

    return (
        <ProductTypesContext.Provider value={context}>
            {children}
        </ProductTypesContext.Provider>
    )
}
