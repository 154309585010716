import { makeStyles, Theme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import clsx from 'clsx'
import RoundButton from 'components/Buttons/RoundButton'
import GridContainer from 'components/Surfaces/GridContainer'
import GridItem from 'components/Surfaces/GridItem'
import React from 'react'
import { LeftSideProps, RightSideProps } from './ContentContainer'
import { LeftSide } from './LeftSide'
import { RightSide } from './RightSide'

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        border: '1px solid #C9C9C9',
        boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.3)',
        borderRadius: 5,
        padding: theme.spacing(4),
        paddingBottom: 0,
    },
    body: {
        height: 'calc(90%)',
        marginBottom: 0,
    },
    footer: {
        height: 'calc(10%)',
    },
    checked: {
        backgroundColor: '#10B091',
    },
    clearButtonContainer: {
        '& button.MuiButton-contained': {
            height: 38,
            padding: `${theme.spacing(0.25)}px ${theme.spacing(2)}px`,
        },
    },
    closeButtonContainer: {
        marginLeft: theme.spacing(0.25),
        color: '#FFF',
        height: 38,
        padding: `${theme.spacing(0.75)}px ${theme.spacing(2.5)}px`,
        textTransform: 'none',
        background: '#04797C',
        '&:hover': {
            background: '#036063',
        },
        '&:focus': {
            outline: 'none',
            background: '#036063',
        },
        fontWeight: 'normal',
        borderRadius: 100,

        border: 'none',
        cursor: 'pointer',
    },

    buttonText: {
        fontWeight: 'normal',
    },
}))

interface ContentComponentProps extends LeftSideProps, RightSideProps {
    handleClose: VoidFunction
    handleClearProductTypeProperty: VoidFunction
}

export const ContentComponent: React.FC<ContentComponentProps> = ({
    productTypeOptions,
    debounceInput,
    setProductType,
    handleClose,
    selectedProductType,
    loadingSelectedProductType,
    errorSelectedProductType,
    onSelectProductTypeProperty,
    handleClearProductTypeProperty,
    propertiesSelectedCount,
}) => {
    const classes = useStyles()

    return (
        <>
            <GridContainer
                item
                className={clsx('productTypeFilterContent', classes.body)}
                spacing={3}
            >
                <LeftSide
                    loadingSelectedProductType={loadingSelectedProductType}
                    productTypeOptions={productTypeOptions}
                    debounceInput={debounceInput}
                    setProductType={setProductType}
                />
                <RightSide
                    selectedProductType={selectedProductType}
                    loadingSelectedProductType={loadingSelectedProductType}
                    errorSelectedProductType={errorSelectedProductType}
                    onSelectProductTypeProperty={onSelectProductTypeProperty}
                    propertiesSelectedCount={propertiesSelectedCount}
                />
            </GridContainer>
            <GridContainer
                item
                justifyContent="flex-end"
                alignItems="flex-end"
                className={clsx(classes.footer)}
                spacing={1}
            >
                <GridItem className={classes.clearButtonContainer}>
                    <RoundButton
                        backgroundColor="#FFF"
                        borderColor="#5E5F61"
                        color="#5E5F61"
                        disabled={
                            propertiesSelectedCount === 0 ||
                            loadingSelectedProductType
                        }
                        hasShadow={false}
                        borderRadius={100}
                        onClick={handleClearProductTypeProperty}
                        data-testid="btnClearFilter"
                    >
                        <Typography
                            variant="subtitle2"
                            data-testid="lblBtnClearFilter"
                            component="span"
                            className={classes.buttonText}
                        >
                            Clear Filters
                        </Typography>
                    </RoundButton>
                </GridItem>
                <GridItem>
                    <button
                        className={classes.closeButtonContainer}
                        onClick={handleClose}
                        data-testid="btnClose"
                    >
                        <Typography
                            variant="subtitle2"
                            data-testid="lblBtnClose"
                            component="span"
                            className={classes.buttonText}
                        >
                            Close
                        </Typography>
                    </button>
                </GridItem>
            </GridContainer>
        </>
    )
}
