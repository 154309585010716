import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import React, { useEffect, useState } from 'react'

const specImageStyles = makeStyles({
    specImageTransparent: {
        color: 'transparent',
    },
    specImageBlack: {
        color: 'black',
    },
    specImageHidden: {
        display: 'none',
    },
    specImageVisible: {
        display: 'unset',
    },
})

export interface SpecImageProps
    extends React.ImgHTMLAttributes<HTMLImageElement> {
    additionalClassNames?: string
    handleImageLoading?: (imgLoading: boolean) => void
}

/**
 * HOC of an <img /> element to only show the image when its fully loaded.
 * It doesn't show the alt text before loading an image.
 */
const SpecImage = ({
    additionalClassNames,
    handleImageLoading,
    src,
    alt,
    ...rest
}: SpecImageProps) => {
    const classes = specImageStyles()
    let hasError = false
    const [imageLoading, setImageLoading] = useState<boolean>(!!src)

    const onImageError = () => {
        hasError = true
        setImageLoading(false)
    }
    const onLoad = () => {
        hasError = false
        setImageLoading(false)
    }

    useEffect(() => {
        handleImageLoading?.(imageLoading)
    }, [imageLoading, handleImageLoading])

    return (
        <img
            onError={onImageError}
            onLoad={onLoad}
            className={clsx(
                'spec-image',
                classes.specImageTransparent,
                hasError && classes.specImageBlack,
                imageLoading
                    ? classes.specImageHidden
                    : classes.specImageVisible,
                additionalClassNames
            )}
            src={src}
            alt={alt}
            {...rest}
        />
    )
}

export default SpecImage
