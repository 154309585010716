import Checkbox from '@material-ui/core/Checkbox'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormGroup from '@material-ui/core/FormGroup'
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import CheckIcon from '@material-ui/icons/Check'
import clsx from 'clsx'
import {
    ManufacturerProductPropertyOptionDetail,
    ManufacturerProductPropertyOptionGroupDetail,
} from 'components/Models/product'
import GridContainer from 'components/Surfaces/GridContainer'
import GridItem from 'components/Surfaces/GridItem'
import { pxToRem } from 'components/Utilities/unitUtils'
import propertiesColors from 'components/Widgets/PropertyDetailsViewMode/propertiesColors'
import React from 'react'
import ReactResizeDetector from 'react-resize-detector'
import { NormalizedProductTypeProperties } from '../../model'

const { premiumPrimary, premiumPrimaryDisabled } = propertiesColors

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    checked: {
        backgroundColor: '#10B091',
        color: theme.palette.common.white,
    },
    unchecked: {
        border: '1px solid #10B091',
    },
    disabledUnchecked: {
        border: '1px solid #10B09140',
    },
    checkedManufacturer: {
        backgroundColor: premiumPrimary,
        color: theme.palette.common.white,
    },
    uncheckedManufacturer: {
        border: `1px solid ${premiumPrimary}`,
        color: premiumPrimary,
    },
    disabledUncheckedManufacturer: {
        border: `1px solid ${premiumPrimaryDisabled}`,
    },
    choice: {
        borderRadius: 50,
        paddingRight: theme.spacing(2.25),
        marginTop: theme.spacing(0.75),
        marginLeft: 0,
        minHeight: 35,
    },
    text: {
        color: '#474747',
        fontSize: pxToRem(13),
    },
    prefixText: {
        paddingRight: theme.spacing(2),
    },
    checkedText: { color: theme.palette.common.white, fontSize: pxToRem(13) },
    disabledText: {
        color: '#47474740',
        fontSize: pxToRem(13),
    },
    icon: { height: 15 },
    iconWidth: { width: 20 },
    level: {
        color: '#474747',
        fontWeight: 500,
    },
    optionText: {
        width: 'calc(100%)',
    },
    fullWidth: {
        width: '100%',
    },
    maxWidth: {
        maxWidth: '100%',
    },
    textLevel: {
        wordBreak: 'break-word',
    },
    iconPadding: {
        '& .MuiButtonBase-root': {
            paddingRight: theme.spacing(0.25),
        },
    },
    option: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}))

interface RightSidePropertyItemProps {
    property: Partial<NormalizedProductTypeProperties>
    onSelectProductTypeProperty: (propertyId: string, optionId: string) => void
    showHeader?: boolean
}

export const RightSidePropertyItem: React.FC<RightSidePropertyItemProps> = ({
    property,
    onSelectProductTypeProperty,
    showHeader = true,
}) => {
    const classes = useStyles()

    const [selected, setSelected] = React.useState<string[]>([])

    const theme = useTheme()

    const xsDown = useMediaQuery(theme.breakpoints.down('xs'))

    const handleClick = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        const value = (event.target as HTMLInputElement).value
        setSelected((past) =>
            past.some((each) => each === value)
                ? past.filter((each) => each !== value)
                : [value, ...past]
        )
        onSelectProductTypeProperty(property.propertyId!, value)
    }

    React.useMemo(() => {
        if (property) {
            setSelected(
                property.optionGroups
                    ?.flatMap((each) =>
                        each.options?.filter((each) => each.selected)
                    )
                    .map((each) => each?.optionId ?? '') || []
            )
        }
    }, [property])

    if (
        !property.optionGroups?.filter(
            (
                optionGroup: Partial<ManufacturerProductPropertyOptionGroupDetail>
            ) =>
                !optionGroup?.options?.some(
                    (each: Partial<ManufacturerProductPropertyOptionDetail>) =>
                        each.isInlineTextOnly
                )
        ).length &&
        !property.optionGroups?.filter(
            (
                optionGroup: Partial<ManufacturerProductPropertyOptionGroupDetail>
            ) =>
                optionGroup?.options?.some(
                    (each: Partial<ManufacturerProductPropertyOptionDetail>) =>
                        each?.isPremiumPlus && !each.isInlineTextOnly
                )
        ).length
    ) {
        return <></>
    }

    return (
        // This is an item of RightSide
        <GridContainer
            item
            justifyContent="center"
            alignItems="center"
            className={classes.container}
        >
            {property.propertyTypeLevel && showHeader && (
                <GridItem xs={12}>
                    <Typography
                        data-testid="lblPropertyLevel"
                        className={clsx('lblPropertyLevel', classes.level)}
                    >
                        {property.propertyTypeLevel}
                    </Typography>
                </GridItem>
            )}
            <GridItem xs={3} sm={2}>
                <Typography
                    className={clsx(
                        'lblPropertyLevelName',
                        classes.text,
                        xsDown && classes.textLevel
                    )}
                >
                    {property.propertyTypeLevelName}
                </Typography>
            </GridItem>

            <GridItem xs={9} sm={10}>
                <FormControl component="fieldset" className={classes.fullWidth}>
                    <ReactResizeDetector handleWidth>
                        {({ width: maxWidth }: { width: number }) => (
                            <FormGroup
                                aria-label="position"
                                row
                                className={classes.fullWidth}
                            >
                                {property.optionGroups?.map((each) => {
                                    {
                                        return (
                                            <GridContainer
                                                key={`${property.propertyId}-${each.groupId}`}
                                                alignItems="center"
                                            >
                                                {each.prefix && (
                                                    <GridItem>
                                                        <Typography
                                                            className={clsx(
                                                                classes.text,
                                                                classes.prefixText
                                                            )}
                                                        >
                                                            {each.prefix}
                                                        </Typography>
                                                    </GridItem>
                                                )}
                                                {each.options
                                                    ?.filter(
                                                        (
                                                            option: Partial<ManufacturerProductPropertyOptionDetail>
                                                        ) =>
                                                            option?.isInlineTextOnly ===
                                                            false
                                                    )
                                                    ?.map((each, index) => {
                                                        const checked = selected.some(
                                                            (eachSelected) =>
                                                                eachSelected ===
                                                                each.optionId
                                                        )
                                                        const disabled =
                                                            each.isSupported &&
                                                            each.isOther // change this value
                                                        let eachMaxWidth = maxWidth
                                                            ? maxWidth - 36
                                                            : 300
                                                        if (
                                                            eachMaxWidth &&
                                                            checked
                                                        ) {
                                                            eachMaxWidth -= 24
                                                        }
                                                        if (
                                                            eachMaxWidth &&
                                                            each.isPremiumPlus
                                                        ) {
                                                            eachMaxWidth -= 24
                                                        }
                                                        return (
                                                            <GridItem>
                                                                <FormControlLabel
                                                                    data-testid={`lblOption-${each.optionId}`}
                                                                    key={`${each.optionId}${index}`}
                                                                    className={clsx(
                                                                        'lblOption',
                                                                        classes.choice,
                                                                        classes.maxWidth,
                                                                        {
                                                                            [classes.checked]:
                                                                                !each.isPremiumPlus &&
                                                                                checked,
                                                                            [classes.unchecked]:
                                                                                !each.isPremiumPlus &&
                                                                                !checked,
                                                                            [classes.disabledUnchecked]:
                                                                                !each.isPremiumPlus &&
                                                                                disabled,
                                                                            [classes.checkedManufacturer]:
                                                                                !!each.isPremiumPlus &&
                                                                                checked,
                                                                            [classes.uncheckedManufacturer]:
                                                                                !!each.isPremiumPlus &&
                                                                                !checked,
                                                                            [classes.disabledUncheckedManufacturer]:
                                                                                !!each.isPremiumPlus &&
                                                                                disabled,
                                                                        }
                                                                    )}
                                                                    value={
                                                                        each.optionId
                                                                    }
                                                                    disabled={
                                                                        disabled
                                                                    }
                                                                    control={
                                                                        <Checkbox
                                                                            value={
                                                                                each.optionId
                                                                            }
                                                                            size="small"
                                                                            checkedIcon={
                                                                                <>
                                                                                    <CheckIcon
                                                                                        color="secondary"
                                                                                        className={clsx(
                                                                                            classes.icon,
                                                                                            classes.iconWidth
                                                                                        )}
                                                                                    />
                                                                                </>
                                                                            }
                                                                            checked={
                                                                                checked
                                                                            }
                                                                            icon={
                                                                                <span
                                                                                    className={
                                                                                        classes.icon
                                                                                    }
                                                                                />
                                                                            }
                                                                            color="primary"
                                                                            onClick={
                                                                                handleClick
                                                                            }
                                                                        />
                                                                    }
                                                                    label={
                                                                        <span
                                                                            className={
                                                                                classes.option
                                                                            }
                                                                        >
                                                                            <Typography
                                                                                component="span"
                                                                                className={clsx(
                                                                                    'lblPropertyOption',
                                                                                    checked
                                                                                        ? classes.checkedText
                                                                                        : classes.text,
                                                                                    disabled &&
                                                                                        classes.disabledText,

                                                                                    classes.optionText
                                                                                )}
                                                                                style={{
                                                                                    maxWidth: eachMaxWidth,
                                                                                }}
                                                                            >
                                                                                {
                                                                                    each.value
                                                                                }
                                                                            </Typography>
                                                                        </span>
                                                                    }
                                                                />
                                                            </GridItem>
                                                        )
                                                    })}
                                                {each.postfix && (
                                                    <GridItem>
                                                        <Typography
                                                            className={clsx(
                                                                classes.text
                                                            )}
                                                        >
                                                            {each.postfix}
                                                        </Typography>
                                                    </GridItem>
                                                )}
                                            </GridContainer>
                                        )
                                    }
                                })}
                            </FormGroup>
                        )}
                    </ReactResizeDetector>
                </FormControl>
            </GridItem>
        </GridContainer>
    )
}
