import Alert from '@material-ui/lab/Alert'
import AlertTitle from '@material-ui/lab/AlertTitle'
import React from 'react'
import GridContainer from './GridContainer'
import GridItem from './GridItem'

/**
 * This component is only used by WidgetContainer for rendering
 * error state.
 */
const WidgetError: React.FC<Error> = ({
    name = 'Unknown',
    message = 'An unknown error occured.',
}) => {
    return (
        <GridContainer justifyContent="center">
            <GridItem>
                <Alert severity="error">
                    <AlertTitle>{name}</AlertTitle>
                    {message}
                </Alert>
            </GridItem>
        </GridContainer>
    )
}

export default WidgetError
