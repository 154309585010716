import Button from '@material-ui/core/Button'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { CreateCSSProperties } from '@material-ui/core/styles/withStyles'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp'
import clsx from 'clsx'
import { ListingMatch } from 'components/Models/listingSearch'
import GridContainer from 'components/Surfaces/GridContainer'
import GridItem from 'components/Surfaces/GridItem'
import { suffixDimension } from 'components/Utilities/stringUtils'
import { pxToRem } from 'components/Utilities/unitUtils'
import {
    DEFAULT_GROUP_OF,
    getFirmName,
    ListingMatchLeaf,
} from 'components/Widgets/ProductListing/groupedTreeUtils'
import React, { CSSProperties, FC } from 'react'
import {
    VariableSizeNodeComponentProps,
    VariableSizeNodeData,
} from 'react-vtree'
import ProductListingCard from './ProductListingCard'

type CSSProps = {
    data: ExtendedTreeData
    style: CreateCSSProperties
}
const useStyles = makeStyles((theme: Theme) => ({
    loadMoreButton: {
        right: 0,
        color: '#FFFFFF',
        fontWeight: 'normal',
        fontSize: pxToRem(18),
        marginTop: theme.spacing(2),
        position: 'absolute',
        width: 317,
        height: 41,
        left: 0,
        margin: '0 auto',
        background: 'linear-gradient(90.96deg, #04325B 0%, #0069AA 100%)',
        border: '1px solid #0098D6',
        boxSizing: 'border-box',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.25)',
        borderRadius: 8,
        marginBottom: 50,
    },
    showMoreDisabled: {
        '&.Mui-disabled': {
            color: 'rgba(255,255,255,0.5)',
        },
    },
    mainTree: {
        overflowX: 'hidden',
    },
    buttonGrid: (props: CSSProps) => props.style,
    accordionStyle: (props: CSSProps) => {
        const bgcolor = ['#5E5F61', '#FFFFFF', 'rgb(94,95,97,0.3)']
        let accordionStyle = {
            maxWidth: '100%',
            marginTop: theme.spacing(2.5),
            marginLeft: theme.spacing(4.75),
        } as CSSProperties
        const { data, style } = props
        const { isLeaf, nestingLevel } = data
        if (!isLeaf) {
            accordionStyle = {
                ...accordionStyle,
                marginLeft: theme.spacing(4),
                height: 40,
                padding: '0 16px 0 24px',
                border: nestingLevel === 1 ? '1px solid #bdbdbd' : 'none',
                maxWidth: 'calc(100% - 40px)',
                lineHeight: pxToRem(40),
                backgroundColor: bgcolor[nestingLevel ?? 1],
                color: nestingLevel === 0 ? '#ffffff' : 'inherit',
                fontSize: pxToRem(22),
                fontWeight: 500,
            }
        }
        return {
            ...style,
            ...accordionStyle,
        }
    },
    itemStyle: {
        height: 415,
        minWidth: 370,
        maxWidth: 370,
        flexBasis: 370,
    },
    gridWidth: { maxWidth: 'calc(100% - 5px)' },
    leafItem: { textAlign: 'right' },
    gridStyle: { minHeight: 415, fontSize: pxToRem(14), fontWeight: 'normal' },
}))
export type ExtendedTreeData = VariableSizeNodeData &
    Readonly<{
        productCount: number
        listingCount: number
        searchLoading?: boolean
        isLeaf?: boolean
        nestingLevel?: number
        margin?: number
        name?: string
        listingLeaf?: ListingMatchLeaf
        count?: number
        onLoadMore: (
            event: React.MouseEvent<HTMLButtonElement, MouseEvent>
        ) => void
    }>
const DEFAULT_DISPLAY_WIDTH = 1920
export const AccordionWindowNode: FC<
    VariableSizeNodeComponentProps<ExtendedTreeData>
> = ({ data, isOpen, style, toggle, resize }) => {
    const classes = useStyles({ data, style } as CSSProps)
    const {
        isLeaf,
        name,
        listingLeaf,
        count,
        onLoadMore,
        listingCount,
        productCount,
        searchLoading,
    } = data

    const computedHeight = React.useMemo(() => Number(style.height), [style])
    const leafLength = React.useMemo(
        () => listingLeaf?.groupedProduct?.length,
        [listingLeaf]
    )

    if (!isLeaf) {
        if (name !== 'spLoadMore') resize(52)
        else resize(72)
    }

    React.useEffect(() => {
        if (isLeaf) {
            let lineCount = 1
            const windowWidth = window.innerWidth
            if (leafLength !== undefined) {
                lineCount = Math.ceil(
                    leafLength /
                        (windowWidth <= DEFAULT_DISPLAY_WIDTH
                            ? Math.floor(windowWidth / 380)
                            : DEFAULT_GROUP_OF)
                )
            }
            resize(lineCount * 427, true)
        }
    }, [isLeaf, computedHeight, leafLength, resize])

    return name === 'spLoadMore' ? (
        <GridItem className={classes.buttonGrid} lg={12} md={6}>
            {listingCount !== productCount && listingCount && (
                <Button
                    onClick={onLoadMore}
                    data-testid="loadMore"
                    className={clsx(classes.loadMoreButton, 'showMore')}
                    classes={{
                        root: clsx(searchLoading && classes.showMoreDisabled),
                    }}
                    disabled={searchLoading}
                >
                    Show More ({productCount} of {listingCount})
                </Button>
            )}
        </GridItem>
    ) : (
        <GridItem
            container
            data-testid="accordionWindow"
            xs={12}
            className={clsx(
                classes.accordionStyle,
                !isLeaf ? 'accordionHead' : 'accordionContent'
            )}
            onClick={() => {
                if (!isLeaf) toggle()
            }}
        >
            <GridContainer spacing={1} className={classes.gridWidth}>
                {!!name && (
                    <GridItem xs={11}>
                        {name}
                        {!searchLoading && <span> ({count})</span>}
                    </GridItem>
                )}
                {!isLeaf && (
                    <GridItem xs={1} className={classes.leafItem}>
                        {isOpen ? (
                            <ArrowDropDownIcon fontSize="large" />
                        ) : (
                            <ArrowDropUpIcon fontSize="large" />
                        )}
                    </GridItem>
                )}

                {!name &&
                    listingLeaf &&
                    listingLeaf.groupedProduct?.map(
                        (leafData: Partial<ListingMatch>) => {
                            return (
                                <GridItem
                                    key={leafData.listingId}
                                    className={clsx(
                                        classes.itemStyle,
                                        classes.gridStyle
                                    )}
                                >
                                    <ProductListingCard
                                        loading={searchLoading}
                                        {...leafData}
                                        firmName={getFirmName(
                                            leafData.displayNameWithFirmName
                                        )}
                                        categories={leafData.categories ?? []}
                                        firmLogoUrl={
                                            leafData.firmLogoUrl &&
                                            suffixDimension({
                                                filename: leafData.firmLogoUrl,
                                                dimension: {
                                                    percentage: 25,
                                                },
                                            })
                                        }
                                        publishDate={leafData.lastUpdated}
                                        familyName={leafData.sectionName}
                                        listingLevel={leafData.type}
                                        displayFirmName={
                                            leafData.firmDisplayName
                                                ? leafData.firmDisplayName
                                                : leafData.firmName
                                        }
                                    />
                                </GridItem>
                            )
                        }
                    )}
            </GridContainer>
        </GridItem>
    )
}
