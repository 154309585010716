import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Typography from '@material-ui/core/Typography/Typography'
import React from 'react'
import GridContainer from './GridContainer'
import GridItem from './GridItem'

const useContentStyles = makeStyles({
    circularProgress: {
        color: '#05666D',
    },
})

/**
 * This component is only used by WidgetContainer for rendering
 * error state.
 */
const WidgetLoader: React.FC<{ loadMessage?: string }> = ({ loadMessage }) => {
    const classes = useContentStyles()
    return (
        <GridContainer
            direction="column"
            alignItems="center"
            spacing={1}
            justifyContent="center"
        >
            {loadMessage && (
                <GridItem>
                    <Typography data-testid="widgetLoadMessage">
                        {loadMessage}
                    </Typography>
                </GridItem>
            )}
            <GridItem>
                <CircularProgress
                    className={classes.circularProgress}
                    data-testid="widgetLoader"
                    size={24}
                />
            </GridItem>
        </GridContainer>
    )
}

export default WidgetLoader
