import { makeStyles, Theme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { pxToRem } from 'components/Utilities/unitUtils'
import React from 'react'

const useStyles = makeStyles((theme: Theme) => ({
    productName: {
        fontSize: pxToRem(36),
        fontWeight: 500,
        lineHeight: 1,
        marginBottom: theme.spacing(3),
        marginTop: theme.spacing(1),
        wordWrap: 'break-word',
    },
}))

interface ViewNameProps {
    productName: string
}

const ViewName: React.FC<ViewNameProps> = ({ productName }) => {
    const classes = useStyles()
    return (
        <Typography
            variant="h1"
            className={classes.productName}
            data-testid="lblName"
        >
            {productName}
        </Typography>
    )
}

export default ViewName
