import React from 'react'

const expandedFiltersList: number[] = []
const setExpandedFiltersList = (value: number[]) => {
    console.log(value)
}

interface FilterCategoriesExpansionInterface {
    expandedFiltersList: number[]
    setExpandedFiltersList: (val: number[]) => void
}

export const FilterCategoriesExpansion =
    React.createContext<FilterCategoriesExpansionInterface>({
        expandedFiltersList,
        setExpandedFiltersList,
    })
