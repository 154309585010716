import React from 'react'

export interface GetIframeComponentProps {
    mdDown?: boolean
    videoId: string
    videoSource: string
    lgDown?: boolean
}

const IframeComponent = ({
    videoId,
    videoSource,
    lgDown,
}: GetIframeComponentProps) => {
    if (!videoId || !videoSource) return null
    const actualWidth = lgDown ? '440' : '640'
    const actualHeight = lgDown ? '249' : '360'
    switch (String(videoSource).toLowerCase()) {
        case 'youtube':
            return (
                <iframe
                    title="youtube-video"
                    className="listingDetailYouTubePlayer"
                    width={actualWidth}
                    height={actualHeight}
                    frameBorder={0}
                    allowFullScreen
                    src={`https://www.youtube.com/embed/${videoId}`}
                />
            )
        case 'vimeo': {
            return (
                <iframe
                    title="vimeo-video"
                    className="listingDetailVimeoPlayer"
                    src={`https://player.vimeo.com/video/${videoId}`}
                    width={actualWidth}
                    height={actualHeight}
                    allowFullScreen
                    frameBorder={0}
                />
            )
        }
        default:
            return null
    }
}

export default IframeComponent
