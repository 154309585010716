import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import VideoOverlay from 'components/Widgets/ProductListing/VideoOverlay'
import { Link as GatsbyLink } from 'gatsby'
import React from 'react'
import ImageGallery, { ReactImageGalleryItem } from 'react-image-gallery'
import 'react-image-gallery/styles/css/image-gallery.css'
import { ListingCardMedia } from './ProductListingCard'

const useStyles = makeStyles({
    galleryContainer: {
        height: 130,
        '& .image-gallery-icon': {
            color: '#fff',
            '&:hover': {
                '& > svg.image-gallery-svg': {
                    color: '#fff',
                    transform: 'scale(1.1)',
                    stroke: '#fff !important',
                },
            },
            '&:focus': {
                color: '#ffff',
                outline: 'none',
            },
        },
        '& .image-gallery-left-nav, .image-gallery-right-nav': {
            padding: 0,
        },
        '& button.image-gallery-left-nav': {
            width: 50,
            background:
                'linear-gradient(90deg, rgba(0, 0, 0, 0.76) -10px, rgba(0, 0, 0, 0.06) 80%, rgba(255, 255, 255, 0))',
            filter: 'none',
            transition: '200ms',
            '& > svg': {
                left: 10,
            },
        },
        '& .image-gallery-bullets .image-gallery-bullet': {
            boxShadow: '0 1px 5px #1a1a1a',
            '&:hover': {
                background: '#000',
            },
        },
        '& button.image-gallery-right-nav': {
            width: 50,
            filter: 'none',
            '& > svg': {
                right: 10,
            },
        },
        '& .image-gallery-svg': {
            height: `50px !important`,
            width: 'auto !important',
            position: 'absolute',
            top: 'calc(50% - 25px)',
        },
        '& .image-gallery-bullets': {
            marginBottom: -15,
        },
    },
    activeImage: {
        height: 130,
        display: 'block',
        width: '100%',
        objectFit: 'cover',
    },
    overlay: {
        width: 'calc(100%)',
        height: 130,
        background: 'rgba(0,0,0,0.15)',
        position: 'absolute',
    },
})

export interface ProductListingCardCarouselProps {
    media?: ListingCardMedia[]
    redirectPath?: string
}

const ProductListingCardCarousel: React.FC<ProductListingCardCarouselProps> = ({
    media = [],
    redirectPath,
}) => {
    const classes = useStyles()

    if (media.length === 0) return null
    return (
        <div
            className={clsx(
                classes.galleryContainer,
                'productListingCardCarousel'
            )}
        >
            <ImageGallery
                showBullets={media.length > 1}
                showNav={media.length > 1}
                disableSwipe={false}
                disableKeyDown
                showFullscreenButton={false}
                showPlayButton={false}
                showThumbnails={false}
                lazyLoad
                items={media.map(
                    (mediaItem: ListingCardMedia, index: number) => ({
                        original: mediaItem.imgUrl,
                        isVideo: mediaItem.isVideo,
                        renderItem: (item: ReactImageGalleryItem) => (
                            <GatsbyLink
                                to={
                                    redirectPath
                                        ? `${redirectPath}?preview=${index}`
                                        : ''
                                }
                            >
                                {!mediaItem.isVideo ? (
                                    <>
                                        <div className={classes.overlay} />
                                        <img
                                            className={clsx(
                                                classes.activeImage,
                                                'listingCardCarouselImagePreview'
                                            )}
                                            alt="Product listing preview"
                                            src={item.original}
                                        />
                                    </>
                                ) : (
                                    <VideoOverlay
                                        src={item.original}
                                        hasFullOverlay={true}
                                        width={355}
                                        height={130}
                                    />
                                )}
                            </GatsbyLink>
                        ),
                    })
                )}
            />
        </div>
    )
}

export default ProductListingCardCarousel
