import { makeStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import clsx from 'clsx'
import React from 'react'
import 'react-image-gallery/styles/css/image-gallery.css'
import IframeComponent from './IframeComponent'
import { ProductListingDetailVideoSource } from './productListingDetailConsolidatedProductMedia'

const useEmbeddedVideoStyles = makeStyles((theme) => ({
    container: {
        width: 800,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            height: 400,
            width: '100%',
        },
        [theme.breakpoints.between('lg', 'xl')]: {
            height: 500,
            width: '100%',
        },
        [theme.breakpoints.up('md')]: {
            height: 380,
            width: '100%',
        },
        [theme.breakpoints.up('xl')]: {
            height: 500,
            width: '100%',
        },
    },
    theater: {
        background: '#000',
    },
}))

export interface EmbeddedVideoProps {
    /**
     * Video ID.
     */
    videoId: string

    /**
     * Can either be YouTube or Vimeo.
     *
     * May add support for other platforms in the future.
     */
    videoSource: ProductListingDetailVideoSource

    className?: string | string[]
}

/**
 *  Generates embedded video players for
 *  YouTube or Vimeo content.
 */
const EmbeddedVideo: React.FC<EmbeddedVideoProps> = ({
    videoId,
    videoSource,
    className,
}) => {
    const [divRef, setDivRef] = React.useState<HTMLDivElement | null>()
    const [isActive, setIsActive] = React.useState(false)
    const transformCss = divRef?.parentElement?.style.transform
    const classes = useEmbeddedVideoStyles()
    const theme = useTheme()
    const mdDown = useMediaQuery(theme.breakpoints.down('md'))
    const lgDown = useMediaQuery(theme.breakpoints.down('lg'))
    /**
     * This unmounts the embedded video player so it doesn't play when out of view.
     */
    React.useEffect(() => {
        setIsActive(
            (transformCss || '').indexOf('translate3d(0%, 0px, 0px)') !== -1
        )
    }, [setIsActive, transformCss])

    return (
        <div
            ref={setDivRef}
            className={clsx(
                classes.container,
                'listingDetailEmbeddedVideo',
                className
            )}
        >
            {isActive ? (
                <IframeComponent
                    videoSource={videoSource}
                    videoId={videoId}
                    mdDown={mdDown}
                    lgDown={lgDown}
                />
            ) : null}
        </div>
    )
}

export default EmbeddedVideo
