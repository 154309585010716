import IconButton from '@material-ui/core/IconButton'
import { makeStyles, Theme } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import DragIndicatorIcon from '@material-ui/icons/DragIndicator'
import clsx from 'clsx'
import SimpleSelect from 'components/Forms/SimpleSelect'
import GridContainer from 'components/Surfaces/GridContainer'
import GridItem from 'components/Surfaces/GridItem'
import React, { useEffect, useState } from 'react'
import { Draggable, DraggableProvided } from 'react-beautiful-dnd'
import { DomObject } from './ProductSortingModal'

const useStyles = makeStyles((theme: Theme) => ({
    sortingForm: {
        overflowX: 'hidden',
        width: 'calc(100% + 16px)',
        marginRight: theme.spacing(-4),
    },
    coloumContainer: {
        marginTop: theme.spacing(1),
    },
    rowContainer: {
        backgroundColor: '#ffffff',
        '& $dragIcon': {
            display: 'block',
        },
    },
    autoAdded: { paddingLeft: '8.333%' },
    dragIcon: {
        fill: '#C9C9C9',
        display: 'none',
        marginTop: theme.spacing(1.5),
        '&:hover': {
            cursor: 'move',
        },
    },
}))
interface ProductSortingFormProps {
    sortingDomArr: DomObject[]
    columnValueChangeHandler: (
        key: string,
        value: string | boolean,
        id: string
    ) => void
    handleRemoveSorting: (id: string) => void
    placeHolder?: React.ReactElement<HTMLElement> | null
}

export const ProductSortingForm: React.FC<ProductSortingFormProps> = ({
    sortingDomArr,
    columnValueChangeHandler,
    handleRemoveSorting,
    placeHolder,
}) => {
    const sortingOptions = [
        {
            display: 'Ascending',
            value: '',
        },
        {
            display: 'Descending',
            value: 'desc',
        },
    ]
    const [formDataAttr, setFormDataAttr] = useState<Record<string, string>>({
        name: 'columnName',
        value: '',
        id: '',
    })
    useEffect(() => {
        const currentData: DomObject = sortingDomArr.filter(
            (sd) => sd.id === formDataAttr?.id
        )?.[0]

        if (
            currentData &&
            formDataAttr &&
            currentData[formDataAttr.name as keyof DomObject] !==
                formDataAttr.value
        )
            columnValueChangeHandler(
                formDataAttr.name,
                formDataAttr.value,
                formDataAttr.id
            )
    }, [formDataAttr, sortingDomArr, columnValueChangeHandler])

    const classes = useStyles()

    const sortRowDom = (sort: DomObject) => (
        <GridItem xs={sort.isAddedDynamically ? 12 : 11}>
            <GridContainer className="dataRowContainer">
                <GridItem
                    xs={6}
                    className={clsx(classes.coloumContainer, 'rowContainer')}
                >
                    <SimpleSelect
                        fontSize={14}
                        disabled={sort.isAddedDynamically}
                        width="90%"
                        value={sort.columnName}
                        options={sort.columnOptions}
                        onValueChange={(value) =>
                            setFormDataAttr({
                                name: 'columnName',
                                id: sort.id,
                                value,
                            })
                        }
                    />
                </GridItem>
                <GridItem xs={4} className={classes.coloumContainer}>
                    <SimpleSelect
                        data-testid="selectValue"
                        fontSize={14}
                        width="90%"
                        value={sort.value}
                        options={sortingOptions}
                        onValueChange={(value) =>
                            setFormDataAttr({
                                name: 'value',
                                id: sort.id,
                                value,
                            })
                        }
                    />
                </GridItem>
                <GridItem xs={2} className={classes.coloumContainer}>
                    {(sort.columnName || sortingDomArr.length > 1) &&
                        !sort.isAddedDynamically && (
                            <IconButton
                                className="deleteSorting"
                                onClick={() => {
                                    handleRemoveSorting(sort.id)
                                }}
                            >
                                <CloseIcon />
                            </IconButton>
                        )}
                </GridItem>
            </GridContainer>
        </GridItem>
    )

    return (
        <GridContainer
            data-testid="sortingForm"
            className={classes.sortingForm}
        >
            {sortingDomArr.map((sort: DomObject, index: number) =>
                !sort.isAddedDynamically ? (
                    <Draggable
                        key={sort.id}
                        draggableId={sort.id as string}
                        index={index}
                    >
                        {(provided: DraggableProvided) => (
                            <GridItem
                                className={clsx(classes.rowContainer)}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                xs={12}
                            >
                                <GridContainer {...provided.dragHandleProps}>
                                    <GridItem xs={1}>
                                        <DragIndicatorIcon
                                            className={clsx(
                                                'svgDragIcon',
                                                classes.dragIcon
                                            )}
                                            fontSize="large"
                                        />
                                    </GridItem>
                                    {sortRowDom(sort)}
                                </GridContainer>
                            </GridItem>
                        )}
                    </Draggable>
                ) : (
                    <GridContainer
                        item
                        key={sort.id}
                        className={classes.autoAdded}
                    >
                        {sortRowDom(sort)}
                    </GridContainer>
                )
            )}
            {/* button here */}
            {placeHolder}
        </GridContainer>
    )
}
