import { makeStyles, Theme } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import clsx from 'clsx'
import {
    ListingLevel,
    ManufacturerProductCatalogDetail,
    ManufacturerProductSpecDocDetail,
} from 'components/Models/product'
import React from 'react'
import GridContainer from 'components/Surfaces/GridContainer'
import GridItem from 'components/Surfaces/GridItem'
import ViewDocumentRow from './ViewDocumentRow'

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        maxWidth: 504,
    },
    fullWidth: {
        width: '100%',
    },
    title: {
        color: '#04325B',
        fontWeight: 500,
        borderBottom: '6px solid #04325B',
        textTransform: 'uppercase',
    },
    itemContainer: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    tableBorder: {
        border: '1px solid #C9C9C9',
    },
    tableHead: {
        backgroundColor: '#343334',
    },
    tableWhiteText: {
        color: '#FFF',
        fontWeight: 500,
    },
}))

interface ViewDocumentsProps {
    level?: ListingLevel
    catalog?: Partial<ManufacturerProductCatalogDetail>
    longDocType?: Partial<ManufacturerProductSpecDocDetail>
    shortDocType?: Partial<ManufacturerProductSpecDocDetail>
    specificationDocLoading?: boolean
    showSpecificationDoc?: boolean
    executePSDQuery?: () => void
}

const ViewDocuments: React.FC<ViewDocumentsProps> = ({
    level,
    catalog,
    longDocType,
    shortDocType,
    specificationDocLoading,
    showSpecificationDoc,
    executePSDQuery,
}) => {
    const classes = useStyles()
    if (!level) return null
    if (!catalog && !longDocType && !shortDocType && !showSpecificationDoc)
        return null
    return (
        <GridContainer data-testid="dvDocuments" className={classes.container}>
            <GridItem className={classes.fullWidth}>
                <Typography variant="h6" className={classes.title}>
                    Download Documents
                </Typography>
            </GridItem>
            <GridItem
                className={clsx(classes.itemContainer, classes.fullWidth)}
            >
                <Table
                    className={clsx(classes.container, classes.tableBorder)}
                    size="small"
                >
                    <TableHead>
                        <TableRow className={classes.tableHead}>
                            <TableCell colSpan={2}>
                                <Typography
                                    variant="subtitle2"
                                    className={classes.tableWhiteText}
                                >
                                    Filename
                                </Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {showSpecificationDoc ? (
                            <ViewDocumentRow
                                icon="longform"
                                text="Product Specification Document"
                                showSpecificationDoc={showSpecificationDoc}
                                executePSDQuery={executePSDQuery}
                                loading={specificationDocLoading}
                            />
                        ) : (
                            <>
                                {longDocType && (
                                    <ViewDocumentRow
                                        icon="longform"
                                        text="Full Length Document"
                                        title={longDocType.title}
                                        link={longDocType.path}
                                    />
                                )}
                                {shortDocType && (
                                    <ViewDocumentRow
                                        icon="shortform"
                                        text="Short Form Document"
                                        title={shortDocType.title}
                                        link={shortDocType.path}
                                    />
                                )}
                            </>
                        )}
                        {catalog && (
                            <ViewDocumentRow
                                icon="catalog"
                                text="Catalog"
                                title={catalog.title}
                                link={catalog.path}
                            />
                        )}
                    </TableBody>
                </Table>
            </GridItem>
        </GridContainer>
    )
}

export default ViewDocuments
