import Divider from '@material-ui/core/Divider'
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Skeleton from '@material-ui/lab/Skeleton'
import clsx from 'clsx'
import RoundButton from 'components/Buttons/RoundButton'
import Layout from 'components/common/Layout'
import { AppHeaderContext } from 'components/Contexts/AppHeaderContext'
import TooltipEllipsis from 'components/Feedbacks/TooltipEllipsis'
import { ProductListingFacets } from 'components/GraphQL/Types/productSearch.types'
import { FacetValueToCount } from 'components/Models/facet'
import { Firm } from 'components/Models/firm'
import { ListingMatch } from 'components/Models/listingSearch'
import { ListingLevel } from 'components/Models/product'
import GridContainer from 'components/Surfaces/GridContainer'
import GridItem from 'components/Surfaces/GridItem'
import { pxToRem } from 'components/Utilities/unitUtils'
import CompanyBanner from 'components/Widgets/Company/CompanyBanner'
import CompanyContactInfo from 'components/Widgets/Company/CompanyContactInfo'
import CompanyLogo from 'components/Widgets/Company/CompanyLogo'
import {
    dummyListingTypeWidgetInfoBubble,
    TextBlockLoadSkeleton,
} from 'components/Widgets/Company/companyPageUtils'
import ListingCategoriesWidget from 'components/Widgets/Company/ListingCategoriesWidget'
import ListingTypeWidget from 'components/Widgets/Company/ListingTypeWidget'
import { escapeElementDelimiter } from 'components/Widgets/ProductListing/productsUtils'
import { queryNameToFacetNameMap } from 'components/Widgets/ProductListing/queryNameMaps'
import { graphql, navigate, PageProps } from 'gatsby'
import React from 'react'
import { useHistory } from 'react-router-dom'

interface PureCompanyPageProps {
    firm: Partial<Firm>
    listingData?: Partial<ProductListingFacets>
    displayNameWithFirmName: string
}

// The query for this page
// $firmId is passed down as context when the page is produced in gatsby-node
// This is accessed on build as such, kindly optimize to lesser build time
export const query = graphql`
    query($listingSearchInput: SPAPI_ManufacturerProductSearchInput!) {
        spapi {
            listingSearch(input: $listingSearchInput) {
                facets {
                    name
                    values {
                        count
                        value
                    }
                }
                matches {
                    firmId
                    displayNameWithFirmName
                }
            }
        }
    }
`
const overriddenFontStyle = {
    fontFamily: 'inherit !important',
    fontSize: 'inherit !important',
    lineHeight: 'inherit !important',
    textDecoration: 'none !important',
    color: 'inherit !important',
    border: 'none !important',
    width: 'auto !important',
}
const useStyles = makeStyles((theme: Theme) => ({
    content: {
        marginTop: 16,
    },
    page: {},
    fullWidth: {
        width: '100%',
    },
    dividerContainer: {
        marginTop: theme.spacing(2),
    },
    thickDivider: {
        height: 6,
        backgroundColor: '#f2f2f2',
    },
    mapText: {
        fontWeight: 500,
    },
    leftColumnContainer: {
        flexWrap: 'nowrap',
        overflowY: 'hidden',
        overflowX: 'hidden',
    },
    noRightPadding: {
        '&.MuiGrid-item': {
            paddingRight: 0,
        },
    },
    limitedHeight: {
        minHeight: 542,
        height: 'calc(100vh - 330px)',
    },
    textBlocks: {
        bottom: 0,
        flexWrap: 'nowrap',
        overflowY: 'auto',
        minHeight: 200,
        height: 'calc(100%)',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    categoryBlocks: {
        bottom: 0,
        flexWrap: 'nowrap',
        overflowY: 'auto',
        minHeight: 100,
    },
    editableTextContainer: {
        width: '100%',
    },
    normalizedFont: {
        // It is needed to specify the font-family here because of `pre` tag.
        fontSize: pxToRem(26.25),
        fontFamily: 'Roboto',
        // Removes top and bottom margin for `pre` tag
        margin: 0,
    },
    editableTextBlockTitle: {
        color: '#02A09C',
        fontWeight: 500,
        marginBottom: theme.spacing(2),
    },
    editableTextBlockContentText: {
        ...theme.typography.h4,
        border: 'none',
        outline: 'none',
        color: '#5E5F61',
        width: '100%',
        '& .MuiInputBase-input.Mui-disabled': {
            color: '#5E5F61',
        },
        minHeight: 200,
        padding: theme.spacing(1),
        whiteSpace: 'pre-wrap',
        overflowWrap: 'anywhere',
        '&.editableTextBlockInput[contenteditable="true"]': {
            fontWeight: 'normal',
        },
        //For overriding Browese  user-agent style/auto created style for content editable, style from ms word document etc.
        '& span,& i, & div, & b, & strong, & li, & blockquote, & p, & u': {
            ...overriddenFontStyle,
        },
        '& dl, & ol, & ul': {
            marginTop: 0,
            marginBottom: '1rem',
        },
    },
    editableTextBlockContent: {
        outline: 'none',
        transition: '140ms',
    },
}))

const headerMinHeight = 8.8

// this is a temeplete page, it recieves data and is rendered by gatsby-node.js
// The pageContext has the value passed down from the createPage context
// data is the resulf of the static query of this page
export const PureCompanyPage: React.FC<PureCompanyPageProps> = ({
    firm,
    listingData,
    displayNameWithFirmName,
}) => {
    const classes = useStyles()
    const theme = useTheme()
    const history = useHistory()
    const mdDown = useMediaQuery(theme.breakpoints.down('sm'))
    const { updateAppHeaderContent } = React.useContext(AppHeaderContext)

    const [categories, setCategories] = React.useState<
        Partial<FacetValueToCount>[]
    >([])

    const getListingLevelCount = React.useCallback(
        (listingLevelName: string) => {
            if (listingData) {
                return (
                    listingData?.facets
                        ?.find((facet) => facet.name === 'ListingLevels')
                        ?.values?.find(
                            (facetItem) => facetItem.value === listingLevelName
                        )?.count ?? 0
                )
            } else {
                return 0
            }
        },
        [listingData]
    )

    const handleLink = (link: string) => {
        navigate(link)
    }

    const openCompanyProductListings = React.useCallback(() => {
        let newQS: string = ''
        let queryParams = new URLSearchParams(location.search)
        const filterSelection: Record<string, string[]> = {
            categories: [],
            listingLevels: [],
            partnerships: [],
            submissionStatus: [],
            isActive: ['true'],
            displayNameWithFirmName: [`${displayNameWithFirmName}`],
            sectionNames: [],
            additionalContent: [],
            sectionNameWithProductType: [],
            orderBy: [
                'sectionNumber',
                'productType',
                'type',
                'lastUpdated desc',
            ],
        }
        if (filterSelection) {
            for (let index in queryNameToFacetNameMap) {
                let vals = filterSelection[
                    queryNameToFacetNameMap[index].facetName
                ]?.map((v) => escapeElementDelimiter(v))

                queryParams.set(
                    queryNameToFacetNameMap[index].queryName,
                    vals?.join(',')
                )
            }
        }

        // Filter undefined values from querystring
        queryParams.forEach(function (value, key) {
            if (value && value !== 'undefined') {
                newQS += `${key}=${encodeURIComponent(value)}&`
            }
        })

        // Cleanup
        if (newQS.endsWith('&')) {
            newQS = newQS.slice(0, -1)
        }

        const url = `/products/all?${newQS}`
        history.push(url)
        navigate(url)
    }, [
        queryNameToFacetNameMap,
        navigate,
        location,
        firm,
        displayNameWithFirmName,
    ])

    React.useEffect(() => {
        updateAppHeaderContent(
            'Company Page',
            <GridContainer>
                <GridItem>
                    <RoundButton
                        hasShadow={false}
                        data-testid="MyCompanyPage"
                        backgroundColor="#0098D6"
                        onClick={openCompanyProductListings}
                    >
                        Product Listings
                    </RoundButton>
                </GridItem>
            </GridContainer>
        )
    }, [updateAppHeaderContent])

    React.useEffect(() => {
        if (listingData) {
            setCategories(
                listingData?.facets?.find(
                    (facet) => facet.name === 'Categories'
                )?.values || []
            )
        }
    }, [listingData])

    return (
        <Layout>
            <CompanyBanner
                bannerUrl={firm?.profileInfo?.bannerUrl}
                defaultCategoryName={categories?.[0]?.value ?? 'generic'}
                loading={!listingData || !firm}
            />
            <GridContainer
                justifyContent="space-between"
                spacing={4}
                className={clsx(classes.page, classes.content)}
            >
                <GridItem
                    container
                    direction="column"
                    xs={12}
                    md={7}
                    spacing={2}
                    className={clsx(
                        mdDown && classes.noRightPadding,
                        classes.leftColumnContainer,
                        !mdDown && classes.limitedHeight
                    )}
                >
                    <GridItem>
                        <CompanyLogo
                            loading={!firm}
                            logoUrl={firm?.profileInfo?.logoUrl}
                        />
                    </GridItem>
                    <GridItem
                        container
                        direction="column"
                        className={classes.fullWidth}
                    >
                        <GridItem className={classes.fullWidth}>
                            {!firm ? (
                                <Skeleton
                                    width="80%"
                                    height="24px"
                                    data-testid="dvEditableTextLoadSkeleton"
                                />
                            ) : (
                                <div className={classes.editableTextContainer}>
                                    <pre className={classes.normalizedFont}>
                                        <TooltipEllipsis
                                            text={firm?.displayName ?? ''}
                                            lineLimit={2}
                                        />
                                    </pre>
                                </div>
                            )}
                        </GridItem>
                        <GridItem>
                            <CompanyContactInfo
                                firmLoading={!firm}
                                address={firm?.profileInfo?.address}
                                website={firm?.profileInfo?.website ?? ''}
                                publicPhone={
                                    firm?.profileInfo?.publicPhone ?? ''
                                }
                            />
                        </GridItem>
                        <GridItem
                            className={clsx(
                                classes.fullWidth,
                                classes.dividerContainer
                            )}
                        >
                            <Divider className={classes.thickDivider} />
                        </GridItem>
                    </GridItem>
                    <GridItem
                        container
                        direction="column"
                        style={{
                            maxHeight: mdDown ? 300 : 'unset',
                        }}
                        className={clsx(classes.fullWidth, classes.textBlocks)}
                        spacing={2}
                    >
                        <GridItem>
                            {!firm ? (
                                <TextBlockLoadSkeleton />
                            ) : (
                                <GridContainer
                                    direction="column"
                                    className={'editableTextBlock'}
                                >
                                    <GridItem
                                        container
                                        direction="row"
                                        alignItems="center"
                                        spacing={1}
                                        className="editableTextBlockTitleBar"
                                    >
                                        <GridItem>
                                            <Typography
                                                variant="h2"
                                                className={clsx(
                                                    'editableTextBlockTitle',
                                                    classes.editableTextBlockTitle
                                                )}
                                            >
                                                Who We Are
                                            </Typography>
                                        </GridItem>
                                    </GridItem>
                                    <GridContainer>
                                        <GridItem
                                            xs
                                            container
                                            direction="column"
                                            className={clsx(
                                                'editableTextBlockContentContainer',
                                                classes.editableTextBlockContent
                                            )}
                                        >
                                            <GridItem>
                                                <Typography
                                                    variant="h4"
                                                    data-testid="editableContent"
                                                    className={
                                                        classes.editableTextBlockContentText
                                                    }
                                                    dangerouslySetInnerHTML={{
                                                        __html:
                                                            firm.profileInfo
                                                                ?.whoWeAre ??
                                                            '', //for rendering rich html text
                                                    }}
                                                />
                                            </GridItem>
                                        </GridItem>
                                    </GridContainer>
                                </GridContainer>
                            )}
                        </GridItem>
                    </GridItem>
                </GridItem>
                <GridContainer
                    item
                    xs={12}
                    md={5}
                    spacing={2}
                    direction="column"
                    className={clsx(
                        mdDown && classes.noRightPadding,
                        classes.leftColumnContainer,
                        !mdDown && classes.limitedHeight
                    )}
                >
                    <GridContainer
                        justifyContent="flex-end"
                        alignContent="stretch"
                        item
                        spacing={2}
                    >
                        <GridItem sm={4} xs={12}>
                            <ListingTypeWidget
                                data-testid="premiumMasterSpecListingCountWidget"
                                loading={!listingData}
                                count={getListingLevelCount(
                                    ListingLevel.PremiumMasterSpec
                                )}
                                infoBubble={dummyListingTypeWidgetInfoBubble}
                                listingName="Premium MasterSpec Listings"
                                headerMinHeight={headerMinHeight}
                                firmName={firm?.name}
                                firmDisplayName={firm?.displayName}
                                listingTypeFilters={[
                                    ListingLevel.PremiumMasterSpec,
                                ]}
                                handleListingTypeWidgetClick={handleLink}
                            />
                        </GridItem>
                        <GridItem sm={4} xs={12}>
                            <ListingTypeWidget
                                data-testid="premiumListingCountWidget"
                                loading={!listingData}
                                count={getListingLevelCount(
                                    ListingLevel.Premium
                                )}
                                infoBubble={dummyListingTypeWidgetInfoBubble}
                                listingName="Premium Listings"
                                headerMinHeight={headerMinHeight}
                                firmName={firm?.name}
                                firmDisplayName={firm?.displayName}
                                listingTypeFilters={[ListingLevel.Premium]}
                                handleListingTypeWidgetClick={handleLink}
                            />
                        </GridItem>
                        <GridItem sm={4} xs={12}>
                            <ListingTypeWidget
                                data-testid="proprietaryListingCountWidget"
                                loading={!listingData}
                                count={getListingLevelCount(
                                    ListingLevel.PremiumProprietary
                                )}
                                infoBubble={dummyListingTypeWidgetInfoBubble}
                                listingName="Proprietary Listings"
                                headerMinHeight={headerMinHeight}
                                firmName={firm?.name}
                                firmDisplayName={firm?.displayName}
                                listingTypeFilters={[
                                    ListingLevel.PremiumProprietary,
                                ]}
                                handleListingTypeWidgetClick={handleLink}
                            />
                        </GridItem>
                    </GridContainer>
                    <GridContainer justifyContent="flex-end" item spacing={2}>
                        <GridItem xs={12}>
                            <ListingCategoriesWidget
                                categories={categories}
                                firmLoading={!listingData}
                                contentHeight={
                                    categories.length > 4
                                        ? window.innerHeight - 677
                                        : 180
                                }
                                firmName={firm?.name}
                                firmDisplayName={firm?.displayName}
                                handleCategoriesWidgetClick={handleLink}
                            />
                        </GridItem>
                    </GridContainer>
                </GridContainer>
            </GridContainer>
        </Layout>
    )
}

interface PageContext {
    firm: Partial<Firm>
    listingSearchInput: { facets: { name: string; values: string[] }[] }
}

interface PageData {
    spapi?: {
        listingSearch: {
            facets: Partial<ProductListingFacets>[]
            matches: Partial<ListingMatch>[]
        }
    }
}

/**
 * Data for this page comes from the context
 * This is to get all data from just one request instead of many.
 */
const CompanyPage: React.FC<PageProps<PageData, PageContext>> = ({
    data,
    pageContext,
    ...rest
}) => {
    return (
        <PureCompanyPage
            {...rest}
            firm={pageContext.firm}
            listingData={
                data.spapi?.listingSearch as Partial<ProductListingFacets>
            }
            displayNameWithFirmName={
                data.spapi?.listingSearch?.matches?.[0]
                    ?.displayNameWithFirmName ?? ''
            }
        />
    )
}

export default CompanyPage
