import Button from '@material-ui/core/Button'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import clsx from 'clsx'
import {
    ManufacturerProductImageDetail,
    ManufacturerProductVideoDetail,
} from 'components/Models/product'
import { isEqual } from 'components/Utilities/objectUtils'
import { suffixDimension } from 'components/Utilities/stringUtils'
import { pxToRem } from 'components/Utilities/unitUtils'
import { CdnBaseUrl } from 'components/Utilities/urlUtils'
import EmbeddedVideo from 'components/Widgets/ProductListing/Details/Media/EmbeddedVideo'
import {
    ProductListingDetailConsolidatedProductMedia,
    ProductListingDetailVideoSource,
} from 'components/Widgets/ProductListing/Details/Media/productListingDetailConsolidatedProductMedia'
import { convertVideoDetailToLinks } from 'components/Widgets/ProductListing/Details/productDetailUtils'
import VideoOverlay from 'components/Widgets/ProductListing/VideoOverlay'
import React, { useEffect, useMemo, useState } from 'react'
import ImageGallery from 'react-image-gallery'
import 'react-image-gallery/styles/css/image-gallery.css'

const noDefaultImage = `${CdnBaseUrl}/bpm/noimage-default.png`

const useStyles = makeStyles((theme: Theme) => ({
    galleryContainer: {
        marginLeft: -theme.spacing(3),
        paddingLeft: theme.spacing(3),
        borderBottom: '1px solid #c9c9c9',
        width: 1050,
        [theme.breakpoints.between('sm', 'md')]: {
            borderBottom: 'none',
            width: 693.5,
        },
        '& .image-gallery-content': {
            borderRight: '1px solid #c9c9c9',
        },
        '& .image-gallery-thumbnails-wrapper.left': {
            width: 200,
            height: '335px !important',
            margin: 0,
            marginTop: 30,
            [theme.breakpoints.down('md')]: {
                width: 128.7,
                height: '335px !important',
            },
        },
        '& .image-gallery-slide-wrapper.left': {
            borderLeft: '1px solid #c9c9c9',
            borderBottom: '1px solid #c9c9c9',
            width: 'calc(100% - 224px)',
            left: theme.spacing(3),
            [theme.breakpoints.between('lg', 'md')]: {
                width: 'calc(100% - 129px)',
                borderBottom: 'none',
            },
        },
        '& .image-gallery-swipe': {
            height: '100%',
        },
        '& .image-gallery-slide, .image-gallery-slides': {
            height: '100%',
            [theme.breakpoints.down('md')]: {
                width: 514.85,
            },
        },
        '& .image-gallery-icon': {
            '&:hover': {
                '& > svg': {
                    color: '#fff',
                    transform: 'scale(1.1)',
                },
            },
            '&:focus': {
                color: '#ffff',
            },
        },
        '& .image-gallery-left-nav, .image-gallery-right-nav': {
            padding: 0,
        },
        '& button.image-gallery-left-nav': {
            height: '100%',
            width: 40,
            background:
                'linear-gradient(90deg, rgba(0, 0, 0, 0.76) -10px, rgba(0, 0, 0, 0.06) 80%, rgba(255, 255, 255, 0))',
            filter: 'none',
            transition: '200ms',
            '& > svg': {
                left: 10,
            },
        },
        '& .image-gallery-slide.center': {
            width: 800,
            [theme.breakpoints.down('md')]: {
                width: 514.85,
                height: '100%',
            },
            [theme.breakpoints.between('md', 'lg')]: {
                width: '100%',
            },
        },
        '& .image-gallery-bullets .image-gallery-bullet': {
            boxShadow: '0 1px 5px #1a1a1a',
            '&:hover': {
                background: '#000',
            },
        },
        '& button.image-gallery-right-nav': {
            height: '100%',
            width: 40,
            background:
                'linear-gradient(270deg, rgba(0, 0, 0, 0.76) -10px, rgba(0, 0, 0, 0.06) 80%, rgba(255, 255, 255, 0))',
            filter: 'none',
            '& > svg': {
                right: 10,
            },
        },
        '& .image-gallery-svg': {
            height: 25,
            width: 'auto',
            position: 'absolute',
            top: 'calc(50% - 12.5px)',
        },
        '& .image-gallery-bullets': {
            marginBottom: -15,
        },
    },
    noImage: {
        height: 600,
        [theme.breakpoints.down('md')]: {
            width: 514.85,
            height: 386,
        },
        [theme.breakpoints.up('md')]: {
            borderBottom: 'none',
            height: '100%',
        },
    },
    lightGrayBackground: {
        backgroundColor: '#c9c9c9',
    },
    noImageThumbText: {
        color: '#fff',
        fontWeight: 500,
        [theme.breakpoints.down('md')]: {
            fontSize: pxToRem(13),
        },
    },
    largeFont: {
        fontSize: pxToRem(72),
        color: '#fff',
        fontWeight: 500,
        [theme.breakpoints.between('md', 'lg')]: {
            fontSize: pxToRem(50),
        },
    },
    thumbnail: {
        width: '200px !important',
        height: 100,
        border: '1px solid #c9c9c9',
        [theme.breakpoints.down('md')]: {
            width: 128.7,
            height: 62.35,
        },
        '&.image-gallery-thumbnail:not(.active):focus, &.image-gallery-thumbnail:not(.active):hover': {
            border: '1px solid',
        },
        '& img': {
            width: '100%',
            height: 98,
            objectFit: 'cover',
            [theme.breakpoints.down('md')]: {
                width: '100%',
                height: 60.35,
            },
        },
        '&.active img': {
            height: 92,
            [theme.breakpoints.down('md')]: {
                width: '100%',
                height: 54.35,
            },
        },
    },
    noImgThumbnail: {
        width: 192,
        height: 92,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            width: 120.7,
            height: 56.35,
        },
    },
    customNav: {
        padding: 0,
        width: 200,
        position: 'absolute',
        left: -224,
        background: '#fff',
        borderRadius: 0,
        height: 30,
        color: 'rgba(0,0,0,0.54)',
        [theme.breakpoints.down('md')]: {
            left: -152.7,
            width: 128.7,
        },
    },
    customPrevNav: {
        top: 0,
    },
    customNextNav: {
        bottom: 1,
        height: 29,
        top: 336,
    },
    activeImage: {
        width: '100%',
        objectFit: 'cover',
        borderBottom: `1px solid #c9c9c9`,
        [theme.breakpoints.between('md', 'lg')]: {
            borderBottom: 'none',
            objectFit: 'fill',
        },
        [theme.breakpoints.up('xl')]: {
            borderBottom: 'none',
        },
    },
}))

const emptyPlaceholder = {
    empty: {
        // empty placeholder to show no image / video
        order: 0,
        imgUrl: '',
        thumbnailUrl: '',
    },
}

export interface ListingDetailMediaCarouselProps {
    images?: Partial<ManufacturerProductImageDetail>[]
    videos?: Partial<ManufacturerProductVideoDetail>[]
}

const ListingDetailMediaCarousel = React.forwardRef<
    ImageGallery | null,
    ListingDetailMediaCarouselProps
>(({ images, videos }, ref) => {
    const classes = useStyles()

    const [
        initialConsolidatedProductMedia,
        setInitialConsolidatedProductMedia,
    ] = useState<Record<string, ProductListingDetailConsolidatedProductMedia>>(
        {}
    )
    /**
     * The state that will be use to update mutation.
     * This needs to be converted to the proper input of the photos and videos mutation.
     * FYI: the newly added images or videos will have an id prefix of image-* and video-* respectively.
     * Coordinate with Matt if the backend mutation will check for ID.
     * If the ID doesn't exist in the backend, it means that it is a new image/video.  If the ID exists,
     * then update the correct image / video.
     */
    const [consolidatedProductMedia, setConsolidatedProductMedia] = useState<
        Record<string, ProductListingDetailConsolidatedProductMedia>
    >(emptyPlaceholder)

    // Consolidates images and videos to ConsolidatedProductMedia
    useEffect(() => {
        if (
            !images?.length &&
            !videos?.length &&
            !isEqual(initialConsolidatedProductMedia, {})
        ) {
            setInitialConsolidatedProductMedia({})
            setConsolidatedProductMedia({})
            return
        }

        // consolidate media
        const media: Record<
            string,
            ProductListingDetailConsolidatedProductMedia
        > = {}

        images?.forEach((image) => {
            media[image.id!] = {
                id: image.id,
                order: image.order!,
                imgUrl: suffixDimension({
                    filename: image?.filename ?? '',
                    dimension: {
                        width: 800,
                        height: 600,
                    },
                }),
                thumbnailUrl: suffixDimension({
                    filename: image?.filename ?? '',
                    dimension: {
                        width: 400,
                        height: 300,
                    },
                }),
                originalUrl: image.filename,
                originalFilename:
                    image?.originalFilename ?? image?.filename ?? '',
            }
        })

        videos?.forEach((video) => {
            media[video.id!] = {
                id: video.id,
                order: video.order!,
                imgUrl: video?.videoStillImageUrl ?? '',
                thumbnailUrl: video?.videoStillImageUrl ?? '',
                fromSource: video?.fromSource as ProductListingDetailVideoSource,
                videoId: video?.videoId ?? '',
                originalFilename: convertVideoDetailToLinks(video),
            }
        })
        if (isEqual(initialConsolidatedProductMedia, media)) return
        setInitialConsolidatedProductMedia(media)
        setConsolidatedProductMedia(media)
    }, [images, videos, initialConsolidatedProductMedia])

    // Empty list
    useEffect(() => {
        if (Object.keys(consolidatedProductMedia).length === 0) {
            setConsolidatedProductMedia(emptyPlaceholder)
        }
    }, [consolidatedProductMedia])

    const getItems = useMemo(() => {
        const consolidatedMediaKeys = Object.keys(consolidatedProductMedia)
        const orderedConsolidatedMediaKeys = consolidatedMediaKeys.sort(
            (key1, key2) =>
                consolidatedProductMedia[key1].order >
                consolidatedProductMedia[key2].order
                    ? 1
                    : -1
        )
        return orderedConsolidatedMediaKeys.map((key) => ({
            original: consolidatedProductMedia[key].imgUrl,
            videoSource: consolidatedProductMedia[key].fromSource,
            videoId: consolidatedProductMedia[key].videoId,
            thumbnail: consolidatedProductMedia[key].imgUrl,
            thumbnailClass: classes.thumbnail,
            renderThumbInner: () => {
                if (!consolidatedProductMedia[key].imgUrl) {
                    return (
                        <div
                            className={clsx(
                                classes.noImgThumbnail,
                                classes.lightGrayBackground
                            )}
                        >
                            <Typography
                                className={classes.noImageThumbText}
                                variant="h6"
                                component="span"
                            >
                                No Image / Video
                            </Typography>
                        </div>
                    )
                }
                const isVideo =
                    consolidatedProductMedia[key].videoId &&
                    consolidatedProductMedia[key].fromSource

                if (!isVideo) {
                    return (
                        <img
                            alt="Product listing preview thumbnail"
                            src={consolidatedProductMedia[key].thumbnailUrl}
                        />
                    )
                }

                return (
                    <VideoOverlay
                        src={consolidatedProductMedia[key].imgUrl}
                        className="listingPreviewThumbnail"
                    />
                )
            },
            renderItem: (item: any) => {
                if (!consolidatedProductMedia[key].imgUrl) {
                    return (
                        <>
                            <img
                                className={classes.activeImage}
                                alt="Product listing preview"
                                src={noDefaultImage}
                            />
                        </>
                    )
                }

                if (!consolidatedProductMedia[key].fromSource) {
                    return (
                        <>
                            <img
                                className={classes.activeImage}
                                alt="Product listing preview"
                                src={item.original}
                            />
                        </>
                    )
                }

                return (
                    <>
                        <EmbeddedVideo
                            videoId={item.videoId}
                            videoSource={item.videoSource}
                            className={classes.activeImage}
                        />
                    </>
                )
            },
        }))
    }, [classes, consolidatedProductMedia])
    return (
        <div className={clsx(classes.galleryContainer, 'listing')}>
            <ImageGallery
                ref={ref}
                showBullets={false}
                showNav
                disableKeyDown
                disableSwipe={false}
                showFullscreenButton={false}
                showPlayButton={false}
                showThumbnails
                lazyLoad
                thumbnailPosition="left"
                renderLeftNav={(onClick) => {
                    return (
                        <Button
                            className={clsx(
                                classes.customNav,
                                classes.customPrevNav,
                                'previousListingPreviewItemButton'
                            )}
                            onClick={onClick}
                        >
                            <KeyboardArrowUpIcon fontSize="large" />
                        </Button>
                    )
                }}
                renderRightNav={(onClick) => {
                    return (
                        <Button
                            className={clsx(
                                classes.customNav,
                                classes.customNextNav,
                                'previousListingNextItemButton'
                            )}
                            onClick={onClick}
                        >
                            <KeyboardArrowDownIcon fontSize="large" />
                        </Button>
                    )
                }}
                items={getItems}
            />
        </div>
    )
})

export default ListingDetailMediaCarousel
