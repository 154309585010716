import {
    ApolloError,
    MutationFunctionOptions,
    QueryLazyOptions,
} from '@apollo/client'
import { makeStyles, Theme } from '@material-ui/core/styles'
import clsx from 'clsx'
import {
    ShowGlobalSnackbarMutationProps,
    ShowGlobalSnackbarRequest,
} from 'components/Configurations/GlobalSnackbar'
import { ProductPropertyDetailResponse } from 'components/GraphQL/Types/product.types'
import GridContainer from 'components/Surfaces/GridContainer'
import GridItem from 'components/Surfaces/GridItem'
import { ExecutionResult } from 'graphql/execution/execute'
import React, {
    Ref,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react'
import { NormalizedProductType } from '../model'
import { removeFamilyNumberFromFamilyNameRegex } from '../productsUtils'
import ClearAllFilter from './ClearAllFilter'
import { FilterCategoriesExpansion } from './Context/FilterCategoriesExpansionContext'
import FilterButton, { SecondaryFilter } from './FilterButton'
import MoreFilterButton from './MoreFilterButton'
import MoreFilters from './MoreFilters'
import { ProductSortingModal } from './ProductSortingGrouping/ProductSortingModal'
import { ProductTypesFilter } from './ProductTypes'

const ProductSearchBarStyles = makeStyles((theme: Theme) => ({
    container: {
        backgroundColor: '#ffffff',
        height: 60,
        border: '1px solid #C9C9C9',
        paddingRight: theme.spacing(2),
        boxShadow:
            '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
        justifyContent: 'flex-end',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            height: 94,
        },
    },
    searchBox: {
        display: 'flex',
    },
    buttonContainer: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
    },
    productTypeContainer: {
        '& button.MuiButton-contained': {
            height: 38,
        },
    },
    editContainer: {
        marginLeft: theme.spacing(2),
        borderLeft: '1px solid #211F20',
    },
}))

export const moreFilterCategories = [
    {
        value: 1,
        name: 'Partnerships',
    },
    {
        value: 2,
        name: 'Additional Content',
    },
    {
        value: 3,
        name: 'Date Range',
    },
    {
        value: 4,
        name: 'State',
    },
    {
        value: 5,
        name: 'Status',
    },
]

export const listingTypeSecondaryFilter: SecondaryFilter = {
    key: 'withManufacturerOption',
    label: 'With Manufacturer Options',
}

export interface ProductListingSearchBarProps {
    /** The values a user can select in each filter.
     * The key is the filter name; the value is the available options for that filter (including any already-selected ones). */
    filterValues: Record<string, string[]>
    assignedFirm?: string

    /** The selected values for each filter. The key is the filter name and the value is the selected items for that filter.
     * Filters with no selected items may be omitted from the lookup. */
    filterSelection?: Record<string, string[]>

    /** The disabled values for each filter. The key is the filter name and the value is the disabled items for that filter.
     * Only applies to checkbox filters.
     */
    filterDisabledValues?: Record<string, string[]>

    /** Called when the user changes their filter selection (e.g., by selecting an item in a filter,
     * unselecting an item, clearing a filter, or clearing all filters). */
    onFilterSelectionChange?: (
        /** The new filter selection, a mapping of filter names to selected values. */
        selection: Record<string, string[]> | undefined
    ) => void

    /** Callback fired shortly after the input text field changes in one of the autocomplete filters. */
    onFilterInputChangeDebounced?: (
        /** The name of the filter whose input text changed. */
        filterName: string,
        /** The value of the filter's input text. */
        inputValue: string
    ) => void

    /** Called when the user opens or closes the drop-down combo box for an Autosuggest filter. */
    handleAutoSuggestDropdown: (value: string) => void

    /** Name of filter whose Autosuggest drop-down to show in a loading state, if any. */
    loadingFilterName?: string

    /**
     * callback fired when input selection in product search filter changes
     */
    handleEmptyInputSelection?: (
        /** The new filter selection, a mapping of filter names to selected values. */
        selection: Record<string, Date | string | null> | undefined
    ) => void

    emptyInputSelection?: Record<string, Date | string | null> | undefined

    /**
     * ManufacturerProductSearchInput productTypeSelections
     */
    productTypeSelections?: NormalizedProductType[]

    /**
     * Handle changes to productTypeSelections
     */
    handelProductTypeSelection?: (values: NormalizedProductType[]) => void

    showGlobalSnackbar?: (
        options?:
            | MutationFunctionOptions<
                  ShowGlobalSnackbarRequest,
                  ShowGlobalSnackbarMutationProps
              >
            | undefined
    ) => Promise<ExecutionResult>

    resultCount: number

    dataSelectedProductType?: ProductPropertyDetailResponse
    loadingSelectedProductType: boolean
    errorSelectedProductType?: Partial<ApolloError>
    getListingProductTypeProperties: (
        options?: QueryLazyOptions<Record<string, any>> | undefined
    ) => void
    onSelected: boolean
    setOnSelected: (value: boolean) => void
    showSubmissionStatus?: boolean
    clearAllHandler?: () => void
    showProductSortingModal?: boolean
    showSwatchboxFilter?: boolean
}

const ProductSearchBar: React.FC<ProductListingSearchBarProps> = ({
    filterValues,
    filterSelection,
    filterDisabledValues,
    onFilterSelectionChange,
    onFilterInputChangeDebounced,
    handleAutoSuggestDropdown,
    loadingFilterName,
    handleEmptyInputSelection,
    emptyInputSelection,
    handelProductTypeSelection,
    productTypeSelections,
    showGlobalSnackbar,
    resultCount,
    assignedFirm,
    dataSelectedProductType,
    loadingSelectedProductType,
    errorSelectedProductType,
    getListingProductTypeProperties,
    onSelected,
    setOnSelected,
    showSubmissionStatus = true,
    clearAllHandler,
    showProductSortingModal = true,
    showSwatchboxFilter = false,
}) => {
    const [selection, setSelection] = useState<
        Record<string, string[]> | undefined
    >()

    const [isExpanded, setIsExpanded] = useState(false)
    const moreFiltersRef = useRef<HTMLButtonElement>()

    const defaultExpandedFilters: number[] = moreFilterCategories.map(
        (elem) => elem.value
    )

    const [expandedFiltersList, setExpandedFiltersList] = useState(
        defaultExpandedFilters
    )

    const providerValue = useMemo(
        () => ({ expandedFiltersList, setExpandedFiltersList }),
        [expandedFiltersList, setExpandedFiltersList]
    )

    const searchBarStyles = ProductSearchBarStyles()

    const expandMoreFilters = useCallback(
        (value: boolean) => {
            setIsExpanded(value)
        },
        [setIsExpanded]
    )

    /** Reusable function for raising onFilterSelectionChange when a filter selection changes. */
    const onFilterChange = useCallback(
        (newSelectedItems: string[], selectionKey: string) => {
            const selectedItems: string[] = newSelectedItems
            const newSelection: Record<string, string[]> = {
                ...selection,
                [selectionKey]: selectedItems,
            }
            setSelection(newSelection)
            onFilterSelectionChange?.(newSelection)
        },
        [selection, setSelection, onFilterSelectionChange]
    )

    const onCategoryChange = useCallback(
        (_: React.ChangeEvent<{}>, newSelectedItems: string[]) => {
            onFilterChange(newSelectedItems, 'categories')
        },
        [onFilterChange]
    )

    const onCompanyChange = useCallback(
        (_: React.ChangeEvent<{}>, newSelectedItems: string[]) => {
            onFilterChange(newSelectedItems, 'displayNameWithFirmName')
        },
        [onFilterChange]
    )

    const onOpenCompanyDropDown = useCallback(() => {
        handleAutoSuggestDropdown('displayNameWithFirmName')
    }, [handleAutoSuggestDropdown])

    const onCompanyInputChangeDebounced = useCallback(
        (value: string) => {
            onFilterInputChangeDebounced?.('displayNameWithFirmName', value)
        },
        [onFilterInputChangeDebounced]
    )

    const onFamilyChange = useCallback(
        (_: React.ChangeEvent<{}>, newSelectedItems: string[]) => {
            onFilterChange(newSelectedItems, 'sectionNames')
        },
        [onFilterChange]
    )

    const onOpenFamilyDropDown = useCallback(() => {
        handleAutoSuggestDropdown?.('sectionNames')
    }, [handleAutoSuggestDropdown])

    const onFamilyInputChangeDebounced = useCallback(
        (value: string) => {
            onFilterInputChangeDebounced?.('sectionNames', value)
        },
        [onFilterInputChangeDebounced]
    )

    const onListingLevelChange = useCallback(
        (_: React.ChangeEvent<{}>, newSelectedItems: string[]) => {
            onFilterChange(newSelectedItems, 'listingLevels')
        },
        [onFilterChange]
    )

    const onManufacterOptionChange = useCallback(
        (_: React.ChangeEvent<{}>, newSelectedItems: string[]) => {
            onFilterChange(newSelectedItems, listingTypeSecondaryFilter.key)
        },
        [onFilterChange]
    )

    const onClearFilters = useCallback(
        (keys: string[]) => {
            const emptyKeys = keys.reduce((acc, value) => {
                return { ...acc, [value]: [] }
            }, {})

            const selections: Record<string, string[]> = {
                ...selection,
                ...emptyKeys,
            }
            setSelection(selections)
            onFilterSelectionChange?.(selections)
        },
        [selection, setSelection, onFilterSelectionChange]
    )

    const onCloseDropDown = useCallback(() => {
        handleAutoSuggestDropdown('')
    }, [handleAutoSuggestDropdown])

    useEffect(() => {
        setSelection({ ...filterSelection })
    }, [filterSelection])

    const hasSelectedManufacturerOption = useMemo(
        () =>
            !!selection?.withManufacturerOption?.find(
                (value) => value === 'true'
            ),
        [selection]
    )

    const selectedListingLevels = useMemo(
        () =>
            hasSelectedManufacturerOption
                ? selection?.listingLevels
                : selection?.listingLevels?.filter(
                      (value) =>
                          !filterDisabledValues?.listingLevels?.includes(value)
                  ),
        [selection, filterDisabledValues, hasSelectedManufacturerOption]
    )

    const selectedFamilyDisplayItems = useMemo(
        () =>
            selection?.sectionNames?.map((x) =>
                x.replace(removeFamilyNumberFromFamilyNameRegex, '')
            ),
        [selection]
    )

    return (
        <>
            <GridContainer
                className={searchBarStyles.container}
                direction="row"
            >
                <GridItem className={searchBarStyles.buttonContainer}>
                    <FilterButton
                        label="Category"
                        selectPlaceholder="Search Categories"
                        name="filter-category"
                        items={filterValues?.categories}
                        selectedItems={selection?.categories}
                        onChange={onCategoryChange}
                        expandMoreFilters={expandMoreFilters}
                    />
                </GridItem>
                <GridItem className={searchBarStyles.buttonContainer}>
                    <FilterButton
                        label="Company"
                        selectPlaceholder="Search Companies"
                        name="filter-company"
                        items={filterValues?.displayNameWithFirmName}
                        selectedItems={selection?.displayNameWithFirmName}
                        onChange={onCompanyChange}
                        onInputChangeDebounced={onCompanyInputChangeDebounced}
                        onOpenDropDown={onOpenCompanyDropDown}
                        onCloseDropDown={onCloseDropDown}
                        loading={
                            loadingFilterName === 'displayNameWithFirmName'
                        }
                        expandMoreFilters={expandMoreFilters}
                        customFormat={true}
                    />
                </GridItem>
                <GridItem className={searchBarStyles.buttonContainer}>
                    <FilterButton
                        label="Listing Type"
                        variant="Checkboxes"
                        name="filter-listing-type"
                        items={filterValues?.listingLevels}
                        selectedItems={selectedListingLevels}
                        disabledItems={filterDisabledValues?.listingLevels}
                        onChange={onListingLevelChange}
                        expandMoreFilters={expandMoreFilters}
                        secondaryFilter={listingTypeSecondaryFilter}
                        onSecondaryFilterChange={onManufacterOptionChange}
                        hasSelectedSecondaryFilter={
                            hasSelectedManufacturerOption
                        }
                        onClearFilters={onClearFilters}
                    />
                </GridItem>
                <GridItem className={searchBarStyles.buttonContainer}>
                    <FilterButton
                        label="Families"
                        selectPlaceholder="Search Families"
                        name="filter-family"
                        items={filterValues?.sectionNames}
                        selectedItems={selection?.sectionNames}
                        selectedDisplayItems={selectedFamilyDisplayItems}
                        onChange={onFamilyChange}
                        onInputChangeDebounced={onFamilyInputChangeDebounced}
                        onOpenDropDown={onOpenFamilyDropDown}
                        onCloseDropDown={onCloseDropDown}
                        loading={loadingFilterName === 'sectionNames'}
                        expandMoreFilters={expandMoreFilters}
                    />
                </GridItem>
                <GridItem
                    className={clsx(
                        searchBarStyles.buttonContainer,
                        searchBarStyles.productTypeContainer
                    )}
                >
                    <ProductTypesFilter
                        autoSuggestName="sectionNameWithProductType"
                        dataAutoSuggest={
                            filterValues?.sectionNameWithProductType
                        }
                        handleAutoSuggestDropdown={handleAutoSuggestDropdown}
                        loadingAutoSuggest={
                            loadingFilterName === 'sectionNameWithProductType'
                        }
                        onFilterInputChangeDebounced={
                            onFilterInputChangeDebounced
                        }
                        onFilterChange={onFilterChange}
                        selectedItems={
                            selection?.sectionNameWithProductType ?? []
                        }
                        handelProductTypeSelection={handelProductTypeSelection}
                        productTypeSelections={productTypeSelections}
                        showGlobalSnackbar={showGlobalSnackbar}
                        expandMoreFilters={expandMoreFilters}
                        loadingSelectedProductType={loadingSelectedProductType}
                        errorSelectedProductType={errorSelectedProductType}
                        dataSelectedProductType={dataSelectedProductType}
                        getListingProductTypeProperties={
                            getListingProductTypeProperties
                        }
                        onSelected={onSelected}
                        setOnSelected={setOnSelected}
                    />
                </GridItem>
                <GridItem className={searchBarStyles.buttonContainer}>
                    <MoreFilterButton
                        isExpanded={isExpanded}
                        expandMoreFilters={expandMoreFilters}
                        selectedItems={filterSelection}
                        emptyInputSelection={emptyInputSelection}
                        ref={moreFiltersRef as Ref<HTMLButtonElement>}
                    />
                </GridItem>
                <GridItem>
                    <ClearAllFilter
                        clearAllHandler={clearAllHandler}
                        expandMoreFilters={expandMoreFilters}
                        selectedItems={filterSelection}
                        onFilterSelectionChange={onFilterSelectionChange}
                        setSelection={setSelection}
                        emptyInputSelection={emptyInputSelection}
                        handleEmptyInputSelection={handleEmptyInputSelection}
                        handelProductTypeSelection={handelProductTypeSelection}
                        productTypeSelections={productTypeSelections}
                        assignedFirm={assignedFirm}
                    />
                </GridItem>
                {showProductSortingModal && (
                    <GridItem className={searchBarStyles.editContainer}>
                        <ProductSortingModal onFilterChange={onFilterChange} />
                    </GridItem>
                )}
            </GridContainer>
            <FilterCategoriesExpansion.Provider value={providerValue}>
                {isExpanded && (
                    <MoreFilters
                        expandMoreFilters={expandMoreFilters}
                        selectedItems={filterSelection}
                        filterDisabledValues={filterDisabledValues}
                        onFilterSelectionChange={onFilterSelectionChange}
                        setSelection={setSelection}
                        handleEmptyInputSelection={handleEmptyInputSelection}
                        emptyInputSelection={emptyInputSelection}
                        resultCount={resultCount}
                        showSubmissionStatus={showSubmissionStatus}
                        anchorElement={moreFiltersRef}
                        showSwatchboxFilter={showSwatchboxFilter}
                    />
                )}
            </FilterCategoriesExpansion.Provider>
        </>
    )
}

export default ProductSearchBar
