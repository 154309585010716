import { useMutation } from '@apollo/client'
import { makeStyles, Theme } from '@material-ui/core/styles'
import clsx from 'clsx'
import {
    ShowGlobalSnackbarMutation,
    ShowGlobalSnackbarMutationProps,
    ShowGlobalSnackbarRequest,
} from 'components/Configurations/GlobalSnackbar'
import { GenerateProductSpecification } from 'components/GraphQL/Mutations/productSpecification.mutation'
import { ListingLevel } from 'components/Models/product'
import GridContainer from 'components/Surfaces/GridContainer'
import { navigateToExternalLink } from 'components/Utilities/urlUtils'
import React, { useEffect } from 'react'
import ProductListingCardDocuments, {
    ProductListingCardDocumentsProps,
} from './ProductListingCardDocuments'
import ProductListingCardType from './ProductListingCardType'
import ProductListingManufacturerOptionItem from './ProductListingManufacturerOptionItem'

const useStyles = makeStyles((theme: Theme) => ({
    cardFooter: {
        backgroundColor: '#5E5F61',
        height: 40,
    },
    guttered: {
        padding: theme.spacing(1.2),
    },
    loadingSkeleton: {
        minWidth: 80,
        height: 20,
        backgroundColor: 'rgba(0,0,0,0.2)',
    },
    cardIcons: {
        marginRight: theme.spacing(2),
    },
}))

const getListingColor = (type: ListingLevel): string => {
    const listingcolor: Record<ListingLevel, string> = {
        [ListingLevel.PremiumMasterSpec]: '#FBB41F',
        [ListingLevel.PremiumProprietary]: '#FD3431',
        [ListingLevel.Premium]: '#F78909',
        [ListingLevel.Product]: '#C4782A',
        [ListingLevel.Manufacturer]: '#b1b0b1',
        [ListingLevel.None]: '#FFFFFF',
    }
    return listingcolor[type] ?? '#FFFFFF'
}
type ProductListingCardActionType = 'Link' | 'Action'
export interface ProductListingCardFooterProps
    extends ProductListingCardDocumentsProps {
    loading?: boolean
    firmName: string
    cardAction?: ProductListingCardActionType
    cardClick?: (value?: number) => void
    listingId?: string
    showManufacturerOptionItem?: boolean
    hasGBPButton?: boolean
}
export const ProductListingCardFooter: React.FC<ProductListingCardFooterProps> = ({
    listingLevel,
    loading,
    firmName,
    familyName,
    catalogs,
    bimLinks,
    partnershipLinkBIM,
    partnershipLinkSwatchbox,
    manufacturerDocs,
    cardAction,
    cardClick,
    showSpecificationDoc,
    showManufacturerOptionItem,
    listingId,
    hasGBPButton,
}) => {
    const classes = useStyles()
    const [
        generateProductSpecification,
        { data: prodSpecificationData, loading: spfDocLoading },
    ] = useMutation<any>(GenerateProductSpecification)
    const [showGlobalSnackbar] = useMutation<
        ShowGlobalSnackbarRequest,
        ShowGlobalSnackbarMutationProps
    >(ShowGlobalSnackbarMutation)
    const executePSDQuery = (listingId: string) => {
        generateProductSpecification({
            variables: { listingId: listingId },
        })
    }
    useEffect(() => {
        if (
            listingId &&
            showSpecificationDoc &&
            prodSpecificationData?.generateProductSpecification?.downloadURI
        )
            navigateToExternalLink(
                prodSpecificationData?.generateProductSpecification?.downloadURI
            )
        else if (
            listingId &&
            showSpecificationDoc &&
            prodSpecificationData?.generateProductSpecification?.errorMessage
        ) {
            showGlobalSnackbar({
                variables: {
                    message: 'Product Specification Document not Available',
                    variant: 'error',
                    id: `Card_PSD_Error`,
                },
            })
        }
    }, [prodSpecificationData])
    return (
        <GridContainer direction="column">
            <GridContainer
                direction="row"
                item
                className={clsx(
                    classes.cardFooter,
                    classes.guttered,
                    'footerContainer'
                )}
                alignItems="center"
                alignContent="center"
                style={{
                    backgroundColor: getListingColor(listingLevel),
                }}
                onClick={() => cardAction === 'Action' && cardClick?.()}
            >
                <GridContainer item xs>
                    <ProductListingCardType
                        loading={loading}
                        variant={listingLevel}
                    />
                </GridContainer>
                <GridContainer
                    item
                    xs
                    justifyContent="flex-end"
                    className={hasGBPButton ? classes.cardIcons : ''}
                >
                    <ProductListingCardDocuments
                        listingLevel={listingLevel}
                        companyName={firmName}
                        familyName={familyName}
                        catalogs={catalogs}
                        manufacturerDocs={manufacturerDocs}
                        bimLinks={bimLinks}
                        partnershipLinkBIM={partnershipLinkBIM}
                        partnershipLinkSwatchbox={partnershipLinkSwatchbox}
                        executePSDQuery={() => {
                            executePSDQuery(listingId ?? '')
                        }}
                        showSpecificationDoc={showSpecificationDoc}
                        spfDocLoading={spfDocLoading}
                    />
                    {!!showManufacturerOptionItem && (
                        <ProductListingManufacturerOptionItem />
                    )}
                </GridContainer>
            </GridContainer>
        </GridContainer>
    )
}
