import IconButton from '@material-ui/core/IconButton'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import clsx from 'clsx'
import React from 'react'
import GridContainer from 'components/Surfaces/GridContainer'
import GridItem from 'components/Surfaces/GridItem'
import { pxToRem } from 'components/Utilities/unitUtils'
import { NormalizedProductType } from '../../model'

const useStyles = makeStyles((theme: Theme) => ({
    fullHeight: {
        height: '100%',
    },
    fullWidth: {
        width: '100%',
    },
    sidePadding: {
        paddingLeft: theme.spacing(1.5),
        paddingRight: theme.spacing(1.5),
    },
    bodyItem: {
        minHeight: 50,
    },
    bodyGroup: { minHeight: 25 },
    bodyItemContainer: {
        paddingLeft: theme.spacing(2),
    },
    bodyItemSelected: {
        backgroundColor: '#10B090',
    },
    bodyItemText: {
        fontSize: pxToRem(13),
        color: '#FFF',
        cursor: 'default',
        verticalAlign: 'middle',
    },
    bodyItemRightSideItem: {
        display: 'table-cell',
        verticalAlign: 'middle',
    },
}))

interface LeftSideListItemProps {
    item: NormalizedProductType
    handleSelect: (value: NormalizedProductType) => void
    handleRemove: (value: NormalizedProductType) => void
}

export const LeftSideListItem: React.FC<LeftSideListItemProps> = ({
    item,
    handleSelect,
    handleRemove,
}) => {
    const classes = useStyles()
    return (
        <div
            className={clsx(
                classes.sidePadding,
                classes.bodyItem,
                item.selected && classes.bodyItemSelected
            )}
        >
            <GridContainer
                item
                className={clsx(
                    'dvItem',
                    classes.fullHeight,
                    classes.bodyItemContainer
                )}
                justifyContent="center"
                alignItems="center"
            >
                <GridItem
                    className={clsx('dvProductTypeSelect', classes.fullHeight)}
                    xs={11}
                    onClick={() => handleSelect(item)}
                >
                    <Typography
                        component="span"
                        className={clsx(
                            `lblItemName`,
                            classes.bodyItemText,
                            classes.bodyItemRightSideItem
                        )}
                    >
                        {item.productTypeName}
                    </Typography>
                </GridItem>
                <GridItem
                    xs={1}
                    className={clsx('dvProductTypeRemove', classes.fullHeight)}
                >
                    <IconButton
                        onClick={() => handleRemove(item)}
                        className={clsx('btnDel', classes.fullWidth)}
                    >
                        <HighlightOffIcon color="secondary" />
                    </IconButton>
                </GridItem>
            </GridContainer>
        </div>
    )
}
