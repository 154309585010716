import { makeStyles, Theme } from '@material-ui/core/styles'
import clsx from 'clsx'
import { ListingMatch } from 'components/Models/listingSearch'
import GridContainer from 'components/Surfaces/GridContainer'
import GridItem from 'components/Surfaces/GridItem'
import React from 'react'
import AutoSizer, { Size } from 'react-virtualized-auto-sizer'
import { FixedSizeGrid } from 'react-window'
import InfiniteLoader from 'react-window-infinite-loader'
import { ProductListingResultGridItem } from './ProductListingResultGridItem'

const useStyles = makeStyles((theme: Theme) => ({
    resultContainer: {
        width: '100%',
        paddingTop: theme.spacing(4),
        height: '100%',
        margin: 0,
        overflow: 'hidden',
        overflowY: 'auto',
        overscrollBehavior: 'none',
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
    },
}))

interface ProductListingResultGridProps {
    currentTotal: number
    searchLoading?: boolean
    searchFetchMoreLoading?: boolean
    products?: Partial<ListingMatch>[]
    handleLoadMore: (
        startIndex: number,
        stopIndex: number
    ) => Promise<any> | null
    hasNextPage: boolean
    isItemLoaded: (index: number) => boolean
    scrollState: {
        rowIndex: number
        columnIndex: number
    }
    setScrollRowAndColumn: (rowIndex: number, columnIndex: number) => void
    infiniteLoaderRef: React.Ref<any>
    setColumnCount: (value: number) => void
    // Enable only when in gatsby
    forGatsby?: boolean
}

// row height and column width takes into consideration the padding within the grid item
export const ROW_HEIGHT = 420
export const COLUMN_WIDTH = 370
export const PADDING = 24

export const ProductListingResultGrid: React.FC<ProductListingResultGridProps> = ({
    currentTotal,
    searchLoading,
    searchFetchMoreLoading,
    products = [],
    handleLoadMore,
    isItemLoaded,
    scrollState,
    setScrollRowAndColumn,
    hasNextPage,
    infiniteLoaderRef,
    setColumnCount,
}) => {
    const classes = useStyles()
    const itemCount = hasNextPage ? products.length + 1 : products.length

    const handleResize = ({ width }: Size) => {
        setColumnCount(Math.floor(width / COLUMN_WIDTH))
    }

    return (
        <>
            {!searchLoading && products.length === 0 ? (
                <GridContainer
                    spacing={1}
                    className={clsx(
                        'divProductListingNoResultGrid',
                        classes.resultContainer
                    )}
                    justifyContent="center"
                    alignContent="space-between"
                >
                    <GridItem>
                        <h3>No Results</h3>
                    </GridItem>
                </GridContainer>
            ) : (
                <AutoSizer
                    className="divProductListingResultGrid"
                    onResize={handleResize}
                >
                    {({ width, height }) => {
                        const gridHeight = height
                        const gridWidth = width

                        // Divide the width with the default column width to get the number of columns
                        const columnCount = Math.floor(gridWidth / COLUMN_WIDTH)

                        // Based on the columnCount
                        const calculatedGridWidth = columnCount * COLUMN_WIDTH

                        // Get the excess width
                        const excessWidth =
                            gridWidth - calculatedGridWidth - PADDING //padding on the right side
                        const excessWidthPerColumn = excessWidth / columnCount

                        // Add excess to default column width
                        const newColumnWidth =
                            COLUMN_WIDTH +
                            excessWidthPerColumn -
                            PADDING / columnCount // distribute left padding to each item

                        const newColumnHeight =
                            ROW_HEIGHT - PADDING / columnCount // distribute top padding to each item

                        // Divide the count with column count to get the items count or the row count
                        const listItemCount = Math.ceil(
                            currentTotal / columnCount
                        )

                        return (
                            <InfiniteLoader
                                ref={infiniteLoaderRef}
                                isItemLoaded={isItemLoaded}
                                itemCount={listItemCount}
                                loadMoreItems={handleLoadMore}
                                // get threshold based on the items rendered
                                threshold={(itemCount / columnCount) * 0.6}
                            >
                                {({ onItemsRendered, ref }) => (
                                    <FixedSizeGrid
                                        height={gridHeight}
                                        width={gridWidth}
                                        rowHeight={newColumnHeight}
                                        columnWidth={newColumnWidth}
                                        rowCount={listItemCount}
                                        columnCount={columnCount}
                                        initialScrollTop={
                                            ROW_HEIGHT * scrollState.rowIndex
                                        }
                                        onItemsRendered={({
                                            visibleRowStartIndex,
                                            visibleColumnStartIndex,
                                            visibleRowStopIndex,
                                            overscanRowStopIndex,
                                            overscanRowStartIndex,
                                        }) => {
                                            setScrollRowAndColumn(
                                                visibleRowStartIndex,
                                                visibleColumnStartIndex
                                            )
                                            onItemsRendered({
                                                overscanStartIndex: overscanRowStartIndex,
                                                overscanStopIndex: overscanRowStopIndex,
                                                visibleStartIndex: visibleRowStartIndex,
                                                visibleStopIndex: visibleRowStopIndex,
                                            })
                                        }}
                                        ref={ref}
                                        innerElementType={(props) => {
                                            return (
                                                <div
                                                    style={{
                                                        ...props.style,
                                                        paddingLeft: PADDING,
                                                        paddingTop: PADDING,
                                                    }}
                                                >
                                                    {props.children}
                                                </div>
                                            )
                                        }}
                                    >
                                        {({
                                            columnIndex,
                                            rowIndex,
                                            ...rest
                                        }) => {
                                            const count =
                                                rowIndex * columnCount +
                                                columnIndex
                                            const match = products[count]

                                            return (
                                                <ProductListingResultGridItem
                                                    {...rest}
                                                    match={match}
                                                    loaded={isItemLoaded(count)}
                                                    loading={
                                                        searchFetchMoreLoading ||
                                                        searchLoading
                                                    }
                                                />
                                            )
                                        }}
                                    </FixedSizeGrid>
                                )}
                            </InfiniteLoader>
                        )
                    }}
                </AutoSizer>
            )}
        </>
    )
}
