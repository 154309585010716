import { makeStyles, Theme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import clsx from 'clsx'
import { pxToRem } from 'components/Utilities/unitUtils'
import React from 'react'

const useStyles = makeStyles((theme: Theme) => ({
    sidePadding: {
        paddingLeft: theme.spacing(1.5),
        paddingRight: theme.spacing(1.5),
    },
    bodyGroup: { minHeight: 25 },
    bodyItemText: {
        fontSize: pxToRem(13),
        color: '#FFF',
        cursor: 'default',
        verticalAlign: 'middle',
    },
    bodyItemRightSideItem: {
        display: 'table-cell',
        verticalAlign: 'middle',
    },
}))

interface LeftSideListGroupProps {
    name: string
    children: React.ReactNode
}

export const LeftSideListGroup: React.FC<LeftSideListGroupProps> = ({
    name,
    children,
}) => {
    const classes = useStyles()
    return (
        <div>
            <div className={clsx(classes.sidePadding, classes.bodyGroup)}>
                <Typography
                    component="span"
                    className={clsx(
                        `lblGroupName`,
                        classes.bodyItemText,
                        classes.bodyItemRightSideItem
                    )}
                >
                    {name}
                </Typography>
            </div>
            {children}
        </div>
    )
}
