import Link from '@material-ui/core/Link'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import clsx from 'clsx'
import {
    ListingLevel,
    ManufacturerProductWebLinkDetail,
} from 'components/Models/product'
import GridContainer from 'components/Surfaces/GridContainer'
import GridItem from 'components/Surfaces/GridItem'
import { navigateToExternalLink } from 'components/Utilities/urlUtils'
import React from 'react'

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        maxWidth: 504,
    },
    fullWidth: {
        width: '100%',
    },
    title: {
        color: '#04325B',
        fontWeight: 500,
        borderBottom: '6px solid #04325B',
        textTransform: 'uppercase',
    },
    itemContainer: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    tableContainer: {
        maxHeight: 248,
        overflowY: 'auto',
        overflowX: 'hidden',
    },
    tableBorder: {
        border: '1px solid #C9C9C9',
    },
    itemLink: {
        maxWidth: 470,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        '&:hover': {
            cursor: 'pointer',
        },
    },
}))

interface LinkTableRowProps {
    name: string
    url: string
}

const LinkTableRow: React.FC<LinkTableRowProps> = ({ name, url }) => {
    const classes = useStyles()

    return (
        <TableRow className={clsx(classes.tableBorder, classes.fullWidth)}>
            <TableCell component="th" scope="row" className={classes.fullWidth}>
                <Link onClick={() => navigateToExternalLink(url)}>
                    <Typography
                        variant="h6"
                        className={clsx('webLinkName', classes.itemLink)}
                    >
                        {name}
                    </Typography>
                </Link>
            </TableCell>
        </TableRow>
    )
}

export interface ViewLinksProps {
    level?: ListingLevel
    webLinks?: Partial<ManufacturerProductWebLinkDetail>[]
}

const ViewLinks: React.FC<ViewLinksProps> = ({ level, webLinks }) => {
    const classes = useStyles()

    if (!level || !webLinks) return null
    if (webLinks.length === 0) return null
    if (
        webLinks.filter(
            (each) =>
                each.url !== '' &&
                each.url !== 'http://' &&
                each.url !== 'https://'
        ).length === 0
    ) {
        return null
    }

    return (
        <GridContainer data-testid="dvLinks" className={classes.container}>
            <GridItem className={classes.fullWidth}>
                <Typography variant="h6" className={classes.title}>
                    Links
                </Typography>
            </GridItem>
            <GridItem
                className={clsx(classes.itemContainer, classes.fullWidth)}
            >
                <div className={classes.tableContainer}>
                    <Table
                        className={clsx(classes.tableBorder, classes.fullWidth)}
                        size="small"
                    >
                        <TableBody className={classes.fullWidth}>
                            {webLinks?.map(
                                (
                                    each: Partial<ManufacturerProductWebLinkDetail>,
                                    index: number
                                ) => (
                                    <LinkTableRow
                                        key={index}
                                        name={each.type ?? ''}
                                        url={each.url ?? ''}
                                    />
                                )
                            )}
                        </TableBody>
                    </Table>
                </div>
            </GridItem>
        </GridContainer>
    )
}

export default ViewLinks
