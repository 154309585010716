import { Button, makeStyles, Theme, Typography } from '@material-ui/core'
import { EditOutlined } from '@material-ui/icons'
import clsx from 'clsx'
import GridItem from 'components/Surfaces/GridItem'
import React, { FC, useCallback } from 'react'

type Props = {
    propertyName: string
    isEditable?: boolean
    isHovered?: boolean
    onEditPropertyClick?: () => void
}

const useStyles = makeStyles((theme: Theme) => ({
    propContent: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        display: 'flex',
        flexDirection: 'row-reverse',
        justifyContent: 'space-between',
    },
    editPropertyButton: {
        visibility: 'hidden',
        alignSelf: 'flex-end',
        paddingRight: theme.spacing(1),
        marginRight: theme.spacing(2),
    },
    hovered: {
        visibility: 'visible',
        backgroundColor: 'none',
        cursor: 'pointer',
    },
}))

export const Propertyname: FC<Props> = ({
    propertyName,
    isEditable = false,
    isHovered = false,
    onEditPropertyClick,
}) => {
    const classes = useStyles()

    const noop = useCallback(() => {}, [])

    return (
        <GridItem className={classes.propContent} xs={12}>
            <Button
                className={clsx(classes.editPropertyButton, {
                    [classes.hovered]: isHovered && isEditable,
                })}
                onClick={
                    isHovered && !!onEditPropertyClick
                        ? onEditPropertyClick
                        : noop
                }
                data-testid="editPropertyButton"
            >
                <EditOutlined></EditOutlined>
            </Button>
            {!!propertyName && (
                <Typography
                    variant="h4"
                    component="span"
                    className={classes.propContent}
                >
                    {propertyName}
                </Typography>
            )}
        </GridItem>
    )
}
