import React from 'react'
import ReactDOM from 'react-dom'
import AppThemeConfiguration from '../Configurations/AppThemeConfiguration'
import { NavPromptMessageProps } from './NavPrompt'
import NavPromptDialogContent from './NavPromptDialogContent'

const NavPromptDialogContainer = (
    message: string,
    callback: (ok: boolean) => void
) => {
    const container = document.createElement('div') as HTMLDivElement
    container.setAttribute('id', 'specpointAlertDialogContainer')
    document.body.appendChild(container)

    const {
        title,
        preferredMessage,
        confirmText,
        cancelText,
        buttonColor,
        isImportTab,
    } = JSON.parse(message) as NavPromptMessageProps

    const handleOnClose = (callbackState: boolean) => {
        // have to unmount manually since this is higher than our application's reat component.
        ReactDOM.unmountComponentAtNode(container)
        document.body.removeChild(container)
        callback(callbackState)
    }

    ReactDOM.render(
        <AppThemeConfiguration>
            <NavPromptDialogContent
                title={title}
                isImportTab={isImportTab}
                preferredMessage={preferredMessage}
                confirmText={confirmText}
                cancelText={cancelText}
                buttonColor={buttonColor}
                onClose={handleOnClose}
            />
        </AppThemeConfiguration>,
        container
    )
}

export default NavPromptDialogContainer
