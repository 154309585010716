export const formatCityRegionPostalCode = ({
    city,
    region,
    postalCode,
}: {
    city?: string
    region?: string
    postalCode?: string
}): string => {
    if (!city && !region && !postalCode) return ''

    let toReturnCityRegionPostalCode = ''
    if (city) toReturnCityRegionPostalCode = `${city}`
    if (region)
        toReturnCityRegionPostalCode = toReturnCityRegionPostalCode
            ? `${toReturnCityRegionPostalCode}, ${region}`
            : `${region}`
    if (postalCode)
        toReturnCityRegionPostalCode = toReturnCityRegionPostalCode
            ? `${toReturnCityRegionPostalCode}, ${postalCode}`
            : `${postalCode}`

    return toReturnCityRegionPostalCode
}
