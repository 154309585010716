import { makeStyles, Theme } from '@material-ui/core/styles'
import {
    CreateCSSProperties,
    CSSProperties,
} from '@material-ui/core/styles/withStyles'
import { ListingMatch } from 'components/Models/listingSearch'
import { suffixDimension } from 'components/Utilities/stringUtils'
import React from 'react'
import { areEqual, ListChildComponentProps } from 'react-window'
import ProductListingCard from './ProductListingCard'
import { PADDING } from './ProductListingResultGrid'

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        display: 'flex',
        justifyContent: 'center',
    },
}))

const getFirmName = (name?: string) => {
    //string = display name - firm name
    if (name) {
        const names = name.split('%|%')
        if (names.length > 1) {
            if (names[0] === names[1]) {
                return names[0].slice(1, -1)
            }
            if (names[0] === 'null') {
                return names[1].slice(1, -1)
            }
            return `${names[0].slice(1, -1)} (${names[1].slice(1, -1)})`
        }
    }
    return name
}

interface ProductListingResultGridItemProps
    extends Partial<ListChildComponentProps> {
    loaded: boolean
    match?: Partial<ListingMatch>
    loading?: boolean
}

export const ProductListingResultGridItem: React.FC<ProductListingResultGridItemProps> = React.memo(
    ({ style, match, loaded, loading }) => {
        const classes = useStyles()

        return (
            <div
                style={
                    {
                        ...style,
                        left: style ? (style.left as number) + PADDING : 0,
                        top: style ? (style.top as number) + PADDING : 0,
                    } as CSSProperties | CreateCSSProperties<{}> | any
                }
                className={classes.container}
            >
                {loaded ? (
                    <ProductListingCard
                        firmId={match?.firmId}
                        firmName={getFirmName(match?.displayNameWithFirmName)}
                        categories={match?.categories ?? []}
                        firmLogoUrl={
                            match?.firmLogoUrl &&
                            suffixDimension({
                                filename: match?.firmLogoUrl,
                                dimension: {
                                    percentage: 25,
                                },
                            })
                        }
                        productName={match?.productName}
                        listingId={match?.listingId}
                        productType={match?.productType}
                        productTypeId={match?.specAgentProductTypeId}
                        isActive={match?.isActive}
                        publishDate={match?.lastUpdated}
                        submissionStatus={match?.submissionStatus}
                        familyName={match?.sectionName}
                        images={match?.images}
                        videos={match?.videos}
                        listingLevel={match?.type}
                        catalogs={match?.catalogs}
                        partnershipLinksBIM={match?.partnershipLinksBIM}
                        partnershipLinksSwatchbox={
                            match?.partnershipLinksSwatchbox
                        }
                        manufacturerDocs={match?.manufacturerDocs}
                        displayFirmName={match?.firmDisplayName}
                        canAutoGenerateProductSpecification={
                            match?.canAutoGenerateProductSpecification
                        }
                        hasManufacturerOptions={match?.hasManufacturerOptions}
                    />
                ) : loading ? (
                    <ProductListingCard loading={true} />
                ) : null}
            </div>
        )
    },
    areEqual
)
