import { GridSize } from '@material-ui/core'
import Link from '@material-ui/core/Link'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import clsx from 'clsx'
import HoverTooltip from 'components/Feedbacks/HoverTooltip'
import GridContainer from 'components/Surfaces/GridContainer'
import GridItem from 'components/Surfaces/GridItem'
import {
    CdnClientUrl,
    navigateToExternalLink,
} from 'components/Utilities/urlUtils'
import React, { useCallback } from 'react'

const useStyles = makeStyles((theme: Theme) => ({
    fullWidth: {
        width: '100%',
    },
    itemLeftSide: { width: 30 },
    itemRightSide: {
        width: 'calc(100% - 30px)',
        paddingLeft: theme.spacing(1),
    },
    itemLink: {
        '&:hover': {
            cursor: 'pointer',
        },
    },
    icon: {
        height: 30,
        width: 30,
    },
    iconFull: {
        height: 30,
    },
    itemLabel: {
        width: '100%',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
}))

export interface ViewPartnershipTypeProps {
    xs: GridSize
    label: string
    link?: string
}

const ViewPartnershipType: React.FC<ViewPartnershipTypeProps> = ({
    xs,
    label,
    link,
}) => {
    const classes = useStyles()

    const handleKeyDown = useCallback(
        (e) => {
            if (e.key === 'Enter' || e.code === 'Enter') {
                navigateToExternalLink(link!)
            }
        },
        [navigateToExternalLink]
    )

    if (link) {
        return label === 'Swatchbox' ? (
            <HoverTooltip text="Swatchbox - Samples">
                <GridContainer item xs={xs}>
                    <GridContainer
                        className={clsx(
                            'partnershipType',
                            classes.fullWidth,
                            classes.itemLink
                        )}
                        item
                        alignItems="center"
                        justifyContent="center"
                    >
                        <img
                            src={`${CdnClientUrl}/${label}_Full.png`}
                            className={classes.iconFull}
                            alt={label.toLowerCase().slice(0, 2)}
                            onClick={() => navigateToExternalLink(link!)}
                            onKeyDown={handleKeyDown}
                            role="button"
                            tabIndex={0}
                        />
                    </GridContainer>
                </GridContainer>
            </HoverTooltip>
        ) : (
            <GridContainer item xs={xs}>
                {label === 'BIMsmith' ? (
                    <HoverTooltip text="BIMsmith - Revit Families">
                        <GridContainer
                            className={classes.itemLeftSide}
                            item
                            alignItems="center"
                            justifyContent="center"
                        >
                            <img
                                src={`${CdnClientUrl}/${label}.png`}
                                className={classes.icon}
                                alt={label.toLowerCase().slice(0, 2)}
                            />
                        </GridContainer>
                    </HoverTooltip>
                ) : (
                    <GridContainer
                        className={classes.itemLeftSide}
                        item
                        alignItems="center"
                        justifyContent="center"
                    >
                        <img
                            src={`${CdnClientUrl}/${label}.png`}
                            className={classes.icon}
                            alt={label.toLowerCase().slice(0, 2)}
                        />
                    </GridContainer>
                )}
                <GridContainer
                    className={classes.itemRightSide}
                    item
                    alignItems="center"
                    justifyContent="flex-start"
                >
                    <GridItem className={classes.fullWidth}>
                        <Link
                            onClick={() => navigateToExternalLink(link!)}
                            onKeyDown={handleKeyDown}
                            role="button"
                            tabIndex={0}
                        >
                            <Typography
                                variant="h6"
                                className={clsx(
                                    'partnershipType',
                                    classes.itemLabel,
                                    classes.itemLink
                                )}
                            >
                                {label}
                            </Typography>
                        </Link>
                    </GridItem>
                </GridContainer>
            </GridContainer>
        )
    }
    return null
}

export default ViewPartnershipType
