import { MutationFunctionOptions } from '@apollo/client'
import Popover from '@material-ui/core/Popover'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import clsx from 'clsx'
import RoundButton from 'components/Buttons/RoundButton'
import {
    ShowGlobalSnackbarMutationProps,
    ShowGlobalSnackbarRequest,
} from 'components/Configurations/GlobalSnackbar'
import GridContainer from 'components/Surfaces/GridContainer'
import { ExecutionResult } from 'graphql/execution/execute'
import React from 'react'
import { ContentContainer } from './ContentContainer'
import {
    ProductTypesContext,
    ProductTypesProvider,
    ProductTypesProviderProps,
} from './ProductTypesFilterContext'

const useStyles = makeStyles((theme: Theme) => ({
    buttonText: {
        ...theme.typography.subtitle2,
        fontWeight: 'normal',
        lineHeight: '16px',
    },
    buttonTextFontWeight: {
        fontWeight: 500,
    },
    container: {
        border: '1px solid #C9C9C9',
        boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.3)',
        borderRadius: 5,
        padding: theme.spacing(4),
        paddingBottom: 0,
    },
}))

const closeColors = {
    border: '1px solid #02A09C',
    button: '#FFF',
    text: '#5E5F61',
}

const openColors = {
    border: '1px solid #02A09C',
    button: '#24CEAD',
    text: '#FFF',
}

const closeWithCountColors = {
    border: '2px solid #02A09C',
    button: '#D9F1F0',
    text: '#202020',
    hasFontWeight: true,
}

interface Colors {
    border: string
    button: string
    text: string
    hasFontWeight?: boolean
}

interface ProductTypesPopoverProps {
    open: boolean
    handleClose: VoidFunction
    popoverAnchorEl: Element | null | undefined
    children: React.ReactNode
}

const ProductTypesPopover: React.FC<ProductTypesPopoverProps> = ({
    open,
    handleClose,
    popoverAnchorEl,
    children,
}) => {
    const { selectedItems } = React.useContext(ProductTypesContext)
    const classes = useStyles()

    const [height, setHeight] = React.useState<number>(640)
    const [width, setWidth] = React.useState<number>(1375)

    const check = React.useCallback(() => {
        setHeight(window.innerHeight < 640 ? window.innerHeight * 0.9 : 640)
        setWidth(window.innerWidth < 1375 ? window.innerWidth * 0.9 : 1375)
    }, [])

    const onEvent = React.useCallback(() => {
        const interval = setTimeout(check, 200)
        return () => {
            clearInterval(interval)
        }
    }, [check])

    React.useEffect(check)

    React.useEffect(() => {
        window.addEventListener('resize', onEvent)
        return () => {
            window.removeEventListener('resize', onEvent)
        }
    }, [onEvent])

    return (
        <Popover
            open={open}
            onClose={handleClose}
            anchorEl={popoverAnchorEl}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: selectedItems.length > 0 ? -59 : -100,
            }}
            transformOrigin={{
                vertical: -7,
                horizontal: 'center',
            }}
        >
            <GridContainer
                direction="column"
                className={clsx(
                    'productTypeFilterContainer',
                    classes.container
                )}
                style={{ height, width }}
            >
                {children}
            </GridContainer>
        </Popover>
    )
}

interface ProductTypesFilterButtonProps {
    colors: Colors
    handleOpen: (event: React.MouseEvent<HTMLButtonElement>) => void
}

export const ProductTypesFilterButton: React.FC<ProductTypesFilterButtonProps> = ({
    colors,
    handleOpen,
}) => {
    const classes = useStyles()
    const { selectedItems } = React.useContext(ProductTypesContext)
    const countText =
        selectedItems.length > 0 ? ` (${selectedItems.length})` : ''

    return (
        <RoundButton
            backgroundColor={colors.button}
            border={colors.border}
            color={colors.text}
            onClick={handleOpen}
            disableElevation={true}
            size="large"
            hasShadow={false}
            hoverColor={true}
        >
            <Typography
                className={clsx(
                    classes.buttonText,
                    colors.hasFontWeight && classes.buttonTextFontWeight
                )}
                data-testid="btnLblProductTypes"
            >
                {`Product Types${countText}`}
            </Typography>
        </RoundButton>
    )
}

export interface ProductTypesFilterProps extends ProductTypesProviderProps {
    showGlobalSnackbar?: (
        options?:
            | MutationFunctionOptions<
                  ShowGlobalSnackbarRequest,
                  ShowGlobalSnackbarMutationProps
              >
            | undefined
    ) => Promise<ExecutionResult>
    expandMoreFilters: (value: boolean) => void
}

export const ProductTypesFilter: React.FC<ProductTypesFilterProps> = ({
    autoSuggestName,
    dataAutoSuggest,
    loadingAutoSuggest,
    handleAutoSuggestDropdown,
    onFilterInputChangeDebounced,
    onFilterChange,
    selectedItems,
    handelProductTypeSelection,
    productTypeSelections,
    expandMoreFilters,
    showGlobalSnackbar,
    dataSelectedProductType,
    loadingSelectedProductType,
    errorSelectedProductType,
    getListingProductTypeProperties,
    onSelected,
    setOnSelected,
}) => {
    const [colors, setColors] = React.useState<Colors>(closeColors)
    const [
        popoverAnchorEl,
        setPopoverAnchorEl,
    ] = React.useState<HTMLButtonElement | null>(null)

    const open = Boolean(popoverAnchorEl)

    const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
        setColors(openColors)
        setPopoverAnchorEl(event.currentTarget)
        expandMoreFilters(false)
    }

    const handleClose = () => {
        setColors(closeColors)
        setPopoverAnchorEl(null)
    }

    React.useMemo(() => {
        if (!open) {
            if (selectedItems?.length! > 0) {
                setColors(closeWithCountColors)
            } else {
                setColors(closeColors)
            }
        }
    }, [open, selectedItems])

    return (
        <ProductTypesProvider
            autoSuggestName={autoSuggestName}
            selectedItems={selectedItems}
            dataAutoSuggest={dataAutoSuggest}
            loadingAutoSuggest={loadingAutoSuggest}
            handleAutoSuggestDropdown={handleAutoSuggestDropdown}
            onFilterInputChangeDebounced={onFilterInputChangeDebounced}
            onFilterChange={onFilterChange}
            handelProductTypeSelection={handelProductTypeSelection}
            productTypeSelections={productTypeSelections}
            loadingSelectedProductType={loadingSelectedProductType}
            errorSelectedProductType={errorSelectedProductType}
            dataSelectedProductType={dataSelectedProductType}
            getListingProductTypeProperties={getListingProductTypeProperties}
            onSelected={onSelected}
            setOnSelected={setOnSelected}
        >
            <ProductTypesFilterButton handleOpen={handleOpen} colors={colors} />

            <ProductTypesPopover
                open={open}
                handleClose={handleClose}
                popoverAnchorEl={popoverAnchorEl}
            >
                <ContentContainer
                    handleClose={handleClose}
                    showGlobalSnackbar={showGlobalSnackbar}
                />
            </ProductTypesPopover>
        </ProductTypesProvider>
    )
}
