import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select/Select'
import makeStyles from '@material-ui/core/styles/makeStyles'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import clsx from 'clsx'
import React from 'react'
import { pxToRem } from '../Utilities/unitUtils'

const useStyles = makeStyles({
    simpleSelectFormControl: {
        minWidth: (props: SimpleSelectStyleProps) => props.minWidth,
        width: (props: SimpleSelectStyleProps) => props.width,

        '& > .MuiOutlinedInput-root': {
            borderColor: '#5E5F61',
            borderRadius: 8,
        },
    },
    selectDiv: {
        padding: (props: SimpleSelectStyleProps) => props.padding,
        fontSize: (props: SimpleSelectStyleProps) => pxToRem(props.fontSize),
    },
})

export interface SimpleSelectStyleProps {
    /** Select component width */
    width?: string | number

    /** Select component minimum width */
    minWidth?: string | number

    /** Select div padding */
    padding?: string | number

    /** Font size */
    fontSize?: number
}

export interface SimpleSelectProps extends Partial<SimpleSelectStyleProps> {
    /** List of select options */
    options: { display: React.ReactNode; value: any }[]
    /** Selected value  */
    value: any
    /** Event handler for value change */
    onValueChange?: (newValue: any) => void
    /** Enables/disables select component */
    disabled?: boolean
    /** Label of select */
    label?: string
}

/**
 *  Select component using MUI Select as base element
 */
const SimpleSelect: React.FC<SimpleSelectProps> = ({
    options = [],
    value,
    onValueChange,
    width = 'auto',
    minWidth = 180,
    disabled,
    padding = 12,
    fontSize = 18,
    label,
    ...rest
}) => {
    const classes = useStyles({ width, minWidth, padding, fontSize })

    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        onValueChange?.(event.target.value)
    }

    return (
        <FormControl
            className={clsx('simpleSelect', classes.simpleSelectFormControl)}
            {...rest}
        >
            {label && (
                <InputLabel className="simpleSelectLabel">{label}</InputLabel>
            )}
            <Select
                IconComponent={ExpandMoreIcon}
                onChange={handleChange}
                disabled={disabled}
                displayEmpty
                variant="outlined"
                classes={{
                    select: classes.selectDiv,
                }}
                value={value}
            >
                {options.map((menuItem) => (
                    <MenuItem key={menuItem.value} value={menuItem.value}>
                        {menuItem.display}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}

export default SimpleSelect
