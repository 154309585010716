import React from 'react'

import { Tooltip, TooltipProps } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import clsx from 'clsx'
import { pxToRem } from 'components/Utilities/unitUtils'

interface OptionTooltipProps extends TooltipProps {
    padding?: string | number
    delay?: number
    tooltipClass?: string
    tooltipArrowClass?: string
}

const useStyles = makeStyles((theme: Theme) => ({
    infoTooltip: {
        padding: (props: OptionTooltipProps) =>
            props.padding ? props.padding : theme.spacing(1),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        fontSize: pxToRem(14),
        fontWeight: 400,
        borderRadius: 4,
        backgroundColor: '#FFFFFF',
        border: 'none',
        color: '#000000',
        boxShadow: `0px 1px 4px 1px rgba(0, 0, 0, .2);`,
        top: theme.spacing(0.5),
        '&.MuiTooltip-tooltipPlacementBottom': {
            top: 3,
            '& .MuiTooltip-arrow': {
                '&::after': {
                    left: 24,
                },
            },
        },
    },
    infoTooltipArrow: {
        fontSize: pxToRem(17),
        width: pxToRem(17),
        '&::before': {
            border: '1px solid #f8f9fa',
            boxShadow: '0px 1px 4px 1px rgb(0 0 0 / 20%)',
            backgroundColor: '#FFFFFF',
            boxSizing: 'border-box',
        },
    },
}))

export const OptionTooltip: React.FC<OptionTooltipProps> = (props) => {
    const {
        children,
        delay,
        padding,
        tooltipClass,
        tooltipArrowClass,
        ...rest
    } = props
    const classes = useStyles(props)

    return (
        <Tooltip
            arrow
            enterDelay={delay}
            enterNextDelay={delay}
            TransitionProps={{
                timeout: 0,
            }}
            placement="bottom-end"
            classes={{
                tooltip: clsx(
                    tooltipClass && tooltipClass,
                    classes.infoTooltip
                ),
                arrow: clsx(
                    tooltipArrowClass && tooltipArrowClass,
                    classes.infoTooltipArrow
                ),
            }}
            {...rest}
        >
            {children}
        </Tooltip>
    )
}

export default OptionTooltip
