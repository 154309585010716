import { ApolloError, MutationFunctionOptions } from '@apollo/client'
import {
    ShowGlobalSnackbarMutationProps,
    ShowGlobalSnackbarRequest,
} from 'components/Configurations/GlobalSnackbar'
import { GetListingProductTypePropertiesInput } from 'components/Models/productType'
import { debounce } from 'components/Utilities/formUtils'
import { makeTitleCase } from 'components/Utilities/stringUtils'
import { ExecutionResult } from 'graphql/execution/execute'
import React from 'react'
import { removeFamilyNumberFromFamilyNameRegex } from '../..//productsUtils'
import {
    NormalizedProductType,
    NormalizedProductTypeProperties,
    NormalizedProductTypePropertyOption,
    NormalizedProductTypePropertyOptionGroup,
} from '../../model'
import { ContentComponent } from './ContentComponent'
import { ProductTypesContext } from './ProductTypesFilterContext'

export interface LeftSideProps {
    productTypeOptions: NormalizedProductType[]
    debounceInput: (value: string) => void
    setProductType: (value: NormalizedProductType) => void
    loadingSelectedProductType: boolean
}

export interface RightSideProps {
    selectedProductType: NormalizedProductType | undefined
    loadingSelectedProductType: boolean
    errorSelectedProductType?: Partial<ApolloError>
    onSelectProductTypeProperty: (propertyId: string, optionId: string) => void
    propertiesSelectedCount: number
}

export interface ContentContainerProps {
    handleClose: VoidFunction
    showGlobalSnackbar?: (
        options?:
            | MutationFunctionOptions<
                  ShowGlobalSnackbarRequest,
                  ShowGlobalSnackbarMutationProps
              >
            | undefined
    ) => Promise<ExecutionResult>
}

const productTypeSelected = (productTypeSelections: NormalizedProductType[]) =>
    productTypeSelections?.filter((each) => each.selected)?.[0]

const propertiesSelectedCount = (
    productTypeSelections: NormalizedProductType[],
    loadingSelectedProductType: boolean
) => {
    const selected = productTypeSelected(productTypeSelections)
    if (loadingSelectedProductType || !selected?.properties) {
        return 0
    }

    return (
        selected.properties
            ?.map(
                (each: Partial<NormalizedProductTypeProperties>) =>
                    each.optionGroups?.map(
                        (
                            eachOptionGroup: Partial<NormalizedProductTypePropertyOptionGroup>
                        ) =>
                            eachOptionGroup.options?.filter(
                                (
                                    eachOption: Partial<NormalizedProductTypePropertyOption>
                                ) => eachOption.selected
                            )?.length
                    )?.length
            )
            ?.reduce(
                (prev: number, curr: number) => (prev || 0) + (curr || 0),
                0
            ) || 0
    )
}

const getSectionNameAndProductType = (value: string) =>
    value ? value.split('%|%').map((each) => each.slice(1, -1)) : ''

export const ContentContainer: React.FC<ContentContainerProps> = ({
    handleClose,
}) => {
    const {
        dataAutoSuggest,
        loadingAutoSuggest,
        handleAutoCompleteInputChange,
        handleProductTypeChange,
        productTypeSelections,
        loadingSelectedProductType,
        errorSelectedProductType,
        dataSelectedProductType,
        getListingProductTypeProperties,
        onSelected,
        setOnSelected,
    } = React.useContext(ProductTypesContext)

    const [productTypeOptions, setProductTypeOptions] = React.useState<
        NormalizedProductType[]
    >([])

    // Set actively selected product type (Left Side)
    const setProductType = React.useCallback(
        (value: NormalizedProductType) => {
            if (value.selected && !!!value.loaded) {
                getListingProductTypeProperties({
                    variables: {
                        input: {
                            productType: value.productTypeName,
                            sectionName: value.sectionName,
                        } as GetListingProductTypePropertiesInput,
                    },
                })
            }
            handleProductTypeChange(value)
        },
        [getListingProductTypeProperties, handleProductTypeChange]
    )

    // Search (Left Side)
    const debouncedAutoCompleteInput = React.useCallback(
        debounce((value: string) => {
            handleAutoCompleteInputChange(value)
        }),
        []
    )

    // Called when option is selected (Right Side)
    const onSelectProductTypeProperty = (
        propertyId: string,
        optionId: string
    ) => {
        const selected = productTypeSelected(productTypeSelections)

        const newProperties = selected?.properties?.map((each) => {
            if (each.propertyId === propertyId) {
                const optionGroups = each.optionGroups?.map((each) => {
                    const options = each.options?.map((each) =>
                        each.optionId === optionId
                            ? {
                                  ...each,
                                  selected: !each.selected,
                              }
                            : each
                    )
                    return {
                        ...each,
                        options,
                    }
                })

                return {
                    ...each,
                    optionGroups,
                }
            }
            return each
        })

        handleProductTypeChange({
            ...selected,
            properties: newProperties,
            productTypeProperties: newProperties
                ?.map((each) => ({
                    propertyName: each?.propertyName!,
                    propertyOptionIds:
                        each.optionGroups
                            ?.flatMap((each) =>
                                each.options?.filter((each) => each.selected)
                            )
                            .map((each) => each?.optionId ?? '') || [],
                }))
                .filter((each) => (each.propertyOptionIds ?? []).length > 0),
        } as NormalizedProductType)
    }

    // Clear Product Types selected (Left Side)
    const handleClearProductTypeProperty = () => {
        const selected = productTypeSelected(productTypeSelections)
        const newProperties = selected?.properties?.map(
            (each: Partial<NormalizedProductTypeProperties>) => {
                const optionGroups = each.optionGroups?.map(
                    (
                        eachOptionGroup: Partial<NormalizedProductTypePropertyOptionGroup>
                    ) => ({
                        ...eachOptionGroup,
                        options: eachOptionGroup.options?.map(
                            (
                                eachOption: Partial<NormalizedProductTypePropertyOption>
                            ) => ({
                                ...eachOption,
                                selected: false,
                            })
                        ),
                    })
                )
                return {
                    ...each,
                    optionGroups,
                }
            }
        )

        handleProductTypeChange({
            ...selected,
            properties: newProperties,
            productTypeProperties: [],
        } as NormalizedProductType)
    }

    // Values not in option will be populated
    React.useMemo(() => {
        if (!loadingAutoSuggest && dataAutoSuggest) {
            let options = dataAutoSuggest.map((each) => {
                const values = getSectionNameAndProductType(each)
                return {
                    name: each,
                    value: each,
                    sectionNameWithoutNmber: values[0]?.replace(
                        removeFamilyNumberFromFamilyNameRegex,
                        ''
                    ),
                    selected: false,
                    sectionName: values[0],
                    productTypeName: values[1],
                }
            }) as NormalizedProductType[]

            options.sort(
                (a, b) =>
                    -b
                        .productTypeName!.trim()
                        .toLocaleLowerCase()
                        .localeCompare(
                            a.productTypeName!.trim().toLocaleLowerCase()
                        )
            )

            setProductTypeOptions(options)
        }
    }, [dataAutoSuggest, loadingAutoSuggest])

    // This effect will run when the selected product type is not loaded
    // yet and if there is a response
    React.useEffect(() => {
        if (
            productTypeSelections?.some((each) => each.selected) &&
            dataSelectedProductType &&
            !loadingSelectedProductType &&
            onSelected
        ) {
            const selected = productTypeSelected(productTypeSelections)
            setOnSelected(false)
            handleProductTypeChange({
                ...selected,
                properties:
                    dataSelectedProductType?.listingsGetListingProductTypeProperties?.properties?.map(
                        (eachProperties) => {
                            const levels = eachProperties.propertyName?.split(
                                '|'
                            )
                            const property = selected.productTypeProperties?.filter(
                                (each) =>
                                    each.propertyName ===
                                    eachProperties.propertyName
                            )?.[0]

                            let propertyTypeLevel = makeTitleCase(
                                levels?.[0] || ''
                            )
                                .split('<')[0] //Removes any <Insert Note> from the pt
                                .trim()

                            if ((levels || []).length > 1) {
                                propertyTypeLevel =
                                    levels
                                        ?.slice(0, levels.length - 1)
                                        ?.map((each) =>
                                            each
                                                .split(' ')
                                                .map((each) => each)
                                                .join(' ')
                                                .split('<')[0] //Removes any <Insert Note> from the pt
                                                .trim()
                                        )
                                        .join(' / ') || ''
                            }
                            return {
                                options:
                                    eachProperties.options?.map(
                                        (eachOption) => ({
                                            ...eachOption,
                                            selected:
                                                property?.propertyOptionIds?.some(
                                                    (each) =>
                                                        each ===
                                                        eachOption.value
                                                ) || false,
                                        })
                                    ) || [],
                                optionGroups:
                                    eachProperties.optionGroups?.map(
                                        (eachOptionGroup) => ({
                                            ...eachOptionGroup,
                                            options: eachOptionGroup.options?.filter(
                                                (option) => !option?.isOther
                                            ),
                                        })
                                    ) || [],
                                propertyId: eachProperties.propertyId ?? '',
                                propertyName: eachProperties.propertyName ?? '',
                                propertyTypeLevel,
                                propertyTypeLevelName: levels
                                    ? `${levels[levels.length - 1]
                                          .split('<')[0] //Removes any <Insert Note> from the pt
                                          .trim()}:`
                                    : '',
                            }
                        }
                    ) || [],
                categories:
                    dataSelectedProductType
                        ?.listingsGetListingProductTypeProperties?.categories ||
                    [],
                loaded: true,
            })
        }
    }, [
        loadingSelectedProductType,
        dataSelectedProductType,
        handleProductTypeChange,
        productTypeSelections,
        onSelected,
        setOnSelected,
    ])

    // This will set the selected the selected product type (Left Side)
    React.useEffect(() => {
        if (
            productTypeSelections?.length === 1 &&
            !productTypeSelections[0].selected
        ) {
            setProductType({ ...productTypeSelections[0], selected: true })
        }
        if (
            productTypeSelections?.length > 1 &&
            !productTypeSelections.some((each) => each.selected)
        ) {
            setProductType({ ...productTypeSelections[0], selected: true })
        }
    }, [productTypeSelections, setProductType])

    return (
        <ContentComponent
            debounceInput={debouncedAutoCompleteInput}
            productTypeOptions={productTypeOptions}
            setProductType={setProductType}
            handleClose={handleClose}
            selectedProductType={productTypeSelected(productTypeSelections)}
            loadingSelectedProductType={loadingSelectedProductType}
            errorSelectedProductType={errorSelectedProductType}
            onSelectProductTypeProperty={onSelectProductTypeProperty}
            handleClearProductTypeProperty={handleClearProductTypeProperty}
            propertiesSelectedCount={propertiesSelectedCount(
                productTypeSelections,
                loadingSelectedProductType
            )}
        />
    )
}
