import { ListingMatch } from 'components/Models/listingSearch'
import { newGuid } from 'components/Utilities/GuidGenerator'
import { sortList } from './productsUtils'

export type ListingMatchLeaf = Partial<ListingMatch> & {
    isLeaf: boolean
    id?: string
    groupedProduct?: Partial<ListingMatch>[]
}

export interface TreeNodeBase {
    [key: string]: TreeNodeBase | ListingMatchLeaf
}

export type TreeNode = TreeNodeBase & {
    name?: string
    count: number
    id: string
    groupName?: string
    isCollapsed?: boolean
}

export interface AccordionWindowBase {
    listings: Partial<ListingMatch>[]
    groupBy: Record<string, boolean | string>
    listingCount?: number
}
export const MAX_ITEMS_PER_PAGE = 200

export const getFirmName = (name?: string) => {
    if (name) {
        const names = name.split('%|%')
        if (names.length > 1) {
            if (names[0] === names[1]) {
                return names[0].slice(1, -1)
            }
            if (names[0] === 'null') {
                return names[1].slice(1, -1)
            }
            return `${names[0].slice(1, -1)} (${names[1].slice(1, -1)})`
        }
    }
    return name
}
export const extractListsFromTree = (childNodeObj: TreeNode) => {
    const allListings = [] as Partial<ListingMatchLeaf>[]
    Object.keys(childNodeObj).forEach((key) => {
        if (
            key !== 'name' &&
            key !== 'count' &&
            key !== 'id' &&
            key !== 'isCollapsed' &&
            key !== 'groupName'
        ) {
            allListings.push(childNodeObj[key])
        }
    })
    return sortList([...allListings], '', true)
}
export const DEFAULT_GROUP_OF = 5
export const groupChildNodes = (childNodeObj: TreeNode) => {
    const parentName = childNodeObj.id
    const totalSiblings = childNodeObj.count
    let groupedObj: Record<string, ListingMatchLeaf> = {}
    let listArr: Partial<ListingMatch>[] = []
    let counter = 0
    const groupof = totalSiblings || DEFAULT_GROUP_OF

    const extractedLists = extractListsFromTree({ ...childNodeObj })
    extractedLists.forEach((list) => {
        listArr.push(list)
        if (
            listArr.length === groupof ||
            (totalSiblings !== groupof &&
                totalSiblings - counter === listArr.length)
        ) {
            const groupKey = parentName + '_' + counter
            groupedObj[groupKey] = {
                isLeaf: true,
                groupedProduct: [...listArr],
                id: groupKey,
            }
            counter = counter + listArr.length
            listArr = []
        }
    })

    return {
        name: childNodeObj.name,
        count: totalSiblings,
        id: childNodeObj.id,
        isCollapsed: childNodeObj.isCollapsed,
        groupName: childNodeObj.groupName,
        ...groupedObj,
    }
}

export const groupByProduct = (
    obj: TreeNode | Record<string, Partial<TreeNode> | any>
) => {
    let objects: Record<string, TreeNode>[] = []
    obj &&
        Object.keys(obj).forEach((key) => {
            if (!obj.hasOwnProperty(key)) return
            if (obj[key] && typeof obj[key] === 'object') {
                const isLeafParent =
                    Object.keys(obj[key]).filter((k) =>
                        obj[key]?.[k]?.hasOwnProperty('isLeaf')
                    ).length > 0
                if (!isLeafParent) {
                    objects = objects.concat(
                        (groupByProduct(obj[key]) as unknown) as Record<
                            string,
                            TreeNode
                        >[]
                    )
                } else obj[key] = { ...groupChildNodes(obj[key]) }
            }
        })
    return obj
}

export const getGroupByData = (groupByData: string[]) => {
    const sectionIndex = groupByData.indexOf('sectionNumber')
    groupByData[sectionIndex] = 'sectionName'
    return groupByData
}

export const getGroupBy = (
    groupByData: string[],
    groupByObj: Record<string, string | boolean>
) => {
    let groupBy: Record<string, string | boolean> = {}
    groupByData.forEach((g, i) => {
        groupBy[g] = Object.values(groupByObj)[i]
    })
    return groupBy
}

const getTotalCount = (currentTreeNode: TreeNode) => {
    return (
        Object.keys(currentTreeNode).filter(
            (key) =>
                key !== 'name' &&
                key !== 'count' &&
                key !== 'id' &&
                key !== 'isCollapsed' &&
                key !== 'groupName'
        ).length || 1
    )
}

export const getGroupedData = (
    listings: Partial<ListingMatch>[],
    groupByData: string[],
    groupBy: Record<string, string | boolean>
) => {
    return listings.reduce((listingPrevValue, listingCurrentValue) => {
        let currentTreeNode: TreeNode | undefined
        // if 2nd arg has keys
        const groupByLength = groupByData.length
        //iterate over all the keys
        groupByData.forEach((id, idx) => {
            //check if the current value contains value for the key
            let groupByValue =
                listingCurrentValue[id as keyof ListingMatch]?.toString() ??
                'Undefined'
            // if key is `lastUpdated`
            if (id === 'lastUpdated') {
                groupByValue = groupByValue.split('-').slice(0, 2).join('-')
            } else if (id === 'isActive') {
                groupByValue = groupByValue === 'true' ? 'Active' : 'Inactive'
            }
            // get the value from the passed object for the key
            const collapseFlag = groupBy[id]

            if (idx === 0) {
                currentTreeNode = listingPrevValue[groupByValue] as TreeNode
                if (!currentTreeNode) {
                    currentTreeNode = {
                        name: groupByValue,
                        id: newGuid(),
                        isCollapsed: collapseFlag,
                        groupName: id,
                    } as TreeNode
                }

                if (groupByLength - 1 === idx) {
                    currentTreeNode[listingCurrentValue.listingId ?? ''] = {
                        ...listingCurrentValue,
                        isLeaf: true,
                        id: listingCurrentValue.listingId ?? '',
                    }
                }
                currentTreeNode.count = getTotalCount(currentTreeNode)

                listingPrevValue[groupByValue] = currentTreeNode

                return
            }

            //if current node contains the value for the key, return {name, id, isCollapsed, groupName }
            let childNode = (currentTreeNode?.[groupByValue] ?? {
                name: groupByValue,
                id: newGuid(),
                isCollapsed: collapseFlag,
                groupName: id,
            }) as TreeNode

            if (groupByLength - 1 === idx) {
                childNode[listingCurrentValue.listingId ?? ''] = {
                    ...listingCurrentValue,
                    isLeaf: true,
                    id: listingCurrentValue.listingId ?? '',
                }
            }
            childNode.count = getTotalCount(childNode)
            currentTreeNode![groupByValue] = childNode
            currentTreeNode!.count = getTotalCount(currentTreeNode!)
            // pass the new childnode to the currentTreeNode for the next loop
            currentTreeNode = childNode
        })
        return listingPrevValue
    }, {} as TreeNode)
}
export const groupByListings = ({
    listings,
    groupBy: groupByObj,
    listingCount = MAX_ITEMS_PER_PAGE + 1,
}: AccordionWindowBase) => {
    let groupBy = { ...groupByObj } // object passed as argument
    let groupByData = Object.keys(groupByObj) // array of keys passed as 2nd argument
    if (groupByData.includes('sectionNumber')) {
        groupByData = getGroupByData(groupByData)
        groupBy = getGroupBy(groupByData, groupByObj)
    }
    // using the 1st argument
    const groupedData = getGroupedData(listings, groupByData, groupBy)
    const lastGroupName = Object.keys(groupedData)[
        Object.keys(groupedData).length - 1
    ]
    const lastGroupObj = { ...groupedData[lastGroupName] } as
        | TreeNode
        | Record<string, TreeNode>
    if (
        groupBy[groupByData[0]] &&
        listings.length &&
        listingCount > MAX_ITEMS_PER_PAGE
    ) {
        lastGroupObj.isCollapsed = false
        groupedData[lastGroupName] = { ...lastGroupObj }
    }
    return (
        (Object.keys(groupedData).length &&
            groupByProduct({ ...groupedData })) || { ...listings }
    )
}
