import { makeStyles, Theme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Skeleton from '@material-ui/lab/Skeleton'
import clsx from 'clsx'
import { ListingLevel } from 'components/Models/product'
import React from 'react'

const useStyles = makeStyles((theme: Theme) => ({
    listingTypeContainer: {
        display: 'flex',
        alignItems: 'center',
        '& > svg': {
            marginLeft: -theme.spacing(0.375),
            marginRight: theme.spacing(1.3),
        },
        color: '#FFFFFF',
    },
}))

export interface ProductListingCard {
    variant?: ListingLevel
    loading?: boolean
}

const ProductListingCardType: React.FC<ProductListingCard> = ({
    variant = ListingLevel.Manufacturer,
    loading,
}) => {
    const classes = useStyles()

    if (!loading) {
        return (
            <Typography
                className={clsx(
                    'productListingType',
                    classes.listingTypeContainer
                )}
            >
                {`${variant}`}
            </Typography>
        )
    } else {
        return (
            <Skeleton
                className="loadingSkeleton"
                variant="text"
                animation="pulse"
                width={150}
            />
        )
    }
}

export default ProductListingCardType
