import { ApolloError } from '@apollo/client'

/**
 * This summarizes all apollo error in one string separated by a pipe.
 * @param apolloError The error you receive from apollo
 * @param defaultErrorMessage Default error just in case a network error is undefined
 */
export const summarizeApolloError = (
    apolloError: ApolloError,
    defaultErrorMessage: string
) =>
    apolloError.graphQLErrors.length > 0
        ? apolloError.graphQLErrors.map((error) => error.message).join('|')
        : apolloError.networkError?.message ?? defaultErrorMessage

export const customDataIdFromObject = (object: any, key: string) => {
    return `${object.__typename}:${object[key]}`
}
