import { makeStyles, Theme, TypographyVariant } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import HoverTooltip from 'components/Feedbacks/HoverTooltip'
import GridContainer from 'components/Surfaces/GridContainer'
import React from 'react'

interface ViewDetailStyleProps {
    lefSideFontWeight?: number
    fontSize?: number
}

const useStyles = makeStyles((theme: Theme) => ({
    typoContainer: {
        display: 'flex',
        alignItems: 'baseline',
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(1),
    },
    leftSide: {
        color: '#242424',
        fontWeight: (props: ViewDetailStyleProps) => props.lefSideFontWeight,
        marginRight: theme.spacing(0.5),
    },
    rightSide: {
        textTransform: 'capitalize',
        color: '#242424',
        fontWeight: 400,
        overflowWrap: 'break-word',
        wordWrap: 'break-word',
        '-ms-word-break': 'break-all',
        wordBreak: 'break-word',
        '-ms-hyphens': 'auto',
        '-moz-hyphens': 'auto',
        '-webkit-hyphens': 'auto',
        hyphens: 'auto',
    },
}))

interface ViewDetailProps extends ViewDetailStyleProps {
    name: string
    value?: string
    variant?: TypographyVariant
    tooltipText?: string
}

/**
 * View Detail
 * Displays detail with name on left side and value on right side
 * @param name
 * @param value
 * @param variant
 * @param lefSideFontWeight
 */

const ViewDetail: React.FC<ViewDetailProps> = ({
    name,
    value,
    variant = 'h4',
    lefSideFontWeight = 700,
    tooltipText,
    fontSize,
}) => {
    const classes = useStyles({ lefSideFontWeight })
    return (
        <GridContainer item className={classes.typoContainer}>
            <Typography
                variant={variant}
                component="span"
                data-testid="lblDetailName"
                className={classes.leftSide}
            >
                {name}:
            </Typography>
            <HoverTooltip
                text={tooltipText ?? ''}
                color="#242424"
                fontSize={fontSize}
            >
                <Typography
                    variant={variant}
                    component="span"
                    data-testid="lblDetailValue"
                    className={classes.rightSide}
                >
                    {value ? value : 'N/A'}
                </Typography>
            </HoverTooltip>
        </GridContainer>
    )
}

export default ViewDetail
