import Link from '@material-ui/core/Link'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import { NormalizedProductType } from '../model'

const useStyles = makeStyles({
    clearAllTitle: {
        fontSize: '1.3rem',
        fontWeight: 500,
        marginLeft: 7,
        marginRight: 7,
        cursor: 'pointer',
    },
})

const emptySelectionObject: Record<string, string[]> = {
    categories: [],
    listingLevels: [],
    partnerships: [],
    submissionStatus: [],
    isActive: ['true'], //#24625 default filter should be always active, so in this case isActive property alwasys true
    displayNameWithFirmName: [],
    sectionNames: [],
    productTypeWithSectionNames: [],
}
const emptyInputSelectionObject: Record<string, Date | string | null> = {
    lastUpdatedEndDate: null,
    lastUpdatedStartDate: null,
    lastUpdatedInPastDays: null,
}

interface ClearAllFilterProps {
    selectedItems?: Record<string, string[]> | undefined
    onFilterSelectionChange?: (
        selection: Record<string, string[]> | undefined
    ) => void
    setSelection: (selection: Record<string, string[]> | undefined) => void
    emptyInputSelection?: Record<string, Date | string | null> | undefined
    handleEmptyInputSelection?: (
        selection: Record<string, Date | string | null> | undefined
    ) => void
    expandMoreFilters?: (val: boolean) => void
    productTypeSelections?: NormalizedProductType[]
    handelProductTypeSelection?: (values: NormalizedProductType[]) => void
    assignedFirm?: string
    clearAllHandler?: () => void
}

const ClearAllFilter: React.FC<ClearAllFilterProps> = ({
    selectedItems,
    onFilterSelectionChange,
    setSelection,
    emptyInputSelection,
    handleEmptyInputSelection,
    expandMoreFilters,
    productTypeSelections,
    handelProductTypeSelection,
    assignedFirm,
    clearAllHandler,
}) => {
    const classes = useStyles()

    const [
        isAnyFilterSelected,
        setIsAnyFilterSelected,
    ] = React.useState<boolean>(false)

    React.useEffect(() => {
        if (selectedItems && emptyInputSelection) {
            const newSelectedItems = { ...selectedItems }

            delete newSelectedItems.isActive

            let hasSelectedItems = !Object.values(newSelectedItems).every(
                (cat: string[]) => !cat.length
            )

            if (!hasSelectedItems) {
                hasSelectedItems = !Object.values(emptyInputSelection).every(
                    (cat: string | Date | null) => !cat
                )
            }

            if (!hasSelectedItems) {
                hasSelectedItems = (productTypeSelections ?? []).length > 0
            }

            setIsAnyFilterSelected(hasSelectedItems)
        }
    }, [selectedItems, emptyInputSelection, productTypeSelections])

    const clearAllSelectedFilters = () => {
        if (clearAllHandler) {
            clearAllHandler()
            return
        }
        if (
            !selectedItems ||
            !onFilterSelectionChange ||
            !handleEmptyInputSelection ||
            !expandMoreFilters
        ) {
            return
        }

        let newSelectionItems: Record<string, string[]> = {
            ...emptySelectionObject,
            displayNameWithFirmName: assignedFirm ? [assignedFirm] : [],
        }

        newSelectionItems = {
            ...newSelectionItems,
            isActive: ['true'],
        }

        handleEmptyInputSelection(emptyInputSelectionObject)
        onFilterSelectionChange(newSelectionItems)
        setSelection(newSelectionItems)
        expandMoreFilters(false)
        handelProductTypeSelection?.([])
    }

    return (
        <>
            {isAnyFilterSelected && (
                <Link
                    data-testid={'clear-all-testid'}
                    underline={'none'}
                    onClick={clearAllSelectedFilters}
                    className={classes.clearAllTitle}
                >
                    Clear All
                </Link>
            )}
        </>
    )
}

export default ClearAllFilter
