import { Popover } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import clsx from 'clsx'
import GridContainer from 'components/Surfaces/GridContainer'
import GridItem from 'components/Surfaces/GridItem'
import { pxToRem } from 'components/Utilities/unitUtils'
import React from 'react'
import { MoreFilterContext } from './Context/MoreFilterContext'
import MoreFilterCategories from './MoreFilterCategories'

const useStyles = makeStyles((theme: Theme) => ({
    moreFilterBlock: {
        borderBottom: '1px solid #05666D',
        height: '57px',
    },
    moreFilterTitle: {
        width: '100%',
        color: '#217279',
        lineHeight: '57px',
        paddingLeft: theme.spacing(3),
        fontSize: '2.4rem',
    },
    moreFilterBackground: {
        background: '#EFEDED',
        borderBottom: '1px solid #05666D',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        border: '1px solid #C9C9C9',
    },
    filterFooter: {
        borderTop: '1px solid #05666D',
        padding: theme.spacing(2),
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    filterFooterButtons: {
        fontWeight: 'normal',
        borderRadius: '100px',
        height: '38px',
        textTransform: 'capitalize',
    },
    filterButtonColor: {
        background: '#05686F',
        color: '#FFFFFF',
        width: '83px',
        '&:hover': {
            //you want this to be the same as the backgroundColor above
            backgroundColor: '#05686F',
        },
    },
    clearFilterButton: {
        color: '#5E5F61',
        width: '110px',
        marginRight: '10px',
        border: '1px solid #5E5F61',
    },
    filterResult: {
        paddingLeft: theme.spacing(2),
        color: '#5E5F61',
        [theme.breakpoints.down('sm')]: {
            marginBottom: '8px',
        },
    },
    moreFilterFooterButton: {
        paddingRight: theme.spacing(2),
    },
    filtersPopover: {
        marginTop: pxToRem(144),
        maxWidth: pxToRem(1967),
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    filterPopoverPaper: {
        width: '100%',
        maxWidth: 'inherit',
        left: 'unset !important',
    },
}))

const emptyInputSelectionObject: Record<string, Date | string | null> = {
    lastUpdatedEndDate: null,
    lastUpdatedStartDate: null,
    lastUpdatedInPastDays: null,
}

interface MoreFiltersProps {
    expandMoreFilters: (val: boolean) => void
    selectedItems?: Record<string, string[]> | undefined
    /** Called when the user changes their filter selection (e.g., by selecting an item in a filter,
     * unselecting an item, clearing a filter, or clearing all filters). */
    onFilterSelectionChange?: (
        selection: Record<string, string[]> | undefined
    ) => void
    setSelection: (selection: Record<string, string[]> | undefined) => void
    handleEmptyInputSelection?: (
        selection: Record<string, Date | string | null> | undefined
    ) => void
    emptyInputSelection?: Record<string, Date | string | null> | undefined
    resultCount: number
    filterDisabledValues?: Record<string, string[]>
    showSubmissionStatus?: boolean
    anchorElement?: any
    showSwatchboxFilter?: boolean
}

const MoreFilters: React.FC<MoreFiltersProps> = ({
    expandMoreFilters,
    selectedItems,
    onFilterSelectionChange,
    setSelection,
    handleEmptyInputSelection,
    emptyInputSelection,
    filterDisabledValues,
    resultCount,
    showSubmissionStatus,
    anchorElement,
    showSwatchboxFilter,
}) => {
    const classes = useStyles()
    const closeMoreFilters = (_event: React.MouseEvent<HTMLButtonElement>) => {
        expandMoreFilters(false)
    }

    const clearAllMoreFilters = (
        _event: React.MouseEvent<HTMLButtonElement>
    ) => {
        if (
            !onFilterSelectionChange ||
            !handleEmptyInputSelection ||
            !setSelection
        ) {
            return
        }
        const clearMoreFilterItems: Record<string, string[]> = {
            ...selectedItems,
            partnerships: [],
            submissionStatus: [],
            isActive: ['true'],
            additionalContent: [],
            isDeltekWriterDraft: [],
        }
        onFilterSelectionChange(clearMoreFilterItems)
        handleEmptyInputSelection(emptyInputSelectionObject)
        setSelection(clearMoreFilterItems)
    }
    return (
        <Popover
            open={true}
            anchorEl={anchorElement}
            classes={{
                root: classes.filtersPopover,
                paper: classes.filterPopoverPaper,
            }}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
        >
            <GridContainer
                direction="column"
                className={classes.moreFilterBackground}
            >
                <GridItem className={classes.moreFilterBlock}>
                    <Typography
                        variant="h4"
                        className={classes.moreFilterTitle}
                    >
                        More Filters
                    </Typography>
                </GridItem>
                <GridItem>
                    <GridContainer direction="column">
                        <MoreFilterContext.Provider
                            value={{
                                selectedItems,
                                onFilterSelectionChange,
                                setSelection,
                                handleEmptyInputSelection,
                                emptyInputSelection,
                            }}
                        >
                            <MoreFilterCategories
                                showSubmissionStatus={showSubmissionStatus}
                                filterDisabledValues={filterDisabledValues}
                                showSwatchboxFilter={showSwatchboxFilter}
                            />
                        </MoreFilterContext.Provider>
                    </GridContainer>
                </GridItem>
                <GridItem>
                    <GridContainer
                        className={classes.filterFooter}
                        direction="row"
                    >
                        <GridItem>
                            <Typography
                                variant="body2"
                                className={classes.filterResult}
                            >
                                Results: {resultCount}
                            </Typography>
                        </GridItem>
                        <GridItem className={classes.moreFilterFooterButton}>
                            <Button
                                variant="contained"
                                className={clsx(
                                    classes.clearFilterButton,
                                    classes.filterFooterButtons
                                )}
                                onClick={clearAllMoreFilters}
                                data-testid={`clear-filter-button`}
                            >
                                Clear Filters
                            </Button>
                            <Button
                                variant="contained"
                                className={clsx(
                                    classes.filterButtonColor,
                                    classes.filterFooterButtons
                                )}
                                onClick={closeMoreFilters}
                                data-testid={`close-filter-button`}
                            >
                                Close
                            </Button>
                        </GridItem>
                    </GridContainer>
                </GridItem>
            </GridContainer>
        </Popover>
    )
}

export default MoreFilters
