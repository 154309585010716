import IconButton from '@material-ui/core/IconButton'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import GridContainer from 'components/Surfaces/GridContainer'
import GridItem from 'components/Surfaces/GridItem'
import { pxToRem } from 'components/Utilities/unitUtils'

const useStyles = makeStyles((theme: Theme) => ({
    headerTitle: {
        display: 'inline-flex',
        color: '#04325b',
        fontSize: pxToRem(20),
        fontWeight: 500,
    },
    listingHeader: {
        display: 'flex',
        alignItems: 'center',
    },
    backButton: {
        borderRadius: 100,
        color: theme.palette.secondary.light,
        backgroundColor: theme.palette.primary.main,
        marginRight: theme.spacing(1),
        width: 24,
        height: 24,
        display: 'inline-flex',
        border: `1px solid ${theme.palette.primary.main}`,
        '&:hover': {
            color: theme.palette.primary.main,
        },
    },
}))
export interface ProductListingHeaderProps {
    backButton?: boolean
    listingHeader: string
    urlLocation?: string
    handleClick?: (url: string) => void
}
export const ProductListingHeader: React.FC<ProductListingHeaderProps> = ({
    backButton,
    listingHeader,
    urlLocation = '/products/all',
    handleClick,
}) => {
    const history = useHistory()
    const classes = useStyles()

    useEffect(() => {
        const currentState = history.location.state as any
        const fromParams = currentState?.from?.split('?')?.[1]
        const searchParams = new URLSearchParams(
            fromParams ?? history.location.search
        )
        if (
            !history.location.search &&
            currentState?.from.includes(urlLocation)
        )
            history.replace({
                ...history.location,
                search: searchParams.toString(),
            })
    }, [history])

    const handleIconClick = () => {
        const url = urlLocation + history.location.search ?? ''
        if (handleClick) {
            handleClick(url)
        } else {
            history.push(url)
        }
    }

    return (
        <GridContainer>
            <GridItem xs="auto" className={classes.listingHeader}>
                {backButton && (
                    <IconButton
                        data-testid="backButton"
                        className={classes.backButton}
                        size="small"
                        onClick={handleIconClick}
                    >
                        <ArrowBackIcon fontSize="small" />
                    </IconButton>
                )}
                <Typography variant="h1" className={classes.headerTitle}>
                    {listingHeader}
                </Typography>
            </GridItem>
        </GridContainer>
    )
}
