import Button from '@material-ui/core/Button'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import React from 'react'

const useStyles = makeStyles((theme: Theme) => ({
    filterButtonOpen: {
        backgroundColor: '#24CEAD',
        border: '1px solid #02A09C',
        borderRadius: 100,
        height: 38,
    },
    filterButton: {
        backgroundColor: '#FFFFFF',
        border: '1px solid #02A09C',
        borderRadius: 100,
        height: 38,
    },
    filterButtonTextOpen: {
        ...theme.typography.subtitle2,
        lineHeight: '16px',
        color: '#ffffff',
        textTransform: 'none',
    },
    filterButtonText: {
        ...theme.typography.body2,
        lineHeight: '16px',
        color: '#5E5F61',
        textTransform: 'none',
    },
    filterButtonSelected: {
        backgroundColor: '#D9F1F0',
        border: '2px solid #02A09C',
        borderRadius: 100,
        height: 38,
    },
    filterButtonTextSelected: {
        ...theme.typography.body2,
        lineHeight: '16px',
        color: '#202020',
        textTransform: 'none',
    },
}))
let categoryArr: string[] = [
    'isActive',
    'partnerships',
    'submissionStatus',
    'additionalContent',
    'isDeltekWriterDraft',
]
export interface MoreFilterButtonProps {
    isExpanded: boolean
    expandMoreFilters: (val: boolean) => void
    selectedItems?: Record<string, string[]> | undefined
    emptyInputSelection?: Record<string, Date | string | null> | undefined
    ref: any
}
export const MoreFilterButton = React.forwardRef<
    HTMLButtonElement,
    MoreFilterButtonProps
>(
    (
        { isExpanded, expandMoreFilters, selectedItems, emptyInputSelection },
        ref
    ) => {
        const classes = useStyles()
        const [
            isAnyFilterSelected,
            handleAnyFilterSelection,
        ] = React.useState<boolean>(false)

        React.useEffect(() => {
            if (selectedItems && emptyInputSelection) {
                let hasSelectedItems = false

                categoryArr = categoryArr.filter((cat) => cat !== 'isActive')

                hasSelectedItems = categoryArr.some(
                    (category) =>
                        selectedItems[category] &&
                        selectedItems[category].length > 0
                )

                if (!hasSelectedItems) {
                    hasSelectedItems = !Object.values(
                        emptyInputSelection
                    ).every((cat) => cat === null)
                }
                handleAnyFilterSelection(hasSelectedItems)
            } else {
                handleAnyFilterSelection(false)
            }
        }, [selectedItems, emptyInputSelection])

        const handleExpandFilters = (
            _event: React.MouseEvent<HTMLButtonElement>
        ) => {
            expandMoreFilters(!isExpanded)
        }

        return (
            <Button
                disableElevation
                className={
                    isExpanded
                        ? classes.filterButtonOpen
                        : isAnyFilterSelected
                        ? classes.filterButtonSelected
                        : classes.filterButton
                }
                variant="contained"
                onClick={handleExpandFilters}
                data-testid="more-filter-button"
                ref={ref}
            >
                <Typography
                    className={
                        isExpanded
                            ? classes.filterButtonTextOpen
                            : isAnyFilterSelected
                            ? classes.filterButtonTextSelected
                            : classes.filterButtonText
                    }
                >
                    More Filters
                </Typography>
            </Button>
        )
    }
)

export default MoreFilterButton
