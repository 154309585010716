import clsx from 'clsx'
import React from 'react'
import Truncate from 'react-truncate'
import HoverTooltip, { TooltipPlacements } from './HoverTooltip'

export interface TooltipEllipsisProps {
    /** Text to display */
    text?: string
    /** Class name of text */
    className?: string
    /** Max number of lines before adding ellipsis */
    lineLimit?: number
    /** Sets the 'smallest' element to be kept intact. If set to 'character',
     * the component will truncate text even mid-word.
     */
    tokenize?: 'characters' | 'words'
    /** Sets custom element to be appended instead of the deafault ellipsis. */
    ellipsis?: React.ReactNode | ((element: React.ReactNode) => React.ReactNode)

    tooltipPlacements?: TooltipPlacements[]

    /** Optional children component. If provided, this will be rendered
     * instead of the `text`.
     */
    children?: React.ReactNode

    width?: number
}

/**
 *  Custom tooltip with ellipsis text checking
 */
const TooltipEllipsis: React.FC<TooltipEllipsisProps> = ({
    text,
    className = '',
    lineLimit = 1,
    tooltipPlacements,
    tokenize = 'characters',
    ellipsis,
    children,
    width,
    ...rest
}: TooltipEllipsisProps) => {
    const _isMounted = React.useRef(true)
    const [active, setActive] = React.useState(false)

    React.useEffect(() => {
        return () => {
            _isMounted.current = false
        }
    }, [])

    if (!text && !children) {
        return null
    }

    const handleTruncate = (isTruncated: boolean) => {
        if (_isMounted.current === true) {
            if (active !== isTruncated) {
                setActive(isTruncated)
            }
        }
    }

    return (
        <HoverTooltip
            placements={tooltipPlacements ?? ['bottom', 'top']}
            text={active ? text : ''}
            className={clsx('tooltipEllipsis', className)}
            {...rest}
        >
            <div>
                <Truncate
                    width={width}
                    lines={lineLimit}
                    onTruncate={handleTruncate}
                    ellipsis={ellipsis}
                >
                    {children ? children : text}
                </Truncate>
            </div>
        </HoverTooltip>
    )
}

export default TooltipEllipsis
