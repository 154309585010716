import CircularProgress from '@material-ui/core/CircularProgress'
import Link from '@material-ui/core/Link'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import clsx from 'clsx'
import HoverTooltip from 'components/Feedbacks/HoverTooltip'
import { pxToRem } from 'components/Utilities/unitUtils'
import {
    CdnBaseUrl,
    navigateToExternalLink,
} from 'components/Utilities/urlUtils'
import React, { useCallback } from 'react'

const useStyles = makeStyles((theme: Theme) => ({
    iconBase: {
        backgroundColor: 'rgba(255, 255, 255, 0.95)',
        width: 20,
        height: 20,
        marginLeft: theme.spacing(0.4),
        marginRight: theme.spacing(0.4),
        borderRadius: 3,
        cursor: 'pointer',
    },
    icon: {
        display: 'block',
        marginTop: theme.spacing(0.25),
        marginLeft: 'auto',
        marginRight: 'auto',
        height: 16,
    },
    tooltipLink: {
        cursor: 'pointer',
        fontSize: pxToRem(13),
    },
    header: {
        borderBottom: '3px solid',
    },
    tooltipFont: {
        fontSize: pxToRem(13),
    },
    documentTooltipLink: {
        cursor: 'pointer',
        fontSize: pxToRem(13),
    },
}))

interface DocumentLink {
    name: string
    companyName?: string
    familyName?: string
    url: string
}

interface ProductListingCardDocumentItemProps {
    link: DocumentLink[]
    cardType:
        | 'ShortForm'
        | 'FullLength'
        | 'BIM'
        | 'Catalog'
        | 'Specification'
        | 'Swatchbox'
    executePSDQuery?: () => void
    loadingPSD?: boolean
}

const ProductListingCardDocumentItem: React.FC<ProductListingCardDocumentItemProps> = ({
    link,
    cardType,
    executePSDQuery,
    loadingPSD,
}) => {
    const classes = useStyles()

    const handleKeyDown = useCallback(
        (e) => {
            if (e.key === 'Enter' || e.code === 'Enter') {
                navigateToExternalLink(link[0].url)
            }
        },
        [navigateToExternalLink]
    )

    let hoverTextNode = <></>

    if (cardType === 'ShortForm' || cardType === 'FullLength') {
        hoverTextNode = (
            <Link
                data-testid={`linkDocument${cardType}`}
                onClick={() => navigateToExternalLink(link[0].url)}
                className={clsx(classes.documentTooltipLink)}
            >
                {cardType === 'ShortForm' ? 'Short Form' : 'Full Length'}
            </Link>
        )
    }

    if (cardType === 'Catalog') {
        hoverTextNode = (
            <>
                <Typography
                    className={clsx(classes.header, classes.tooltipFont)}
                >
                    Catalog - {link[0].companyName}
                </Typography>

                <Link
                    data-testid={`linkDocument${cardType}`}
                    onClick={() => navigateToExternalLink(link[0].url)}
                    className={clsx(classes.tooltipLink)}
                >
                    {link[0].name}
                </Link>
            </>
        )
    }

    if (cardType === 'BIM') {
        hoverTextNode = (
            <>
                <Typography
                    className={clsx(classes.header, classes.tooltipFont)}
                >
                    BIM - {link[0].companyName}
                </Typography>

                {link.map((each, index) => (
                    <React.Fragment key={index}>
                        <Link
                            data-testid={`linkDocument${index}`}
                            onClick={() => navigateToExternalLink(each.url)}
                            className={clsx(classes.tooltipLink)}
                        >
                            {`${
                                each.url.includes('.zip')
                                    ? 'Download:'
                                    : 'Visit:'
                            } ${each.name}`}
                        </Link>
                        <br />
                    </React.Fragment>
                ))}
            </>
        )
    }

    return cardType === 'Swatchbox' ? (
        <HoverTooltip text="Click to navigate to Swatchbox">
            <div
                className={clsx('dvDocument', classes.iconBase)}
                data-testid={`dvDocument${cardType}`}
            >
                <img
                    data-testid="imgDocument"
                    className={classes.icon}
                    src={`${CdnBaseUrl}/bpm/product_card_Swatchbox.png`}
                    onClick={() => navigateToExternalLink(link[0].url)}
                    onKeyDown={handleKeyDown}
                    alt={cardType}
                    role="button"
                    tabIndex={0}
                />
            </div>
        </HoverTooltip>
    ) : cardType !== 'Specification' ? (
        <HoverTooltip text={hoverTextNode}>
            <div
                className={clsx('dvDocument', classes.iconBase)}
                data-testid={`dvDocument${cardType}`}
            >
                <img
                    data-testid="imgDocument"
                    className={classes.icon}
                    src={`${CdnBaseUrl}/bpm/product_card_${cardType}.svg`}
                    alt={cardType}
                    role="button"
                    tabIndex={0}
                />
            </div>
        </HoverTooltip>
    ) : !loadingPSD ? (
        <HoverTooltip text="Click to generate product specification document">
            <div
                className={clsx('dvDocument', classes.iconBase)}
                data-testid={`dvDocument${cardType}`}
            >
                <img
                    data-testid="imgDocument"
                    className={classes.icon}
                    src={`${CdnBaseUrl}/bpm/product_card_FullLength.svg`}
                    onClick={executePSDQuery}
                    alt={cardType}
                    role="button"
                    tabIndex={0}
                />
            </div>
        </HoverTooltip>
    ) : (
        <CircularProgress size={24} />
    )
}

export default ProductListingCardDocumentItem
