import { Theme, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { ImageDimensionsInput } from 'components/Models/imageDimensionsInput'
import { useMemo } from 'react'

export interface UseCompanyBannerSizeResponse {
    isXs: boolean
    resizeDimensions: Partial<ImageDimensionsInput>[]
    computedDimension: Partial<ImageDimensionsInput>
    isGreaterThanXl: boolean
    theme: Theme
}

const useCompanyBannerSizeConstants = (): UseCompanyBannerSizeResponse => {
    const theme = useTheme()
    const isGreaterThanXl = useMediaQuery('(min-width: 1921px)') // not including 1920
    const isSm = useMediaQuery(theme.breakpoints.down('sm'))
    const isXs = useMediaQuery(theme.breakpoints.down('xs'))
    const resizeDimensions: Partial<ImageDimensionsInput>[] = useMemo(
        () => [
            { width: 1920, height: 200 },
            { width: 960, height: 100 },
        ],
        []
    )
    const computedDimension: Partial<ImageDimensionsInput> = useMemo(
        () => (isSm ? resizeDimensions[1] : resizeDimensions[0]),
        [isSm, resizeDimensions]
    )

    return {
        resizeDimensions,
        computedDimension,
        isGreaterThanXl,
        isXs,
        theme,
    }
}

export default useCompanyBannerSizeConstants
